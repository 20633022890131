import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ApiKeysComponent } from './api-keys.component';
import { MatTableModule } from '@angular/material/table';


@NgModule({
  declarations: [ApiKeysComponent],
  exports: [ApiKeysComponent],
  entryComponents: [ApiKeysComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatTableModule,
  ]
})
export class ApiKeysModule { }
