import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';
import { OrderStatus } from '@interfaces/dws/order-status';
import { Country } from '@interfaces/dws/country';

@Injectable({
  providedIn: 'root'
})
export class CrmOrdersService {
  constructor() { }

  list(filters: DWS.Order.RequestFilter, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.CrmOrder>> {
    return resource('crm://orders')
      .params({ page, count, ...filters })
      .get<DWS.Page<DWS.CrmOrder>>();
  }

  get(orderId: string): Observable<DWS.CrmOrder> {
    return resource('crm://orders/id')
      .params({ id: orderId })
      .get<DWS.CrmOrder>();
  }

  create(order: DWS.Order.New): Observable<DWS.CrmOrder> {
    return resource('crm://orders')
      .post<DWS.CrmOrder>(order);
  }

  update(orderId: any, order: DWS.Order.New): Observable<DWS.CrmOrder> {
    return resource('crm://orders/id')
      .params({ id: orderId })
      .put<DWS.CrmOrder>(order);
  }

  sendReceiptEmail(orderId: string): Observable<void> {
    return resource('crm://orders/id/send-receipt-email')
      .params({ id: orderId })
      .post<void>();
  }

  delete(orderId: string) {
    return resource('crm://orders/id')
    .params({ id: orderId })
    .delete();
  }

  orderExport(filters: CrmOrderFiltersExport, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.CrmOrder>> {
    return resource('crm://orders/export')
        .params({ ...filters, page, count })
        .get<DWS.Page<DWS.CrmOrder>>();
  }

  getOrdersCountries(wineryId: string): Observable<Country[]> {
    return resource('crm://orders/orders-countries')
        .params({wineryId})
        .get<Country[]>();
  }

  getOrdersStatuses(wineryId: string): Observable<OrderStatus[]> {
    return resource('crm://orders/orders-statuses')
        .params({wineryId})
        .get<OrderStatus[]>();
  }
}

export interface CrmOrderFilters {
  wineryId: string
  contactId?: number;
  wineryChannelId?: string;
}

export interface CrmOrderFiltersExport {
  wineryId: string;
  startDate?: string | Date;
  endDate?: string | Date;
  wineryChannelId?: string;
}
