import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthorizationInterceptor } from '@services/interceptors/authorization.interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CommonModule, registerLocaleData } from '@angular/common';

import locale_it from '@angular/common/locales/it';
import locale_en from '@angular/common/locales/en';
import locale_fr from '@angular/common/locales/fr';
import locale_de from '@angular/common/locales/de';
import locale_pt from '@angular/common/locales/pt';
import { locale } from '@util/locale/locale';
import { LangInterceptor } from '@services/interceptors/lang.interceptor';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpCacheInterceptorService } from '@services/interceptors/http-cache-interceptor.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { NgxMaskModule } from 'ngx-mask';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ExpiredAuthorizationInterceptor } from '@services/interceptors/expired-authorization.interceptor';
import { LetDirectiveModule } from '@web/app/directives/let/let.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LoadingModule } from '@web/app/components/loading/loading.module';
import { FeatureToggleModule } from '@web/app/modules/feature-toggle/feature-toggle.module';
import { MatCarouselModule } from '@ngbmodule/material-carousel';
import { environment } from '@env/environment';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MsalModule } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { QRCodeGenerateModule } from '@web/app/components/qr-code-generate/qr-code-generate.module';
import { QuillModule } from 'ngx-quill';
import { MatIconModule } from "@angular/material/icon";
import { DwsSocket } from '@web/app/services/dws-socket.service';
import { ManualReservationWebSocketService } from '@services/manual-reservation-ws.service';
import { WineryWineriesUtilService } from './modules/winery-wineries-util/winery-wineries-util.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatRadioModule } from '@angular/material/radio';
import { CommonMessagePopupModule } from './modules/common-message-popup/common-message-popup.module';
import { ReservationLogsModule } from './pages/admin/reservation-logs/reservation-logs.module';
import { PreviewHtmlModule } from './components/preview-html/preview-html.module';
import { InfoIconHoverableModule } from './components/info-icon-hoverable/info-icon-hoverable.module';
import { EventBusService } from './services/event-bus.service';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { ReservationDetailModule } from './components/reservation-detail/reservation-detail.module';
import { MailingTimingModule } from './components/dialogs/mailing-timing/mailing-timing.module';
import { DwsPermissionDirectiveModule } from '@util/directives/dwsPermission/dws-permission.module';
import { DashboardModule } from './pages/dashboard/dashboard.module';
import { IntegrationsModule } from './pages/integrations/integrations.module';
import {MatTabsModule} from '@angular/material/tabs';
import { ApiKeysModule } from './components/api-keys/api-keys.module';
import { WebhooksModule } from './components/webhooks/webhooks.module';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function getCurrentLang(): string {
  registerLocaleData(locale_it, 'it');
  registerLocaleData(locale_en, 'en');
  registerLocaleData(locale_fr, 'fr');
  registerLocaleData(locale_de, 'de');
  registerLocaleData(locale_pt, 'pt');
  return locale().locale;
}



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      defaultLanguage: 'it'
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    MatSnackBarModule,
    FlexLayoutModule,
    ReservationDetailModule,
    MatSidenavModule,
    MatButtonModule,
    MatToolbarModule,
    MatDialogModule,
    MatMenuModule,
    NgxMaskModule.forRoot(),
    MatTooltipModule,
    LetDirectiveModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    LoadingModule,
    FeatureToggleModule,
    MatCarouselModule.forRoot(),
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    GooglePlaceModule,
    QRCodeGenerateModule,
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: environment.values['outlook-client-id'],
        redirectUri: environment.values['outlook-redirect-uri']
      }
    }), {
      interactionType: InteractionType.Popup,
    }, {
      interactionType: InteractionType.Popup,
      protectedResourceMap: new Map()
    }),
    CommonModule,
    QuillModule.forRoot(),
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    CommonMessagePopupModule,
    ReservationLogsModule,
    PreviewHtmlModule,
    InfoIconHoverableModule,
    MatDividerModule,
    MatButtonToggleModule,
    DashboardModule,
    MailingTimingModule,
    DwsPermissionDirectiveModule,
    MatTabsModule,
    IntegrationsModule,
    ApiKeysModule,
    WebhooksModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthorizationInterceptor,
    multi: true
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: LangInterceptor,
    multi: true
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpCacheInterceptorService,
    multi: true
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: ExpiredAuthorizationInterceptor,
    multi: true
  }, {
    provide: LOCALE_ID,
    useFactory: (getCurrentLang),
    deps: [TranslateService]
  },
    DwsSocket,
    ManualReservationWebSocketService,
    WineryWineriesUtilService,
    EventBusService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}