import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {resource} from '@util/resource';
import {formatISO} from 'date-fns';
import {DWS} from '@interfaces/dws';
import {WineryScheduling} from "../../interfaces/dws/winery-scheduling";
import ExperiencesTitle = WineryScheduling.ExperiencesTitle;
import {ExportGift} from "../../interfaces/dws/gift";
import { Moment } from 'moment';

@Injectable({
  providedIn: 'root'
})
export class GiftsService {

  constructor() {
  }

  list(wineryId: string, filters?: GiftsFilters, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.Gift>> {
    const appliedFilters = {
      wineryId,
      ...filters
    }
    return resource('crm://gifts')
        .params(
            {page, count, ...appliedFilters}
        )
        .get<DWS.Page<DWS.Gift>>();
  }

  getExperiencesTitle(wineryId: string): Observable<ExperiencesTitle[]> {
    return resource('crm://gifts/experiences-title')
        .params({wineryId})
        .get<ExperiencesTitle[]>();
  }

  resendEmail(giftId: string, giftEmail: string, toEmail: string): Observable<unknown> {
    return resource('crm://gifts/resend')
        .post({giftId, giftEmail, toEmail})
  }
  
  update(giftId: string, beneficiaryEmail: string, notifyAt: string | Moment, beneficiaryFirstName: string, beneficiaryLastName: string): Observable<unknown> {
    return resource('v1://gifts/id/update')
        .params({giftId})
        .post({beneficiaryEmail, notifyAt, beneficiaryFirstName, beneficiaryLastName})
  }

  sendNow(giftId: string, toEmail: string): Observable<unknown> {
    return resource('v1://gifts/id/send_manual_email')
        .params({giftId})
        .post({toEmail})
  }

  getGiftById(giftId: string): Observable<DWS.Gift> {
    return resource('crm://gifts/id')
        .params({id: giftId})
        .get<DWS.Gift>();
  }

  giftsToExport(wineryId: string, startDate: Date, endDate: Date): Observable<ExportGift[]> {
    return resource('crm://gifts/export')
        .params({
          startDate: formatISO(startDate, {representation: 'date'}),
          endDate: formatISO(endDate, {representation: 'date'}),
          wineryId,
        })
        .get<ExportGift[]>()
  }

  getPDF(wineryId: string, giftId: string, langCode: string): Observable<Blob> {
    return resource('crm://gifts/create-voucher')
        .params({
          giftId,
          langCode,
          wineryId,
        })
        .get("blob")
  }

}

export interface GiftsFilters {
  giftId?: string;
  experienceId?: string;
  buyerName?: string;
  beneficiaryName?: string;
  excludeStates?: string[];
  createdAt?: string | Date;
  redemptionDate?: string | Date;
  expirationDate?: string | Date;
  redeemLink?: string;
}
