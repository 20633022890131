import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { ga } from "@util/ga";
import { ReservationDTO } from '@interfaces/dws/reservation';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit {
  reservationId?: string;
  reservationToClone?: ReservationDTO;
  byGift?: boolean;
  returnGift?: boolean;
  isTourOperator: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dlg: MatDialogRef<ReservationComponent>,
    private fb: FormBuilder
  ) {
    if (data.reservationId) { this.reservationId = data.reservationId };
    if (data.reservationToClone) { this.reservationToClone = data.reservationToClone as ReservationDTO };
    if (data.byGift) { this.byGift = data.byGift };
    if (data.isTourOperator) { this.isTourOperator = data.isTourOperator || false };

    dlg.disableClose = true;
  }

  ngOnInit(): void {
    ga.track(`Reservation ${this.reservationId}`);
  }

  closeClicked() {
    this.dlg.close();
  }

  returnToGift() {
    this.dlg.close({
      returnGift: true
    });
  }
}