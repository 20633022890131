import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';

@Injectable({
  providedIn: 'root'
})
export class ContactsLanguagesService {

  constructor() {
  }

  list(filters?: ContactLanguageFilters): Observable<DWS.Page<DWS.ContactLanguage>> {
    return resource('crm://contacts-languages')
      .params({ ...filters })
      .get<DWS.Page<DWS.ContactLanguage>>();
  }

  create(contactLanguage: DWS.ContactLanguage): Observable<DWS.ContactLanguage> {
    return resource('crm://contacts-languages')
      .post<DWS.ContactLanguage>(contactLanguage);
  }

  delete(contactId: number, languageId: number) {
    return resource('crm://contacts-languages/contactId/languageId')
      .params({contactId, languageId})
      .delete();
  }
}

export interface ContactLanguageFilters {
  contactId?: number;
}
