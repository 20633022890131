import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';
import { RoomsByWineryResolverService } from '../resolvers/rooms-by-winery-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class RoomsService {

  constructor() {
  }

  list(wineryId: string): Observable<DWS.Room[]> {
    return resource('crm://rooms')
        .params({wineryId})
        .get<DWS.Room[]>();
  }

  create(room: DWS.Room): Observable<DWS.Room[]> {
    return resource('crm://rooms')
    .post<DWS.Room[]>(room);
  }

  update(room: DWS.Room): Observable<DWS.Room> {
    return resource('crm://rooms')
    .put<DWS.Room>(room);
  }

  delete(roomId: string): Observable<void> {
    return resource('crm://rooms/id')
    .params({id: roomId})
    .delete();
  }

}
