import { Injectable } from '@angular/core';
import {
  CanLoad,
  Route,
  Router,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AccountsService } from "@services/accounts.service";
import { first, map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class OnlyProGuard implements CanLoad {
  constructor(private accounts: AccountsService, private router: Router) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const dwsPro$ = this.accounts.company$.pipe(first());
    return dwsPro$
      .pipe(map((company) => {
        if (company && company.dws_pro_enabled) return true;
        return this.router.parseUrl('/homepage');
      }));
  }
}
