import { environment } from '@env/environment';
import { Injector } from '@angular/core';
import { AccountsService } from "../../services/accounts.service";

declare let gtag: Function;

function track(pageTitle: string) {
  const injector: Injector = (window as any)['injector'];
  const accountsService: AccountsService = injector.get(AccountsService);
  accountsService.getLogin().then((login) => {
    gtag('config', environment.values['googleAnalyticsId'], {
      custom_map: {
        'dimension1': 'winery_id',
        'dimension2': 'winery_name'
      },
      page_title: pageTitle,
      winery_id: login!.company.id,
      winery_name: login!.company.name
    });
  });
}

const ga = { track }

export { ga, track };
