<div>
  <mat-card class="card-code" id="pdfTable" style="background: #fff;">
    <div class="wrapper" [ngStyle]="{'justify-content': !qrCode ? 'space-around' : 'flex-start'}">
      <div *ngIf="qrCode" class="winery-pic" [style.background-image]="'url(' + company?.avatar + ')'"></div>

<!--      <div>-->
<!--        <img [src]="company.avatar" width="200" alt="">-->
<!--      </div>-->

      <div>
        <canvas id="code"></canvas>
      </div>

      <div class="img-divinea">
        <img src="assets/images/logo-blue-horizontal.svg" alt="wine-suite">
      </div>
    </div>
  </mat-card>

  <div class="btn-pdf" data-html2canvas-ignore="true" [ngStyle]="{'justify-content': !qrCode ? 'center' : 'space-around'}">
    <div>
      <button *ngIf="showCopyButton" (click)="showMessage()" [cdkCopyToClipboard]="code" class="btn-copy" mat-raised-button>
        {{ 'Copy link' | translate }}
        <mat-icon>content_copy</mat-icon>
      </button>
    </div>
    <div>
      <button *ngIf="qrCode" class="import-btn" mat-flat-button (click)="downloadAsPDF()">
        {{'Download PDF' | translate }}
        <i class="icon-download"></i>
      </button>
    </div>
  </div>
</div>