<div mat-dialog-content>
    <mat-toolbar>
        <div>
            {{'Payment Link' | translate}}
        </div>
        <div class="actions">
            <button (click)="closeClicked()" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>

    <div class="body-wrapper">
        <div class="flex space-between">
            <div class="flex align-center bold">{{'Generated Link' | translate}}:</div>
            <div class="flex align-center">
                {{paymentLink.url | truncate : [35, '...']}}
                &nbsp;
                <button (click)="copyLinkToClipboard()" mat-icon-button>
                    <mat-icon>content_copy</mat-icon>
                </button>
            </div>
        </div>
        <br>
        <div>
            <div class="bold">{{'Payment Details' | translate}}:</div>

            <div class="flex space-between">
                <div class="flex align-center gray">{{'Customer Name' | translate}}:</div>
                <div class="flex align-center">{{customerName}}</div>
            </div>
            <div class="flex space-between">
                <div class="flex align-center gray">{{'Amount' | translate}}:</div>
                <div class="flex align-center">{{(paymentLink.amountCents || 0) / 100 | currency:
                    reservation.paymentCurrency}}
                </div>
            </div>
        </div>
        <br>
        <div class="flex space-between">
            <div class="flex align-center bold">{{'Status' | translate}}:</div>
            <div class="flex align-center">
                {{( isPaid
                    ? 'Paid Check'
                    : paymentLink.lastSentTo ? 'sent' : 'generated'
                ) | translate}}
                <span *ngIf="isPaid && paymentLink.expiresAt" class="gray">
                    &nbsp;
                    {{'on date' | translate}}:
                    {{paymentLink.expiresAt | date : 'dd/MM/yyyy'}}
                </span>
            </div>
        </div>
        <br>
        <div class="flex justify-end">
            <button
                    (click)="sendMenuOpen = !sendMenuOpen"
                    [mat-menu-trigger-for]="actions"
                    class="send-btn"
                    mat-flat-button
                    [disabled]="isPaid"
            >
                {{ 'Send' | translate }}
                <i [class.arrow-up]="sendMenuOpen" class="icon-arrow-down"></i>
            </button>
            <mat-menu #actions="matMenu" (close)="sendMenuOpen = false" class="send-menu">
                <button (click)="sendMailTo()" *ngIf="customerEmail" class="add-btn" mat-menu-item>
                    {{'Email' | translate}}
                </button>
                <button (click)="sendWhatsApp()" *ngIf="customerPhone" class="add-btn" mat-menu-item>
                    {{'Whatsapp' | translate}}
                </button>
            </mat-menu>
        </div>
    </div>
</div>