import { inject } from '@util/inject';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { tr } from '@util/tr/tr';

const toast = async (
  message: string|any, duration: number = 3000,
  position: 'top' | 'bottom' = 'bottom',
  close: string = ''
): Promise<MatSnackBarRef<SimpleSnackBar>> => {
  message = tr(message);
  const snackBar: MatSnackBar = await inject(MatSnackBar);
  return snackBar.open(message, close, {
    duration,
    verticalPosition: position
  });
};

const lazyToast = async (
  message: string|any, duration: number = 3000,
  position: 'top' | 'bottom' = 'bottom',
  close: string = ''
) => {
  message = tr(message);
  const snackBar: MatSnackBar = await inject(MatSnackBar);
  const b = snackBar.open(message, close, {
    duration: duration === 0? undefined : duration,
    verticalPosition: position
  });

  await b.afterDismissed().toPromise();
};

type ToastPosition =  'top' | 'bottom';

interface ToastBuilder {
  present(): Promise<MatSnackBarRef<SimpleSnackBar>>;

  message(): string;
  message(value: string): ToastBuilder;
  position(): ToastPosition;
  position(value: ToastPosition): ToastBuilder;
  duration(): number;
  duration(value: number): ToastBuilder;
  close(): string;
  close(value: string): ToastBuilder;
}

class ToastBuilderImplementation implements ToastBuilder {
  private data: {
    message: string;
    position: 'top' | 'bottom';
    close: string;
    duration: number;
  } = {
    message: '',
    position: 'bottom',
    close: '',
    duration: 0
  };

  present(): Promise<MatSnackBarRef<SimpleSnackBar>> {
    const data = this.data;
    return toast(data.message, data.duration, data.position, data.close);
  }

  message(): string;
  message(value: string): ToastBuilder;
  message(value?: string): string | ToastBuilder {
    if (value === undefined) return this.data.message;
    else {
      this.data.message = value;
      return this;
    }
  }

  close(): string;
  close(value: string): ToastBuilder;
  close(value?: string): string | ToastBuilder {
    if (value === undefined) return this.data.close;
    else {
      this.data.close = value;
      return this;
    }
  }

  duration(): number;
  duration(value: number): ToastBuilder;
  duration(value?: number): number | ToastBuilder {
    if (value === undefined) return this.data.duration;
    else {
      this.data.duration = value;
      return this;
    }
  }

  position(): ToastPosition;
  position(value: ToastPosition): ToastBuilder;
  position(value?: ToastPosition): ToastPosition | ToastBuilder {
    if (value === undefined) return this.data.position;
    else {
      this.data.position = value;
      return this;
    }
  }
}

const toasty = (message: string): ToastBuilder => {
  return new ToastBuilderImplementation().message(message);
};

export { toast, toasty, lazyToast };
