import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExperienceDWS } from "@interfaces/dws/experience-dws";
import { PaymentLink } from "@interfaces/dws/payment";
import { ReservationDTO } from "@interfaces/dws/reservation";
import { Host } from "@interfaces/host";
import { WSPaymentsService } from "@services/dws";
import { locale } from "@util/locale";
import { tr } from "@util/tr";
import { toast } from "@web/util/toast";

@Component({
  selector: 'web-payment-link-dialog',
  templateUrl: './payment-link-dialog.component.html',
  styleUrls: ['./payment-link-dialog.component.scss']
})
export class PaymentLinkDialogComponent {
  paymentLink!: PaymentLink;
  isPaid!: boolean;
  reservation!: ReservationDTO;
  experience!: ExperienceDWS;
  companyInt!: Host.Company;
  sendMenuOpen = false;

  get customerName() {
    return this.reservation?.reservationMasterContact?.name;
  }

  get customerEmail() {
    return this.reservation?.reservationMasterContact?.email;
  }

  get customerPhone() {
    return this.reservation?.reservationMasterContact?.phoneNumber;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dlg: MatDialogRef<PaymentLinkDialogComponent>,
    private wsPaymentsService: WSPaymentsService
  ) {
    this.reservation = data.reservation;
    this.experience = data.experience;
    this.companyInt = data.companyInt;
    this.paymentLink = data.paymentLink;
    this.isPaid = data.isPaid || false;
  }

  closeClicked() {
    this.dlg.close();
  }

  copyLinkToClipboard() {
    navigator.clipboard.writeText(this.paymentLink.url).then(() => {
      toast(tr('Copied to clipboard!'));
    });
  }

  sendWhatsApp() {
    var message = this.generateMessage();
    var phone = this.customerPhone
      ?.replace("+", "")
      .replace(" ", "");
    const url =
      "https://wa.me/" + phone + "/?text=" + encodeURIComponent(message);
    this.wsPaymentsService.markPaymentLinkAsSent(this.paymentLink.id, this.customerPhone!).subscribe(
      () => this.paymentLink.lastSentTo = phone,
      err => toast(tr('There was an error marking the payment link as sent'))
    )
    window.open(url, "_blank");
  }

  sendMailTo() {
    var message = this.generateMessage();
    var subject = this.generateSubject();
    var email = this.customerEmail;
    const url =
      "mailto:" +
      email +
      "?subject=" +
      encodeURIComponent(subject) +
      "&body=" +
      encodeURIComponent(message);
    this.wsPaymentsService.markPaymentLinkAsSent(this.paymentLink.id, email!).subscribe(
      () => this.paymentLink.lastSentTo = email,
      err => toast(tr('There was an error marking the payment link as sent'))
    )
    window.open(url, "_blank");
  }

  generateMessage(): string {
    var messageOrig = tr("Contact_USER_payment_link");
    var name = this.reservation?.reservationMasterContact?.name;
    var date = this.reservation?.date;
    const inputDate = new Date(date!);
    // Format the date in "dd-mm-yyyy" format
    const outputDateStr = `${inputDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(inputDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${inputDate.getFullYear()}`;
    var time = this.reservation?.time;
    var reservationId = this.reservation?.id;
    var cantina = this.companyInt?.name;
    var experience = this.experience?.details.titles.find(t => t.languageIsoCode.toLowerCase() == locale().locale)?.text || '';
    return this.formatString(
      messageOrig,
      name,
      outputDateStr,
      time,
      cantina,
      reservationId,
      experience,
      this.paymentLink.url,
      this.companyInt.company_email,
      this.companyInt.phone || tr('on our website')
    );
  }

  generateSubject(): string {
    var messageOrig = tr("Contact_USER_subj_payment_link");
    var name = this.reservation?.reservationMasterContact?.name;
    var reservationId = this.reservation?.id;
    var date = this.reservation?.date;
    const inputDate = new Date(date!);
    // Format the date in "dd-mm-yyyy" format
    const outputDateStr = `${inputDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(inputDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${inputDate.getFullYear()}`;
    var time = this.reservation?.time;
    var experience = this.experience?.details.titles.find(t => t.languageIsoCode.toLowerCase() == locale().locale)?.text || '';
    var cantina = this.companyInt?.name;
    return this.formatString(
      messageOrig,
      name,
      outputDateStr,
      time,
      cantina,
      reservationId,
      experience,
      this.paymentLink.url,
      this.companyInt.company_email,
      this.companyInt.phone
    );
  }

  formatString(template: string, ...values: any[]): string {
    return template.replace(/{(\d+)}/g, (match, index) => {
      const adjustedIndex = parseInt(index) - 1;
      const value = values[adjustedIndex];
      return value !== undefined ? value : match;
    });
  }
}