import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ComponentStatusRestoreService {
  private savedStatus: Map<string, any>;

  constructor() {
    this.savedStatus = new Map<string, any>();
  }

  set (componentName: string, status: any, clone: boolean = false): void {
    this.savedStatus.set(componentName, clone ? _.cloneDeep(status) : status);
  }

  get (componentName: string): any | undefined {
    return this.savedStatus.get(componentName) || undefined;
  }

  clear (componentName: string): void {
    this.savedStatus.delete(componentName);
  }
}
