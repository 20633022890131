<mat-card *ngIf="reservation && reservationMasterContact"
          class="singleDetailsCard animate__animated animate__slideInLeft animate__faster">
  <div class="singleDetailsToolbar">
    <mat-grid-list cols="10" rowHeight="100%" style="width: 100%;">
      <mat-grid-tile colspan="3">
        <div class="field-wrapperlt">
            <label class="singleDetailsName color-base-6" *ngIf="!!reservationMasterContact?.first_name || !!reservationMasterContact?.last_name">
              {{ (reservationMasterContact?.first_name || '') + ' ' + (reservationMasterContact?.last_name || '') }}
            </label>
            <label class="singleDetailsName color-base-6" *ngIf="!reservationMasterContact?.first_name && !reservationMasterContact?.last_name">
              {{ 'No Name' }}
            </label>
        </div>
      </mat-grid-tile>
      <mat-grid-tile colspan="2">
        <div class="field-wrapperlt">
          <img *ngIf="reservation.state === 'completed'" class="statusImagesIconReservation"
            src="assets/icons/status/completed.png" alt="completed" />
          <img *ngIf="reservation.state === 'confirmed'" class="statusImagesIconReservation"
            src="assets/icons/status/confirmed.png" alt="confirmed" />
            <img *ngIf="reservation.state === 'draft'" class="statusImagesIconReservation"
            src="assets/icons/status/draft.png" alt="draft" />
          <img *ngIf="reservation.state === 'waiting'" class="statusImagesIconReservation"
            src="assets/icons/status/waiting.png" alt="waiting" />
          <img *ngIf="reservation.state === 'revoked'" class="statusImagesIconReservation"
            src="assets/icons/status/revoked.png" alt="revoked" />
          <img *ngIf="reservation.state === 'rejected'" class="statusImagesIconReservation"
            src="assets/icons/status/rejected.png" alt="rejected" />
          <label class="singleDetailsStatus color-base-6">{{ capitalize(reservation.state || '') | translate }}</label>
        </div>
      </mat-grid-tile>
      <mat-grid-tile colspan="3">
        <div class="field-wrapperlt">
            <button (click)="onClickDetail(reservation)" mat-buttonclass="singleDetailsLinkDetails">{{ 'Aprire per
                maggior dettaglio' |
            translate}}</button>
            <div *ngIf="reservation.giftId" class="gift" style="transform: translateY(8px);">
                gift <i
                    class="icon-gift-box-open"></i>
            </div>
        </div>
      </mat-grid-tile>
        <mat-grid-tile colspan="2">
        <div class="field-wrapperlt">
          <mat-icon [ngClass]="{'green': isPaid(reservation)}">payments</mat-icon>
          <mat-icon [ngClass]="{'green': isSales(reservation)}">receipt_long</mat-icon>
            <mat-icon [ngClass]="{'green': isCheckedIn(reservation)}">location_on</mat-icon>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <div class="singleDetailsContent">
    <mat-grid-list class="vertical-divider-cols" cols="4" rowHeight="100%">
      <mat-grid-tile colspan="1" rowspan="1">
        <mat-grid-list cols="1" rowHeight="40px" style="width: 100%;">
          <!-- COLUMN 1-->
          <mat-grid-tile colspan="1" rowspan="1">
            <div class="field-wrapperlt">
              <mat-icon class="matIconForLabel">alternate_email</mat-icon>
                <label [title]="reservationMasterContact?.email"
                       class="wrapperltLabelforValue wrapperltLabelforValueXSmall maxWidthForEmail">{{reservationMasterContact?.email}}</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="1" rowspan="1">
              <div *ngIf="reservationMasterContact?.phone_number"
                   class="field-wrapperlt">
              <mat-icon class="matIconForLabel">phone_in_talk</mat-icon>
              <label
                      class="wrapperltLabelforValue wrapperltLabelforValueSmall">{{reservationMasterContact?.phone_number}}</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="1" rowspan="1">
              <div *ngIf="!!!!reservationMasterContact?.country_iso"
                   class="field-wrapperlt">
                  <img *ngIf="!!!!reservationMasterContact?.country_iso"
                       class="flag"
                       src="https://www.fotw.info/images/{{reservationMasterContact?.country_iso![0]}}/{{reservationMasterContact?.country_iso}}.gif"/>
              <label class="wrapperltLabelforValue wrapperltLabelforValueSmall"
                     *ngIf="!!!!reservationMasterContact?.country_iso">{{reservationMasterContact?.country_iso!
                | translate}}</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="1" rowspan="2">
            <div class="multiline">
              <div class="field-wrapperlt">
                <mat-icon class="matIconForLabel">schedule</mat-icon>
                <label class="wrapperltLabelforLabel">{{ 'Reservation timestamp' | translate }}:</label>
              </div>
              <div class="multilineRight">
                  <span class="wrapperltLabelforValue">{{displayDate(moment(reservation.createdAt).utc(true).toDate())}}</span>
              </div>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1" class="reservation-information">
        <mat-grid-list cols="1" rowHeight="40px" style="width: 100%;">
          <!-- COLUMN 2 -->
          <mat-grid-tile rowspan="1">
            <div class="field-wrapperlt">
                <img
                        alt="conversion"
                        class="imageIconForLabel"
                        src="assets/icons/icon-price-e-filled.png"
                />
                <label class="wrapperltLabelforLabel">{{
                (reservation
                    ? getLabel1Title(reservation)
                : ('Price 1' | translate))
                | truncate:[20, '...']
                }}: </label>
                <span class="wrapperltLabelforValue">{{reservation?.guestCount01}}</span>
            </div>
          </mat-grid-tile>
            <mat-grid-tile *ngFor="let priceLabel of reservationPriceLabelsExcludeFirstIfPresent">
            <div class="field-wrapperlt" *ngIf="priceLabel.quantity > 0">
                <img
                        alt="conversion"
                        class="imageIconForLabel"
                        src="assets/icons/icon-price-e-filled.png"
                />
                <label class="wrapperltLabelforLabel"> {{
                (priceLabel?.title_translations?.it | truncate:[20, '...'])
                }}: </label>
              <label class="wrapperltLabelforValue">{{priceLabel.quantity}}</label>
            </div>
          </mat-grid-tile>
            <mat-grid-tile *ngFor="let priceExtra of reservation?.reservationPriceExtras">
            <div class="field-wrapperlt" *ngIf="priceExtra.quantity > 0">
                <img
                        alt="conversion"
                        class="imageIconForLabel"
                        src="assets/icons/icon-price-e.png"
                />
                <label class="wrapperltLabelforLabel"> {{
                (priceExtra?.title_translations?.it | truncate:[20, '...'])
                }}: </label>
              <label class="wrapperltLabelforValue">{{priceExtra.quantity}}</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile rowspan="1" *ngFor="let deltaEmptys of deltaEmptySpaces">
          </mat-grid-tile>
        </mat-grid-list>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        <mat-grid-list cols="1" rowHeight="40px" style="width: 100%;">
          <!-- COLUMN 3-->
          <mat-grid-tile rowspan="2">
              <div *ngIf="reservation?.isTourOperator" class="field-wrapperlt">
                  <mat-icon class="matIconForLabel">location_on</mat-icon>
                  <label class="wrapperltLabelforLabel">{{'Origin' | translate}}: </label>
                  <label class="wrapperltLabelforValue wrapperltLabelforValueXSmall">
                      {{ 'Tour Operator' | translate }}
                  </label>
              </div>
              <div *ngIf="reservation?.origin && !reservation?.isTourOperator" class="field-wrapperlt">
              <mat-icon class="matIconForLabel">location_on</mat-icon>
              <label class="wrapperltLabelforLabel">{{'Origin' | translate}}: </label>
              <label class="wrapperltLabelforValue wrapperltLabelforValueXSmall">
                {{ getOrigin(reservation.origin) }}
              </label>
            </div>
              <div *ngIf="!reservation.origin && !reservation?.isTourOperator" class="field-wrapperlt">
              <mat-icon class="matIconForLabel">location_on</mat-icon>
              <label class="wrapperltLabelforLabel">{{'Origin' | translate}}: </label>
                <label class="wrapperltLabelforValue wrapperltLabelforValueXSmall">{{'Manual Reservation' |
                    translate}}</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="field-wrapperlt" *ngIf="reservation.roomId">
              <mat-icon class="matIconForLabel">sensor_door</mat-icon>
                <label class="wrapperltLabelforLabel">{{'Room' | translate}}: </label>
                <label class="wrapperltLabelforValue wrapperltLabelforValueXSmall">{{roomName(reservation.roomId) |
                    truncate:[30,
                    '...']}}</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile>
            <div class="field-wrapperlt" *ngIf="reservation.employeeId">
                <label class="wrapperltLabelforLabel">{{'Employee' | translate}}: </label>
                <label class="wrapperltLabelforValue wrapperltLabelforValueXSmall">{{employeeName(reservation.employeeId) |
                    truncate:[30, '...']}}</label>
            </div>
          </mat-grid-tile>
          <mat-grid-tile rowspan="1">
            <div class="field-wrapperlt">
              <mat-icon class="matIconForLabel">euro</mat-icon>
                <label class="wrapperltLabelforLabel">{{'Total' | translate}}: </label>
              <label class="wrapperltLabelforValue">{{formatTotal(reservation.netTotalCents || 0)}}</label>
            </div>
          </mat-grid-tile>

        </mat-grid-list>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        <mat-grid-list cols="1" rowHeight="30px" style="width: 100%;">
          <!-- ROW 4-->
            <mat-grid-tile rowspan="1">
            <label class="wrapperltLabelforLabel">{{'Message of Customer' | translate}}: </label>
          </mat-grid-tile>
          <mat-grid-tile rowspan="2">
            <div class="textAreaLT">{{reservation.message}}</div>
          </mat-grid-tile>
          <mat-grid-tile rowspan="1">
            <label class="wrapperltLabelforLabel">{{'Internal Note' | translate}}: </label>
          </mat-grid-tile>
          <mat-grid-tile rowspan="2">
            <div class="textAreaLT">{{reservation.notes}}</div>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</mat-card>