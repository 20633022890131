import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AccountsService } from '@services/accounts.service';

@Injectable()
export class ExpiredAuthorizationInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private accountsService: AccountsService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 && request.headers.get('Authorization')) {
              this.accountsService.logout();
              this.router.navigate(['/login'], { replaceUrl: true }).then();
            }
          }
          return throwError(err);
        })
      );
  }
}
