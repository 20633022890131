import { Injectable } from '@angular/core';
import { WineryWineries } from '@interfaces/dws/winery-wineries';
import { WineryInfoService } from '@services/dws/winery-info-service';

@Injectable({
  providedIn: 'platform',
})
export class WineryWineriesUtilService {

  public wineryWineries?: WineryWineries = undefined;
  wineryId: string;
  private isWineryWineriesFetched: boolean = false;

  constructor(private wineryInfoService: WineryInfoService) {
    var storageStr = localStorage.getItem('winery-saas:login');
    var obj = JSON.parse(storageStr!);
    this.wineryId = obj?.company.id;
    this.retriveWineryWineries();
  }

  getWineryWineries(): WineryWineries {
    return this.wineryWineries!;
  }

  retriveWineryWineries(forced: boolean = false) {
    if ((!this.isWineryWineriesFetched && this.wineryId) || forced) {
      this.wineryInfoService.getWineryWineries(this.wineryId).toPromise().then(res => { 
        this.wineryWineries = res;
        this.isWineryWineriesFetched = true;
      });
    }
  }

  hasWineryChilds(): boolean {
    var wineryWineries = this.getWineryWineries();
    if(!wineryWineries) return false;
    var wineryChilds = wineryWineries.wineryChilds;
    return wineryChilds && wineryChilds.length > 0;
  }

  isThisWinery(wineryId: string): boolean {
    var wineries = this.getWineryWineries();
    if(!wineries){
      return true;
    }
    return wineryId === wineries.wineryId || !!!wineryId;
  }
}
