import { Component, Input } from '@angular/core';
import {
  calendarInterval,
  CalendarSchedulingEvent,
  calendarStatus
} from '@services/dws/scheduling/calendar-event/calendar-scheduling-event';

import { capitalizeEachWord } from '../../../../../../../../util/transform-string/capitalize-each-word';
import { TranslateService } from '@ngx-translate/core';
import { formatLabelsFromEvent } from '../../../../../util/calendar-reservation';
import { CurrencyPipe, getCurrencySymbol } from '@angular/common';
import { VwReservation } from '@interfaces/dws/reservation';
import * as moment from 'moment';
import { Room } from '@interfaces/dws/room';
import { Employee } from '@interfaces/dws/employee';
import { locale } from '@util/locale';

@Component({
  selector: 'app-event-1hr',
  templateUrl: './event-1hr.component.html',
  styleUrls: ['../common.scss', './event-1hr.component.scss']
})
export class Event1hrComponent {
  private _meta: VwReservation = {} as any;
  public capitalizeEachWord = capitalizeEachWord;
  language = locale().locale;
  public formatLabelsFromEvent = formatLabelsFromEvent;
  cPipe: CurrencyPipe;
  public moment = moment;

  @Input() rooms: Room[] = [];
  @Input() employees: Employee[] = [];

  constructor(public translate: TranslateService,  private cpipe: CurrencyPipe) {
    this.cPipe = cpipe;
  }

  @Input() set meta(v: VwReservation) {
    this.status = calendarStatus(v);
    this._meta = v;
  }

  get meta() { return this._meta; }

  get currencySymbol() {
    return getCurrencySymbol(this._meta?.paymentCurrency || 'EUR', 'wide', undefined) || '' as string;
  }

  formatTotal(totalCents: number): string{
    let money = (totalCents/100);
    return this.cPipe.transform(money, this.currencySymbol, "", "1.0-0")!;
  }

  status: { cssClass: string; icon: string; text: string; } = {} as any;

  @Input() width!: number;
  @Input() height!: number;
  @Input() color: any;
  @Input() view: 'week' | 'day' | 'month' = 'week';

  interval(): number {
    return calendarInterval(this.meta as CalendarSchedulingEvent)
  }

  get contactFullName() {
    let contact = this.meta.reservationContacts?.find(contact => contact.contact_type === 'MASTER');
    if (!contact) return '';
    if (!contact.first_name && !contact.last_name) return "No name";
    return `${contact.first_name || ''} ${contact.last_name || ''}`;
  }

  roomName(roomId: string): string {
    return this.rooms.find(x => x.id === roomId)?.name || '';
  }

  employeeName(employeeId: string): string {
    const employee = this.employees.find(x => x.id === employeeId);
    if (!employee) return '';
    return (employee?.firstName + ' ' + employee?.lastName) || '';
  }

  getExperienceName(reservation: VwReservation): string {
    return reservation.experienceTranslations?.find(t => t.translation_id == 'TITLE')?._translations[this.language] || '';
  }
}
