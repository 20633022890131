import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { IntegrationSyncMetadata, IntegrationEntityTypes } from '@interfaces/integration-sync-metadata';
import { DWS } from '@interfaces/dws';

@Injectable({
  providedIn: 'root'
})
export class CashRegisterService {

  constructor() {}

  /**
   * Checks if the winery is integrated with the cash register.
   * @returns Observable<boolean> indicating integration status
   */
  isIntegrated(): Observable<boolean> {
    return resource('crm://cash-register/is-integrated')
      .get<boolean>();
  }

  /**
   * Fetches metadata for a specified object type and ID.
   * @param objType Type of the cash register object (e.g., ORDER, RESERVATION)
   * @param objId The ID of the specific object
   * @returns Observable<IntegrationSyncMetadata> containing metadata information
   */
  getMetadata( objType: IntegrationEntityTypes, objId: string): Observable<IntegrationSyncMetadata> {
    return resource('crm://cash-register/get-metadata')
    .params({ objType, objId })
      .get<IntegrationSyncMetadata>();
  }

  /**
   * Synchronizes a reservation with the cash register.
   * @param wineryId The ID of the winery
   * @param reservationId The ID of the reservation to be synchronized
   * @returns Observable<boolean> indicating success or failure of synchronization
   */
  syncReservation(wineryId: string, reservationId: string): Observable<boolean> {
    return resource('crm://cash-register/sync-reservation')
      .post<boolean>({ wineryId, objId: reservationId });
  }

  /**
   * Synchronizes an order with the cash register.
   * @param wineryId The ID of the winery
   * @param orderId The ID of the order to be synchronized
   * @returns Observable<boolean> indicating success or failure of synchronization
   */
  syncOrder(wineryId: string, orderId: string): Observable<boolean> {
    return resource('crm://cash-register/sync-order')
      .post<boolean>({ wineryId, objId: orderId });
  }

  /**
   * Synchronizes a preorder with the cash register.
   * @param wineryId The ID of the winery
   * @param preorderId The ID of the preorder to be synchronized
   * @returns Observable<boolean> indicating success or failure of synchronization
   */
  syncPreOrder(wineryId: string, preorderId: string): Observable<boolean> {
    return resource('crm://cash-register/sync-preorder')
      .post<boolean>({ wineryId, objId: preorderId });
  }

  /**
   * Synchronizes a gift with the cash register.
   * @param wineryId The ID of the winery
   * @param giftId The ID of the gift to be synchronized
   * @returns Observable<boolean> indicating success or failure of synchronization
   */
    syncGift(wineryId: string, giftId: string): Observable<boolean> {
      return resource('crm://cash-register/sync-gift')
        .post<boolean>({ wineryId, objId: giftId });
    }
}
