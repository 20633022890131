import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from 'util/resource';
import { formatISO } from 'date-fns';
import { map } from 'rxjs/operators';
import { ExperienceAvailabilityForDay, ExperienceAvailabilityOverride } from '@interfaces/experience-availability';

@Injectable({
  providedIn: 'root'
})

export class ExperienceAvailabilitiesService {
  public get(experienceId: string, period: { start: Date, end: Date }): Observable<ExperienceAvailabilityForDay[]> {
    const day_start = formatISO(period.start, { representation: 'date' });
    const day_end = formatISO(period.end, { representation: 'date' });
    return resource('v2://experiences/id/availabilities')
      .params({ id: experienceId, day_start, day_end })
      .get<{ data: ExperienceAvailabilityForDay[] }>()
      .pipe(map(response => response.data))
  }

  public override(experienceId: string, override: ExperienceAvailabilityOverride) {
    return resource('v2://host/experiences/id/availability-override')
      .params({ id: experienceId })
      .post(override);
  }
}
