import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { Host } from '@interfaces/host';
import { map, mergeMap } from 'rxjs/operators';
import CompanyClosing = Host.CompanyClosing;

@Injectable({
  providedIn: 'root'
})
export class HostCompanyService {
  constructor() { }
  get(): Observable<Host.Company> {
    return resource('v2://host/company')
      .get<Host.Company>()
  }
  getCompanyWithCompanyClosings(): Observable<Host.ReducedCompany> {
    return resource('v2://host/company/reduced')
        .get<Host.ReducedCompany>()
        .pipe(
          mergeMap((value: Host.ReducedCompany) => {
            return resource('crm://winery/wineryId/closing-days')
              .params({wineryId: value.id}
              ).get<CompanyClosing[]>().pipe(
                map(e => {
                  return {...value, company_closings: e}
                })
              );
          })
        );
  }
  
  update(company_attributes: any) {
    return resource('v2://host/company')
      .put({ data: company_attributes });
  }
  
  contract(): Observable<Blob> {
    return resource('v2://host/company/contract')
      .get('blob')
  }
  
  dwsProEnabled(): Observable<boolean> {
    return resource('v2://host/company/dws-pro').get().pipe(map((data: any) => data.dws_pro_enabled))
  }
  
  stripeLink(): Observable<string> {
    return resource('v2://host/company/stripe-connect-link')
      .get()
      .pipe(map((data: any) => data.url));
  }
  
  paypalLink(): Observable<string> {
    return resource('v2://host/company/paypal-partner-referral-link')
      .get()
      .pipe(map((data: any) => data.url));
  }
}
