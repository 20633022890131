import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';
import { feature } from '@web/app/modules/feature-toggle/feature-toggle.util';

@Pipe({
  name: 'feature'
})
export class FeatureTogglePipe implements PipeTransform {
  static transform(toggle: string, check: 'on' | 'off' | 'enabled' | 'disabled' = 'on'): boolean {
    const f = environment.features || {};
    const mustBeOn = check == 'on' || check == 'enabled' || check === undefined;
    if (mustBeOn) return feature.on(toggle)
    else return feature.off(toggle);
  }
  
  transform(toggle: string, check: 'on' | 'off' | 'enabled' | 'disabled' = 'on'): boolean {
    return FeatureTogglePipe.transform(toggle, check);
  }
}

@Pipe({
  name: 'on'
})
export class FeatureOnPipe implements PipeTransform {
  transform(toggle: string): any {
    return FeatureTogglePipe.transform(toggle, 'on');
  }
}

@Pipe({
  name: 'off'
})
export class FeatureOffPipe implements PipeTransform {
  transform(toggle: string): any {
    return FeatureTogglePipe.transform(toggle, 'off');
  }
}