import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservationPdfExporterComponent } from './reservation-pdf-exporter.component';
import { FeatureToggleModule } from '@web/app/modules/feature-toggle/feature-toggle.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CalendarModule } from 'angular-calendar';
import { FlexModule } from '@angular/flex-layout';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LetDirectiveModule } from '@web/app/directives/let/let.module';
import { MatMenuModule } from '@angular/material/menu';
import { LoadingModule } from '@web/app/components/loading/loading.module';
import { MatSortModule } from '@angular/material/sort';
import { TranslateDomainModule } from '@web/app/pipes/translate/translate-domain.module';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from "@angular/material/radio";
import { DurationModule } from "@pipes/duration/duration.module";
import { OriginModule } from "@web/app/pipes/origin/origin.module";
import { ReservationPdfExporterRoutingModule } from './reservation-pdf-exporter-routing.module';

@NgModule({
  declarations: [ReservationPdfExporterComponent],
  exports: [ReservationPdfExporterComponent],
  imports: [
    CommonModule,
    ReservationPdfExporterRoutingModule,
    MatToolbarModule,
    TranslateModule,
    MatDatepickerModule,
    CalendarModule,
    FlexModule,
    MatTableModule,
    MatSelectModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    LetDirectiveModule,
    MatMenuModule,
    LoadingModule,
    MatSortModule,
    TranslateDomainModule,
    MatIconModule,
    MatRadioModule,
    FeatureToggleModule,
    DurationModule,
    OriginModule
  ]
})
export class ReservationPdfExporterModule { }