import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';
import { EmployeesByWineryResolverService } from '../resolvers/employees-by-winery-resolver.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {

  constructor() {
  }

  list(wineryId: string): Observable<DWS.Employee[]> {
    return resource('crm://employees')
        .params({wineryId})
        .get<DWS.Employee[]>();
  }

  create(employee: DWS.Employee): Observable<DWS.Employee[]> {
    return resource('crm://employees')
        .post<DWS.Employee[]>(employee);
  }

  update(employee: DWS.Employee): Observable<DWS.Employee> {
    return resource('crm://employees')
        .put<DWS.Employee>(employee);
  }

  delete(employeeId: string): Observable<void> {
    return resource('crm://employees/id')
        .params({id: employeeId})
        .delete()
  }
}
