import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { resource } from '@util/resource';
import {
  addMonths,
  endOfDay,
  endOfMonth,
  format,
  formatISO,
  isAfter,
  isBefore,
  parseISO,
  startOfDay,
  startOfMonth,
  subMonths
} from 'date-fns';
import { Reservation } from '@interfaces/reservation';
import { calendarColor, CalendarSchedulingEvent } from './calendar-scheduling-event';
import { map, shareReplay } from 'rxjs/operators';
import { flatten } from 'lodash';

const getRange = (filters: SchedulingByDateRangeFilters): { start: Date, end: Date } => {
  let start, end;
  if (typeof filters?.startTime == 'string') {
    start = parseISO(filters.startTime);
  } else start = filters.startTime;
  if (typeof filters?.endTime === 'string') {
    end = parseISO(filters.endTime);
  } else end = filters.endTime;
  return {start, end};
};

type EventCacheForWinery = {
  [yyyMM: string]: Observable<CalendarSchedulingEvent[]>
}

@Injectable({
  providedIn: 'root'
})
export class SchedulingByDateRangeService {
  private cache: {
    [wineryId: string]: EventCacheForWinery
  } = {};

  private provider = new SchedulingByDateRangeProviderService();

  flush(wineryId: string) {
    this.cache[wineryId] = {};
  }

  list(wineryId: string, filters: SchedulingByDateRangeFilters): Observable<CalendarSchedulingEvent[]> {
    const {start, end} = getRange(filters);
    const cacheForWinery: EventCacheForWinery = this.cache[wineryId] || {};
    this.cache[wineryId] = cacheForWinery;
    const responses = [];
    const months = ((end.getUTCFullYear() - start.getUTCFullYear()) * 12) + end.getUTCMonth() - start.getUTCMonth() + 1;
    const download = (date: Date) => {
      const $ = this.provider
        .list(wineryId, {
          startTime: startOfMonth(date),
          endTime: endOfMonth(date),
          state: ['confirmed', 'waiting', 'rejected', 'completed', 'canceled', 'revoked']
        })
        .pipe(shareReplay(1));
      const sub = $.subscribe().add(() => sub.unsubscribe());
      return $;
    };

    const expand = () => {
      const before = subMonths(startOfMonth(start), 1),
        after = addMonths(endOfMonth(end), 1),
        beforeMonth = format(before, 'yyyy-MM'),
        afterMonth = format(after, 'yyyy-MM');
      if (!cacheForWinery[beforeMonth]) cacheForWinery[beforeMonth] = download(before);
      if (!cacheForWinery[afterMonth]) cacheForWinery[afterMonth] = download(after);
    };

    for (let i = 0; i < months; i++) {
      const current = addMonths(start, i);
      const requestedMonth = format(current, 'yyyy-MM');
      if (!cacheForWinery[requestedMonth]) cacheForWinery[requestedMonth] = download(current);
      responses.push(cacheForWinery[requestedMonth]);
    }
    expand();

    this.cache[wineryId] = cacheForWinery;
    return combineLatest(responses).pipe(
      map(data => flatten(data)),
      map((data: CalendarSchedulingEvent[]) => data.filter(ev => !ev.state || filters.state.includes(ev.state as any))),
      map(data => data.filter(ev => isBefore(ev.startAt, endOfDay(end)) && isAfter(ev.endAt, startOfDay(start))))
    );
  }
}

export class SchedulingByDateRangeProviderService {

  constructor() {}

  public list(wineryId: string, filters: SchedulingByDateRangeFilters): Observable<CalendarSchedulingEvent[]> {
    if (filters?.startTime instanceof Date) {
      filters.startTime = formatISO(filters?.startTime, {representation: 'date'});
    }
    if (filters?.endTime instanceof Date) {
      filters.endTime = formatISO(filters?.endTime, {representation: 'date'});
    }

    const appliedFilters = {
      wineryId,
      'dateRange.startOfRange': filters.startTime,
      'dateRange.endOfRange': filters.endTime,
      state: filters.state
    }
    return resource('crm://scheduling-old/date-range')
      .params(appliedFilters)
      .get<CalendarSchedulingEvent[]>()
      .pipe(
        map(e => e.map(item => {
          return {
            ...item,
            startAt: new Date(item.startAt),
            endAt: new Date(item.endAt),
            color: calendarColor(item.type, item.primaryColor, item.state)
          };
        }))
      );
  }
}

export interface SchedulingByDateRangeFilters {
  startTime: Date | string;
  endTime: Date | string;
  state: Reservation.State[];
}