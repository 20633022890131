import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { resource } from '@util/resource';
import { AvailabilityBlock, AvailabilityBlockResult } from "../../interfaces/dws/availability-block";

@Injectable({
    providedIn: 'root'
})
export class AvailabilityBlockService {

    constructor() {
    }

    create(ab: AvailabilityBlock): Observable<AvailabilityBlockResult[]> {
        return resource('crm://availability-block')
        .post<AvailabilityBlockResult[]>(ab);
    }

    remove(availabilityBlockId: string): Observable<unknown> {
        return resource('crm://availability-block/id')
            .params({id: availabilityBlockId})
            .delete();
    }
}
