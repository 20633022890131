<div class="add-client-wrapper" [formGroup]="form">
  <div class="add-client-fields-wrapper">

      <div *ngIf="!isTourOperator" class="field-combo">
      <div class="field-wrapper name-input">
        <label for="firstname">
            {{'Name' | translate}}
        </label>
          <input formControlName="firstName" id="firstname" matInput type="text">
      </div>

      <div class="field-wrapper surname-input">
        <label for="lastname">
            {{'Surname' | translate}}
        </label>
          <input formControlName="lastName" id="lastname" matInput type="text">
      </div>
    </div>

      <div *ngIf="isTourOperator" class="field-combo">
          <div class="field-wrapper name-input">
              <label for="firstname">
                  {{'Business name' | translate}}
              </label>
              <input formControlName="firstName" id="firstname" matInput style="width: 420px;" type="text">
          </div>
      </div>

    <div class="field-combo" style="display: flex; align-items: flex-end;">

      <div class="field-wrapper phone-input" style="margin-left: 0;">
        <label for="phone" class="label-countries">{{'International prefix' | translate}}</label>
        <input
          (input)="filterExperiences($event)"
          type="text"
          matInput
          formControlName="mainPhonePrefix"
          [matAutocomplete]="autocompletePrefix"
          />
        <mat-autocomplete #autocompletePrefix="matAutocomplete" [displayWith]="displayPhonePrefix">
          <mat-option *ngFor="let countryPrefix of filteredCountriesPrefixData; let i = index" [value]="countryPrefix.phonePrefix">
            <img
              class="flag"
              src="https://www.fotw.info/images/{{
                (countryPrefix.isoCode2.includes('US') ? 'US' : countryPrefix.isoCode2[0])[0]
              }}/{{
                countryPrefix.isoCode2.includes('US') ? 'US' : countryPrefix.isoCode2[0]
              }}.gif"
            />
            {{ displayPhonePrefix('', countryPrefix) }}
            <mat-divider style="background-color: #aaaaaa;" *ngIf="filteredCountriesPrefixData.length > 3 && i === 2 && !form.get('mainPhonePrefix')!.value"></mat-divider>
          </mat-option>
        </mat-autocomplete>
      </div>

      <div class="field-wrapper phone-input">
          <label for="phone">{{'Phone' | translate}}</label>
          <input formControlName="phoneNumber" mask="999999999999999999999999999999999999999999999" matInput type="tel"
               [prefix]="getPrefixWithPlus()" id="phone">
      </div>
    </div>

    <div class="field-combo">
      <div class="field-wrapper email-input">
        <label for="email">
            {{'Email' | translate}}
        </label>
        <input matInput id="email" type="text" formControlName="email" style="width: 420px;">
      </div>
    </div>

      <div class="field-combo">
          <div class="field-wrapper email-input">
              <label for="email">
                  {{'Country of Residence' | translate}}
              </label>
              <input
                (input)="filterCountries($event)"
                type="text"
                matInput
                formControlName="countryIso"
                [matAutocomplete]="autocompleteCountry"
                style="width: 420px;"
                />
              <mat-autocomplete #autocompleteCountry="matAutocomplete" style="border: none; box-shadow: none;" [displayWith]="displayCountry">
                <mat-option *ngFor="let country of filteredCountriesIsoAndNames; let i = index" [value]="country.iso">
                  <img
                    class="flag"
                    src="https://www.fotw.info/images/{{country.iso[0]}}/{{country.iso}}.gif"
                  />
                  {{ displayCountry('', country) }}
                  <mat-divider style="background-color: #aaaaaa;" *ngIf="filteredCountriesIsoAndNames.length > 3 && i === 2 && !form.get('countryIso')!.value"></mat-divider>
                </mat-option>
              </mat-autocomplete>
          </div>
      </div>

      <div class="field-combo">
          <div class="field-wrapper manual-marketing-input">
              <mat-checkbox [disabled]="form.get('email')?.invalid || !form.get('email')?.value"
                            formControlName="manualMarketing">{{'Manual marketing consent' | translate}}
              </mat-checkbox>
          </div>
      </div>

      <div *ngIf="isTourOperator" class="field-combo">
          <div class="field-wrapper manual-marketing-input">
              <mat-checkbox [checked]="true" [disabled]="true">{{'Tour Operator' | translate}}</mat-checkbox>
          </div>
      </div>
  </div>


  <div class="actions-wrapper">
    <button mat-stroked-button (click)="cancelClicked()">{{ 'Cancel' | translate }}</button>
    <button mat-flat-button [disabled]="!form.valid"
            (click)="saveClicked()">{{ (contactIsNew ? 'Add' : 'Edit') | translate }}
    </button>
  </div>
</div>