import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstupper'
})
export class FirstupperPipe implements PipeTransform {
  static transform(v: string): string {
    if (!v) return v;
    return v[0].toLocaleUpperCase() + v.substr(1)
  }

  transform(v: string): string {
    return FirstupperPipe.transform(v);
  }
}
