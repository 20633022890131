<mat-toolbar>
    <h1>{{'E-mail to the beneficiary' | translate}}</h1>

    <div class="actions">
        <button mat-icon-button (click)="closeClicked()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</mat-toolbar>

<div mat-dialog-content>
    <div class="email-detail-wrapper">

        <form class="field-wrapper" [formGroup]="form">
            <label for="email">
                {{'Email' | translate}}:
            </label>
            <input matInput id="email" type="email" formControlName="email">
        </form>

        <div class="actions-wrapper">
            <button mat-flat-button class="btn-cancel" (click)="closeClicked()">{{ 'Back' | translate }}</button>
            <button mat-flat-button class="btn-resend" (click)="sendClicked()">{{ 'Send' | translate }}</button>
        </div>

    </div>
</div>
