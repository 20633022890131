import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';

@Injectable({
  providedIn: 'root'
})
export class CountriesService {

  constructor() {}

  list(name?: string, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.Country>> {
    return resource('crm://countries')
      .params(
        { page, count, name }
      )
      .get<DWS.Page<DWS.Country>>();
  }

  get(countryId: number): Observable<DWS.Country> {
    return resource('crm://countries/id')
      .params({ id: countryId })
      .get<DWS.Country>();
  }
}
