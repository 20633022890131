import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { observe } from 'projects/web/src/util/loading/loading';
import { access } from '@util/route/access';
import { QRCodeDisplay } from '../../interfaces/dws/qr-code';
import { QrCodesService } from '../dws/qr-codes.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class QrcodeResolverService implements Resolve<QrCodeResolved> {
  constructor(private service: QrCodesService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<QrCodeResolved> {
    const wineryId = access(route).param('companyId');
    const qrCodeId = access(route).param('qrcodeId');
    if (!wineryId || !qrCodeId) return of({qrcode: null, error: null}) as any;
    return observe(this.service.get(qrCodeId, wineryId))
      .pipe(
        map((qrcode: QRCodeDisplay) => ({qrcode})),
        catchError(() => of({error: 'not-found'}))
      );
  }
}

export interface QrCodeResolved {
  qrcode?: QRCodeDisplay;
  error?: any;

}
