import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexModule } from '@angular/flex-layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxMaskModule } from 'ngx-mask';
import { DurationModule } from '@web/app/pipes/duration/duration.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { ConfirmModule } from '@web/app/components/dialogs/confirm';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LoadingModule } from '@web/app/components/loading/loading.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ArrayPipeModule } from '@pipes/array/array.module';
import { FeatureToggleModule } from 'projects/web/src/app/modules/feature-toggle/feature-toggle.module';
import { TranslateDomainModule } from '@web/app/pipes/translate/translate-domain.module';
import { TruncateModule } from '@pipes/truncate/truncate.module';
import { NewContactSidenavModule } from "@web/app/components/new-contact-sidenav/new-contact-sidenav.module";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { NgEventBus } from 'ng-event-bus';
import { OriginModule } from '@web/app/pipes/origin/origin.module';
import { ReservationPdfExporterModule } from '@web/app/components/reservation-pdf-exporter/reservation-pdf-exporter.module';
import { GroupedReservationsDetailComponent } from './grouped-reservations-detail.component';
import { GroupedCalendarSingleReservationComponent } from './grouped-calendar-single-reservation/grouped-calendar-single-reservation.component';

@NgModule({
  declarations: [GroupedReservationsDetailComponent, GroupedCalendarSingleReservationComponent],
  entryComponents: [GroupedReservationsDetailComponent, GroupedCalendarSingleReservationComponent],
  exports: [
    GroupedReservationsDetailComponent, GroupedCalendarSingleReservationComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    FlexModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    TranslateModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NgxMaskModule,
    DurationModule,
    MatCheckboxModule,
    MatSelectModule,
    ConfirmModule,
    MatAutocompleteModule,
    LoadingModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    ArrayPipeModule,
    FeatureToggleModule,
    TranslateDomainModule,
    TruncateModule,
    NewContactSidenavModule,
    MatSlideToggleModule,
    MatGridListModule,
    MatDividerModule,
    MatCardModule,
    ReservationPdfExporterModule,
    OriginModule
  ], providers: [
    CurrencyPipe,
    NgEventBus
  ]
})
export class GroupedReservationsDetailModule { }
