import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';

@Injectable({
  providedIn: 'root'
})
export class ContactReservationsService {

  constructor() {
  }

  list(filter?: ReservationsFilter, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.ContactReservation>> {
    return resource('crm://reservations')
      .params(
        { page, count, ...filter }
      )
      .get<DWS.Page<DWS.ContactReservation>>();
  }
}

export interface ReservationsFilter {
  wineryId: string;
  contactId: number|string|any;
}
