import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { resource } from '@util/resource';
import { Appointment } from '@interfaces/appointment';
import { formatISO } from 'date-fns';
import { pluck, tap } from 'rxjs/operators';
import { DWS } from "../interfaces/dws";

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {
  updated$: Subject<void> = new Subject<void>();

  constructor() { }

  list(wineryId: string, startTime: string|Date, endTime?: string|Date): Observable<Appointment[]> {
    if (startTime instanceof Date) {
      startTime = formatISO(startTime, { representation: 'date' });
    }
    if (endTime instanceof Date) {
      endTime = formatISO(endTime, { representation: 'date' });
    }

    return resource('crm://appointments')
      .params({
        page: 0,
        count: 9999999,
        wineryId,
        startTime,
        endTime
      })
      .get<DWS.Page<Appointment>>()
      .pipe(
          pluck('content'),
          tap(aps => aps.forEach(a => a.type = 'appointment'))
      )
  }

  create(appointment: Appointment): Observable<Appointment> {
    return resource('crm://appointments')
      .post<Appointment>(appointment)
      .pipe(tap(r => r.type = 'appointment'));
  }

  get(appointmentId: string): Observable<Appointment> {
    return resource('crm://appointments/id')
      .params({ id: appointmentId })
      .get<Appointment>()
      .pipe(tap(r => r.type = 'appointment'));
  }
  
  update(appointmentId: string, values: Appointment): Observable<Appointment> {
    return resource('crm://appointments/id')
      .params({ id: appointmentId })
      .put<Appointment>(values)
      .pipe(tap(r => r.type = 'appointment'));
  }
  
  remove(appointmentId: string): Observable<void> {
    return resource('crm://appointments/id')
      .params({ id: appointmentId })
      .delete()
  }
}
