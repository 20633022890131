import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';

@Injectable({
  providedIn: 'root'
})
export class PersonContactsService {

  constructor() {
  }

  list(wineryId: string, name?: string): Observable<DWS.Page<DWS.PersonContact>> {
    return resource('crm://person-contacts')
      .params({
        wineryId,
        name
      }).get<DWS.Page<DWS.PersonContact>>();
  }

  create(personContact: DWS.PersonContact): Observable<DWS.PersonContact> {
    return resource('crm://person-contacts')
      .post<DWS.PersonContact>(personContact);
  }

  get(contactId: any): Observable<DWS.PersonContact> {
    return resource('crm://person-contacts/id')
      .params({ id: contactId })
      .get<DWS.PersonContact>();
  }

  update(personId: number, personContact: DWS.PersonContact) {
    return resource('crm://person-contacts/id')
      .params({ id: personId })
      .put(personContact);
  }
}
