import { Component, OnInit, ContentChild, TemplateRef, AfterContentInit, Input, EventEmitter, Output } from '@angular/core';
import { Observable, observable, of } from 'rxjs';

@Component({
  selector: 'web-info-icon-hoverable',
  templateUrl: './info-icon-hoverable.component.html',
  styleUrls: ['./info-icon-hoverable.component.scss'],
})
export class InfoIconHoverableComponent {

  @Input() description: string = "";
  @Input() descriptionObservable: Observable<String> = of('');
  @Input() overrides: string = '';
  @Input() iconOverrides: string = '';

  @Input() showIcon: boolean = true;


  @Output() onMouseOver: EventEmitter<any> = new EventEmitter();

  infoVisibility = false;
  _internalDescription: string = '';

  constructor() { }

  showDescription(): string {
    if (!!!!this._internalDescription) {
      return this._internalDescription;
    }
    return '';
  }

  public onMouseOut(event: MouseEvent) {
    this.infoVisibility = false; // Show info
  }

  public onMouseOverInternal(event: MouseEvent) {
    try {
      if (!!!!this.description) {
        // If description is not a function (string), directly assign it
        this._internalDescription = this.description;
      } else {
        // If description is a function, execute it to retrieve data
        const data$ = this.descriptionObservable; // Assuming this function returns an Observable

        // Subscribe to the observable to retrieve data
        data$.subscribe((data: String) => {
          this._internalDescription = data.toString(); // Assign retrieved data to _internalDescription
        });

      }
    } catch (err) {

    }
    this.infoVisibility = true; // Show info
    this.onMouseOver.emit(event); // Emit event
  }
}