import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { observe } from '@web/util/loading/loading';
import { DWS } from '@interfaces/dws';
import { ProductsService } from 'services/dws/products.service';
import { route as r } from '@util/route';

@Injectable({
  providedIn: 'root'
})
export class ProductResolverService implements Resolve<DWS.Product> {
  constructor(private productsService: ProductsService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<DWS.Product> {
    const productId = r.access(route).param('productId');
    if (productId === 'new') return of(null) as any;
    if (productId === 'copy') {
      const product = this.router.getCurrentNavigation()?.extras.state?.product
      return !!product ? of(product) : of(null) as any;
    }
    const extras = this.router.getCurrentNavigation()?.extras;
    if (extras && extras.state && extras.state.product) return of(extras.state.product);
    return observe(this.productsService.get(productId));
  }
}
