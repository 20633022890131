import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor() {}

  create(address: DWS.Address): Observable<DWS.Address> {
    return resource('crm://addresses')
      .post<DWS.Address>(address);
  }

  list(filters?: AddressFilters, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.Address>> {
    return resource('crm://addresses')
      .params(
        { page, count, ...filters }
      )
      .get<DWS.Page<DWS.Address>>();
  }

  update(addressId: number, address: DWS.Address) {
    return resource('crm://addresses/id')
      .params({ id: addressId })
      .put(address);
  }

  getCities(): Observable<DWS.ObjectValue[]> {
    return resource('crm://addresses/cities').get();
  }

  getProvinces(): Observable<DWS.ObjectValue[]>  {
    return resource('crm://addresses/provinces').get();
  }
}

export interface AddressFilters {
  contactId?: number;
}
