import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeycountPipe } from 'pipes/keycount/keycount.pipe';

@NgModule({
  declarations: [KeycountPipe],
  exports: [KeycountPipe],
  imports: [
    CommonModule
  ]
})
export class KeycountPipeModule {}
