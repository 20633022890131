import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { route as r } from '@util/route';
import { observe } from '@web/util/loading/loading';
import { DWS } from '@interfaces/dws';
import { TemplateHtmlService } from '@services/dws/template-html.service';
import { from } from 'rxjs';
import { AccountsService } from '@services/accounts.service';
import { first, map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TemplateHtmlResolverService implements Resolve<DWS.TemplateHtml> {
    constructor(private service: TemplateHtmlService, private accounts: AccountsService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DWS.TemplateHtml> {
        const transactionalEmailTypeFull = r.access(route).param('templateHtmlType');
        const langCode = transactionalEmailTypeFull.split('-')[transactionalEmailTypeFull.split('-').length-1];
        const transactionalEmailType = transactionalEmailTypeFull.substring(0, transactionalEmailTypeFull.length-3);
        const args$ = from(this.accounts.getLogin()).pipe(map(login => login!.company!.id));
        const obs$ = args$.pipe(first(), switchMap(args => this.service.get(transactionalEmailType, args, langCode)));
        return observe(obs$);
    }
}
