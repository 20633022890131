import { Winery } from '@interfaces/dws/winery';
import { CampaignEditor } from 'interfaces/dws/campaign-editor';
import { Audience } from 'interfaces/dws/audience';
import { TagCrm } from 'interfaces/dws/tag';
import { Contact } from 'interfaces/dws/contact';
import { Segment } from 'interfaces/dws/segment';
import { AutomationType } from './automation-type';

export namespace Campaign {

  export enum StatusEnum {
    scheduled = 'scheduled',
    send = 'send',
    draft = 'draft'
  }

  export interface Status {
    id: string;
    nameEn?: string;
    nameIt?: string;
  }

  export interface Scheduled {
    date?: string;
    time?: string;
    status: string;
    automationType?: AutomationType;
  }

  export interface Data {
    campaignTarget: string;
    campaignStatus: Status;
    createdAt: string;
    subject: string;
    id: number;
    name: string;
    scheduledDate?: string;
    scheduledTime?: string;
    scheduledTimezone?: string;
    automationType?: AutomationType;
    active?: boolean;
    targetTypology?: string;
    send: number | null;
    subscribeType: string;
    template: CampaignEditor;
    updatedAt: string;
    sentAt: string;
    processedAt?: string;
    winery: Winery;
    senderEmail?: string;
    testEmail?: string;
    openedPercentage?: number;
    qtSend?: number;
    inputValue: number;
    includeSubscribed?: boolean;
    includeUnsubscribed?: boolean;
    blocklist?: number[];
    senderName?: string;
    replyTo?: string;
    isArchived?: boolean;
    scheduledDateTime: string;

    delivered?: number;
    processed?: number;
    opens?: number;
    clicks?: number;
    bounces?: number;
    unsubscribes?: number;
  }

  export interface DataTemplate {
    id?: number;
    name: string;
    template: CampaignEditor;
    winery: Winery;
  }

  export interface DataTarget {
    audience?: Audience;
    tag?: TagCrm;
    segment?: Segment;
    campaign?: Campaign;
    qtyContactsRemoved?: number;
  }

  export interface DataContact {
    contact: Contact;
    campaign?: Campaign;
    isManualSelected: boolean;
  }

  export interface DataContactCampaign {
    id: number;
    name: string;
    email: string;
    opened: string;
    readAt: string;
  }

  export interface DataOrigin {
    campaignId: number;
    originId: number;
    qrcodePresenceName: string;
  }
}

export type Campaign = Campaign.Data;
export type CampaignTemplate = Campaign.DataTemplate;
export type CampaignTarget = Campaign.DataTarget;
export type CampaignOrigin = Campaign.DataOrigin;
export type CampaignContact = Campaign.DataContact;
export type CampaignScheduled = Campaign.Scheduled;
export type ContactCampaign = Campaign.DataContactCampaign;
