<!--suppress AngularInvalidExpressionResultType -->
<mwl-calendar-week-view *ngIf="view == 'week'" (beforeViewRender)="calendarPeriodChanged($event.period)"
  [events]="events" [viewDate]="date" [eventTemplate]="weekEventTemplate" [headerTemplate]="weekHeaderTemplate"
  [hourSegments]="2" precision="minutes" (eventClicked)="eventClicked($event.event)"
  [dayStartHour]="calendarConfiguration?.startTime" [dayEndHour]="calendarConfiguration?.endTime" [weekStartsOn]="1"
  [hourSegmentHeight]="50" [className]="'calendar-week-view'">

</mwl-calendar-week-view>
<mwl-calendar-month-view *ngIf="view == 'month'" (beforeViewRender)="calendarPeriodChanged($event.period)"
  [events]="events" [viewDate]="date" [weekStartsOn]="1" [headerTemplate]="monthViewHeaderTemplate"
  [className]="'calendar-month-view'" (eventClicked)="eventClicked($event.event)" [activeDayIsOpen]="activeDayIsOpen"
  (dayClicked)="dayClicked($event.day)" [openDayEventsTemplate]="monthViewOpenDayTemplate" [activeDay]="activeDay"
  [cellTemplate]="customCellTemplate">

</mwl-calendar-month-view>
<mwl-calendar-week-view *ngIf="view == 'day'" (beforeViewRender)="calendarPeriodChanged($event.period)"
  [events]="events" [viewDate]="date" [dayStartHour]="calendarConfiguration?.startTime"
  [dayEndHour]="calendarConfiguration?.endTime" [hourSegmentHeight]="80" [headerTemplate]="weekHeaderTemplate"
  [eventTemplate]="weekEventTemplate" [hourSegments]="1" [daysInWeek]="1" [className]="'calendar-day-view'"
  (eventClicked)="eventClicked($event.event)">

</mwl-calendar-week-view>

<!-- header templates -->
<ng-template #weekHeaderTemplate let-days="days" let-locale="locale" let-dayHeaderClicked="dayHeaderClicked"
  let-eventDropped="eventDropped" let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate" let-dragEnter="dragEnter">
  <div class="cal-day-headers" role="row">
    <div class="cal-header" *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate" [class.cal-past]="day.isPast"
      [class.cal-today]="day.isToday" [class.cal-future]="day.isFuture" [class.cal-weekend]="day.isWeekend"
      [ngClass]="day.cssClass" (mwlClick)="dayHeaderClicked.emit({ day: day, sourceEvent: $event })" mwlDroppable
      dragOverClass="cal-drag-over" (drop)="
            eventDropped.emit({
              event: $event.dropData.event,
              newStart: day.date
            })
          " (dragEnter)="dragEnter.emit({ date: day.date })" tabindex="0" role="columnheader">
      <span *ngIf="days.length > 1">{{ day.date | date:'EEE'}} {{day.date | date:'dd'}}</span>
      <strong *ngIf="days.length == 1">{{ day.date | date:'EEEE' }}</strong>
    </div>
  </div>
</ng-template>


<ng-template #monthViewHeaderTemplate let-days="days" let-locale="locale"
  let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate">
  <div class="cal-cell-row cal-header" role="row">
    <div class="cal-cell" *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate" [class.cal-past]="day.isPast"
      [class.cal-today]="day.isToday" [class.cal-future]="day.isFuture" [class.cal-weekend]="day.isWeekend"
      [ngClass]="day.cssClass" tabindex="0" role="columnheader">
      {{ day.date | calendarDate: 'monthViewColumnHeader':locale }}
    </div>
  </div>
</ng-template>


<ng-template #monthViewOpenDayTemplate let-events="events" let-eventClicked="eventClicked" let-isOpen="isOpen"
  let-trackByEventId="trackByEventId" let-validateDrag="validateDrag">
  <div class="cal-open-day-events" [@collapse] *ngIf="isOpen" role="application">
    <span tabindex="-1" role="alert" [attr.aria-label]="
            { date: date, locale: locale } | calendarA11y: 'openDayEventsAlert'
          "></span>
    <span tabindex="0" role="landmark" [attr.aria-label]="
            { date: date, locale: locale }
              | calendarA11y: 'openDayEventsLandmark'
          "></span>
    <button mat-icon-button class="close-button" (click)="activeDayIsOpen = false"><mat-icon>close</mat-icon></button>
    <div *ngFor="let event of events; trackBy: trackByEventId" [ngClass]="event?.cssClass" mwlDraggable
      [class.cal-draggable]="event.draggable" dragActiveClass="cal-drag-active" [dropData]="{ event: event }"
      [dragAxis]="{ x: event.draggable, y: event.draggable }" [validateDrag]="validateDrag"
      [touchStartLongPress]="{ delay: 300, delta: 30 }">
      <span class="cal-event" [ngStyle]="{ backgroundColor: event.color?.primary }">
      </span>

        <span *ngIf="event.meta.time"><strong>
          {{ event.meta.time }} - </strong></span>

        <mwl-calendar-event-title (mwlClick)="eventClicked.emit({ event: event, sourceEvent: $event })" (mwlKeydownEnter)="eventClicked.emit({ event: event, sourceEvent: $event })"
                                  [attr.aria-label]="{ event: event, locale: locale } | calendarA11y: 'eventDescription'"
                                  [event]="event"
                                  tabindex="0"
                                  view="month">
      </mwl-calendar-event-title>
      <ng-container *ngIf="event.meta.type !== 'appointment'">
          <span *ngIf="event.meta.optionalData?.calendarTileType === 'grouped'"><strong> | {{ ('Multiple Reservations' | translate) + ": " + event.meta.optionalData?.childEvents.length }} | </strong></span>
          <span *ngIf="reservationMasterContact(event) as contact"><strong>
           | {{ capitalizeEachWord(contact.first_name + " " + contact.last_name)}} | 
        </strong></span>

          <span *ngIf="event.meta.languageIso">{{ ('lang_' + event.meta.languageIso) | translate }} | </span>

        <span>{{ formatTotal(event.meta.netTotalCents, event.meta.paymentCurrency) }}</span>

        <span
                *ngIf="event.meta.guestCount01 || event.meta.reservationPriceLabels?.length > 0 || event.reservationPriceExtras?.length > 0"> | {{
          formatLabelsFromEvent(event.meta, translate.getDefaultLang(), ", ") }}</span>

          <span *ngIf="event.meta.roomId"> | {{ capitalizeEachWord(roomName(event.meta.roomId)) }}</span>

          <span *ngIf="event.meta.employeeId"> | {{ capitalizeEachWord(employeeName(event.meta.employeeId)) }}</span>
      </ng-container>
      <ng-container *ngIf="event.meta.type === 'appointment'">
        <span> | {{  "Appointment" | translate }} </span>
      </ng-container>
        <span *ngIf="calendarStatus(event.meta).text && event.meta.optionalData?.calendarTileType !== 'grouped' && event.meta.type !== 'appointment'"> | {{ calendarStatus(event.meta).text.toUpperCase()}}</span>

      <mwl-calendar-event-actions [event]="event">
      </mwl-calendar-event-actions>
    </div>
  </div>
</ng-template>


<!-- event templates -->
<ng-template #weekEventTemplate let-event="weekEvent" let-tooltipPlacement="tooltipPlacement"
  let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate" let-tooltipAppendToBody="tooltipAppendToBody"
             let-column="column" let-daysInWeek="daysInWeek" let-tooltipDelay="tooltipDelay"
             let-tooltipDisabled="tooltipDisabled">
  <div class="cal-event" [ngStyle]="{
          'z-index': event.event.zIndex,
          backgroundColor: event.event.color?.secondary,
          borderColor: event.event.color?.primary
      }" [mwlCalendarTooltip]="
          !tooltipDisabled
            ? (tooltipText(event.event.meta))
            : ''
        " [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="event.tempEvent || event.event"
    [tooltipTemplate]="tooltipTemplate" [tooltipAppendToBody]="tooltipAppendToBody" [tooltipDelay]="tooltipDelay"
    (mwlClick)="eventClicked.emit({ sourceEvent: $event })"
    (mwlKeydownEnter)="eventClicked.emit({ sourceEvent: $event })" tabindex="0" role="application" [attr.aria-label]="
          { event: event.tempEvent || event.event, locale: locale }
            | calendarA11y: 'eventDescription'
        ">
    <mwl-calendar-event-actions [event]="event.tempEvent || event.event">
    </mwl-calendar-event-actions>

    <ng-container *let="event.event.meta as meta">
      <ng-container *ngIf="meta.type !== 'appointment'">
          <app-grouped-events *ngIf="meta.optionalData.calendarTileType === 'grouped'; else elseBlock"
                              [color]="event.event.color?.primary" [employees]="employees"
                              [meta]="meta"
                              [rooms]="rooms" [smallEvent]="interval(event) < 60*60" [view]="view"
                              [width]="event.width"></app-grouped-events>
        <ng-template #elseBlock>
          <app-event-30m *ngIf="interval(event) < 60*60" [meta]="meta" [width]="event.width"
                         [color]="event.event.color?.primary" [employees]="employees" [rooms]="rooms"
                         [view]="view"></app-event-30m>
          <app-event-1hr *ngIf="interval(event) >= 60*60" [meta]="meta" [width]="event.width"
                         [color]="event.event.color?.primary" [employees]="employees" [rooms]="rooms"
                         [view]="view"></app-event-1hr>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="meta.type === 'appointment'">
          <app-event-appointment [color]="event.event.color?.primary" [meta]="meta" [width]="event.width"
                                 style="overflow: hidden!important;"
          [view]="view"></app-event-appointment>
      </ng-container>
    </ng-container>

  </div>
</ng-template>

<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div class="cal-cell-top">
    <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
    <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
  </div>
</ng-template>