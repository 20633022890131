import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OriginPipe } from './origin.pipe';



@NgModule({
  declarations: [OriginPipe],
  exports: [OriginPipe],
  imports: [
    CommonModule
  ]
})
export class OriginModule { }
