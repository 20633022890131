import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'keycount'
})
export class KeycountPipe implements PipeTransform {
  constructor() {
  }

  static transform(value: any, keyExcluded?: string): any {
    return Object.keys(value || {}).reduce((count, key) => {
      if (keyExcluded && key === keyExcluded) return 0;
      return count + (value[key] ? 1 : 0);
    }, 0);
  }

   transform(value: any, keyExcluded?: string): any {
    return KeycountPipe.transform(value, keyExcluded)
  }
}
