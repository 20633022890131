/**
 * ```
 * import { access } from '@util/route'
 * constructor(route: Activatedroute) {
 *   console.log(access(route).param('id'))
 * }
 */
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

class RouteAccessor {
  snapshot: ActivatedRouteSnapshot;

  constructor(route: ActivatedRouteSnapshot | ActivatedRoute) {
    if (route instanceof ActivatedRoute) {
      this.snapshot = route.snapshot;
    } else {
      this.snapshot = route;
    }
  }

  traverse(access: (snapshot: ActivatedRouteSnapshot) => any): any {
    for (let snapshot = this.snapshot; snapshot; snapshot = snapshot.parent!) {
      const data = access(snapshot);
      if (data) {
        return data;
      }
    }
    return undefined;
  }

  param(key: string) { return this.traverse(snapshot => snapshot.params[key]); }
  data(key: string) { return this.traverse(snapshot => snapshot.data[key]); }
  query(key: string) { return this.traverse(snapshot => snapshot.queryParams[key]); }
}

const accessfn = (route: ActivatedRouteSnapshot | ActivatedRoute): RouteAccessor => {
  return new RouteAccessor(route);
};

export { accessfn as access };
