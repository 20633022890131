import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { route as r } from '@util/route';
import { observe } from '@web/util/loading/loading';
import { DWS } from '@interfaces/dws';
import { TransactionalEmailsService } from '@services/dws/transactional-emails.service';
import { from } from 'rxjs';
import { AccountsService } from '@services/accounts.service';
import { first, map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TransactionalEmailResolverService implements Resolve<DWS.TransactionalEmail> {
    constructor(private service: TransactionalEmailsService, private accounts: AccountsService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DWS.TransactionalEmail> {
        const transactionalEmailId = r.access(route).param('transactionalEmailId');
        const args$ = from(this.accounts.getLogin()).pipe(map(login => login!.company!.id));
        const obs$ = args$.pipe(first(), switchMap(args => this.service.getById(transactionalEmailId, args)));
        return observe(obs$);
    }
}
