import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  private eventBus: Subject<any> = new Subject<any>();

  constructor(){
    console.info('created WS eventBus');
    this.eventBus.subscribe(event => console.debug('eventBus', event));
  }

  publish(event: EventBusEvent, eventName?: string): void {
    this.eventBus.next(event);
  }

  getEventBus(): Subject<any> {
    return this.eventBus;
  }

  subscribe(eventName: string): Observable<EventBusEvent> {
    return this.eventBus.asObservable().pipe(
      filter((event: EventBusEvent) => event.name === eventName)
    );
  }
}

export interface EventBusEvent{
  id: string;
  data: any;
  name: string;
  date?: string;
}