import { Conversor } from '@util/convert/conversor';

export class BlobConverter implements Conversor<Blob> {
  private readonly input: Blob;

  constructor(input: Blob) {
    this.input = input;
  }

  to(type: 'base64'): Promise<string>;
  to(type: 'arraybuffer'): Promise<ArrayBuffer>;
  to(type: 'blob'): Promise<Blob>;
  to(type: 'base64' | 'arraybuffer' | 'blob'): Promise<string> | Promise<ArrayBuffer> | Promise<Blob> | Promise<null> {
    switch (type) {
      case 'base64': return this.toBase64();
      case 'arraybuffer': return this.toArrayBuffer();
      default: return Promise.resolve(null);
    }
  }

  private toBase64() {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataUrl = reader.result as string, base64 = dataUrl.split(',')[1];
        resolve(base64);
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsDataURL(this.input);
    });
  }

  private toArrayBuffer() {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as ArrayBuffer);
      };
      reader.onerror = () => {
        reject(reader.error);
      };
      reader.readAsArrayBuffer(this.input);
    });
  }
}
