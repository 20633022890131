import { Injectable } from '@angular/core';
import { resource } from '@util/resource';
import { Observable } from 'rxjs';
import { DWS } from '@interfaces/dws';
import { HttpCacheService } from '@services/http-cache.service';

@Injectable({
  providedIn: 'root'
})
export class DWSSegmentsService {
  constructor(private cache: HttpCacheService) {
  }

  create(segment: DWS.Segment): Observable<DWS.Segment> {
    return resource('crm://segments')
      .post<DWS.Segment>(segment)
  }

  update(id: number, segment: DWS.Segment): Observable<DWS.Segment> {
    return resource('crm://segments/id')
      .params({ id })
      .put<DWS.Segment>(segment)
  }

  list(filter?: SegmentsFilter, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.Segment>> {
    return resource('crm://segments')
      .params(
        { page, count, ...filter }
      )
      .get<DWS.Page<DWS.Segment>>();
  }

  get(id: number): Observable<DWS.Segment> {
    return resource('crm://segments/id')
      .params(
        { id }
      )
      .get<DWS.Segment>();
  }

  delete(id: number): Observable<void> {
    return resource('crm://segments/id')
      .params({ id })
      .delete()
  }
}

export interface SegmentsFilter {
  wineryIds: string[];
}
