<br/>
<mat-paginator
    [ngStyle]="{'display': ((dataSource.elementsSubject$ | async) || 0) < 50 ? 'none' : 'inherit'}"
    #paginatorTop [length]="dataSource.elementsSubject$ | async"
    (page)="handlePageTop($event)" showFirstLastButtons>
</mat-paginator>
<table
    mat-table
    matSort
    matTableFilter
    matSortActive="date"
    matSortDirection="desc"
    [dataSource]="dataSource"
    matSortDisableClear
>
  <ng-container matColumnDef="id" class="mat-column-id">
    <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-left">{{'Booking Ref' | translate}}</th>
    <td mat-cell *matCellDef="let wineryScheduling"
        class="align-left">{{wineryScheduling.id?.split('-').shift()}}
    </td>
  </ng-container>

  <ng-container matColumnDef="experienceTitleIt" class="mat-column-experience-title">
    <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-left">{{'Experience' | translate}}</th>
    <td mat-cell *matCellDef="let wineryScheduling"
        class="align-left">{{getExperienceName(wineryScheduling)}}
    </td>
  </ng-container>

    <ng-container class="mat-column-name" matColumnDef="masterContactName">
    <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-left">{{'Name' | translate}}</th>
      <td *matCellDef="let wineryScheduling" class="align-left" mat-cell>{{getMasterContactName(wineryScheduling)}}</td>
  </ng-container>

    <ng-container class="mat-column-adults" matColumnDef="guestCountTotal">
      <th *matHeaderCellDef class="align-left" mat-header-cell mat-sort-header>{{'Partecipants' | translate}}</th>
      <td *matCellDef="let wineryScheduling" mat-cell>
        <div style="display: flex;">
            <span *ngIf="wineryScheduling.type == 'appointment'"></span>
            <div *ngIf="!(wineryScheduling.type == 'appointment')">
                <span (mouseenter)="showDetails($event, true, iconOverables)" (mouseleave)="showDetails($event, false, iconOverables)"
                      class="hoverableWithPopover">{{wineryScheduling.guestCountTotal}}</span>
            <web-info-icon-hoverable
                    #iconOverables
                    class="infoIconParticipants"
                    [descriptionObservable]="generateInfoCall(wineryScheduling)"
                    [showIcon]="false"
                    overrides="position: absolute; display: flex; z-index: 1000; max-width: 10vw; margin-top: -40px; left: 40%; flex-direction: row; flex-wrap: wrap; justify-content: center; align-content: space-between; align-items: baseline;"
            ></web-info-icon-hoverable>
        </div>

        </div>
      </td>
  </ng-container>

    <ng-container class="mat-column-created_at" matColumnDef="createdAt">
    <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-left">{{'Reservation date' | translate}}</th>
      <td *matCellDef="let wineryScheduling" mat-cell>{{wineryScheduling.createdAt | date:'dd/MM/yyyy'}}</td>
  </ng-container>

    <ng-container class="mat-column-start_at" matColumnDef="date">
    <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-left">{{'Experience date' | translate}}</th>
      <td *matCellDef="let wineryScheduling" mat-cell>{{wineryScheduling.date | date:'dd/MM/yyyy'}}</td>
  </ng-container>

  <ng-container matColumnDef="origin" class="mat-column-origin">
    <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-left">{{'Origin' | translate}}</th>
    <td mat-cell *matCellDef="let wineryScheduling">
      {{ wineryScheduling.isTourOperator ? 'Tour Operator' : getOrigin(wineryScheduling.origin) }}
    </td>
  </ng-container>

  <ng-container matColumnDef="state" class="mat-column-state">
    <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-left">{{'Status' | translate}}</th>
    <td mat-cell *matCellDef="let wineryScheduling">
        {{statusFn(wineryScheduling) | firstupper |
        translate}}
    </td>
  </ng-container>

    <ng-container class="mat-column-total" matColumnDef="netTotalCents">
    <th mat-header-cell mat-sort-header *matHeaderCellDef class="align-left">{{'Total' | translate}}</th>
    <td mat-cell
        *matCellDef="let wineryScheduling">{{wineryScheduling.type == 'appointment'? '' :
        (wineryScheduling.netTotalCents != null ? wineryScheduling.netTotalCents / 100 : '') |
        currency:wineryScheduling.paymentCurrency:'symbol':'1.0-2'}}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row class="reservation-row" *matRowDef="let row; columns: displayedColumns;" (click)="rowClicked(row)"></tr>
</table>
<mat-paginator #paginatorBottom [length]="dataSource.elementsSubject$ | async"
               (page)="handlePageBottom($event)" showFirstLastButtons>
</mat-paginator>