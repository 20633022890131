import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {}

  load(key: string): any{
    var item = localStorage.getItem(`storage-service:${key}`)!;
    return item ? JSON.parse(item) : null;
  }

  save(key: string, value: any): void {
    localStorage.setItem(`storage-service:${key}`, JSON.stringify(value));
  }
}
