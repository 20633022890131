import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { route as r } from '@util/route';
import { observe } from '@web/util/loading/loading';
import { DWS } from '@interfaces/dws';
import { ContactsService } from '@services/dws/contacts.service';

@Injectable({
  providedIn: 'root'
})
export class ContactsResolverService implements Resolve<DWS.Contact> {
  constructor(private contactsService: ContactsService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DWS.Contact> {
    const contactId = r.access(route).param('contactId');
    const companyId = r.access(route).param('companyId');
    if (contactId === 'new' || !contactId) return of(null) as any;
    // arrived via ref
    if (isNaN(contactId)) {
      return observe(this.contactsService.ref(contactId));
    }
    return observe(this.contactsService.getv2(contactId, companyId))
  }
}
