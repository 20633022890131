import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { Host } from '@interfaces/host';

@Injectable({
  providedIn: 'root'
})
export class HostCouponsService {
  constructor() { }

  list(): Observable<Host.Coupon.Data[]> {
    return resource('v2://host/coupons')
      .get<Host.Coupon.Data[]>();
  }
  
  get(id: string | number | any): Observable<Host.Coupon.Data> {
    return resource('v2://host/coupons/id')
      .params({ id })
      .get<Host.Coupon.Data>()
  }
  
  create(coupon: Host.Coupon.New) {
    return resource('v2://host/coupons')
      .post<any>(coupon);
  }
  
  update(id: any, coupon: Host.Coupon.New) {
    return resource('v2://host/coupons/id')
      .params({ id })
      .put<any>(coupon);
  }

  listValidCoupons(): Observable<Host.Coupon.Data[]> {
    return resource('v2://host/coupons-valid')
      .get<Host.Coupon.Data[]>();
  }

}
