import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstupperPipe } from 'pipes/firstupper/firstupper.pipe';


@NgModule({
  declarations: [FirstupperPipe],
  exports: [FirstupperPipe],
  imports: [
    CommonModule
  ]
})
export class FirstupperModule {}
