import { Component, Inject } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'web-availability-automation',
  templateUrl: './availability-automation.component.html',
  styleUrls: ['./availability-automation.component.scss']
})
export class AvailabilityAutomationDialogComponent {
  isAvailabilityBlock: boolean;
  isLanguageBlock: boolean;
  hasEnabledAutomations: boolean;

  choices: ('closeSlots' | 'openSlots' | 'deactivateOtherLangs' | 'activateOtherLangs')[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dlg: MatDialogRef<AvailabilityAutomationDialogComponent>,
  ) {
    this.isAvailabilityBlock = data.isAvailabilityBlock || false;
    this.isLanguageBlock = data.isLanguageBlock || false;
    this.hasEnabledAutomations = data.hasEnabledAutomations || false;
  }

  closeClicked() {
    this.dlg.close();
  }

  confirmClicked() {
    this.dlg.close(this.choices);
  }

  toggleValue(event: MatCheckboxChange, choice: 'closeSlots' | 'openSlots' | 'deactivateOtherLangs' | 'activateOtherLangs') {
    if (event.checked) {
      this.choices.push(choice);
    } else {
      this.choices = this.choices.filter(c => c !== choice);
    }
  }
}