import { environment } from '@env/environment';

export async function set<T = any>(key: string, value: T, prefix?: string): Promise<T> {
  // @ts-ignore
  const appKey = environment.values['storage-key'];
  if (prefix) key = `${appKey}:${prefix}:${key}`;
  else key = `${appKey}:${key}`;
  const str = JSON.stringify(value);
  await localStorage.setItem(key, str);
  return value;
}
