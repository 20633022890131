import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmComponent } from './confirm.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [ConfirmComponent],
  exports: [ConfirmComponent],
  entryComponents: [ConfirmComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    TranslateModule,
    FlexModule,
    MatButtonModule
  ]
})
export class ConfirmModule { }
