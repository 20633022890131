import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CompanyResolverService} from '@services/resolvers/company-resolver.service';
import {QrcodeResolverService} from '@services/resolvers/qrcode-resolver.service';
import {QrCodeGenerateComponent} from "@web/app/components/qr-code-generate/qr-code-generate.component";
import {AccountResolverService} from "@services/resolvers/account-resolver.service";

const routes: Routes = [{
  path: '',
  component: QrCodeGenerateComponent,
  resolve: {
    login: AccountResolverService,
    qrcode: QrcodeResolverService,
    company: CompanyResolverService
  }
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QRCodeGenerateRoutingModule {
}
