import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { tr } from "../util/tr";

marker('Sunday');
marker('Monday');
marker('Tuesday');
marker('Wednesday');
marker('Thursday');
marker('Friday');
marker('Saturday');

export const weekDays: WeekDays[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
export const weekDayName: { [day in WeekDays]: string } = {
  'sunday': tr('Sunday'),
  'monday': tr('Monday'),
  'tuesday': tr('Tuesday'),
  'wednesday': tr('Wednesday'),
  'thursday': tr('Thursday'),
  'friday': tr('Friday'),
  'saturday': tr('Saturday')
}

export type WeekDays = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';
