import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountsService } from '@services/accounts.service';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OnlyAuthorizedGuard implements CanActivate {
  constructor(private accounts: AccountsService, private router: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const login$ = this.accounts.login$.pipe(first())
    return login$
      .pipe(map(login => {
        if (!login) return this.router.parseUrl('/login');
        else return true;
      }))
  }
}
