import { TranslatableName } from "../../../../interfaces/dws/translatable-name";
import { tr } from "../../../../util/tr";
import { pSBC } from "../../../../util/psbc";
import { FormGroup } from "@angular/forms";
import * as moment from "moment";
import { Moment } from "moment";
import { format } from 'date-fns';
import { ExperiencePriceExtra } from "../../../../interfaces/experience-price-extra";
import { ExperiencePriceLabel } from "../../../../interfaces/experience-price-label";
import { ExperienceDWS } from "@interfaces/dws/experience-dws";
import { locale } from "@util/locale";



export namespace ReservationDetail {

  export interface Data {

    //Both reservations
    id: string;
    state: string;
    contact: Contact;
    date: string;
    employee?: Employee;
    grossTotal: number;
    createdDate?: Date,
    updatedDate?: Date,
    guestCount01: number;
    guestCount02: number;
    language: Language;
    discountCents?: number;
    manualPaymentMethodId?: number;
    netTotal: number;
    notes: string;
    paid?: boolean;
    payAtTheWinery: boolean;
    paymentCurrency: string;
    room?: Room;
    time: string;
    visitReason?: VisitReason;
    createdWithSaas: boolean;
    type?: string;
    experiencePriceLabels: ExperiencePriceLabel[];
    experiencePriceExtras: ExperiencePriceExtra[];
    due?: number;
    reservationContacts: ReservationContact[];
    reservationMasterContact: ReservationContact;

    experienceId?: string;
    manualOriginId?: number;
    notifyContact?: boolean;
    wineryId?: string;


    experience: ExperienceDWS;
    couponCode?: string;
    message?: string;
    origin?: string;
    paymentMethod?: string;
    feedback?: Feedback;
    shippingAddress?: ShippingAddress;
    gift?: string;

    createdAt?: Date;

    dwsData?: any;
  }

  export interface ReservationContact {
    id: string | null;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    countryIso: string | null;
    contactType: string;
    otherData: any | null;
    crmContactId?: number;
  };


  export interface Update {
    id: string;
    contact: Contact;
    state: string;
    date: string;
    employee?: Employee;
    grossTotal: number;
    guestCount01: number;
    guestCount02: number;
    language: Language;
    discountCents?: number;
    manualPaymentMethodId?: number;
    netTotal: number;
    due?: number;
    notes: string;
    paymentCurrency: string;
    room?: Room;
    time: string;
    visitReason?: VisitReason;
    manualOriginId?: number;
    notifyContact?: boolean;
    experienceId?: string;
    payAtTheWinery: boolean;
    countryOfResidenceIso?: string;
    experiencePriceLabels: ExperiencePriceLabel[];
    experiencePriceExtras: ExperiencePriceExtra[];
    experienceTitleIt?: string;
    origin?: string;
  }

  export interface Contact {
    contactId?: number;
    email: string;
    name: string;
    phone: string;
    address?: Address;
  }

  export interface Address {
    countryIso?: string;
  }

  export interface Experience {
    id: string;
    color: string;
    duration: string;
    experienceId: string;
    lat?: string;
    lng?: string;
    priceLabel01En: string;
    priceLabel01It: string;
    priceLabel01De?: string;
    priceLabel02En: string;
    priceLabel02It: string;
    priceLabel02De?: string;
    price02Active: boolean;
    price?: { cents: number, currency: string },
    price02?: { cents: number, currency: string },
    eventDates: any[];
    titleEn: string;
    titleIt: string;
    titleDe?: string;
    type?: ExperienceType;
    experiencePriceLabels: ExperiencePriceLabel[];
    experiencePriceExtras: ExperiencePriceExtra[];
    languages?: Language[];
  }

  export interface ExperienceType {
    id?: string;
    nameIt: string;
    nameEn: string;
    nameDe?: string;
    code: string;
    color: string;
  }

  export interface Room {
    id: string;
    name: string
  }

  export interface Employee {
    id: string;
    name: string
  }

  export interface Language {
    id: string,
    nameIt: string;
    nameEn: string;
    nameDe?: string;
    iso: string;
  }

  export interface VisitReason {
    id: string,
    nameIt: string;
    nameEn: string;
    nameDe?: string;
  }

  export interface ShippingAddress {
    country: TranslatableName,
    addressLine1: string,
    addressLine2: string,
    city: string,
    province: string,
    region: string,
    zipcode: string
  }

  export interface Feedback {
    token: string;
    hidden: { [k: string]: string };
    answers: Answer[];
    form_id: string;
    landed_at: Date;
    definition: Definition;
    submitted_at: Date;
  }

  export interface RatingFieldDefinition {
    id: string;
    title: string;
    type: 'rating';
  }

  export interface MultipleChoiceFieldDefinition {
    id: string;
    title: string;
    type: 'multiple_choice';
    choices: {
      id: string;
      label: string;
    }[]
  }

  export interface YesNoFieldDefinition {
    id: string;
    title: string;
    type: 'yes_no'
  }

  export interface LongTextFieldDefinition {
    id: string;
    title: string;
    type: 'long_text'
  }


  export interface TextFieldDefinition {
    id: string;
    title: string;
    type: 'text'
  }

  export type FieldDefinition = TextFieldDefinition |
    LongTextFieldDefinition |
    YesNoFieldDefinition |
    MultipleChoiceFieldDefinition |
    RatingFieldDefinition


  export interface Definition {
    id: string;
    title: string;
    fields: FieldDefinition[];
  }

  export interface NumberAnswer {
    type: 'number';
    number: number;
    field: FieldDefinition;
  }

  export interface ChoiceAnswer {
    type: 'choice';
    field: FieldDefinition;
    choice: {
      label: string;
    };
  }

  export interface BooleanAnswer {
    type: 'boolean';
    boolean: boolean;
    field: FieldDefinition;
  }

  export interface TextAnswer {
    type: 'text';
    text: string;
    field: FieldDefinition;
  }

  export type Answer = TextAnswer |
    BooleanAnswer |
    ChoiceAnswer |
    NumberAnswer;

  interface Payment {
    id: string;
    order_id: string;
    intent: string;
    aasm_state: string;
    provider: string;
    account_id: string;
    amount_cents: number;
    amount_currency: string;
  }

  interface Order {
    payments: Payment[]
  }

  export type ReservationHistoryActionType = 'create' | 'edit_attributes' | 'change_status' | 'edit_gift' | 'change_contact' | 'end_life_cycle' | 'associate_employee' | 'associate_room' | 'payment';

  export type State = 'draft' | 'waiting' | 'confirmed' | 'revoked' | 'rejected' | 'completed';

  interface StatusBase {
    cssClass: 'draft' | 'waiting' | 'confirmed' | 'revoked' | 'rejected' | 'completed' | 'canceled' | 'deleted';
    icon: string;
    text: string;
    color: string;
  }

  const statusMapping: { [k: string]: 'draft' | 'waiting' | 'confirmed' | 'revoked' | 'rejected' | 'completed' | 'canceled' } = {
    'draft': 'draft',
    'waiting': 'waiting',
    'confirmed': 'confirmed',
    'revoked': 'revoked',
    'rejected': 'rejected',
    'canceled': 'rejected',
    'completed': 'completed'
  };

  export const mapStatus = (status: string) => {
    return statusMapping[status];
  }

  const textMapping: { [k: string]: string } = {
    'draft': tr('draft'),
    'confirmed': tr('Confirmed'),
    'completed': tr('Completed'),
    'rejected': tr('Rejected'),
    'waiting': tr('On hold'),
    'revoked': tr('Revoked')
  };

  type StatusDraft = StatusBase & { type: 'draft' };
  type StatusWaiting = StatusBase & { type: 'waiting' };
  type StatusRejected = StatusBase & { type: 'rejected' };
  type StatusConfirmed = StatusBase & { type: 'confirmed' };
  type StatusRevoked = StatusBase & { type: 'revoked' };
  type StatusCompleted = StatusBase & { type: 'completed' };
  type StatusCanceled = StatusBase & { type: 'canceled' };
  type StatusDeleted = StatusBase & { type: 'deleted' };
  export type MappedStatus = StatusDraft | StatusWaiting | StatusConfirmed | StatusRejected | StatusRevoked | StatusCompleted | StatusCanceled | StatusDeleted;

  const experienceTypeCodes = ["tasting", "stay", "event", "special", "smart"];

  export const status = (reservation: ReservationDetail | undefined, override?: "draft" | "waiting" | "confirmed" | "revoked" | "rejected" | "completed" | "canceled" | "deleted"): MappedStatus => {

    const color = (color: string, state: string): string => {

      switch (state) {
        case 'draft':
          return pSBC(0.5, color) as string;
        case 'waiting':
          return pSBC(0.5, color) as string;
        case 'canceled':
        case 'rejected':
          return pSBC(-0.5, color) as string;
        case 'revoked':
          return pSBC(-0.5, color) as string;
        case 'confirmed':
        default:
          return color;
      }
    }

    const iconMapping: { [k: string]: string } = {
      'draft': 'circled-etc',
      'waiting': 'circled-etc',
      'rejected': 'circled-close',
      'canceled': 'circled-close',
      'revoked': 'circled-close',
      'confirmed': 'circled-checkmark',
      'completed': 'circled-checkmark'
    };

    const status = override || (reservation ? statusMapping[reservation.state] : false) || 'waiting';

    return {
      type: status,
      cssClass: status,
      icon: iconMapping[status],
      text: textMapping[status],
      color: reservation ? color(reservation.experience?.details.color || 'fff', status) : ''
    }
  }

  export const mapText = (status: string) => {
    return textMapping[statusMapping[status]];
  }

  export const experienceTitle = (experience: ExperienceDWS) => {
    return (
      experience.details.titles.find(
        (x) => x.languageIsoCode.toLowerCase() == lang
      )?.shortLabel || ""
    );
  }

  export const lang = () => {
    return locale().locale;
  }

  export const languages = (experience: ExperienceDWS): string[] => {
    return (
      experience?.metaDatas
        .filter((x) => x.metadataValue.startsWith("lang_"))
        ?.map((i) => i.metadataValue.split("_")[1]) || []
    );
  }

  export const type = (experience: ExperienceDWS) => {
    return (
      experience?.metaDatas.find((x) =>
        experienceTypeCodes.includes(x.metadataValue)
      )?.metadataValue || "tasting"
    );
  }

  export const title = (experience: ExperienceDWS) => {
    return (
      experience?.details.titles.find(
        (x) => x.languageIsoCode.toLowerCase() == lang()
      )?.shortLabel || ""
    );
  }

  export const duration = (experience: ExperienceDWS) => {
    var duration = "";
    if (experience) {
      duration = experience.details.duration;
    }

    const durationPattern = /(\d+d)?\s*(\d+h)?\s*(\d+m)?/;
    const match = duration.match(durationPattern);

    if (match) {
      const days = match[1] && match[1] != "0d" ? match[1].trim() : "";
      const hours = match[2] && match[2] != "0h" ? match[2].trim() : "";
      const minutes = match[3] && match[3] != "0m" ? match[3].trim() : "";

      // Construct the new duration string, omitting zero values
      duration = `${days ? days : ""}${days && hours ? " " : ""}${hours ? hours : ""
        }${(days || hours) && minutes ? " " : ""}${minutes ? minutes : ""
        }`.trim();
    }

    return duration;
  }
}

export type ReservationDetail = ReservationDetail.Data;
