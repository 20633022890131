import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservationCalendarComponent } from './reservation-calendar.component';
import { CalendarModule } from 'angular-calendar';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { LetDirectiveModule } from '@web/app/directives/let/let.module';
import { Event30mComponent } from '@web/app/components/reservation-calendar/event-template/event-30m.component';
import { Event1hrComponent } from '@web/app/components/reservation-calendar/event-template/event-1h/event-1hr.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppointmentEventComponent } from '@web/app/components/reservation-calendar/event-template/appointment-event.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslateDomainModule } from "@web/app/pipes/translate/translate-domain.module";
import { GroupedEventsComponent } from "./event-template/grouped-events/grouped-events.component"
import { MatBadgeModule } from '@angular/material/badge';

@NgModule({
  declarations: [ReservationCalendarComponent, Event30mComponent, Event1hrComponent, AppointmentEventComponent, GroupedEventsComponent],
  exports: [
    ReservationCalendarComponent
  ],
  imports: [
    CommonModule,
    CalendarModule,
    MatIconModule,
    TranslateModule,
    LetDirectiveModule,
    FlexLayoutModule,
    MatButtonModule,
    TranslateDomainModule,
    MatBadgeModule
  ]
})
export class ReservationCalendarModule { }
