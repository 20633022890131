import { Component, Input } from '@angular/core';
import {
  calendarInterval,
  CalendarSchedulingEvent,
  calendarStatus
} from '@services/dws/scheduling/calendar-event/calendar-scheduling-event';

import { capitalizeEachWord } from '../../../../../../../../util/transform-string/capitalize-each-word';
import { TranslateService } from '@ngx-translate/core';
import { formatLabelsFromEvent } from '../../../../../util/calendar-reservation';
import { CurrencyPipe, getCurrencySymbol } from '@angular/common';
import { VwReservation } from '@interfaces/dws/reservation';
import * as moment from 'moment';
import { Room } from '@interfaces/dws/room';
import { Employee } from '@interfaces/dws/employee';

const WAITING_STATE_STRING = 'waiting';
const COMPLETED_STATE_STRING = 'completed';
const CONFIRMED_STATE_STRING = 'confirmed';
const REJECTED_STATE_STRING = 'rejected';
const REVOKED_STATE_STRING = 'revoked';
const CANCELED_STATE_STRING = 'canceled';

@Component({
  selector: 'app-grouped-events',
  templateUrl: './grouped-events.component.html',
  styleUrls: ['../common.scss', './grouped-events.component.scss']
})
export class GroupedEventsComponent {
  private _meta: VwReservation = {} as any;
  public capitalizeEachWord = capitalizeEachWord;
  language = '';
  public formatLabelsFromEvent = formatLabelsFromEvent;
  cPipe: CurrencyPipe;
  @Input() smallEvent: boolean = false;
  public moment = moment;

  @Input() rooms: Room[] = [];
  @Input() employees: Employee[] = [];

  constructor(public translate: TranslateService, private cpipe: CurrencyPipe) {
    this.language = translate.getDefaultLang();
    this.cPipe = cpipe;
  }

  @Input() set meta(v: VwReservation) {
    this.status = calendarStatus(v);
    this._meta = v;
  }

  get meta() { return this._meta; }

  status: { cssClass: string; icon: string; text: string; } = {} as any;

  get currencySymbol() {
    return getCurrencySymbol(this._meta?.paymentCurrency || 'EUR', 'wide', undefined) || '' as string;
  }

  formatTotal(totalCents: number): string {
    let money = (totalCents / 100);
    return this.cPipe.transform(money, this.currencySymbol, "", "1.0-0")!;
  }

  countInWaiting(childs: CalendarSchedulingEvent[]): number {
    let count = 0;
    if (childs.length > 0) {
      let thisStateElems = childs.filter(x => x.state === WAITING_STATE_STRING);
      return thisStateElems.length;
    }
    return count;
  }

  inWaitingClass(): string {
    let childs = this.meta.optionalData!.childEvents!;
    if (childs.length > 0) {
      return "waitingGroup";
    }
    return "";
  }

  @Input() width!: number;
  @Input() height!: number;
  @Input() color: any;
  @Input() view: 'week' | 'day' | 'month' = 'week';

  interval(): number {
    return calendarInterval(this.meta as CalendarSchedulingEvent)
  }

  countGuests(meta: VwReservation): number {
    var simple = meta.guestCount01 || 0;
    if (meta.reservationPriceLabels?.length || -1 > 0) {
      meta.reservationPriceLabels!.forEach(x => simple += x.quantity);
    }
    return simple;
  }

  roomName(roomId: string): string {
    return this.rooms.find(x => x.id === roomId)?.name || '';
  }

  employeeName(employeeId: string): string {
    const ids = employeeId.split(', ');
    const employees = this.employees.filter(e => ids.includes(e.id) && (e?.firstName || e?.lastName));
    if (employees.length === 0) return '';
    return (employees[0].firstName || '') + ' ' + (employees[0].lastName || '') + (employees.length > 1 ? ' + ' + (employees.length - 1) : '');
  }

  getExperienceName(reservation: VwReservation): string {
    return reservation.experienceTranslations?.find(t => t.translation_id == 'TITLE')?._translations[this.language] || '';
  }
}