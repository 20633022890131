import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentComponent } from 'projects/web/src/app/components/dialogs/appointment/appointment.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexModule } from '@angular/flex-layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxMaskModule } from 'ngx-mask';
import { DurationModule } from '@web/app/pipes/duration/duration.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { ConfirmModule } from '@web/app/components/dialogs/confirm';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AppointmentDetailModule } from '@web/app/components/appointment-detail/appointment-detail.module';


@NgModule({
  declarations: [AppointmentComponent],
  entryComponents: [AppointmentComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    FlexModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    TranslateModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NgxMaskModule,
    DurationModule,
    MatCheckboxModule,
    MatSelectModule,
    ConfirmModule,
    AppointmentDetailModule,
    MatSidenavModule
  ]
})
export class AppointmentModule { }
