import { Contact } from '@interfaces/dws/contact';

export enum EmailEnum {
  master = 'master',
  secondary = 'secondary',
  work = 'work',
  additionalWork = 'additionalWork',
  pec = 'pec'
}

export interface Email {
  id?: number;
  email?: string;
  type?: EmailEnum;
  contact?: Contact;
}
