<div mat-dialog-content>
    <div class="wrapper">
        <p>{{ 'This contact already exists' }}</p>

        <div class="contact-div">
            <div class="row">
                <strong class="full-name">
                    {{!contact.firstName && !contact.lastName ? contact.companyName : contact.firstname}} {{contact.lastName || ''}}
                </strong>
                <strong>
                    {{getCountryByIso(contact.countryISO!) | translatable : 'name' : 'pascal'}}
                    <img *ngIf="contact.countryISO"
                         class="flag"
                         src="https://www.fotw.info/images/{{contact.countryISO[0]}}/{{contact.countryISO}}.gif"/>
                </strong>
            </div>

            <div class="row">
                <strong class="email">{{email}}</strong>
                <span class="phone">{{phone}}</span>
            </div>
        </div>

        <p>{{ 'Associate the reservation to this contact?' }}</p>
        <div class="confirm-buttons">
            <button (click)="noClicked()" class="color-base-6" mat-stroked-button>
                {{'No' | translate}}
            </button>
            <button (click)="yesClicked()" class="btn-yes color-base-1" mat-stroked-button>
                {{'Yes' | translate}}
            </button>
        </div>
    </div>
</div>
