<div mat-dialog-content>
    <mat-toolbar>
      <div>{{'Definitions' | translate}}</div>
  
      <div class="actions">
        <button mat-icon-button (click)="closeClicked()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </mat-toolbar>
</div>
<div class="client-wrapper">

    <div class="field-wrapper">
        <label for="initial"><i class="icon-clock"></i> {{'Calendar start time' | translate}}</label>
        <input matInput
        id="end_time"
        type="time"
        [(ngModel)]="startTime"
        [ngModelOptions]="{ standalone: true }">
    </div>

    <div class="field-wrapper">
        <label for="initial"><i class="icon-clock"></i> {{'Calendar end time' | translate}}</label>
        <input matInput
            id="end_time"
            type="time"
            [(ngModel)]="endTime"
            [ngModelOptions]="{ standalone: true }">
    </div>
    <div class="button-wrapper">
        <button mat-flat-button (click)="saveClicked()">{{ 'Save' | translate }}</button>
    </div>
</div>

<mat-datepicker #datepicker [yPosition]="'below'"></mat-datepicker>