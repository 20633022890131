import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { map } from 'rxjs/operators';
import { Host } from '@interfaces/host';
import { Base64 } from "../util/base64";

@Injectable({
  providedIn: 'root'
})
export class HostExperiencesService {
  constructor() { }
  list(filters: { [k: string]: unknown } = {},showDisabledExperiences: boolean = false): Observable<Host.Experience[]> {
    return resource('v2://host/experiences')
      .params({
        page: '1',
        per_page: '999999',
        showDisabledExperiences,
        ...filters
      })
      .get<Host.Experience[]>()
      // .pipe(
      //   map(experiences => experiences.sort((a, b) => {
      //     const aTitle = a.title || '';
      //     const bTitle = b.title || '';
      //     return aTitle.localeCompare(bTitle);
      //   }))
      // )
  }

  get(experienceId: string): Observable<Host.Experience> {
    return resource('v2://host/experiences/id')
      .params({ id: experienceId })
      .get<Host.Experience>()
  }

  update(id: string, e: Host.ExperiencePendingChanges) {
    return resource('v2://host/experiences/id')
      .params({ id })
      .put<any>(e);
  }

  create(e: Host.ExperiencePendingChanges): Observable<Host.Experience> {
    return resource('v2://host/experiences')
      .post<any>(e);
  }

  deactivate(id: string) {
    return resource('v2://host/experiences/id/deactivate')
      .params({ id })
      .post()
  }

  activate(id: string) {
    return resource('v2://host/experiences/id/activate')
      .params({ id })
      .post()
  }

  alreadyExistsExperienceTitle(title: string, iso: 'it' | 'en' | 'de', id?: string): Observable<number> {
    return resource('v2://host/experiences-name')
        .params({
          id,
          title: encodeURIComponent(Base64.encode(title)),
          iso
        })
        .get<number>() ;
  }
}
