// tslint:disable:no-console
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { flatMap, map, mapTo, mergeMap } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';
import { storage } from '@util/storage';
import { convert } from '@util/convert/convert';
import { hash } from '@util/hash';

export interface ResponseData {
  body?: any;
  headers?: { [k: string]: any | any[] } | HttpHeaders;
  status?: number;
  statusText?: string;
  url?: string | null;
  expires?: number;
  responseType?: any;
  requestBody?: any;
}

@Injectable({
  providedIn: 'root'
})
export class HttpCacheService {
  enabled: boolean;

  constructor(private router: Router) {
    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationStart) {
        this.enable();
      }
    });
    this.enabled = true;
    (window as any)['cache'] = this;
  }

  public enable() {
    this.enabled = true;
  }

  public disable() {
    this.enabled = false;
  }

  public set(request: HttpRequest<any>, response: HttpResponse<any>, maxAge: number = 300000): Observable<HttpResponse<any>> {
    if (!this.enabled) {
      return of(response);
    }
    let k = `${request.urlWithParams}`;
    if (request.body) k = `${k}:${hash(JSON.stringify(request.body))}`;
    const h = response.headers.keys().reduce((headers, key) => {
      headers[key] = response.headers.getAll(key);
      return headers;
    }, {} as any);


    const originalBody = response.body;

    const makeData = async () => {
      let body = null;
      if (request.responseType === 'json')
        body = JSON.parse(JSON.stringify(response.body));

      if (request.responseType === 'arraybuffer')
        body = await convert(response.body).to('base64');

      const data: ResponseData = {
        body,
        headers: h,
        status: response.status,
        statusText: response.statusText,
        url: response.url,
        responseType: request.responseType,
        expires: maxAge > 0 ? Date.now() + maxAge : -1
      };
      return data;
    };

    return from(makeData())
      .pipe(
        mergeMap((data) => storage.set(k, data, 'cache')),
        map((data) => {
          data.headers = new HttpHeaders(data.headers as any);
          data.body = originalBody;
          return new HttpResponse(data as any);
        }));
  }

  public get(request: HttpRequest<any>): Observable<HttpResponse<any> | undefined> {
    if (!this.enabled) {
      return of(undefined);
    }
    const url = request.urlWithParams;
    let k = `${url}`;
    if (request.body) k = `${k}:${hash(JSON.stringify(request.body))}`;
    const expired = (data: ResponseData) => {
      if (!data || !data.expires) return false;
      return data.expires > 0 ? (data.expires - Date.now()) < 0 : false;
    };

    return from(storage.get(k, 'cache'))
      .pipe(
        flatMap(data => {
          if (!data) {
            return of(undefined);
          }
          if (expired(data)) {
            console.debug(`[http-cache] %cexpired ${url}`, 'color: red');
            return this.expire(k).pipe(mapTo(undefined));
          } else {
            if (data.responseType === 'arraybuffer') {
              return from(convert(data.body as string).to('arraybuffer').then(b => {
                data.body = b;
                return data;
              }));
            } else {
              return of(data);
            }
          }
        }),
        map(data => data ? new HttpResponse(data) : undefined)
      );
  }

  public clear(): Observable<any> {
    return from(storage.clear('winery-saas:cache'));
  }

  public expire(k: string): Observable<void> {
    return from(storage.remove(k, 'winery-saas:cache'));
  }
}
