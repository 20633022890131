<mat-toolbar>
    <h1>{{'Presence Filter' | translate}}</h1>

    <div class="actions">
      <button mat-icon-button (click)="closeClicked()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
</mat-toolbar>


<div class="filter-wrapper">
    <form [formGroup]="form">

        <div class="field-wrapper">
            <label>
                {{ 'Registration date' | translate }}
            </label>
            <mat-form-field class="example-form-field" style="margin-bottom: -1.25em">
                <mat-date-range-input
                    (click)="campaignOnePicker.open()"
                    [rangePicker]="campaignOnePicker">
                    <input readonly matStartDate formControlName="registrationFirstDate">
                    <input readonly matEndDate formControlName="registrationLastDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
            </mat-form-field>

            <label>
                {{ 'Registration channel' | translate }}
            </label>
            <mat-select formControlName="registrationChannels" [multiple]="true">
                <mat-option *ngFor="let qrcode of qrCodes" [value]="qrcode.name">{{qrcode.name}}</mat-option>
            </mat-select>
        </div>

    </form>

    <div class="btn-container">
        <button mat-stroked-button type="submit" (click)="submitClicked()">{{ 'Apply' | translate }}</button>
    </div>
</div>
