import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
} from "@angular/core";
import { AccountsService } from "../../../services/accounts.service";
import { ca } from "date-fns/locale";

@Directive({
  selector: "[dwsPermission]",
})
export class DwsPermissionDirective implements OnInit, OnChanges {
  @Input() dwsPermission?: string;
  @Input() dwsPermissionDenyMode: "disable" | "hide" = "disable";

  /**
   *
   * @param permissionName returns the permission name of account requested by code
   * @param isGranted returns the permission granted by default array of permissions of account
   * @returns if return true, the permission is granted, if return false, the permission is denied
   */
  @Input() dwsPermissionCustomLogic: (
    permissionName: string,
    granted: boolean
  ) => boolean = (x: string, y: boolean) => y;

  constructor(
    private elementRef: ElementRef,
    private accountsService: AccountsService,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.internalEvaluatePermission();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.internalEvaluatePermission();
  }

  internalEvaluatePermission() {    
    if (!this.dwsPermission) return;
    var permission = this.dwsPermission!;

    var evaluatePermission = () => {
      var isGranted = this.accountsService.hasPermission(permission);
      if (!this.dwsPermissionCustomLogic(permission, isGranted)) {
        this.denyPermission();
      } else {
        this.acceptPermission();
      }
    };

    this.accountsService.cachedPermissions$.subscribe(() => {
      evaluatePermission();
    });
  }

  private denyPermission() {
    if (this.dwsPermissionDenyMode == "disable") {
      this.renderer.setProperty(
        this.elementRef.nativeElement,
        "disabled",
        true
      );
      try{
        // DISABLE INTERACTIVE ELEMENTS INSIDE THE ELEMENT
        const interactiveElements = this.elementRef.nativeElement.querySelectorAll('button, a, input, select, textarea');
        interactiveElements.forEach((el: HTMLElement) => {
          this.renderer.setProperty(el, 'disabled', true);
          this.renderer.setStyle(el, 'pointer-events', 'none');
        });
      } catch (e) {
        console.error('Error disabling interactive elements', e);
      }

    } else {
      this.elementRef.nativeElement.style.visibility = "hidden";
    }
    this.renderer.setAttribute(
      this.elementRef.nativeElement,
      "dws-permission-denied",
      "true"
    );
  }

  private acceptPermission() {
    if (this.dwsPermissionDenyMode == "disable") {
      this.renderer.setProperty(
        this.elementRef.nativeElement,
        "disabled",
        false
      );
      try {
        const interactiveElements = this.elementRef.nativeElement.querySelectorAll('button, a, input, select, textarea');
        interactiveElements.forEach((el: HTMLElement) => {
          this.renderer.setProperty(el, 'disabled', false);
          this.renderer.removeStyle(el, 'pointer-events');
        });
      } catch (e) {
        console.error('Error enabling interactive elements', e);
      }
    } else {
      this.elementRef.nativeElement.style.visibility = "visible";
    }
    this.renderer.removeAttribute(
      this.elementRef.nativeElement,
      "dws-permission-denied"
    );
  }
}
