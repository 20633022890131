import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OnlyAuthorizedGuard} from '@services/guards/only-authorized.guard';
import {
  HostCompanyIdWithCompanyClosingsResolverService
} from '@services/resolvers/host-company-id-with-company-closings';
import {CompanyResolverService} from "@services/resolvers/company-resolver.service";
import {AccountResolverService} from "@services/resolvers/account-resolver.service";
import { ReservationPdfExporterComponent } from './reservation-pdf-exporter.component';

const routes: Routes = [{
  path: '',
  component: ReservationPdfExporterComponent,
  resolve: {
    login: AccountResolverService,
    company: HostCompanyIdWithCompanyClosingsResolverService,
    companyAlt: CompanyResolverService
  },
  canActivate: [OnlyAuthorizedGuard]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReservationPdfExporterRoutingModule { }
