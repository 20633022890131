import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SafeModule } from '@pipes/safe/safe.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexModule } from '@angular/flex-layout';
import {TranslateDomainModule} from "@web/app/pipes/translate/translate-domain.module";
import { MailingTimingComponent } from './mailing-timing.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [MailingTimingComponent],
  entryComponents: [MailingTimingComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule,
        SafeModule,
        MatExpansionModule,
        FormsModule,
        ReactiveFormsModule,
        MatToolbarModule,
        FlexModule,
        TranslateModule,
        TranslateDomainModule,
        MatFormFieldModule,
        MatSelectModule
    ]
})
export class MailingTimingModule { }
