import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { resource } from '@util/resource';
import { Payment, PaymentLink } from '@interfaces/dws/payment';
import { Sale, SaleRow } from '@interfaces/dws/sale';
import { map, switchMap } from 'rxjs/operators';
import { Page } from '@interfaces/dws/page';

@Injectable({
  providedIn: 'root'
})
export class WSPaymentsService {

  constructor() {}
  
  createPaymentLink(saleRows: SaleRow[], contactId?: number): Observable<[PaymentLink, Sale]> {
    return resource('crm://wspayments/sales')
      .post<Sale>({ saleRows, contactId })
      .pipe(
        switchMap(sale => resource('crm://wspayments/sales/id/payment-links')
          .params({ id: sale.id })
          .post<PaymentLink>()
          .pipe( map(paymentLink => [paymentLink, sale] as [PaymentLink, Sale] ) )
        )
      );
  }

  getPaymentLink(filters: PaymentLinkFilters): Observable<Page<PaymentLink>> {
    return resource('crm://wspayments/payment-links')
      .params({...filters})
      .get();
  }

  markPaymentLinkAsSent(id: string, sentTo: string): Observable<void> {
    return resource('crm://wspayments/payment-links/id/send')
      .params({ id })
      .post({ sentTo });
  }
}

export interface PaymentLinkFilters {
  reservationId?: string;

  page?: number;
  size?: number;
}