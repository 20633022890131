import { Component, OnInit, Input } from '@angular/core';
import { Event } from '@angular/router';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { NotificationDWS } from '@interfaces/dws/notification-dws';
import { EventBusEvent, EventBusService } from '@web/app/services/event-bus.service';
import { Notification } from '@interfaces/notifications';

////////////////////////////////
const LOCAL_STORAGE_KEY = "messagePopUpDismissed";
@Component({
  selector: 'web-common-message-popup',
  templateUrl: './common-message-popup.component.html',
  styleUrls: ['./common-message-popup.component.scss'],
})
export class CommonMessagePopupComponent implements OnInit {

  isMessageVisible = false;
  isButtonVisible = false;
  notificationNumber = 1;
  wineryID = "ND";
  messageId = "db2039aa-66af-40b4-9253-dddb99c86450";

  message: Notification | undefined;

  @Input() delayButton: number = 2;
  @Input() expireTimeButton: number = 1200;
  @Input() delayMessage: number = 10;
  @Input() expireTimeMessage: number = 300;
  @Input() expireTimeMessageDismissed: number = 2160;

  constructor(public dialog: MatDialog, 
    private eventBusService: EventBusService) {  }

    
  ngOnInit() {
    /*if(this.loadIsDismessed(this.messageId))  return;
    this.spotVisibility(this.showBFn, this.hideBFn, this.delayButton, this.expireTimeButton);
    this.spotVisibility(this.showMFn, this.hideMFn, this.delayMessage, this.expireTimeMessage);
    try{
      var kk = localStorage.getItem("winery-saas:login");
      var oo = JSON.parse(kk!);
      this.wineryID = oo.company.id;
    } catch(e){

    }*/

    this.eventBusService.subscribe("news_channel").subscribe((event: EventBusEvent) => {
      const eventO = event as EventBusEvent;
      const notification = eventO.data as Notification;
      this.message = notification;
      this.isMessageVisible = true;
      this.isButtonVisible = true;
    });
    console.log("subscribed");
  }

  async spotVisibility(showFn: Function, hideFn: Function, delay: number, expireTime: number) {
    if (delay > 0) {
      setTimeout(() => showFn.bind(this)(), delay * 1000);
    } else {
      showFn()
    }
    if (expireTime > 0) {
      setTimeout(() => hideFn.bind(this)(), expireTime * 1000);
    }
  }

  loadIsDismessed(messageId: string): boolean {
    const dismissDateS = localStorage.getItem(LOCAL_STORAGE_KEY);
    try{
      if(!!!!dismissDateS){
        const obj = JSON.parse(dismissDateS);
        var dateS = obj[messageId];
        if(!!!dateS) return false;
        const dismissDate = new Date(dateS);
        if(dismissDate.getTime() > ( (new Date()).getTime() - this.expireTimeMessageDismissed * 3600 * 1000) ){
          return true;
        }
      }
      return false;
    } catch(err){
      console.error(err);
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      return false;
    }
  }

  saveDismissed(messageId: string): void {
    var toSave:any = new Object();
    toSave[messageId] = (new Date()).toISOString();
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(toSave));
  }

  showBFn() {
    this.isButtonVisible = true;
  }

  hideBFn() {
    this.isButtonVisible = false;
  }

  showMFn() {
    this.isMessageVisible = true;
    //this.notificationNumber = 1;
  }

  hideMFn() {
    this.isMessageVisible = false;
    //this.notificationNumber = 0;
  }

  openMessage(){

  }


  toogleMessage() {
    this.isMessageVisible = !this.isMessageVisible;
  }

  dismissMessage() {
    this.isMessageVisible = false;
    this.notificationNumber = 0;
    this.isButtonVisible = false;
    this.saveDismissed(this.messageId);
  }

}
