import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {observe} from 'projects/web/src/util/loading/loading';
import {Host} from '@interfaces/host';
import {HostCompanyService} from '../host-company.service';

@Injectable({
  providedIn: 'root'
})
export class HostCompanyIdWithCompanyClosingsResolverService implements Resolve<Host.ReducedCompany> {
  constructor(private companyService: HostCompanyService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Host.ReducedCompany> {
    return observe(this.companyService.getCompanyWithCompanyClosings());
  }
}
