import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';

@Injectable({
  providedIn: 'root'
})
export class CompanyPersonContactsService {

  constructor() {
  }

  create(companyPersonContact: DWS.CompanyPersonContact): Observable<DWS.CompanyPersonContact> {
    return resource('crm://company-person-contacts')
      .post<DWS.CompanyPersonContact>(companyPersonContact);
  }

  list(filters?: CompanyPersonContactFilters, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.CompanyPersonContact>> {
    return resource('crm://company-person-contacts')
      .params(
        { page, count, ...filters }
      )
      .get<DWS.Page<DWS.CompanyPersonContact>>();
  }

  delete(companyContactId: number, personContactId: number) {
    return resource('crm://company-person-contacts/companyContactId/personContactId')
      .params({companyContactId, personContactId})
      .delete();
  }
}

export interface CompanyPersonContactFilters {
  personContactId?: number;
  companyContactId?: number;
}
