<mat-sidenav-container
  (backdropClick)="sidenav.close()"
  [ngClass]="{ public: isPublicRoute }"
>
  <mat-sidenav
    id="menu"
    (keydown.escape)="sidenav.close()"
    #sidenav
    [fixedInViewport]="true"
    (closed)="restartState()"
  >
    <button
      type="button"
      mat-icon-button
      class="btn-close"
      (click)="sidenav.toggle()"
    >
      <i class="icon-arrow-bold-left color-base-8"></i>
    </button>

    <div class="menu-wrapper">
      <ul>
        <li *ngIf="false">
          <button>
            <i class="icon-home"></i>
            <strong>{{ "Home" | translate }}</strong>
          </button>
        </li>

        <li>
          <button
            (click)="openReportsDashboard(); sidenav.toggle()"
            dwsPermission="DASHBOARD"
            dwsPermissionDenyMode="disable"
          >
            <i class="icon-graph"></i>
            <strong>{{ "Dashboard" | translate }}</strong>
          </button>
        </li>

        <li>
          <button
            (click)="openDashboard(); sidenav.toggle()"
            dwsPermission="CALENDAR"
            dwsPermissionDenyMode="disable"
          >
            <i class="icon-calendar"></i>
            <strong>{{ "Calendar" | translate }}</strong>
          </button>
        </li>

        <li>
          <button
            (click)="openAvailability(); sidenav.toggle()"
            dwsPermission="CALENDAR"
            dwsPermissionDenyMode="disable"
          >
            <i class="icon-dashboard"></i>
            <strong>{{ "Availability management" | translate }}</strong>
          </button>
        </li>

        <li [class.selected]="openExperiencesAndProducts">
          <button
            (click)="
              this.restartStateExperiencesAndProducts();
              openExperiencesAndProducts = !openExperiencesAndProducts
            "
            dwsPermission="WINERY_ADMIN"
            dwsPermissionDenyMode="disable"
          >
            <i class="icon-toast"></i>
            <strong
              >{{ "Experiences" | translate }} <br />&
              {{ "Products" | translate }}</strong
            >
          </button>
        </li>
        <li class="sub-button" *ngIf="openExperiencesAndProducts">
          <button (click)="openExperiences(); sidenav.toggle()">
            <i class="icon-glasses"></i>
            <strong>{{ "Experiences" | translate }}</strong>
          </button>
        </li>
        <li class="sub-button" *ngIf="openExperiencesAndProducts">
          <button
            (click)="openProducts(); sidenav.toggle()"
            [class.disabled]="!dwsPro"
          >
            <i class="icon-two-bottles"></i>
            <strong>{{ "Products" | translate }}</strong>
          </button>
        </li>
        <ng-container *feature="'category-product'">
          <li class="sub-button" *ngIf="openExperiencesAndProducts">
            <button
              (click)="openProductCategory(); sidenav.toggle()"
              [class.disabled]="!dwsPro"
            >
              <i class="icon">
                <img src="assets/icons/category.png" style="width: 20px" />
              </i>
              <strong>{{ "Products Category" | translate }}</strong>
            </button>
          </li>
        </ng-container>
        <li>
          <button
            (click)="gotTo('/pos'); sidenav.toggle()"
            [class.disabled]="!dwsPro"
            dwsPermission="ORDERS"
            dwsPermissionDenyMode="disable"
          >
            <i class="icon-sales"></i>
            <strong>{{ "Point of sale" | translate }}</strong>
          </button>
        </li>

        <li [class.selected]="openSales">
          <button
            (click)="this.restartStateSales(); openSales = !openSales"
            dwsPermission="ORDERS"
            dwsPermissionDenyMode="disable"
          >
            <i class="icon-online"></i>
            <strong>{{ "Sales" | translate }}</strong>
          </button>
        </li>
        <li *ngIf="openSales" class="sub-button">
          <button
            (click)="reservationsClicked(); sidenav.toggle()"
            dwsPermission="CALENDAR"
            dwsPermissionDenyMode="disable"
          >
            <i class="icon-calendar"></i>
            <strong>{{ "Reservations" | translate }}</strong>
          </button>
        </li>
        <li class="sub-button" *ngIf="openSales">
          <button
            (click)="giftClicked(); sidenav.toggle()"
            dwsPermission="CALENDAR"
            dwsPermissionDenyMode="disable"
          >
            <i class="icon-gift"></i>
            <strong>{{ "Gift" }}</strong>
          </button>
        </li>
        <li class="sub-button" *ngIf="openSales">
          <button
            (click)="gotTo('/orders'); sidenav.toggle()"
            [class.disabled]="!dwsPro"
            dwsPermission="ORDERS"
            dwsPermissionDenyMode="disable"
          >
            <i class="icon-ordini"></i>
            <strong>{{ "Orders" | translate }}</strong>
          </button>
        </li>
        <li class="sub-button" *ngIf="openSales">
          <button
            (click)="gotTo('/preorders'); sidenav.toggle()"
            [class.disabled]="!dwsPro"
            dwsPermission="ORDERS"
            dwsPermissionDenyMode="disable"
          >
            <i class="icon-pre-order"></i>
            <strong>{{ "Self Order" | translate }}</strong>
          </button>
        </li>
        <li class="sub-button" *ngIf="openSales">
          <button
            (click)="discontCodeClicked(); sidenav.toggle()"
            dwsPermission="WINERY_ADMIN"
            dwsPermissionDenyMode="disable"
          >
            <i class="icon-discount"></i>
            <strong>{{ "Discount Code" | translate }}</strong>
          </button>
        </li>

        <li [class.selected]="openCrm">
          <button
            (click)="this.restartStateCrm(); openCrm = !openCrm"
            [class.disabled]="!dwsPro"
            dwsPermission="CRM"
            dwsPermissionDenyMode="disable"
          >
            <i class="icon-terminal"></i>
            <strong>{{ "CRM" | translate }}</strong>
          </button>
        </li>
        <li class="sub-button" *ngIf="openCrm">
          <button (click)="openContacts(); sidenav.toggle()">
            <i class="icon-contact"></i>
            <strong>{{ "CONTACTS" | translate }}</strong>
          </button>
        </li>
        <li class="sub-button" *ngIf="openCrm">
          <button (click)="openTags(); sidenav.toggle()">
            <i class="icon-tag"></i>
            <strong>{{ "TAG" }}</strong>
          </button>
        </li>
        <li class="sub-button" *ngIf="openCrm">
          <button (click)="openSegments(); sidenav.toggle()">
            <i class="icon-segments"></i>
            <strong>{{ "Segments" | translate }}</strong>
          </button>
        </li>
        <li class="sub-button" *ngIf="openCrm">
          <button (click)="openImportContact(); sidenav.toggle()">
            <i class="icon-import"></i>
            <strong>{{ "Import" | translate }}</strong>
          </button>
        </li>

        <li [class.selected]="openMarketing" [class.disabled]="!dwsPro">
          <button
            (click)="
              this.restartStateMarketing(); openMarketing = !openMarketing
            "
            dwsPermission="MARKETING"
            dwsPermissionDenyMode="disable"
          >
            <i class="icon-marketing"></i>
            <strong>{{ "Marketing" | translate }}</strong>
          </button>
        </li>
        <ng-container>
          <li class="sub-button" *ngIf="openMarketing">
            <button (click)="openMarketingTab('campaigns'); sidenav.toggle()">
              <i class="icon-target"></i>
              <strong>{{ "Campaigns" | translate | uppercase }}</strong>
            </button>
          </li>
          <li class="sub-button" *ngIf="openMarketing">
            <button
              (click)="openMarketingTab('automations'); sidenav.toggle()"
              [class.disabled]="'automations' | off"
            >
              <i class="icon-automation"></i>
              <strong>{{ "Automation" | translate | uppercase }}</strong>
            </button>
          </li>
          <li class="sub-button" *ngIf="openMarketing">
            <button (click)="openMarketingTab('lists'); sidenav.toggle()">
              <i class="icon-two-people"></i>
              <strong>{{ "Marketing lists" | translate | uppercase }}</strong>
            </button>
          </li>
          <li class="sub-button" *ngIf="openMarketing">
            <button (click)="openMarketingTab('form'); sidenav.toggle()">
              <i><mat-icon>assignment</mat-icon></i>
              <div style="display: grid; width: 130px">
                <strong style="width: unset !important">{{
                  "Form" | translate | uppercase
                }}</strong>
                <span class="beta-label-class">BETA</span>
              </div>
            </button>
          </li>
          <li class="sub-button" *ngIf="openMarketing && false">
            <button (click)="openMarketingTab('additions'); sidenav.toggle()">
              <i class="icon-tag"></i>
              <strong>{{ "Additions" | translate | uppercase }}</strong>
            </button>
          </li>
        </ng-container>

        <!--        <li [class.disabled]="!dwsPro">-->
        <!--          <button (click)="openProducts(); sidenav.toggle()">-->
        <!--            <i class="icon-bottle"></i> <strong>{{ "Products" | translate }}</strong>-->
        <!--          </button>-->
        <!--        </li>-->

        <li [class.selected]="openManagement">
          <button
            (click)="
              this.restartStateManagement(); openManagement = !openManagement
            "
            dwsPermission="WINERY_ADMIN"
            dwsPermissionDenyMode="disable"
          >
            <i class="icon-cellar"></i>
            <strong>{{ "Cellar management" | translate }}</strong>
          </button>
        </li>
        <li class="sub-button" *ngIf="openManagement">
          <button (click)="openCompanyManagement(); sidenav.toggle()">
            <i class="icon-engine"></i>
            <strong>{{ "Cellar settings" | translate }}</strong>
          </button>
        </li>
        <li
          class="sub-button"
          *ngIf="openManagement && ('winery-transactional-emails' | on)"
        >
          <button (click)="openTransactionalEmails(); sidenav.toggle()">
            <i class="icon-envelope"></i>
            <strong>{{ "Transactional emails" | translate }}</strong>
          </button>
        </li>
        <li *ngIf="openManagement" class="sub-button">
          <button (click)="openIntegrations(); sidenav.toggle()">
            <i class="material-icons">insert_link</i>
            <strong>{{ "Integrations" | translate }}</strong>
          </button>
        </li>
        <li *ngIf="openManagement" class="sub-button">
          <button (click)="openDevelopersView(); sidenav.toggle()">
            <i class="material-icons">code</i>
            <strong>{{ "Developers" | translate }}</strong>
          </button>
        </li>
      </ul>
    </div>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar *ngIf="!isPublicRoute && !adminModule">
      <mat-toolbar-row class="main-toolbar">
        <div class="menu-wrapper">
          <button (click)="sidenav.toggle()" mat-icon-button>
            <i class="icon-burger color-base-1"></i>
          </button>
          <div class="logo-wrapper">
            <a [routerLink]="['/homepage']"
              ><img src="assets/images/logo-white-horizontal.svg" alt="divinea"
            /></a>
          </div>
        </div>
        <div
          *ngIf="(adminAsWinery || adminModule) && !isPublicRoute"
          class="adminBadge"
        >
          <a [routerLink]="['/admin/login-as-winery']"
            ><img alt="divinea" src="assets/images/admin_badge.png"
          /></a>
        </div>

        <div>
          <span *ngIf="!!!!versionPostfix" class="version"
            >{{ version }} ({{ versionPostfix }})</span
          >
          <span *ngIf="!!!versionPostfix" class="version">{{ version }}</span>
          <span *ngIf="!!!!versionRibbon" class="ribbon">{{
            versionRibbon
          }}</span>
        </div>

        <div class="actions-wrapper">
          <button
            [matMenuTriggerFor]="messaggingMenu"
            mat-icon-button
            style="margin-right: 15px"
          >
            <mat-icon class="color-base-1"> chat_bubble_outline</mat-icon>
            <span *ngIf="newMessages > 0" class="tip notifications-number">{{
              newMessages
            }}</span>
          </button>

          <mat-menu
            #messaggingMenu="matMenu"
            (close)="messaggesClosed()"
            class="notification-menu messaging-menu"
            style="margin-right: 10px"
          >
            <header>
              <h2>
                {{ "Messages" | translate }}
              </h2>
              <a (click)="messagesAllDismiss()" class="dismissAllLink">{{
                "Set all as readed" | translate
              }}</a>
            </header>
            <div class="dws-button-filter-selector">
              <!-- SELECTOR -->
              <mat-button-toggle-group
                (change)="onChangeVisualization($event, 'messages')"
                (click)="onNotificationVisualizationClick($event)"
                aria-label="Message Filter"
                name="messagesFilter"
                value="all"
              >
                <mat-button-toggle value="all">{{
                  "All" | translate
                }}</mat-button-toggle>
                <mat-button-toggle value="unreaded">{{
                  "Unreaded" | translate
                }}</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <mat-divider></mat-divider>
            <ng-container
              (scrolled)="onScrollDownMessages()"
              *ngFor="let message of messages$ | async; let i = index"
              infiniteScroll
            >
              <div>
                <button (click)="openMessage(message)" mat-menu-item>
                  <div class="leftBarNotification">
                    <span
                      *ngIf="!message.viewed_at"
                      class="dot icon-notification-color"
                    ></span>
                    <!-- <i *ngIf="!notification.viewed_at" class="icon-notification icon-notification-color"></i> -->
                  </div>
                  <div class="rightBarNotification">
                    <div class="notification-txt">
                      <strong>
                        <mat-icon class="notification-single-icon"
                          >chat_bubble_outline</mat-icon
                        >
                        {{ message?.title }}</strong
                      >
                      <span class="notification-message">{{
                        message?.message
                      }}</span>
                    </div>
                    <p class="time-txt">
                      <!-- {{ 'Receipt' | translate}} :  -->
                      {{ formatDate(message?.send_at) | date : "dd/MM HH:mm" }}
                    </p>
                  </div>
                </button>
              </div>
            </ng-container>
            <mat-divider></mat-divider>
            <div class="dws-button-load-more">
              <a (click)="messagesLoadMore($event)" class="loadMoreLink">{{
                "Load More" | translate
              }}</a>
            </div>
          </mat-menu>

          <button [matMenuTriggerFor]="notificationMenu" mat-icon-button>
            <i class="icon-notification color-base-1"></i>
            <span
              class="tip notifications-number"
              *ngIf="newNotification > 0"
              >{{ newNotification }}</span
            >
          </button>

          <mat-menu
            class="notification-menu"
            #notificationMenu="matMenu"
            (close)="notificationsClosed()"
          >
            <header>
              <h2>
                {{ "Notifications" | translate }}
              </h2>
              <a (click)="notificationsAllDismiss()" class="dismissAllLink">{{
                "Set all as readed" | translate
              }}</a>
            </header>
            <div class="dws-button-filter-selector">
              <!-- SELECTOR -->
              <mat-button-toggle-group
                (change)="onChangeVisualization($event, 'notification')"
                (click)="onNotificationVisualizationClick($event)"
                aria-label="Notification filter"
                name="notificationFilter"
                value="all"
              >
                <mat-button-toggle value="all">{{
                  "All" | translate
                }}</mat-button-toggle>
                <mat-button-toggle value="unreaded">{{
                  "Unreaded" | translate
                }}</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <mat-divider></mat-divider>
            <ng-container
              (scrolled)="onScrollDownNotification()"
              *ngFor="let notification of notifications$ | async; let i = index"
              infiniteScroll
            >
              <div *ngIf="notificationIsReservation(notification)">
                <button (click)="openNotification(notification)" mat-menu-item>
                  <div class="leftBarNotification">
                    <span
                      *ngIf="!notification.viewed_at"
                      class="dot icon-notification-color"
                    ></span>
                    <!-- <i *ngIf="!notification.viewed_at" class="icon-notification icon-notification-color"></i> -->
                  </div>
                  <div class="rightBarNotification">
                    <div class="notification-txt">
                      <strong>
                        <mat-icon class="notification-single-icon"
                          >calendar_today</mat-icon
                        >
                        {{ notification?.title }}</strong
                      >
                      <span class="notification-message">{{
                        notification?.message
                      }}</span>
                    </div>
                    <p class="time-txt">
                      <!-- {{ 'Receipt' | translate}} :  -->
                      {{
                        formatDate(notification?.send_at) | date : "dd/MM HH:mm"
                      }}
                    </p>
                  </div>
                </button>
              </div>
              <div *ngIf="notificationIsSelfOrder(notification)">
                <button (click)="openNotification(notification)" mat-menu-item>
                  <p class="notification-txt"></p>
                  <div class="leftBarNotification">
                    <span
                      *ngIf="!notification.viewed_at"
                      class="dot icon-notification-color"
                    ></span>
                    <!-- <i *ngIf="!notification.viewed_at" class="icon-notification icon-notification-color"></i> -->
                  </div>
                  <div class="rightBarNotification">
                    <div class="notification-txt">
                      <strong>
                        <mat-icon class="notification-single-icon"
                          >receipt</mat-icon
                        >
                        {{ notification?.title }}</strong
                      >
                      <span class="notification-message">{{
                        notification?.message
                      }}</span>
                    </div>
                    <p class="time-txt">
                      <!-- {{ 'Receipt' | translate}} :  -->
                      {{
                        formatDate(notification?.send_at) | date : "dd/MM HH:mm"
                      }}
                    </p>
                  </div>
                </button>
              </div>
            </ng-container>
            <mat-divider></mat-divider>
            <div class="dws-button-load-more">
              <a (click)="notificationsLoadMore($event)" class="loadMoreLink">{{
                "Load More" | translate
              }}</a>
            </div>
          </mat-menu>

          <button
            class="helpMenuButtonTopbar"
            mat-icon-button
            [matMenuTriggerFor]="helpMenu"
          >
            <span>{{ "Support" | translate }}</span>
            <i class="icon-faq color-base-1"></i>
          </button>

          <mat-menu #helpMenu="matMenu">
            <button class="helpMenuButton" mat-menu-item (click)="openManual()">
              <span>{{ "Guides and Manual" | translate }}</span>
              <mat-icon class="helpMenuButtonIcon">menu_book</mat-icon>
            </button>
            <button mat-menu-item class="helpMenuButton" (click)="openHelp()">
              <span>{{ "Need Help" | translate }}</span>
              <mat-icon class="helpMenuButtonIcon">contact_support</mat-icon>
            </button>
          </mat-menu>

          <ng-container *ngIf="selfAccount$ | async as account">
            <button mat-button [matMenuTriggerFor]="accountMenu">
              <span class="color-base-1">{{ account.name }}</span>
            </button>

            <mat-menu class="account-menu" #accountMenu="matMenu">
              <div class="profile">
                <!--              <div class="avatar">-->
                <!--                <img src="assets/images/avatar.png" />-->
                <!--              </div>-->

                <strong>{{ account.name }}</strong>
                <span>{{ account.email }}</span>
              </div>

              <div class="actions">
                <button mat-stroked-button [routerLink]="['/account-detail']">
                  {{ "My Account" | translate }}
                </button>
                <button mat-stroked-button (click)="logoutClicked()">
                  {{ "Logout" | translate }}
                </button>
                <!--          <div class="link-button" data-longtitle="true" data-onSuccess="googleSignInSuccess" data-scope="profile https://www.googleapis.com/auth/calendar.events"></div>-->
                <button
                  mat-stroked-button
                  (click)="googleCalendarLinkClicked()"
                >
                  {{ "Link Google Calendar" | translate }}
                </button>
                <button
                  mat-stroked-button
                  (click)="outlookCalendarLinkClicked()"
                >
                  {{ "Link Outlook Calendar" | translate }}
                </button>
                <mat-divider class="paddingTop1"></mat-divider>
                <div class="privacy-link paddingTop1">
                  <a href="/privacy" target="_blank">{{
                    "Privacy Terms" | translate
                  }}</a>
                </div>
              </div>
            </mat-menu>
          </ng-container>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>

    <mat-toolbar *ngIf="adminModule && !isPublicRoute">
      <mat-toolbar-row class="main-toolbar">
        <div class="menu-wrapper"></div>

        <div class="logo-wrapper">
          <a [routerLink]="['/dashboard']"
            ><img src="assets/images/logo-white-horizontal.svg" alt="divinea"
          /></a>
        </div>

        <span>{{ version }}</span>

        <div class="actions-wrapper">
          <ng-container *ngIf="adminAuth$ | async as auth">
            <button mat-button [matMenuTriggerFor]="accountMenu">
              <span class="color-base-1">{{ auth.username }}</span>
            </button>

            <mat-menu class="account-menu" #accountMenu="matMenu">
              <div class="profile">
                <!--              <div class="avatar">-->
                <!--                <img src="assets/images/avatar.png" />-->
                <!--              </div>-->

                <strong>{{ auth.username }}</strong>
              </div>

              <div class="actions">
                <button mat-stroked-button (click)="logoutAdminClicked()">
                  {{ "Logout" | translate }}
                </button>
              </div>
            </mat-menu>
          </ng-container>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>

    <mat-toolbar
      (mouseleave)="hideBannerAdmin($event)"
      (mouseover)="hideBannerAdmin($event)"
      *ngIf="adminAsWinery"
      class="minor-toolbar"
    >
      <mat-toolbar-row [hidden]="!showBannerAdmin" class="minor-toolbar-row">
        <div class="admin-as-winery-banner">
          <mat-label *ngIf="adminAsWinery">{{
            bannerAdminAcessText()
          }}</mat-label>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>

    <!-- <mat-toolbar (mouseleave)="hideBanner($event)" (mouseover)="hideBanner($event)" -->
    <mat-toolbar
      *ngIf="haveBanner"
      class="minor-toolbar-ws-params dws-elevation-z4"
    >
      <mat-toolbar-row [hidden]="!showBanner" class="minor-toolbar-row">
        <div class="message-ws-params-banner">
          <mat-label>{{ showBannerText() }}</mat-label>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>

    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<app-loading *ngIf="isLoading"></app-loading>
<web-common-message-popup
  *ngIf="!isPublicRoute && !adminModule"
></web-common-message-popup>
