<div mat-dialog-content>
  <mat-toolbar>
    <div></div>
    <div class="actions">
      <button mat-icon-button (click)="closeClicked()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <div style="margin-top:20px">
    <div
      *ngIf="showFilterInfo"
      class="line"
      style="margin-left: 40px;"
      fxLayout="row"
      fxLayoutAlign="start center"
      >
      <mat-icon>info</mat-icon> {{ !!nFilters 
        ? ("This export has {} filters applied" | translate).replace('{}', nFilters)
        : ("This export only has a filter applied to the reservation status" | translate)
      }}
    </div>
    <div class="line" fxLayout="row" fxLayoutAlign="start center" >
      <div class="sec1">
        <div class="title">
          <i class="icon-calendar"></i>
          <span>{{title | translate}}</span>
        </div>
      </div>
      <div>
        <mat-form-field class="example-form-field" >
          <mat-date-range-input
                  [formGroup]="campaignOne"
                  (click)="campaignOnePicker.open()"
                  [rangePicker]="campaignOnePicker"
          >
            <input readonly matStartDate [placeholder]="'Start date' | translate" formControlName="start">
            <input readonly matEndDate [placeholder]="'End date' | translate" formControlName="end">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="center">
      <button (click)="exportXLSXClicked()" class="search-btn" type="button" mat-button>{{'Excel' | translate}}</button>
      <button (click)="exportCSVClicked()" class="search-btn" type="button" mat-button>{{'CSV' | translate}}</button>
    </div>
  </div>

</div>
