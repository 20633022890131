import { NgModule } from "@angular/core";
import { InfoIconHoverableComponent } from "./info-icon-hoverable.component";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";


@NgModule({
  imports: [
    CommonModule,
    MatIconModule
  ],
  declarations: [InfoIconHoverableComponent],
  exports: [InfoIconHoverableComponent]
})
export class InfoIconHoverableModule { }