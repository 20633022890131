import { differenceInMinutes, setHours, setMinutes } from "date-fns";
import { WeekDays, weekDays } from "../week-days";

export namespace ExperienceSlots {
  export interface Register {
    dayOfWeek: WeekDays;
    time: string;
    defaultMinPartiesToEnable: number;
    defaultAvailability: number;
    defaultLanguages: string[];
  }

  export interface SlotId {
    experienceId: string,
    time: string,
    dayOfWeek: WeekDays
  }

  export interface handleSlot {
    id: string,
    dayOfWeek: WeekDays,
    time: string,
    defaultMinPartiesToEnable: number,
    defaultAvailability: number,
    defaultLanguages: string[]
  }

  export const availableTimesFor = (availabilities: ExperienceSlots[], day: Date) => {
    const dayOfWeek = weekDays[day.getDay()];
    return availabilities
      .filter((a) => a.dayOfWeek === dayOfWeek)
      .map(av => av.time.split(':', 2).join(':'));
  }
}
export type SlotId = ExperienceSlots.SlotId;
export type ExperienceSlots = ExperienceSlots.Register;
