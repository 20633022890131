import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { formatISO } from 'date-fns';
import {ReservationExporting} from "../../../../interfaces/dws/scheduling/reservation-exporting";

@Injectable({
  providedIn: 'root'
})
export class SchedulingExportingService {

  constructor() {}

  exportToCSV(wineryId: string, start_reservation: Date, end_reservation: Date, page: number = 0, count: number = 999999): Observable<ReservationExporting[]> {

    const appliedFilters = {
      wineryId,
      'dateRange.startOfRange': formatISO(start_reservation, {representation: 'date'}),
      'dateRange.endOfRange': formatISO(end_reservation, {representation: 'date'})
    }

    return resource('crm://reservation-export')
        .params(
            { page, count, ...appliedFilters }
        )
        .get<ReservationExporting[]>();
  }
}
