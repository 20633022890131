import { Origin } from './origin';
import { Language } from './language';
import { TagCrm } from './tag';
import { Segment } from './segment';
import { Moment } from 'moment';
import { Winery } from './winery';

export namespace Contact {
  interface IObjectKeys {
    [key: string]: string | number | boolean;
  }

  export interface Data {
    id?: number;
    acceptedMarketing?: boolean;
    acceptedPrivacyTerms?: boolean;
    acceptedProfiling?: boolean;
    accountId?: string;
    billingAddress?: string;
    birthDate?: string;
    checked?: boolean;
    companyName?: string;
    countryCode?: string;
    countryOfResidenceIso?: string;
    deducedOrigin?: Origin;
    email?: string;
    evaluation?: number;
    facebookProfile?: string;
    firstName?: string;
    instagramProfile?: string;
    languagesEn?: string;
    languagesIt?: string;
    lastName?: string;
    manualMarketing?: boolean;
    name?: string;
    note?: string;
    originExternalId?: string;
    phone?: string;
    presenceDates?: Presence[];
    referencePerson?: string;
    segments?: string;
    tags?: string;
    unsubscribed?: boolean;
    website?: string;
    wineryCreatedAt?: string;
    wineryId?: string;
  }

  export interface Presence {
    created_at: Date | string
    id: number
    register_date?: Date | string
    visit_reason?: string
    visit_reason_id?: string
    winery_id: string
  }

  export interface DataReducedContact {
    id?: number;
    name?: string;
    companyName?: string;
    lastName?: string;
    email?: string;
    winery?: Winery;
  }

  export interface DataFullContact {
    id?: number;
    accountId?: string;
    birthDate?: string;
    name?: string;

    companyName?: string;
    countryCode?: string;
    email?: string;
    evaluation?: number;
    firstName?: string;
    fiscalCode?: string;
    sdi?: string;
    iva?: string;
    lastName?: string;
    phone?: string;
    wineryId?: string;
    // typology
  }

  /* TODO: map for companies (when needed) */
  export interface CompleteInput {
    privacy: {
      wineryId: string;
      acceptedPrivacyTerms: boolean;
      acceptedMarketing?: boolean;
      acceptedProfiling?: boolean;
      manualMarketing?: boolean;
    }
    emails: { email: string; type: string; }[];
    phones: { phone: string; type: string; }[];
    languages?: number[];
    instagramProfile?: string;
    facebookProfile?: string;
    address: any; // see address interface
    personContact: {
      firstName?: string;
      lastName?: string;
      birthDate?: string;
    }
    origin: string;
    wineryId: string;
  }

  export interface ExportContact extends IObjectKeys {
    id: number;
    firstName: string;
    lastName: string;
    personName: string;
    email: string;
    phone: string;
    birthDate: string;
    languagesEn: string;
    languagesIt: string;
    country: string;
    address: string;
    city: string;
    province: string;
    deducedOrigin: string;
    workEmail: string;
    workPhone: string;
    typology: string;
    segments: string;
    tags: string;
    acceptedPrivacyTerms: boolean | string;
    acceptedMarketing: boolean | string;
    acceptedProfiling: boolean | string;
    manualMarketing: boolean | string;
    unsubscribed: boolean | string;
    customerValue: number;
    totalPurchased: number;
    associatedCompanyName: string;
    originExternalId: string;
  }

  export function isCompany(contact: Contact.Data | Contact.DataFullContact) {
    return contact.companyName != null;
  }

  export function isPerson(contact: Contact.Data | Contact.DataFullContact) {
    return contact.firstName != null && !isCompany(contact);
  }

  /**
   * Returns true if a contact matches a type (typology).
   * @param contact The contact to test
   * @param type The type to test. If undefined, returns always true
   */
  export function is(contact: Contact.Data | Contact.DataFullContact, type: { person: boolean, company: boolean }) {
    if (type.person && !type.company) return isPerson(contact);
    if (!type.person && type.company) return isCompany(contact);
    return true;
  }

  export type Filter = Partial<Contact.Data>
    & { wineryId?: string }
    & { typology?: any[] }
    & { languages?: Language[] }
    & { tag?: TagCrm[] }
    & { segment?: Segment[] }
    & { privacies?: { id: number, privacy: string }[] }
    & { origin?: Origin[] }
    & { presence?: { start: Moment, end: Moment, presence: string[] } }
    & { wineryCreatedAt?: { start?: Moment, end?: Moment }}
    & { barSearch?: string}

  export type PrivacyField = 'acceptedPrivacyTerms' | 'acceptedMarketing' | 'acceptedProfiling' | 'manualMarketing';

  export function privacyFieldsHumanName(type: PrivacyField): string {
    switch (type) {
      case 'acceptedPrivacyTerms':
        return 'Terms and conditions';
      case 'acceptedMarketing':
        return 'Marketing purposes';
      case 'acceptedProfiling':
        return 'Profiling';
      case 'manualMarketing':
        return "Registrazione manuale";
      default:
        throw new Error('type does not exist');
    }
  }
}

export type Contact = Contact.Data;
export type ReducedContact = Contact.DataReducedContact;
export type FullContact = Contact.DataFullContact;
export type ContactCompleteInput = Contact.CompleteInput;
export type PrivacyField = Contact.PrivacyField;
export type ExportContact = Contact.ExportContact;
