import { NgModule } from "@angular/core";
import { ExternalSyncButtonComponent } from "./external-sync-button.component";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [ExternalSyncButtonComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
  ],
  exports: [ExternalSyncButtonComponent]
})
export class ExternalSyncButtonModule {}