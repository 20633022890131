// tslint:disable:directive-selector
// tslint:disable:object-literal-key-quotes
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[let]'
})
export class LetDirective {
  constructor(private template: TemplateRef<any>, private container: ViewContainerRef) { }
  @Input('let') set letValue(_: any) {
    const container = this.container;
    container.clear();
    container.createEmbeddedView(this.template, { $implicit: _, 'let': _ });
  }
}

