import { Contact } from '@interfaces/dws/contact';
import { Audit } from '@interfaces/dws/audit';
import { Origin } from '@interfaces/dws/origin';
import { VisitFrequency } from '@interfaces/dws/visit-frequency';
import { CustomProfile } from '@interfaces/dws/custom-profile';
import { WinePreference } from '@interfaces/dws/wine-preference';

export enum MaritalStatusEnum {
  married = 'married',
  divorced = 'divorced',
  widowed = 'widowed',
  unmarried = 'unmarried'
}

export interface PersonContact extends Audit {
  id?: number;
  contact?: Contact;
  firstName?: string;
  lastName?: string;
  birthDate?: string | Date;
  visitFrequency?: VisitFrequency;
  customerProfile?: CustomProfile;
  winePreference?: WinePreference;
  personIva?: number;
  instagramProfile?: string;
  facebookProfile?: string;
  maritalStatus?: MaritalStatusEnum;
  companyName?: string;
}
