import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OnlyAdminAuthorizedGuard } from "@web/app/services/guards/only-admin-authorized.guard";
import { WineSuiteParametersResolverService } from '@services/resolvers/wine-suite-parameters-resolver.service';
import { ReservationLogsComponent } from './reservation-logs.component';

const routes: Routes = [
  { 
    path: '', 
    component: ReservationLogsComponent,
    canActivate: [OnlyAdminAuthorizedGuard],
    resolve: {
      wsParameters: WineSuiteParametersResolverService
    }
  } 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ReservationLogsRoutingModule { }
