import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  DWSIntegration,
  DWSIntegrationConnector,
  WineryIntegration,
} from "@interfaces/dws/integration";
import { WineryIntegrationsService } from "@services/dws/winery-integrations.service";
import { observe } from "@web/util/loading/loading";
import { ga } from "@util/ga";
import { tr } from "@util/tr";
import { toast } from "@web/util/toast";

@Component({
  selector: "web-add-edit-integration",
  templateUrl: "./add-edit-integration.component.html",
  styleUrls: ["./add-edit-integration.component.scss"],
})
export class AddEditIntegrationComponent {
  integration: DWSIntegrationConnector;
  wineryIntegration: WineryIntegration;
  wineryId: string;
  form!: FormGroup;
  isEdit = false;

  objectKeys = Object.keys;

  getRegistryType(key: string) {
    let val: any =
      this.integration.integrationDataRegistry![key as keyof Object] ||
      this.integration.integrationOptionsRegistry![key as keyof Object];

    if (val.startsWith("enum:")) {
    }

    return "text";
  }

  constructor(
    private fb: FormBuilder,
    private dlg: MatDialogRef<AddEditIntegrationComponent>,
    private integrationsService: WineryIntegrationsService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      integration: DWSIntegrationConnector;
      wineryIntegration: WineryIntegration;
      wineryId: string;
    }
  ) {
    this.wineryIntegration = data.wineryIntegration;
    this.integration = data.integration;
    this.wineryId = data.wineryId;

    ga.track("Integration Management");

    this.createForm(this.integration);
    this.patchForm(this.wineryIntegration);
  }

  createForm(integration: DWSIntegrationConnector) {
    let formObject = Object.keys(
      integration.integrationDataRegistry || {}
    ).reduce((acc, key) => {
      acc[key] = [null, Validators.required];
      return acc;
    }, {} as Record<string, any>);
    Object.keys(integration.integrationOptionsRegistry || {}).forEach((key) => {
      formObject[key] = [null];
    });
    this.form = this.fb.group(formObject);
  }

  patchForm(wineryIntegration?: WineryIntegration) {
    if (!wineryIntegration) return;
    this.isEdit = true;
    var formFields = Object.keys(this.form.controls);
    formFields.forEach((field) => {
      if (
        wineryIntegration.integrationData &&
        wineryIntegration.integrationData[field as keyof WineryIntegration] !==
          undefined
      ) {
        var vall =
          wineryIntegration.integrationData[field as keyof WineryIntegration];
        this.form.get(field)?.setValue(vall);
      }
      if (
        wineryIntegration.integrationOptions &&
        wineryIntegration.integrationOptions[
          field as keyof WineryIntegration
        ] !== undefined
      ) {
        var vall =
          wineryIntegration.integrationOptions[
            field as keyof WineryIntegration
          ];
        this.form.get(field)?.setValue(vall);
      }
    });
  }

  generateApiKey(): string {
    const key =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    return key;
  }

  openExternalLink(integration: DWSIntegration) {
    window.open(integration.linkExternal, "_blank");
  }

  closeClicked() {
    this.dlg.close();
  }

  syncClicked() {
    observe(
      this.integrationsService.syncIntegration(
        this.integration.integration!.id,
        this.wineryIntegration
      )
    ).subscribe({
      next: (data) => {
        toast(tr("Integration synced successfully"));
      },
      error: (err) => {
        toast(tr("Failed to sync integration"));
      },
    });
  }

  saveClicked() {
    if (this.form.invalid) {
      toast(tr("Fill in the required information."));
      return;
    }

    let formValues = this.form.value;

    if (!this.wineryIntegration) {
      this.wineryIntegration = {
        integrationId: this.integration.integration!.id,
        integrationData: {},
        integrationOptions: {},
        integrationName: this.integration.integration!.name,
        integration: this.integration.integration,
        wineryId: this.wineryId,
        disabled: false,
        connected: false,
      };
    }

    this.wineryIntegration.integrationData = {
      ...this.wineryIntegration.integrationData, // Keep existing options
      ...Object.keys(formValues).reduce((acc, key) => {
        // Ensure the key is a valid string and check against the registry
        if (
          this.integration.integrationDataRegistry &&
          this.integration.integrationDataRegistry.hasOwnProperty(key) // Check if key exists in registry
        ) {
          acc[key] = formValues[key];
        }
        return acc;
      }, {} as Record<string, any>),
    };

    this.wineryIntegration.integrationOptions = {
      ...this.wineryIntegration.integrationOptions, // Keep existing options
      ...Object.keys(formValues).reduce((acc, key) => {
        // Ensure the key is a valid string and check against the registry
        if (
          this.integration.integrationOptionsRegistry &&
          this.integration.integrationOptionsRegistry.hasOwnProperty(key) // Check if key exists in registry
        ) {
          acc[key] = formValues[key];
        }
        return acc;
      }, {} as Record<string, any>),
    };

    if (this.isEdit) {
      observe(
        this.integrationsService.updateIntegration(
          this.wineryId,
          this.wineryIntegration
        )
      ).subscribe({
        next: (data) => {
          this.dlg.close();
        },
        error: (err) => {
          toast(tr("Failed to save integration"));
        },
      });
    } else {
      observe(
        this.integrationsService.createIntegration(
          this.wineryId,
          this.wineryIntegration
        )
      ).subscribe({
        next: (data) => {
          this.dlg.close();
        },
        error: (err) => {
          toast(tr("Failed to save integration"));
        },
      });
    }
  }

  removeClicked() {
    let v = this.form.value;

    this.integrationsService
      .deleteIntegration(
        this.integration.integration!.id,
        this.wineryIntegration
      )
      .subscribe({
        next: (data) => {
          this.dlg.close();
        },
        error: (err) => {
          toast(tr("Failed to save integration"));
        },
      });
  }
}
