import { NgModule } from "@angular/core";
import { PreviewHtmlComponent } from "./preview-html.component";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";


@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
    MatCardModule,
    MatIconModule,
    TranslateModule
  ],
  declarations: [PreviewHtmlComponent],
  exports: [PreviewHtmlComponent]
})
export class PreviewHtmlModule { }