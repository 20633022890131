import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-gift-language-chooser',
  templateUrl: './gift-language-chooser.component.html',
  styleUrls: ['./gift-language-chooser.component.scss']
})
export class GiftLanguageChooserComponent {
  selectedLanguage: string = 'it';

  constructor(
    public dialogRef: MatDialogRef<GiftLanguageChooserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onConfirmClick(): void {
    this.dialogRef.close(this.selectedLanguage);
  }
}

