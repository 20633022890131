import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';

@Injectable({
  providedIn: 'root'
})
export class ExternalCalendarSyncService {

  sync(id: string): Observable<void> {
    return resource('crm://external-calendar/{id}/sync')
      .params({ id })
      .post<void>();
  }
}