<mat-toolbar>
  <div class="title">
    <p *ngIf="isWarning">{{'Attention!' | translate}}</p>
  </div>
  <div class="actions">
    <button mat-icon-button class="close-btn" (click)="closeClicked()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</mat-toolbar>
<div mat-dialog-content>
  <div class="description-wrapper">
    <p class="bold" [innerHTML]="text | translate"></p>
    <p *ngFor="let item of items">
      <span [innerHTML]="item"></span>
    </p>
  </div>
</div>
