import { Contact } from '@interfaces/dws/contact';
import { Winery } from '@interfaces/dws/winery';
import { Country } from '@interfaces/dws/country';
import { Channel } from '@interfaces/dws/channel';
import { Product } from '@interfaces/dws/product';
import { OrderStatus } from 'interfaces/dws/order-status';
import { Address } from './address';
import { DWS } from '.';

export namespace Order {
  export interface Data {
    amountDiscountCents?: number;
    amountShipmentCents?: number;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    id?: number;
    code: string;
    quantity: number;
    amountCents: number;
    contact: Contact;
    channel: Channel;
    country: Country;
    winery: Winery;
    contactId?: number;
    data: any;
    createdAt: string;
    updatedAt?: string;
    products: Product.InOrder[];
    address?: string;
    city?: string;
    zipcode?: string;
    paymentType: { id: string };
    province?: string;
    status: OrderStatus;
    sequence: number;
    date?: string;
    shippingName?: string;
    shippingPhone?: string;
    wineryChannelId?: string;
    wineryChannelName?: string;
    contactCountryName?: string;
    channelNameIt?: string;
    channelNameEn?: string;
    couponCode?: string;
    couponType?: string;
    couponDiscountCents?: number;
    couponDiscountPercent?: number;
    amountExciseDutyCents?: number;
    amountVatCents?: number;
    amountVatPercentage?: number;
    billingName?: string;
    billingAddress?: string;
    billingCity?: string;
    billingProvince?: string;
    billingZipcode?: string;
    billingCountry?: Country;
    addresses: DWS.Address[];
    notes?: string
  }

  export interface New {
    id?: any;
    address?: string;
    amountCents: number;
    amountDiscountCents: number;
    amountShipmentCents: number;
    city?: string;
    code: any;
    contact: { id: string } | undefined;
    country?: { id: number };
    notes?: string;
    paymentType: { id: string };
    province: string;
    products: {
      amountCents: number;
      amountDiscountCents: number;
      barcode?: any;
      descriptionIt: string;
      descriptionEn?: string;
      nameIt: string;
      nameEn?: string;
      product: { id: any };
      quantity: number;
      type: { id: string };
    }[];
    sendEmail: boolean;
    senderEmail: string;
    couponCode?: string;
    couponType?: string;
    couponDiscountCents?: number;
    couponDiscountPercent?: number;
  }

  export interface Filter {
    code?: string;
    id?: string;
    name?: string;
    createdAt?: string;
    updatedAt?: string;
    country?: Country;
    quantity?: string;
    amount?: any;
    status?: OrderStatus;
  }

  export interface RequestFilter {
    code?: string;
    id?: string;
    name?: string;
    sortBy?: string;
    createdAt?: string;
    updatedAt?: string;
    country?: Country;
    quantity?: string;
    status?: OrderStatus;
    amountLte?: number;
    amountGte?: number;
    wineryId: string
    contactId?: number;
    wineryChannelId?: string;
  }

  export interface Forecast {
    id: string;
    date: string;
    quantity: number;
    amountCents: number;
    wineryId: string;
    wineryChannelId: string;
  }

  export const channelNames = ['unknown', 'woocommerce', 'winery']

  export function getFullNameFromOrder(order: CrmOrder): string {
    return order.companyName ?? ((order.firstName ?? "") + " " + (order.lastName ?? ""))
  }
}

export type CrmOrder = Order.Data;
export type ForecastedOrder = Order.Forecast;