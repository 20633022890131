import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { IntegrationsComponent } from './integrations.component';
import { IntegrationsRoutingModule } from './integrations-routing.module';
import { MatDialog } from '@angular/material/dialog';
import { AddEditIntegrationModule } from '@web/app/components/dialogs/add-edit-integration/add-edit-integration.module';
import { MatGridListModule } from '@angular/material/grid-list';
import { TranslateModule } from '@ngx-translate/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { LetDirectiveModule } from '@web/app/directives/let/let.module';

@NgModule({
  declarations: [IntegrationsComponent],
  exports: [IntegrationsComponent],
  imports: [
    CommonModule,
    IntegrationsRoutingModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    TranslateModule,
    MatCardModule,
    MatIconModule,
    MatListModule,
    MatTabsModule,
    MatTooltipModule,
    MatInputModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    AddEditIntegrationModule,
    MatGridListModule,
    MatToolbarModule,
    LetDirectiveModule,
  ]
})
export class IntegrationsModule { }
