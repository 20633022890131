import { NgModule } from "@angular/core";
import { ReservationLogsComponent } from "./reservation-logs.component";
import { ReservationLogsRoutingModule } from "./reservation-logs-routing.module";
import { MatToolbarModule } from "@angular/material/toolbar"; // Fixed import
import { TranslateModule } from "@ngx-translate/core";
import { MatInputModule } from "@angular/material/input";
import { FormsModule } from "@angular/forms";
import { MatTableModule } from "@angular/material/table";
import { TruncateModule } from "@pipes/truncate/truncate.module";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [ReservationLogsComponent],
    imports: [
        ReservationLogsRoutingModule,
        MatToolbarModule,
        TranslateModule,
        MatInputModule,
        FormsModule,
        MatTableModule,
        TruncateModule,
        MatIconModule,
        MatTooltipModule,
        CommonModule
    ],
    exports: [
        ReservationLogsComponent
    ]
})
export class ReservationLogsModule { }