import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DWS } from "../../interfaces/dws";
import { resource } from "../../util/resource";
import { InactiveTransactionalEmail } from "../../interfaces/dws/inactive-transactional-email";

@Injectable({
  providedIn: "root",
})
export class InactiveTransactionalEmailsService {
  list(wineryId: string): Observable<DWS.InactiveTransactionalEmail[]> {
    return resource("crm://inactive-transactional-email/wineryId").params({ wineryId }).get<DWS.InactiveTransactionalEmail[]>();
  }

  create(wineryId: string, inactiveEmails: InactiveTransactionalEmail[]): Observable<unknown> {
    return resource("crm://inactive-transactional-email/wineryId").params({ wineryId }).post<DWS.InactiveTransactionalEmail[]>(inactiveEmails);
  }

  createSingle(transactionalId: number, wineryId: string, inactiveEmail: InactiveTransactionalEmail): Observable<unknown> {
    return resource("crm://inactive-transactional-email/wineryId/id")
      .params({ wineryId, id: transactionalId })
      .post<DWS.InactiveTransactionalEmail>(inactiveEmail);
  }

  constructor() {}
}
