import { Contact } from '@interfaces/dws/contact';
import { Country } from '@interfaces/dws/country';

export namespace Address {
  export interface Data {
    id: number;
    address?: string;
    name?: string;
    additionalAddress?: string;
    zipcode?: string;
    oldCity?: string;
    oldProvince?: string;
    province?: string;
    city?: string;
    region?: string;
    contact?: Contact;
    country?: Country;
    type: AddressType;
    phone?: string;
    email?: string;
  }
}

export enum AddressType {
  MASTER = 'MASTER',
  BILLING = 'BILLING',
  SHIPPING = 'SHIPPING'
}

export type Address = Address.Data;
