import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';

@Injectable({
  providedIn: 'root'
})
export class CustomerProfilesService {

  constructor() {
  }

  list(page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.CustomProfile>> {
    return resource('crm://customer-profiles')
      .params(
        { page, count }
      )
      .get<DWS.Page<DWS.CustomProfile>>();
  }
}
