<mat-toolbar>
  <mat-toolbar-row class="calendar-toolbar" *let="{open: false} as searchBarCtrl" [ngClass]="{'with-search-bar': searchBarCtrl.open}">
    <div class="actions" style="width: unset;">
        <button [mat-menu-trigger-for]="statusFilterMenu" mat-raised-button>
            {{ "State" | translate }}
        </button>
        <mat-menu
                #statusFilterMenu
                (close)="statusFilterChanged()"
                class="filter-status"
        >
            <div style="padding: 6px 15px">
                <mat-checkbox
                        (click)="$event.stopPropagation()"
                        [(ngModel)]="statusFilter.draft"
                >{{ "Draft" | translate }}
                </mat-checkbox
                >
        </div>
            <div style="padding: 6px 15px">
                <mat-checkbox
                        (click)="$event.stopPropagation()"
                        [(ngModel)]="statusFilter.confirmed"
                >{{ "Confirmed" | translate }}
                </mat-checkbox
                >
        </div>
            <div style="padding: 6px 15px">
                <mat-checkbox
                        (click)="$event.stopPropagation()"
                        [(ngModel)]="statusFilter.completed"
                >{{ "Completeda" | translate }}
          </mat-checkbox>
        </div>
            <div style="padding: 6px 15px">
                <mat-checkbox
                        (click)="$event.stopPropagation()"
                        [(ngModel)]="statusFilter.waiting"
                >{{ "Waiting" | translate }}
                </mat-checkbox
                >
        </div>
            <div style="padding: 6px 15px">
                <mat-checkbox
                        (click)="$event.stopPropagation()"
                        [(ngModel)]="statusFilter.rejected"
                >{{ "Rejected" | translate }}
                </mat-checkbox
                >
        </div>
            <div style="padding: 6px 15px">
                <mat-checkbox
                        (click)="$event.stopPropagation()"
                        [(ngModel)]="statusFilter.revoked"
                >{{ "Revoked" | translate }}
                </mat-checkbox
                >
        </div>
            <div style="padding: 6px 15px">
                <mat-checkbox
                        (click)="$event.stopPropagation()"
                        [(ngModel)]="statusFilter.canceled"
                >{{ "Canceleda" | translate }}
                </mat-checkbox>
            </div>
      </mat-menu>
    </div>

    <div class="actions" style="width: unset;" *ngIf="!searchBarCtrl.open">
      <div class="display-flex align-items-center">
        <button mat-raised-button (click)="todayClicked()"
                *ngIf="view === 'calendar'">{{ 'Today' | translate }}</button>
        <input [ngStyle]="{ 'width': '1px', padding: 0, margin: 0, border: 0, height: '30px' }" [(ngModel)]="pickedDate"
                [matDatepicker]="datepicker"/>
        <button mat-icon-button (click)="datepicker.open()" *ngIf="view === 'calendar'"><i class="icon-calendar"></i>
        </button>
        <mat-datepicker #datepicker [yPosition]="'below'"></mat-datepicker>
      </div>
    </div>

    <div class="filters" *ngIf="!searchBarCtrl.open">
      <mat-button-toggle-group
        name="calendarView"
        aria-label="Calendar View"
        [(ngModel)]="calendarView"
        *ngIf="view === 'calendar'"
        >
        <mat-button-toggle value="day">{{'Day' | translate}}</mat-button-toggle>
        <mat-button-toggle value="week">{{'Week' | translate}}</mat-button-toggle>
        <mat-button-toggle value="month">{{'Month' | translate}}</mat-button-toggle>
      </mat-button-toggle-group>

      <div class="wrapper-btn" *ngIf="view === 'list'">
          <button (click)="openExportDialog()" class="export-btn" mat-flat-button>
              {{ "Export" | translate }}
        </button>
          <button
                  (click)="openFilterDialog()"
                  [matBadge]="appliedFilters | keycount"
                  class="filter-btn"
                  mat-flat-button
          >
              {{ "Filter" | translate }} <i class="icon-filters"></i>
        </button>
      </div>
    </div>

    <web-reservation-search-bar
        (submit)="openListAndApplySearch($event)"
        (openStateToggled)="searchBarCtrl.open = !searchBarCtrl.open"
    ></web-reservation-search-bar>

    <div class="actions">
          <button
                  [mat-menu-trigger-for]="addMenu"
                  class="dws-elevation-z2"
                  dwsPermission="WRITE"
                  dwsPermissionDenyMode="hide"
                  mat-flat-button
          >
              {{ "Add" | translate }}
              <i class="icon-circled-plus"></i>
          </button>
          <mat-menu #addMenu="matMenu">
              <button
                      (click)="openReservationDialogNew()"
                      class="add-btn"
                      mat-menu-item
              >
                  {{ "Reservation" | translate }}
              </button>
              <button (click)="openAppointmentDialog()" class="add-btn" mat-menu-item>
                  {{ "Appointment" | translate }}
              </button>
              <button
                      (click)="openTourOperatorDialog()"
                      class="add-btn"
                      mat-menu-item
              >
                  {{ "Tour Operator" | translate }}
              </button>
      </mat-menu>

      <div class="mode-wrapper">
        <i class="icon-calendar color-base-5"></i>
          <mat-slide-toggle
                  (change)="viewChanged()"
                  [checked]="view == 'list'"
          ></mat-slide-toggle>
        <i class="icon-lista color-base-5"></i>
      </div>

      <button (click)="refreshData()" class="refreshButton" mat-raised-button>
        <mat-icon>refresh</mat-icon>
      </button>

          <button
                  (click)="exportDataOpen()"
                  class="refreshButton"
                  mat-raised-button
          >
        <mat-icon>print</mat-icon>
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>

<div
        class="calendar-wrapper"
        dwsPermission="CALENDAR"
        dwsPermissionDenyMode="hide"
>
  <div class="date-header" *ngIf="view === 'calendar'">
    <div class="date-period">
        <button (click)="openCalendarConfigurationDialog()" mat-icon-button>
            <i class="icon-engine color-base-5"></i>
        </button>

        <p class="color-base-6 selected-date">
            {{
            selectedDate | calendarDate : calendarView + "ViewTitle" : locale : 1
            }}
      </p>
      <p class="color-base-6 date-current-year">
          {{ selectedDate.getFullYear() }}
      </p>

      <div class="date-increment-buttons">
        <button mat-icon-button (click)="decrementDateClicked()">
          <i class="icon-arrow-left"></i>
        </button>

        <button mat-icon-button (click)="incrementDateClicked()">
          <i class="icon-arrow-right"></i>
        </button>
      </div>
    </div>
  </div>

    <div
            [ngClass]="{
      'calendar-day-view': calendarView == 'day',
      'split-view': splitView
    }"
            class="calendar-content"
    >
    <app-reservation-calendar
            (eventClick)="eventClicked($event)"
            (periodChange)="periodSelected($event)"
            [calendarConfiguration]="calendarConfiguration"
            [calendarSchedulingEvents]="calendarSchedulingEvents"
            [date]="selectedDate"
            [hidden]="view !== 'calendar'"
            [ngClass]="{ 'reservation-opened': selectedReservation != null }"
            [view]="calendarView"
    >
    </app-reservation-calendar>
    <app-reservation-list
            (reservationFilterEvent)="reservationFilter($event)"
            (winerySchedulingClick)="winerySchedulingClicked($event)"
            *ngIf="view === 'list'"
            [defaultFilters]="statusFilter"
    >
    </app-reservation-list>
    <app-reservation-detail
            (closeClick)="selectedReservation = undefined"
            *ngIf="calendarView == 'day' && selectedReservation"
            [ngClass]="{ 'reservation-opened': selectedReservation != null }"
            [reservationId]="selectedReservation.id"
    ></app-reservation-detail>
    <web-appointment-detail
            (closeClick)="selectedAppointment = undefined"
            *ngIf="calendarView == 'day' && selectedAppointment"
            [appointment]="selectedAppointment"
            [ngClass]="{ 'appointment-opened': selectedReservation != null }"
    >
    </web-appointment-detail>
        <app-grouped-reservations-detail
                (addClick)="addGroupedClicked()"
                (closeClick)="selectedReservationGroup = undefined"
                (detailsClick)="detailsGroupedClicked($event)"
                *ngIf="calendarView == 'day' && selectedReservationGroup"
                [employees]="employees"
                [reservation]="selectedReservationGroup"
                [rooms]="rooms"
                class="noHeightLimit"
                style="border-radius: 15px"
        ></app-grouped-reservations-detail>

        <div
                *ngIf="calendarView == 'day' && !selectedReservation"
                class="no-event-selected"
        >
            <span>{{ "No event selected" | translate }}</span>
    </div>
  </div>
</div>