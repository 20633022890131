import { ExperienceEvents, EventId } from '@interfaces/dws/experience-events';
import { resource } from '@util/resource';
import { Observable } from 'rxjs';
import { Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ExperienceEventsAvailabilitiesService {
  constructor() {}

  registerNewEvent(experienceAvailability: ExperienceEvents, experienceId: string) : Observable<unknown>{
    return resource('crm://experiences/experienceId/event-availabilities')
        .params({ experienceId: experienceId })
        .post(experienceAvailability)
  }

  updateEvent(slotId: EventId, experienceAvailability: ExperienceEvents): Observable<unknown>{
    const { experienceId, day } = slotId;
    const time = slotId.time.replace(/:/g, '');

    return resource('crm://experiences/experienceId/event-availabilities/day-time')
        .params({
          experienceId,
          time,
          day
        })
        .put(experienceAvailability)
  }

  showExperienceEvents(experienceId : string) : Observable<ExperienceEvents[]> {
    return resource('crm://experiences/experienceId/event-availabilities')
        .params({ experienceId })
        .get<ExperienceEvents[]>()
  }

  removeEvent(slotId: EventId): Observable<unknown> {
    const { experienceId, day } = slotId;
    const time = slotId.time.replace(/:/g, '');
    return resource('crm://experiences/experienceId/event-availabilities/day-time')
        .params({
          experienceId,
          time,
          day
        })
        .delete()
  }
}

