import { Injectable } from '@angular/core';
import {ReservationOLD, ReservationFilters} from "../../interfaces/dws/reservation";
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { formatISO } from 'date-fns';
import { DWS } from "../../interfaces/dws";

/**
 * @deprecated use ReservationsDetailService instead
 */
@Injectable({
  providedIn: 'root'
})
export class ReservationsDwsService {
  constructor() { }

  list(wineryId: string, filters?: ReservationFilters, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.ReservationOLD>> {
    if (filters?.startTime instanceof Date) {
      filters.startTime = formatISO(filters?.startTime, { representation: 'date' });
    }
    if (filters?.endTime instanceof Date) {
      filters.endTime = formatISO(filters?.endTime, { representation: 'date' });
    }

    const appliedFilters = {
      wineryId,
      ...filters
    }

    return resource('crm://reservations')
        .params(
            { page, count, ...appliedFilters }
        )
        .get<DWS.Page<DWS.ReservationOLD>>();
  }

  get(reservationId: string): Observable<ReservationOLD> {
    return resource('crm://reservations/id')
        .params({ id: reservationId })
        .get<ReservationOLD>()
        // .pipe(tap(r => r.type = 'reservation'))
        ;
  }

}
