import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountriesCompleteService {

  constructor() { }

  list(): Observable<CountryComplete[]> {
    return from(fetch('/assets/data/countriesV2.json').then(r => r.json()));
  }
}
export interface CountryComplete {
  name: string;
  alpha2Code: string;
  alpha3Code: string;
  callingCodes: string[];
  translations: {
    en: string;
    it: string;
  }
}
