export class SaveAs {
  constructor(private content: string) {
  }
  
  as(file: string, contentType?: string) {
    const encodedUri = `data:${ contentType },${ encodeURIComponent(this.content) }`;
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', file);
    document.body.appendChild(link);
    
    link.click();
    
    setTimeout(() => link.remove(), 100);
  }
}

export function save(content: string) {
  return new SaveAs(content);
}