import { Component, Input, OnInit } from '@angular/core';
import { VwReservation } from '@interfaces/dws/reservation';

@Component({
  selector: 'app-event-appointment',
  template: `
    <div class="event-data" fxLayout="row" fxLayoutAlign="space-between">
      <div class="event-title" [ngStyle]="{ color: color }">
        
        <span>{{meta.optionalData?.appointment_title || '-'}}</span>
      </div>
    </div>
  `,
  styleUrls: ['./common.scss']
})
export class AppointmentEventComponent implements OnInit {
  @Input() meta!: VwReservation;
  @Input() width!: number;
  @Input() height!: number;
  @Input() color: any;
  @Input() view: 'week' | 'day' | 'month' = 'week';

  ngOnInit() {}
}
