import { Conversor } from '@util/convert/conversor';

export class ArrayBufferConverter implements Conversor<ArrayBuffer> {
  private readonly input: ArrayBuffer;

  constructor(input: ArrayBuffer) {
    this.input = input;
  }
  to(type: 'base64'): Promise<string>;
  to(type: 'arraybuffer'): Promise<ArrayBuffer>;
  to(type: 'blob'): Promise<Blob>;
  to(type: 'base64' | 'arraybuffer' | 'blob'): Promise<string> | Promise<ArrayBuffer> | Promise<Blob> | Promise<null> {
    switch (type) {
      case 'base64': return Promise.resolve(this.toBase64());
      case 'arraybuffer': return Promise.resolve(this.input);
      default: return Promise.resolve(null);
    }
  }

  private toBase64() {
    let binary = '';
    const bytes = new Uint8Array(this.input), len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }
}
