import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateDomainPipe } from '@web/app/pipes/translate/translate-domain.pipe';


@NgModule({
  declarations: [TranslateDomainPipe],
  exports: [TranslateDomainPipe],
  imports: [
    CommonModule
  ]
})
export class TranslateDomainModule { }
