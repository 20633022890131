import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdvancedFilters } from '@interfaces/advanced-filters';
import { Moment } from 'moment';
import { MatDatepicker } from "@angular/material/datepicker";
import { QRCodeReduced } from "@interfaces/dws/qr-code";

const transformArrayIntoObject = (key: String, arr: AdvancedFilters<any>[]) => {
  return arr.reduce((obj: any, item: { [x: string]: any; }) => ({ ...obj, [item[`${key}`]]: [undefined, []] }), {});
}

@Component({
  selector: 'app-presence-filter',
  templateUrl: './presence-filter.component.html',
  styleUrls: ['./presence-filter.component.scss']
})
export class PresenceFilterComponent implements AfterViewInit {
  form: FormGroup | undefined;
  dates: number[] = [];
  qrCodes: QRCodeReduced[] = [];
  appliedFilters: any;

  @ViewChild('datepicker', {read: undefined, static: false}) datepicker!: MatDatepicker<any>;
  dateClass = (date: Moment) => {
    return this.dates.indexOf(date.toDate().getTime()) != -1 ? ['selected'] : null;
  }

  shh(a: any) { return a as any };

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dlg: MatDialogRef<PresenceFilterComponent>,
    private fb: FormBuilder
  ) {
    this.qrCodes = data.qrCodes;
    this.appliedFilters = data.appliedFilters;
    this.form = this.createForm();
    this.fillForm();
  }

  ngAfterViewInit() {
  }

  createForm() {
    return this.fb.group({
      registrationFirstDate: ['', Validators.required],
      registrationLastDate: ['', Validators.required],
      registrationChannels: []
    })
  }

  fillForm() {
    this.form?.patchValue({
      registrationFirstDate: this.appliedFilters.start,
      registrationLastDate: this.appliedFilters.end,
      registrationChannels: this.appliedFilters.presence
    })
  }

  submitClicked() {
    this.dlg.close({
      presenceFilters: {
        startDate: this.form?.get("registrationFirstDate")?.value,
        lastDate: this.form?.get("registrationLastDate")?.value,
        qrCodeChannels: this.form?.get("registrationChannels")?.value
      }
    })
  }

  closeClicked() {
    this.dlg.close();
  }
}
