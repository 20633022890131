import { resource } from '@util/resource';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ExperienceDetails } from '@interfaces/experience';

@Injectable({
  providedIn: 'root'
})
export class ExperienceGenerationService {
  generateDetails(input: string): Observable<ExperienceDetails[]> {
    return resource('crm://experiences/generate/details')
      .params({ input })
      .get();
  }

  generateImage(input: string): Observable<Blob> {
    return resource('crm://experiences/generate/image')
      .params({ input })
      .get('blob');
  }
}
