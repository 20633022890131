import { Tag } from '@interfaces/tag';
import { pSBC } from 'util/psbc';
import { ExperiencePriceLabel } from "./experience-price-label";
import { ExperiencePriceExtra } from "./experience-price-extra";

export namespace Experience {
  import PriceLabel = ExperiencePriceLabel.PriceLabel;
  import PriceExtra = ExperiencePriceExtra.PriceExtra;
  export type CoverSize = 'l' | 'm' | 'original' | 'p' | 's' | 'v' | 'vv' | 'xl';
  export type Cover = {
    [size in CoverSize]: string
  }

  export interface Data {
    id: string;
    company?: any;
    days: {
      sun: boolean;
      mon: boolean;
      tue: boolean;
      wed: boolean;
      thu: boolean;
      fri: boolean;
      sat: boolean;
    }
    description: string;
    short_description: string;
    duration: string;
    event_date?: string;
    photos: any[];
    place: any;
    price: string;
    price01_label: string;
    price_currency: string;
    price_description: string;
    price_symbol: string;
    price02: string;
    price02_label: string;
    price02_currency: string;
    price02_description: string;
    price02_symbol: string;
    price02_active: boolean;
    enable_gifts: boolean;
    summary: string;
    tags: Tag[];
    timetable: string[];
    title: string;
    updated_at: string;
    interval: number;
    checkout: string;
    cover: Cover;
    days_for_automatic_approve: number;
    priority?: number;
    experience_prices_labels: PriceLabel[];
    experience_prices_extras: PriceExtra[];
    is_inquiry: boolean;
    is_gift_only: boolean;
  }

  export interface Details {
    title: string;
    description: string;
    shortDescription: string;
    languageIso: string;
  }

  export const cover = (experience: Experience, size?: CoverSize): string => {
    const c = experience.cover;
    size = size || 'original';
    return c[size];
  }

  export const color = (experience: Experience, state: string): string => {
    if (!experience) return 'fff';
    const c: string = experience
      .tags
      .filter(tag => tag.category?.code === 'exptype')
      .map(tag => tag.color)
      .shift() || '#eee';

    switch (state) {
      case 'draft':
        return pSBC(0.5, c) as string;
      case 'waiting':
        return pSBC(0.5, c) as string;
      case 'rejected':
        return pSBC(-0.5, c) as string;
      case 'revoked':
        return pSBC(-0.5, c) as string;
      case 'cancelled':
          return pSBC(-0.5, c) as string;
      case 'confirmed':
      default:
        return c;
    }
  }
}

export type Experience = Experience.Data;
export type ExperienceDetails = Experience.Details;