<div mat-dialog-content>
  <div class="wrapper">
    <p>{{ message }}</p>
    <div
      class="confirm-buttons"
      fxLayout="row"
      *ngIf="buttons.yes.display && buttons.no.display"
      [ngClass]="{ 'width-unset': (buttons.yes.text?.length || 0) > 15 || (buttons.no.text?.length || 0) > 15 }"
      >
      <button class="color-base-6" mat-stroked-button  (click)="noClicked()">
        {{buttons.no.text}}
      </button>
      <button class="btn-yes color-base-1" mat-stroked-button  (click)="yesClicked()">
        {{buttons.yes.text}}
      </button>
    </div>
    <div
      class="confirm-button"
      fxLayout="row"
      *ngIf="!buttons.yes.display || !buttons.no.display"
      [ngClass]="{ 'width-unset': (buttons.yes.text?.length || 0) > 15 || (buttons.no.text?.length || 0) > 15 }"
      >
        <button class="color-base-6" mat-stroked-button *ngIf="buttons.no.display" (click)="noClicked()">
            {{buttons.no.text}}
          </button>
        <button class="btn-yes color-base-1" mat-stroked-button *ngIf="buttons.yes.display" (click)="yesClicked()">
          {{buttons.yes.text}}
        </button>
      </div>
  </div>
</div>
