<div mat-dialog-content>
  <mat-toolbar>
    <div></div>
    <div class="actions">
      <button mat-icon-button (click)="closeClicked()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <div style="margin-top: 20px">
    <div
      class="line"
      fxLayout="row"
      fxLayoutAlign="start center"
      style="margin: 0 20px"
    >
      <div class="sec1">
        <div class="title">
          <i class="icon-calendar"></i>
          <span>{{ title | translate }}</span>
        </div>
      </div>
      <div>
        <div class="customCalendarButtons">
          <form [formGroup]="dateToUse">
            <div
              style="
                display: flex;
                justify-content: space-between;
                flex-direction: row;
              "
            >
              <mat-form-field
                class="example-form-field"
                style="width: 150px"
                [ngClass]="{
                  'mat-form-field-invalid':
                    dateToUse.hasError('dateRangeInvalid')
                }"
              >
                <div (click)="startPicker.open()">
                  <input
                    matInput
                    [matDatepicker]="startPicker"
                    formControlName="start"
                    [ngClass]="{
                      'input-error': dateToUse.get('end')?.invalid
                    }"
                    [placeholder]="'Start date' | translate"
                    readonly
                  />
                </div>
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="startPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #startPicker></mat-datepicker>
              </mat-form-field>
              <mat-form-field
                class="example-form-field"
                style="width: 150px"
                [ngClass]="{
                  'mat-form-field-invalid':
                    dateToUse.hasError('dateRangeInvalid') &&
                    (dateToUse.get('end')?.touched ||
                      dateToUse.get('end')?.dirty)
                }"
              >
                <div (click)="endPicker.open()">
                  <input
                    matInput
                    [matDatepicker]="endPicker"
                    formControlName="end"
                    [ngClass]="{
                      'input-error':
                        dateToUse.hasError('dateRangeInvalid') &&
                        (dateToUse.get('end')?.touched ||
                          dateToUse.get('end')?.dirty)
                    }"
                    [placeholder]="'End date' | translate"
                    readonly
                  />
                </div>

                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="endPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #endPicker></mat-datepicker>
              </mat-form-field>
            </div>
          </form>
        </div>

        <div style="display: flex; gap: 3rem">
          <mat-form-field
            style="width: 150px"
            [formGroup]="dateToUse"
            [ngClass]="{
              'mat-form-field-invalid': dateToUse.hasError('timeRangeInvalid')
            }"
          >
            <input
              matInput
              type="time"
              [placeholder]="'Start Time' | translate"
              formControlName="startTime"
              [ngClass]="{
                'input-error': dateToUse.get('startTime')?.invalid
              }"
            />
          </mat-form-field>
          <mat-form-field
            style="width: 150px"
            [formGroup]="dateToUse"
            [ngClass]="{
              'mat-form-field-invalid': dateToUse.hasError('timeRangeInvalid')
            }"
          >
            <input
              matInput
              type="time"
              [placeholder]="'End Time' | translate"
              formControlName="endTime"
              [ngClass]="{
                'input-error': dateToUse.get('endTime')?.invalid
              }"
            />
          </mat-form-field>
        </div>

        <mat-form-field class="example-form-field">
          <!-- Reservation ID Autocomplete -->
          <input
            (input)="filterExperiences()"
            [(ngModel)]="selectedReservation"
            [formControl]="experienceIdForm"
            [matAutocomplete]="auto"
            [placeholder]="'Experience name (Optional)' | translate"
            matInput
            type="text"
          />
          <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="displayReservation"
          >
            <mat-option
              *ngFor="let experience of filteredOptions"
              [value]="experience"
            >
              {{ displayReservation(experience) }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="centeredFlexDiv dwsLittlePadding">
      <button
        (click)="exportPDFClicked()"
        class="search-btn"
        mat-button
        type="button"
      >
        {{ "Print" | translate }}
      </button>
    </div>
  </div>
</div>
<reservation-pdf-exporter #exporterPDFPop></reservation-pdf-exporter>
