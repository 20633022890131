import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { observe } from 'projects/web/src/util/loading/loading';
import { Host } from '@interfaces/host';
import { CompanyService } from '@services/company.service';
import { access } from '@util/route/access';

@Injectable({
  providedIn: 'root'
})
export class CompanyResolverService implements Resolve<Host.Company> {
  constructor(private companyService: CompanyService) {
  }
  
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Host.Company> {
    const companyId = access(route).param('companyId');
    if (!companyId) return this.companyService.self();
    return observe(this.companyService.get(companyId));
  }
}
