import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { from } from 'rxjs';

import { first, map, switchMap } from 'rxjs/operators';
import { InactiveTransactionalEmailsService } from "../inactive-transactional-emails.service";
import { AccountsService } from "../../accounts.service";
import { DWS } from "../../../interfaces/dws";
import { observe } from "../../../projects/web/src/util/loading/loading";
import { InactiveTransactionalEmail } from '@interfaces/dws/inactive-transactional-email';

@Injectable({
  providedIn: 'root'
})
export class InactiveTransactionalEmailsResolverService implements Resolve< InactiveTransactionalEmail[]> {
  constructor(private service: InactiveTransactionalEmailsService, private accounts: AccountsService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InactiveTransactionalEmail[]> {
    const args$ = from(this.accounts.getLogin()).pipe(map(login => login!.company!.id));
    const obs$ = args$.pipe(first(), switchMap(args => this.service.list(args)));
    return observe(obs$).pipe(map(e =>e));
  }
}

