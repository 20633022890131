import { CalendarDateFormatter, DateFormatterParams, getWeekViewPeriod } from 'angular-calendar';
import { DatePipe, formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class CalendarFormatterProvider extends CalendarDateFormatter {
  public weekViewTitle({ date, locale, weekStartsOn, excludeDays, daysInWeek, }: DateFormatterParams): string {
    const { viewStart, viewEnd } = getWeekViewPeriod(
      this.dateAdapter,
      date,
      weekStartsOn as number,
      excludeDays,
      daysInWeek
    );
    const format = (dateToFormat: Date, showYear: boolean, showMonth: boolean) =>
      formatDate(dateToFormat, `${showMonth ? 'd MMM' : 'd'}` + (showYear ? ', yyyy' : ''), locale as string);
    return `${format(
      viewStart,
      viewStart.getUTCFullYear() !== viewEnd.getUTCFullYear(), viewStart.getMonth() != viewEnd.getMonth()
    )} - ${format(viewEnd, viewStart.getUTCFullYear() !== viewEnd.getUTCFullYear(), true)}`;
  }

  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale as string).transform(date, 'EEE', locale) as string;
  }

  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale as string).transform(date, 'EEE dd', locale) as string;
  }

  public weekViewColumnSubHeader({ date, locale, }: DateFormatterParams): string {
    return '';
  }

  public monthViewTitle({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale as string).transform(date, 'MMMM', locale) as string;
  }

  public dayViewTitle({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'd MMMM', locale as string);
  }

  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale as string).transform(date, 'HH:mm', locale) as string;
  }

  public weekViewHour({ date, locale }: DateFormatterParams): string {
    return new DatePipe(locale as string).transform(date, 'HH:mm', locale) as string;
  }
}
