import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { io, Socket } from "socket.io-client";
import { AccountsService } from '@services/accounts.service';
import { Observable, of } from 'rxjs';
import { share } from 'rxjs/operators';


interface ServerToClientEvents {
	[k: string ]: (data: any) => void,
	messageToClient: (data: any) => void,
	noArg: () => void;
}

interface ClientToServerEvents {
	hello: () => void;
}

interface InterServerEvents {
	ping: () => void;
}

interface SocketData {
	name: string;
	age: number;
}

@Injectable()
export class DwsSocket {

	subscribersCounter: Record<string, number> = {};
	eventObservables$: Record<string, Observable<any>> = {};
	socket?: Socket<ServerToClientEvents, ClientToServerEvents>;

	constructor(
		private accounts: AccountsService,
	) {
	}

	async session() {
		const g = await this.accounts.getLogin();
		let bearerToken: string = (g?.authorization.token || "").replace('Bearer ', '');
		const opts = {
			transports: ['websocket'],
			query: {
				bearerToken,
			}
		};
		this.socket = io(getWebSocketUrl(), opts)
	}


	fromEvent<T>(eventName: string): Observable<T> {
		if (this.socket) {
			if (!this.subscribersCounter[eventName]) {
				this.subscribersCounter[eventName] = 0;
			}
			this.subscribersCounter[eventName]++;

			if (!this.eventObservables$[eventName]) {
				console.log("from obs")
				this.eventObservables$[eventName] = new Observable((observer: any) => {
					const listener = (data: T) => {
						console.log("from listeners")
						observer.next(data);
					};
					this.socket?.on(eventName, listener);
					return () => {
						this.subscribersCounter[eventName]--;
						if (this.subscribersCounter[eventName] === 0) {
							this.socket?.removeListener("messageToClient", listener);
							delete this.eventObservables$[eventName];
						}
					};
				}).pipe(share());
			}
			return this.eventObservables$[eventName];
		} else {
			return of({} as T);
		}

	}




}

export function getWebSocketUrl(): string {
	const ws = environment.hosts.websocket;
	const port = !ws.port ? "" : `:${ws.port}`;
	const wsUrl = `${ws.protocol}://${ws.host}${port}/${ws.root}`
	let s = `${wsUrl}/reservations`;
	//console.log(s);
	return s;
}