import { Component, OnInit } from '@angular/core';
import { ReservationDetailService } from "@services/dws/scheduling/reservation-detail/reservation-detail.service";
import { tr } from '@util/tr';
import { toast } from '@web/util/toast';
import * as moment from 'moment';
import { ReservationLog } from '@interfaces/reservation';

@Component({
  selector: 'web-reservation-logs',
  templateUrl: './reservation-logs.component.html',
  styleUrls: ['./reservation-logs.component.scss']
})
export class ReservationLogsComponent implements OnInit {
  displayedColumns: string[] = ['contactId', 'automatic', 'actionType', 'details', 'accountId', 'updateTimestamp'];
  reservationId: string | undefined;
  data: (ReservationLog & { popoverVisibility: boolean })[] | undefined;

  constructor(private reservationDetailService: ReservationDetailService) { }

  ngOnInit() {}

  fetch() {
    if(!this.reservationId) return;
    this.reservationDetailService.getAdminReservationHistory(this.reservationId).subscribe((data) => {
      this.data = data.map(x => ({ ...x, popoverVisibility: false }));
    });
  }

  printObject(obj: Object) {
    return JSON.stringify(obj, null, "\t");
  }

  copyToClipboard(obj: Object): void {
    const str = JSON.stringify(obj);
    navigator.clipboard.writeText(str).then(function() {
      toast(tr("Copied to clipboard!"));
    }, function(err) {
      toast(tr("Could not copy to clipboard"));
      console.error(err);
    });
  }

  formatDate(date: Date): string {
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  }
}