<div class="event-data event-view-{{ view }} {{ status.cssClass }}">
  <span
          [ngStyle]="{'background-color': meta.experience && meta.experience![0]?.color ? meta.experience[0].color : 'unset'}"
          class="experienceColorFlag"></span>


    <div [ngStyle]="{ color: color }" class="event-title">
    <span><strong>{{ capitalizeEachWord(getExperienceName(meta)) }}{{view == 'day' ? (' | ' +
        capitalizeEachWord(contactFullName)) :'' }}</strong></span>
  </div>

  <div class="event-title" *ngIf="view == 'week'" [ngStyle]="{ color: color }">
      <span><strong>{{ capitalizeEachWord(contactFullName) }}</strong></span>
  </div>

  <div class="align">
    <div>
      <div class="event-total" [ngStyle]="{ color: color }">
        <i class="icon-global"></i>
        <span>{{ meta.languageIso ? (('lang_' + meta.languageIso) | translate) : '' }}</span>
      </div>

      <div class="event-total" [ngStyle]="{ color: color }">
        <i class="icon-euro"></i>
          <span>{{formatTotal(meta.netTotalCents || 0)}}</span>
      </div>

      <div class="event-time" [ngStyle]="{ color: color }" *ngIf="view !== 'week' && view !== 'day'">
        <i class="icon-clock"></i>
        <span>
          {{
          moment(meta.date + 'T' + meta.time).toDate().toLocaleTimeString("en", {
          hour: "2-digit",
          minute: "2-digit"
          })
          }}</span>
      </div>

        <div *ngIf="interval() > 60*60 && meta.roomId" [ngStyle]="{ color: color }" class="event-room">
        <i class="icon-door"></i>
            <span>{{ capitalizeEachWord(roomName(meta.roomId)) }}</span>
      </div>

        <div *ngIf="interval() > 60*60 && meta.employeeId" [ngStyle]="{ color: color }" class="event-people">
        <i class="icon-employee"></i>
            <span>{{ capitalizeEachWord(employeeName(meta.employeeId)) }}</span>
      </div>

      <div class="event-status" [ngStyle]="{ color: color }" [ngClass]="status.cssClass">
        <i class="icon-{{ status.icon }}"></i>
        <span>{{ status.text }}</span>
      </div>
    </div>
  </div>

    <span *ngIf="meta.isTourOperator" class="ribbon">{{'OTA' | translate}}</span>
</div>