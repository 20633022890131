import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';
import EventType = DWS.Event.EventType;

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor() {
  }

  list(filter?: EventsFilter, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.Event>> {
    return resource('crm://events')
      .params(
        { page, count, ...filter }
      )
      .get<DWS.Page<DWS.Event>>();
  }

  create(event: DWS.Event): Observable<DWS.Event> {
    return resource('crm://events')
      .post<DWS.Event>(event);
  }

  update(id: number, event: DWS.Event): Observable<DWS.Event> {
    return resource('crm://events/id')
      .params({ id })
      .put<DWS.Event>(event);
  }
  
  get(id: number): Observable<DWS.Event> {
    return resource('crm://events/id')
      .params( { id })
      .get<DWS.Event>();
  }  

  delete(id: number): Observable<void> {
    return resource('crm://events/id')
      .params( { id })
      .delete()
  }
}

export interface EventsFilter {
  contactId?: any;
  wineryId?: string;
  type?: EventType;
}
