import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReservationComponent } from '../dialogs/reservation/reservation.component';
import { VwReservation } from '@interfaces/dws/reservation';
import { SchedulingService } from '@services/dws/scheduling/page-scheduling/page-schedulings.service';
import { Login } from '@services/accounts.service';
import { access } from '@util/route/access';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { catchError, debounceTime, distinctUntilChanged, pluck, switchMap, tap } from 'rxjs/operators';
import { result } from 'lodash';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { of, Subject } from 'rxjs';
import { locale } from '@util/locale';
import { tr } from '@util/tr';

@Component({
  selector: 'web-reservation-search-bar',
  templateUrl: './reservation-search-bar.component.html',
  styleUrls: ['./reservation-search-bar.component.scss'],
})
export class ReservationSearchBarComponent implements OnInit {

  @Output() submit = new EventEmitter<string>();
  @Output() openStateToggled = new EventEmitter<boolean>();
  @Input() open = false;

  private searchText$ = new Subject<string>();
  searchResults: VwReservation[] = [];
  @ViewChild('searchBox') searchBox!: any;

  login: Login;
  lang: string = 'it';
  loading = false;

  constructor(
    private route: ActivatedRoute,
    private dlg: MatDialog,
    private schedulingService: SchedulingService,
  ) {
    this.login = access(this.route).data("login");
    this.lang = locale().locale;
  }

  ngOnInit() {
    this.searchText$.pipe(
      distinctUntilChanged(),
      tap(_ => {this.loading = true; this.searchResults = [];}),
      debounceTime(300),
      switchMap(searchText => this.schedulingService.list(
          this.login.company.id, {
            startTime: moment().toDate(),
            searchText
          }, undefined, undefined, 'date,ASC'
        ).pipe(
          tap(_ => this.loading = false),
          catchError(_ => of({content: []})),
          pluck("content"),
        )
      )
    ).subscribe(results => this.searchResults = results);
  }

  toggleOpen() {
    this.open = !this.open;
    if (this.open) setTimeout(() => this.searchBox.nativeElement.focus(), 200);
    this.openStateToggled.emit(this.open);
  }

  reservationClicked(event: MatAutocompleteSelectedEvent) {
    this.searchBox.nativeElement.value = '';
    this.searchResults = [];

    this.dlg.open(ReservationComponent, {
      data: {reservationId: event.option.value},
      minWidth: 1031,
    });
  }
  
  onKeyUp(event: KeyboardEvent) {
    const v = (event.target as HTMLInputElement).value;
    if (event.key === 'Enter') {
      this.searchResults = [];
      this.submit.emit(v);
    } else {
      this.searchText$.next(v);
    }
  }

  mapForDisplay(reservation: VwReservation): {[key: string]: any} {
    let masterContact = reservation.reservationContacts?.find(c => c.contact_type === 'MASTER');
    let expContact = masterContact?.first_name || '';
    expContact += masterContact?.first_name && masterContact?.last_name ? ' ' + masterContact.last_name : '';
    if (expContact === '') expContact = 'No name';
    if (masterContact?.email) expContact += ' - ' + masterContact.email;

    let m = moment(reservation.date);

    return {
      id: reservation.id,
      expName: reservation.experienceTranslations?.find(t => t.translation_id == 'TITLE')?._translations[this.lang],
      expDay: tr( m.diff(moment().startOf('day'), 'days') > 1
        ? m.format("DD/MM/YYYY")
        : m.diff(moment().startOf('day'), 'days') === 0
          ? 'Today'
          : 'Tomorrow'
      ),
      expTime: moment(reservation.time, "HH:mm:ss").format('HH:mm'),
      expContact
    };
  }

  ngOnDestroy() {
    this.searchText$.unsubscribe();
  }
}