import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { resource } from '@util/resource';
import { catchError, map } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { storage } from 'util/storage';

export interface Authorization {
  access_token: string;
  username?: string;
  is_admin: boolean;
  is_winery: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticateAdminService {
  authorization$: ReplaySubject<Authorization | null> = new ReplaySubject<Authorization | null>(1);

  constructor() {
    storage.get('authorization').then(a => this.authorization$.next(a));
    storage.get('authorization').then(auth => this.authorization$.next(auth));
    this.authorization$.subscribe(async auth => {
      await storage.set('is_admin', !!auth?.is_admin);
      await storage.set('is_winery', !!auth?.is_winery);
      await storage.set('authorization', auth);
    })
  }

  login(email: string, password: string): Observable<Authorization> {
    const base64password = btoa(password);
    return resource('crm://admin/authorize')
      .post<any>({email, base64password}, {observe: 'response'})
      .pipe(
        catchError(err => {
          if (err.status === 401 || err.status === 400) {
            return throwError(new Error('Invalid credentials'));
          }
          return throwError(new Error('Unknown error'));
        }))
      .pipe(
        map((response) => {
          if (response instanceof HttpResponse) {
            const token = response.headers.get('Authorization');
            const usuario = response.body;
            usuario.access_token = token;
            usuario.is_admin = true;
            usuario.is_winery = false;
            return usuario;
          }
        })
      );
  }

  async use(authorization: Authorization): Promise<void> {
    this.authorization$.next(authorization);
    await new Promise(r => setTimeout(r, 40));
  }

  logout() {
    this.authorization$.next(null);
  }
}
