import {Component, Input, OnChanges, OnInit, SimpleChanges, ElementRef, ViewChild} from '@angular/core';
import * as QRCode from 'qrcode'
import {toast} from '@web/util/toast';
import {tr} from '@util/tr';
import {ActivatedRoute} from "@angular/router";
import {access} from "@util/route/access";
import {ga} from "@util/ga";
import {TranslateService} from "@ngx-translate/core";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import {Login} from "@services/accounts.service";
import {QrCodeResolved} from "@services/resolvers/qrcode-resolver.service";
import {QRCodeDisplay} from "@interfaces/dws/qr-code";

@Component({
  selector: 'app-qr-code-generate',
  templateUrl: './qr-code-generate.component.html',
  styleUrls: ['./qr-code-generate.component.scss']
})

export class QrCodeGenerateComponent implements OnInit, OnChanges {
  @Input() code: string = "";
  @Input() showCopyButton: boolean = true;
  company: { id: any, name: string, avatar: string };
  login: Login;
  qrCode?: QRCodeDisplay
  downloadIsTrue = false;
  @ViewChild('pdfTable', {static: false}) pdfTable?: ElementRef;

  ngOnChanges(changes: SimpleChanges): void {
    if ('code' in changes) {
      this.generate();
    }
  }

  async showMessage() {
    await toast(tr("Link copied"));
  }

  private generate() {
    QRCode.toCanvas(document.getElementById('code'), this.code, {width: 300}).then();
  }

  constructor(
      private route: ActivatedRoute,
      public translate: TranslateService,
  ) {
    this.login = access(route).data('login');
    this.company = access(route).data('company');
    const data = access(route).data('qrcode') as QrCodeResolved;
    this.qrCode = data?.qrcode;
  }

  ngOnInit(): void {
    ga.track('QR Code Generate');
  }

  public downloadAsPDF(): void {
    this.downloadIsTrue = true;
    const doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [148, 210]
    });

    let wineSuite = 'assets/images/logo-primary-horizontal.png';

    html2canvas(<HTMLElement>document.body.querySelector("canvas")!).then((canvas) => {
      doc.addImage(this.company.avatar, 'PNG', 42, 2, -200, 0, '', "NONE", 0)
      doc.addImage(canvas, 'PNG', 25, 68, 100, 100, '', "NONE", 0)
      doc.addImage(wineSuite, 'PNG', 46, 190, -800, 0, '', "NONE", 0)
      doc.save('qr-code.pdf');
    });

    this.downloadIsTrue = false;
  }

  // PLEASE DO NOT DELETE, ALTERNATIVE PRINT TEMPLATE

  // public downloadAsPDF(): void {
  //   // window.print();
  //   this.downloadIsTrue = true;
  //   const doc = new jsPDF({
  //     orientation: "landscape",
  //     unit: "px",
  //     format: [2480, 1748]
  //   });
  //
  //   // THIS LINE TAKES THE WHOLE PAGE
  //   // html2canvas(document.body).then(function(canvas) {
  //   html2canvas(<HTMLElement>document.body.querySelector("mat-card")!).then((canvas) => {
  //     // document.body.appendChild(canvas);
  //     doc.addImage(canvas, 'PNG', 874, 250, 0, 1240)
  //     doc.save('qr-code.pdf');
  //   });
  //
  //   this.downloadIsTrue = false;
  //
  //   // doc.addImage(canvas, 'PNG', 0, 0, 1748, 2480)
  //   // doc.save('qr-code.pdf');
  // }
}