import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { locale } from '@util/locale/locale';

@Injectable()
export class LangInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let lang = locale().locale;
    const localeSupported = locale().getLocale(lang);
    if(!locale().isLocaleDWSSupported(lang)){
      lang = localeSupported.fallbackLang;
    }
    const params = req.params.append('lang', lang);
    const r = req.clone({
      params,
      setHeaders: {
        'Accept-Language': lang
      }
    })
    return next.handle(r);
  }
}
