import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { environment } from 'environments/environment';
import { resource } from '@util/resource';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { AccountsService } from 'services/accounts.service';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Notification } from '@interfaces/notifications';
import { SwUpdate } from '@angular/service-worker';
import { lazyToast } from '@web/util/toast/toast';
import { tr } from "../util/tr";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  token$: ReplaySubject<string> = new ReplaySubject<string>(1);
  notification$: ReplaySubject<Notification[] | null> = new ReplaySubject<Notification[] | null>(1);
  messages$: ReplaySubject<Notification[] | null> = new ReplaySubject<Notification[] | null>(1);
  totalUnreadedNotifications$: ReplaySubject<number> = new ReplaySubject<number>(1);
  totalUnreadedMessages$: ReplaySubject<number> = new ReplaySubject<number>(1);

  constructor(private accountsService: AccountsService, private updates: SwUpdate) {
    this.initializeFirebaseEnvironment().then();
    combineLatest([this.token$, accountsService.account$])
      .pipe(
        filter(([_, account]) => account != null),
        switchMap(([token, _]) => this.registerDevice(token))
      )
      .subscribe(() => {
        console.info('user registered for notifications');
      })
  }

  private async initializeFirebaseEnvironment() {
    const workerPath = environment.values['fcm-worker'];
    const swRegistration = await navigator.serviceWorker.register(workerPath);
    this.updates.available.subscribe(() => {
      this.updates.activateUpdate().then(() => {
        lazyToast(tr("New version of software is available - Please reload application"), 0, "top", tr("Reload"))
          .then(() =>
            document.location.reload()
          );
      });
    });
    const vapidKey = environment.values.firebaseAuthKey;
    firebase.initializeApp(environment.values.firebaseConfig);
    const messaging = firebase.messaging();
    try {
      const token = await messaging.getToken({ vapidKey, serviceWorkerRegistration: swRegistration });
      this.token$.next(token);

      messaging.onMessage((x) => {
        console.log(x);
        if (x && x.data && x.data.type && x.data.type === 'notification') {
          lazyToast(x.notification.title + " - " + x.notification.body, 10000, "bottom");
        } else {
          this.list().subscribe();
          this.listMexs().subscribe();
        }
      });
    } catch (err) {
      console.info('error asking for notification token');
    }

  }

  private registerDevice(token: string) {
    console.log(token);
    return resource('v2://notification-tokens')
      .post({ token })
  }

  list(page: number = 1, size: number = 10, show_unreaded: boolean = false, show_readed: boolean = false): Observable<Notification[]> {
    return resource('v2://host/push-notifications')
      .params({ page: page, per_page: size, show_unreaded: show_unreaded, show_readed: show_readed })
      .get<{ data: Notification[], unreaded_number: number }>()
      .pipe(
        map(response => response),
        tap((newNotifications) => {
          var notifications = newNotifications.data;
          var unreaded_number = newNotifications.unreaded_number;
          if (page === 1) {
            this.notification$.next(notifications);
          } else {
            // Append the new notifications to the existing ones
            this.notification$.pipe(take(1)).subscribe(existingNotifications => {
              const updatedNotifications = existingNotifications ? [...existingNotifications, ...notifications] : notifications;
              // Update the notification$ subject with the updated notifications
              this.notification$.next(updatedNotifications);
            });
          }

        }),
        tap((newNotifications) => {
          this.totalUnreadedNotifications$.next(newNotifications.unreaded_number);
        })
        ,
        map(response => response.data)
      );
  }

  listMexs(page: number = 1, size: number = 10, show_unreaded: boolean = false, show_readed: boolean = false): Observable<Notification[]> {
    return resource('v2://host/push-notifications/mex')
      .params({ page: page, per_page: size, show_unreaded: show_unreaded, show_readed: show_readed })
      .get<{ data: Notification[], unreaded_number: number }>()
      .pipe(
        map(response => response),
        tap((newMessages) => {
          var notifications = newMessages.data;
          var unreaded_number = newMessages.unreaded_number;
          if (page === 1) {
            this.messages$.next(notifications);
          } else {
            // Append the new notifications to the existing ones
            this.messages$.pipe(take(1)).subscribe(existingNotifications => {
              const updatedNotifications = existingNotifications ? [...existingNotifications, ...notifications] : notifications;
              // Update the notification$ subject with the updated notifications
              this.messages$.next(updatedNotifications);
            });
          }

        }),
        tap((newMessages) => {
          this.totalUnreadedMessages$.next(newMessages.unreaded_number);
        }),
        map(response => response.data)
      );
  }

  get(notificationId: string): Observable<Notification> {
    return resource('v2://host/push-notifications/id')
      .params({ id: notificationId })
      .get<Notification>();
  }

  markAllAsViewed() {
    return resource('v2://host/push-notifications/mark-as-viewed')
      .put({ messages: false })
  }

  markAllAsViewedMex() {
    return resource('v2://host/push-notifications/mark-as-viewed')
      .put({ messages: true })
  }


  markAsViewed(notificationId: string) {
    return resource('v2://host/push-notifications/mark-as-viewed-single')
      .post({ id: notificationId })
  }
}