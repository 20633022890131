<div mat-dialog-content>
    <mat-toolbar>
        <div>
            
        </div>
        <div class="actions">
            <button (click)="closeClicked()" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>

    <div class="body-wrapper">
        <p class="bold">{{'Choose the automations to activate' | translate}}:</p>
        <div class="flex column">
            <mat-checkbox (change)="toggleValue($event, 'closeSlots')" *ngIf="!isAvailabilityBlock" [disabled]="hasEnabledAutomations">
                {{'Close slots at the same time' | translate}}
                <web-info-icon-hoverable
                    [description]="
                    'This will close all slots for other experiences at the same time. This won\'t affect this experience\'s slots.'
                    | translate
                    "
                    iconOverrides="position: absolute; margin-left: 5px;"
                ></web-info-icon-hoverable>
            </mat-checkbox>
            <mat-checkbox (change)="toggleValue($event, 'openSlots')" *ngIf="isAvailabilityBlock" [disabled]="hasEnabledAutomations">
                {{'Open slots at the same time' | translate}}
                <web-info-icon-hoverable
                    [description]="
                    'This will open all slots for other experiences at the same time. This won\'t affect this experience\'s slots.'
                    | translate
                    "
                    iconOverrides="position: absolute; margin-left: 5px;"
                ></web-info-icon-hoverable>
            </mat-checkbox>
            <mat-checkbox (change)="toggleValue($event, 'deactivateOtherLangs')" *ngIf="!isLanguageBlock" [disabled]="hasEnabledAutomations">
                {{'Deactivate different languages' | translate}}
                <web-info-icon-hoverable
                    [description]="
                    'This will deactivate every language for this slot except the one selected for the current reservation. This won\'t affect the other experiences\' slots.'
                    | translate
                    "
                    iconOverrides="position: absolute; margin-left: 5px;"
                ></web-info-icon-hoverable>
            </mat-checkbox>
            <mat-checkbox (change)="toggleValue($event, 'activateOtherLangs')" *ngIf="isLanguageBlock" [disabled]="hasEnabledAutomations">
                {{'Activate different languages' | translate}}
                <web-info-icon-hoverable
                    [description]="
                    'This will activate every language available for this slot. This won\'t affect the other experiences\' slots.'
                    | translate
                    "
                    iconOverrides="position: absolute; margin-left: 5px;"
                ></web-info-icon-hoverable>
            </mat-checkbox>
        </div>
        <br />
        <p *ngIf="hasEnabledAutomations">
            <mat-icon>info</mat-icon>
            {{"These options are being handled by the active automations" | translate}}
        </p>
        <br />
        <div class="confirm-buttons" fxLayout="row">
            <button class="color-base-6" mat-stroked-button  (click)="closeClicked()">
              {{'Cancel' | translate}}
            </button>
            <button class="btn-yes color-base-1" mat-stroked-button  (click)="confirmClicked()">
              {{'Confirm!' | translate}}
            </button>
        </div>
    </div>
</div>