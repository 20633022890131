// tslint:disable:no-console
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { catchError, flatMap, share, tap } from 'rxjs/operators';
import { HttpCacheService } from '@services/http-cache.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpCacheInterceptorService
  implements HttpInterceptor {
  requests: {
    [k: string]: Observable<any> | null
  } = {};

  constructor(private cache: HttpCacheService) { console.log('hello cache'); }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const xForceCache = (req.headers.get('x-force-cache') || '').toString();
    if (environment.values['cache']['enabled'] === false && !xForceCache) return next.handle(req);
    if (req.method !== 'GET' && !xForceCache || req.url.indexOf('i18n') != -1) {
      return next.handle(req);
    }
    const xMaxAge = (req.headers.get('x-max-age') || '').toString();
    let maxAge = 10 /* m */ * 60 /* s */ * 1000;
    if (environment.values && environment.values.cache['max-age']) {
      maxAge = parseInt(environment.values.cache['max-age'] as string);
    }

    if (xMaxAge === '0') {
      return next.handle(req);
    }
    if (xMaxAge.startsWith('inf')) {
      maxAge = -1;
    }
    if (xMaxAge !== '') {
      maxAge = parseInt(xMaxAge, 10);
    }

    req = req.clone ({
      headers: req.headers.delete('x-max-age').delete('x-force-cache')
    });

    return this
      .cache
      .get(req)
      .pipe(
        // @ts-ignore
        flatMap((cached: HttpResponse<any>) => {
          if (cached) {
            console.debug('[http-cache] cache de', req.urlWithParams, 'encontrado');
            return of(cached) as Observable<HttpEvent<any>>;
          }
          return this.dispatch(req, next, maxAge) as Observable<HttpEvent<any>>;
        })
      );
  }

  dispatch(req: HttpRequest<any>, next: HttpHandler, maxAge: number): Observable<HttpEvent<any>> {
    const k = req.urlWithParams;
    if (this.requests[k]) {
      console.debug(`[http-cache] %congoing ${k}`, 'color: #FFCA08');
      return this.requests[k] as Observable<HttpEvent<any>>;
    }

    this.requests[k] = next
      .handle(req)
      .pipe(
        tap((response: HttpEvent<any>) => {
          if (response instanceof HttpResponse) {
            console.debug(`[http-cache] %cfinished ${k}`, 'color: #008140');
            this.requests[k] = null;
            if (response.ok) {
              this
                .cache
                .set(req, response, maxAge)
                .subscribe(() => console.debug(`[http-cache] %ccaching ${k} for ${maxAge} ms`, 'color: #008140'));
            }
          }
        }),
        catchError(err => {
          this.requests[k] = null;
          return throwError(err);
        }),
        share()
      );

    return this.requests[k] as Observable<HttpEvent<any>>;
  }
}
