<div mat-dialog-content>
    <mat-toolbar>
        <div class="title">
            <span class="title-ref">{{'Language' | translate}}</span>
        </div>


        <div class="actions">
            <button (click)="onCancelClick()" mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </mat-toolbar>

    <div class="content-wrapper">

        <div>
            <mat-label>{{'Language' | translate}}</mat-label>
            <mat-select [(value)]="selectedLanguage">
                <mat-option value="it">{{'Italian' | translate}}</mat-option>
                <mat-option value="en">{{'English' | translate}}</mat-option>
                <mat-option value="de">{{'German' | translate}}</mat-option>
            </mat-select>
        </div>

    </div>

    <div class="content-row">
        <div class="actions-wrapper buttons-row justifyAround">
            <button (click)="onCancelClick()" mat-stroked-button>{{'Cancel' | translate | uppercase}}</button>
            <div class="actions-buttons">
                <button (click)="onConfirmClick()" class="dws-elevation-z2" mat-flat-button>{{'Print gift' | translate |
                    uppercase}}
                </button>
            </div>
        </div>
    </div>

</div>