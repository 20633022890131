import { CalendarSchedulingEvent } from "@services/dws/scheduling/calendar-event/calendar-scheduling-event";
import { capitalizeEachWord } from "../../../../util/transform-string/capitalize-each-word";
import { TranslateDomainPipe } from "@web/app/pipes/translate/translate-domain.pipe";
import { VwReservation } from "@interfaces/dws/reservation";

export const formatLabels = (
  labels: any[],
  language: string,
  separator: string
) => {
  let formattedResult: string = "";
  labels
    .sort((a, b) => a.position - b.position)
    .forEach((label) => {
      if (label.quantity > 0) {
        var title = "-";
        if (label.title_translations) {
          title =
            label.title_translations[language] ||
            label.title_translations["it"];
        }

        let formattedLabel: string =
          capitalizeEachWord(title) + ": " + label.quantity + separator;
        formattedResult = formattedResult.concat(formattedLabel);
      }
    });
  return formattedResult.substring(
    0,
    formattedResult.length - separator.length
  );
};

export const formatLabelsFromEvent = (
  event: VwReservation,
  language: string,
  separator: string
) => {
  let labels: string = "";
  if (!!!!event.guestCount01 && event.guestCount01 > 0) {
    labels = capitalizeEachWord(
      (event.experienceTranslations?.find(t => t.translation_id == "TITLE_PRICE_LABEL_0")?._translations[language] || "Label 1") +
        ": " +
        event.guestCount01
    );
  }
  let labelList: any[] = [];
  if ((event.reservationPriceLabels?.length || 0) > 0) {
    labelList = labelList.concat(event.reservationPriceLabels!.filter( x => x.islabel1 != true));
  }
  if ((event.reservationPriceExtras?.length || 0) > 0) {
    labelList = labelList.concat(event.reservationPriceExtras);
  }

  if (
    event.reservationPriceLabels?.filter( x => x.islabel1 != true).find((label) => label.quantity > 0) ||
    event.reservationPriceExtras?.find((label) => label.quantity > 0)
  ) {
    labels = labels.concat(separator);
  }

  labels = labels.concat(formatLabels(labelList, language, separator));

  return labels;
};
