import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  constructor() { }

  create(campaign: DWS.Campaign): Observable<DWS.Campaign> {
    return resource('crm://campaigns')
      .post<DWS.Campaign>(campaign);
  }

  list(filters?: CampaignsFilter, page: number = 0, count: number = 10, archived: boolean = false): Observable<DWS.Page<DWS.Campaign>> {
    return resource('crm://campaigns')
      .params(
        { page, count, ...filters, archived }
      )
      .get<DWS.Page<DWS.Campaign>>();
  }

  update(campaignId: number, campaign: DWS.Campaign): Observable<DWS.Campaign> {
    return resource('crm://campaigns/id')
      .params({ campaignId })
      .put<DWS.Campaign>(campaign);
  }

  updateActiveStatus(campaignId: number, activate: boolean): Observable<DWS.Campaign> {
    return resource(`crm://campaigns/id/${activate ? 'activate' : 'deactivate'}`)
      .params({ campaignId })
      .post<DWS.Campaign>();
  }

  get(campaignId: number): Observable<DWS.Campaign> {
    return resource('crm://campaigns/id')
      .params(
        { campaignId }
      )
      .get<DWS.Campaign>();
  }

  duplicate(campaignId: number): Observable<DWS.Campaign> {
    return resource('crm://campaigns/id/clone')
      .params(
        { campaignId }
      )
      .post<DWS.Campaign>();
  }

  duplicateWithContacts(campaignId: number, contactIds: number[]): Observable<DWS.Campaign> {
    return resource('crm://campaigns/id/clone-with-contacts')
      .params(
        {
          campaignId,
        }
      )
      .post<DWS.Campaign>(contactIds);
  }

  testEmail(testEmail: Partial<DWS.Campaign> & { senderName: string }) {
    return resource('crm://campaigns/test-email')
      .post<DWS.Campaign>(testEmail);
  }

  listContacts(campaignId: number): Observable<DWS.ContactCampaign[]> {
    return resource('crm://campaigns/id/contacts')
      .params(
        { campaignId }
      )
      .get<DWS.ContactCampaign[]>();
  }

  delete(campaignId: number) {
    return resource('crm://campaigns/id')
      .params({ campaignId })
      .delete();
  }

  archive(campaignId: number) {
    return resource('crm://campaigns/id/archive')
      .params({ campaignId })
      .post();
  }

  unarchive(campaignId: number) {
    return resource('crm://campaigns/id/unarchive')
      .params({ campaignId })
      .post();
  }

  retry(campaignId: number) {
    return resource('crm://campaigns/id/retry')
      .params({ campaignId })
      .post();
  }
}

export interface CampaignsFilter {
  wineryId: string;
  campaignsId?: number | string | any;
  audienceId?: number;
  text?: string;
  filterByType: boolean | undefined; // true if automation, false if campaign, undefined if both 
}
