import { Injectable } from '@angular/core';
import { resource } from '@util/resource';
import { Observable } from 'rxjs';
import {QRCodeDisplay, QRCodeReduced} from '../../interfaces/dws/qr-code';

@Injectable({
  providedIn: 'root'
})
export class QrCodesService {

  constructor() {
  }

  create(name: string, wineryId: string, descriptionIt: string, descriptionEn: string): Observable<void> {
    return resource('crm://winery/wineryId/qr-codes')
      .params({wineryId})
      .post<void>({name, descriptionIt, descriptionEn});
  }

  get(id: string, wineryId: string): Observable<QRCodeDisplay> {
    return resource('crm://winery/wineryId/qr-codes/id')
      .params({id, wineryId})
      .get<QRCodeDisplay>();
  }

  list(wineryId: string): Observable<QRCodeDisplay[]> {
    return resource('crm://winery/wineryId/qr-codes')
      .params({wineryId})
      .get<QRCodeDisplay[]>();
  }

  listReduced(wineryId: string): Observable<QRCodeReduced[]> {
    return resource('crm://winery/wineryId/qr-codes/reduced')
        .params({wineryId})
        .get<QRCodeReduced[]>();
  }

  enable(id: string, wineryId: string): Observable<void> {
    return resource('crm://winery/wineryId/qr-codes/id/enable')
      .params({id, wineryId})
      .post<void>();
  }

  disable(id: string, wineryId: string): Observable<void> {
    return resource('crm://winery/wineryId/qr-codes/id/disable')
      .params({id, wineryId})
      .post<void>();
  }

  rename(id: string, name: string, wineryId: string, descriptionIt: string, descriptionEn: string): Observable<void> {
    return resource('crm://winery/wineryId/qr-codes/id')
      .params({id, wineryId})
      .put<void>({name, descriptionIt, descriptionEn});
  }

  delete(id: string, wineryId: string): Observable<void> {
    return resource('crm://winery/wineryId/qr-codes/id')
        .params({id, wineryId})
        .delete();
  }

}

