import { Injectable } from '@angular/core';
import { Reservation } from '@interfaces/dws/reservation';
import { resource } from '@util/resource';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReservationContactsService {
  update(reservationContact: Reservation.ReservationContactDTO): Observable<Reservation.ReservationContactDTO> {
    return resource('crm://scheduling/reservation-contacts/id')
      .params({ id: reservationContact.id })
      .put<Reservation.ReservationContactDTO>(reservationContact);
  }

  create(reservationContact: Reservation.ReservationContactDTO): Observable<Reservation.ReservationContactDTO> {
    return resource('crm://scheduling/reservation-contacts')
      .post<Reservation.ReservationContactDTO>(reservationContact);
  }

  delete(id: number): Observable<void> {
    return resource('crm://scheduling/reservation-contacts/id')
      .params({ id })
      .delete();
  }
}
