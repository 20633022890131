import { FormBuilder, FormGroup, Validators } from "@angular/forms";

export namespace ProductCategory {
  export interface Category {
    id?: any;
    nameIt: string;
    nameEn: string;
    priority: number;
  }

  export interface New {
    nameIt: string;
    nameEn: string;
    priority: number;
  }

  export interface Product {
    id: string;
    nameEn: string;
    nameIt: string;
  }

  export function createFormGroup(fb: FormBuilder, category?: ProductCategory.Category[]): FormGroup {
    const f = fb.group({
      nameIt: ['', Validators.required],
      nameEn: ['', Validators.required]
    })
    if (category)
      f.patchValue(category);
    return f;
  }
}

export type ProductCategory = ProductCategory.Category & { productsQuantity: number };
export type CategoriesWithProducts = ProductCategory.Category & {products: ProductCategory.Product[]};
export type NewCategory = ProductCategory.New;

export const initialCategory = {
  'id': null,
  'nameEn': 'Total',
  'nameIt': 'Totale',
  'priority': 0
} as ProductCategory.Category;