import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DWS } from '@interfaces/dws';
import { resource } from 'util/resource';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderStatusService {

  constructor() {
  }

  list(page: number = 0, count: number = 999999): Observable<DWS.OrderStatus[]> {
    return resource('crm://order-status')
      .params(
        {page, count}
      )
      .get<DWS.Page<DWS.OrderStatus>>()
      .pipe(
        pluck('content')
      )
  }
}
