import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { observe } from 'projects/web/src/util/loading/loading';
import { HostCompanyService } from 'services/host-company.service';
import { Host } from '@interfaces/host';

@Injectable({
  providedIn: 'root'
})
export class HostCompanyResolverService implements Resolve<Host.Company> {
  constructor(private hostCompanyService: HostCompanyService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Host.Company> | Promise<Host.Company> | Host.Company {
    return observe(this.hostCompanyService.get())
  }
}
