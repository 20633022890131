import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Contact } from "./contact";
import { Winery } from "./winery";
import { Gift } from "./gift";
import {ExperiencePriceExtra} from "../experience-price-extra";
import {ExperiencePriceLabel} from "../experience-price-label";
import { Language } from './language';

export namespace Reservation {
  import AtributteTranslations = Gift.AtributteTranslations;

  export interface Data {
    id: number;
    date: string | Date;
    startTime: string | Date;
    createdAt: string | Date;
    updatedAt: string | Date;
    amountCents: number;
    amountCurrency: string;
    reservationId: string;
    state?: 'draft' | 'waiting' | 'confirmed' | 'revoked' | 'rejected' | 'completed' | 'canceled';
    adults?: number;
    children?: number;
    origin?: string;
    experienceId?: string;
    roomId?: string;
    employeeId?: string;
    giftId?: string;
    contact?: Contact;
    extraData: ReservationExtraData;
    winery?: Winery;
    experiencePriceLabels: ExperiencePriceLabel[];
    experiencePriceExtras: ExperiencePriceExtra[];
    ids: String[];
  }

  export interface ReservationExtraData {
    paid: boolean;
    color: string;
    language: string;
    roomName?: string;
    employeeName?: string;
    priceLabel01: AtributteTranslations;
    priceLabel02?: AtributteTranslations;
    experienceTitle: AtributteTranslations;
    languageTranslations: AtributteTranslations;
  }

  export interface Filter {
    reservationId?: string;
    experienceId?: string;
    roomId?: string[];
    employeeId?: number;
    origin?: string[];
    state?: string;
    contactName?: string;
    adults?: number;
    minTotal?: number;
    maxTotal?: number;
    startTime?: string | Date;
    endTime?: string | Date;
  }

  export interface DTO {
    id?: string;
    state?: "draft" | "waiting" | "confirmed" | "revoked" | "rejected" | "completed" | "deleted" | "canceled";
    guestCountTotal?: number;
    guestCount01?: number;
    experiencePriceLabels?: ExperiencePriceLabel[];
    experiencePriceExtras?: ExperiencePriceExtra[];
    languageIso?: string;
    experienceId?: string;
    optionalData?: { [key: string]: any };
    reservationContacts?: ReservationContactDTO[];
    reservationMasterContact?: ReservationContactDTO;
    contactId?: number;
    date?: string;
    time?: string;
    netTotalCents?: number;
    grossTotalCents?: number;
    discountTotalCents?: number;
    additionalDiscountTotalCents?: number;
    manualDiscountTotalCents?: number;
    paidTotalCents?: number;
    dueTotalCents?: number;
    paymentCurrency?: string;
    origin?: string;
    wineryChannelId?: string;
    wineryChannelTypeId?: string;
    wineryId?: string;
    message?: string;
    notes?: string;
    feedback?: string;
    notifyContact?: boolean;
    paid?: boolean;
    giftId?: string;
    roomId?: string;
    employeeId?: string;
    paymentMethodId?: string;
    payment2MethodId?: string;
    manualOriginId?: number;
    visitReasonId?: string;
    paymentMethod?: string;
    paymentId?: string;
    type?: 'manual' | 'automatic' | 'integration';
    createdAt?: string;
    updatedAt?: string;
    language?: Language;
    couponCode?: string;
    experience?: any; // remove
    otherData?: { [key: string]: any };
  }

  export interface View {
    id?: string;
    state?: string;
    guestCountTotal?: number;
    guestCount01?: number;
    reservationPriceLabels?: ExperiencePriceLabel[];
    reservationPriceExtras?: ExperiencePriceExtra[];
    languageIso?: string;
    experienceId?: string;
    experience?: { [key: string]: any }[];
    optionalData?: { [key: string]: any };
    otherData?: { [key: string]: any };
    reservationContacts?: VwReservationContactDTO[];
    contactId?: number;
    date?: string | Date;
    time?: string | Date;
    netTotalCents?: number;
    grossTotalCents?: number;
    discountTotalCents?: number;
    manualDiscountTotalCents?: number;
    dueTotalCents?: number;
    paymentCurrency?: string;
    origin?: string;
    wineryChannelId?: string;
    wineryChannelTypeId?: string;
    wineryId?: string;
    message?: string;
    notes?: string;
    feedback?: string;
    notifyContact?: boolean;
    paid?: boolean;
    giftId?: string;
    roomId?: string;
    employeeId?: string;
    manualPaymentMethodId?: number;
    manualOriginId?: number;
    visitReasonId?: string;
    paymentMethod?: string;
    paymentId?: string;
    createdAt?: string | Date;
    updatedAt?: string | Date;
    type?: string;
    experienceTranslations?: { [key: string]: any }[];
    isTourOperator?: boolean;
    hasPaymentLink?: boolean;
    giftAmountCents?: number;
    experiencePrices?: any[];
  }

  export interface ReservationContactDTO {
    id?: number;
    name?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    countryIso?: string;
    contactType?: string;
    otherData?: { [key: string]: any };

    manualMarketing?: boolean;
  }

  export interface VwReservationContactDTO {
    id?: number;
    first_name?: string;
    last_name?: string;
    email?: string;
    phone_number?: string;
    country_iso?: string;
    contact_type?: string;
    other_data?: { [key: string]: any };
  }

  export interface Forecast {
    id: string;
    date: string;
    netTotalCents: number;
    guestCountTotal: number;
    wineryId: string;
    bookingDate: string;
    isGift: boolean;
    type: 'manual' | 'automatic';
  }

  export function createFormGroup(fb: FormBuilder, reservation?: Partial<Reservation.Data>): FormGroup {
    const f = fb.group({
      name: ['', Validators.required],
      description: [''],
      winery: [],
      typology: []
    })
    return f;
  }
}

export type ReservationFilters = Reservation.Filter
export type ReservationOLD = Reservation.Data;
export type ReservationDTO = Reservation.DTO;
export type VwReservation = Reservation.View;
export type ForecastedReservation = Reservation.Forecast;