import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AdminTemplateHtmlService {

  list(): Observable<DWS.TemplateHtmlReduced[]> {
    return resource('crm://admin/template-html')
      .headers({ 'x-admin-authorization': '1' })
      .params({})
      .get<DWS.TemplateHtmlReduced[]>();
  }

  listFilterByTrigger(trigger: string | undefined): Observable<DWS.TemplateHtmlReduced[]> {
    if (!trigger) {
      return this.list().pipe(
        map(emails => {
          emails = emails.map(email => {
            email.type = email.type + "-" + email.languageCode;
            return email;
          });
          return emails;
        })
      );
    }
    return this.list().pipe(
      map(emails => {
        var ss = emails.filter(email => email.type.includes(trigger) || !!!trigger);
        ss = ss.map(email => {
          email.type = email.type + "-" + email.languageCode;
          return email;
        });
        return ss;
      })
    );
  }

  get(type: string, langCode: string): Observable<DWS.TemplateHtml> {
    return resource('crm://admin/template-html/type')
      .headers({ 'x-admin-authorization': '1' })
      .params({ type, languageCode: langCode })
      .get<DWS.TemplateHtml>();
  }

  saveLive(type: string, value: DWS.TemplateHtml.NewBase, langCode: string): Observable<DWS.TemplateHtml> {
    return resource('crm://admin/template-html/type')
      .headers({ 'x-admin-authorization': '1' })
      .params({ type })
      .post<DWS.TemplateHtml>(value);
  }

  saveDraft(type: string, value: DWS.TemplateHtml.NewBase, langCode: string): Observable<DWS.TemplateHtml> {
    return resource('crm://admin/template-html/type/draft')
      .headers({ 'x-admin-authorization': '1' })
      .params({ type })
      .post<DWS.TemplateHtml>(value);
  }

  restore(type: string, langCode: string): Observable<void> {
    return resource('crm://admin/template-html/type')
      .headers({ 'x-admin-authorization': '1' })
      .params({ type, languageCode: langCode })
      .delete();
  }

  deleteDraft(type: string, langCode: string): Observable<DWS.TemplateHtml> {
    return resource('crm://admin/template-html/type/draft')
      .headers({ 'x-admin-authorization': '1' })
      .params({ type, languageCode: langCode })
      .delete();
  }

  getPDF(template: DWS.TemplateHtml): Observable<HttpResponse<Blob>> {
    var ss = resource('crm://admin/template-html/get-pdf')
      .headers({ 'x-admin-authorization': '1' })
      .postBlob(template);
    return ss;
  }
}
