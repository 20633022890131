<mat-toolbar>
    <mat-toolbar-row>
        <h1>{{'Reservations' | translate}}</h1>
    </mat-toolbar-row>
    <mat-toolbar-row>
        <small>{{'View reservation logs' | translate}}</small>
    </mat-toolbar-row>
</mat-toolbar>

<div class="wrapper-select">
    <mat-form-field class="width-100">
        <input (input)="fetch()" [(ngModel)]="reservationId" class="width-100 center-text" matInput type="text"/>
    </mat-form-field>
</div>

<table [dataSource]="data" [hidden]="!reservationId" class="mat-elevation-z8" mat-table>

    <ng-container matColumnDef="contactId">
        <th *matHeaderCellDef mat-header-cell> Contact ID</th>
        <td *matCellDef="let element" mat-cell> {{element.contactId || ''}}</td>
    </ng-container>

    <ng-container matColumnDef="automatic">
        <th *matHeaderCellDef mat-header-cell> Automatic</th>
        <td *matCellDef="let element" mat-cell>
            <mat-icon [className]="!element.automatic ? 'hidden' : ''" color="primary">check</mat-icon>
            <mat-icon [className]="element.automatic ? 'hidden' : ''" color="warn">close</mat-icon>
        </td>
    </ng-container>

    <ng-container matColumnDef="actionType">
        <th *matHeaderCellDef mat-header-cell> Action Type</th>
        <td *matCellDef="let element" mat-cell> {{element.actionType}}</td>
    </ng-container>

    <ng-container matColumnDef="details">
        <th *matHeaderCellDef mat-header-cell> Details</th>
        <td *matCellDef="let element" mat-cell>
            {{printObject(element.details) | truncate:[30, '...']}}
            &nbsp;
            <mat-icon (click)="copyToClipboard(element.details)" *ngIf="false" style="cursor: pointer;">content_copy
            </mat-icon>
            &nbsp;
            <mat-icon (click)="element.popoverVisibility = !element.popoverVisibility" style="cursor: pointer;">
                remove_red_eye
            </mat-icon>
            <textarea [ngClass]="element.popoverVisibility ? '' : 'hidden'" class="popover">{{'Final state:\n\n' + printObject(element.details)}}</textarea>
        </td>
    </ng-container>

    <ng-container matColumnDef="accountId">
        <th *matHeaderCellDef mat-header-cell> Account ID</th>
        <td *matCellDef="let element" mat-cell> {{element.accountId}}</td>
    </ng-container>

    <ng-container matColumnDef="updateTimestamp">
        <th *matHeaderCellDef mat-header-cell> Time</th>
        <td *matCellDef="let element" mat-cell> {{formatDate(element.updateTimestamp)}}</td>
    </ng-container>

    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
</table>