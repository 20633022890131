import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { observe } from '@web/util/loading/loading';
import { route as r } from '@util/route';
import { DWS } from '@interfaces/dws';
import { DWSTagsService } from '@services/dws/dws-tags.service';
import { mergeMap } from 'rxjs/operators';
import { HttpCacheService } from 'services/http-cache.service';

@Injectable({
  providedIn: 'root'
})
export class TagResolverService implements Resolve<DWS.TagCrm> {
  constructor(private tagsService: DWSTagsService, private httpCacheService: HttpCacheService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DWS.TagCrm> {
    const tagId = r.access(route).param('tagId');
    if (!tagId) return of(null) as any;
    this.httpCacheService.disable();
    return observe(
      this.tagsService.get(tagId)
        .pipe(
          mergeMap((tag) => {
            this.httpCacheService.enable();
            return of(tag);
          })
        )
    )
  }
}
