<input
    (keyup)="onKeyUp($event)"
    [matAutocomplete]="auto"
    class="search-input mat-elevation-z4"
    matInput
    placeholder="{{'Search by customer name, email experience name'| translate }}"
    type="search"
    #searchBox
    [ngClass]="{hidden: !open}"
>
<mat-icon [ngClass]="{'search-icon': open}" (click)="toggleOpen()">search</mat-icon>

<mat-autocomplete #auto="matAutocomplete" (optionSelected)="reservationClicked($event)">
    <mat-option *ngIf="loading">
        <mat-spinner diameter="25"></mat-spinner>
    </mat-option>
    <mat-option *ngFor="let option of searchResults" [value]="option.id" class="search">
        <div *ngFor="let field of ['expName','expContact','expDay','expTime']" [class]="field">{{ mapForDisplay(option)[field] }}</div>
    </mat-option>
</mat-autocomplete>