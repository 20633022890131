import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { DwsSocket } from '../projects/web/src/app/services/dws-socket.service';

@Injectable()
export class ManualReservationWebSocketService {

  constructor(private socket: DwsSocket) {
  }

  async initSession() {
    await this.socket.session();
  }

  listener(): Observable<string> {
    return this.socket.fromEvent('messageToClient')
  }
}
