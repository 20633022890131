import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { map } from 'rxjs/operators';
import { Client } from '@interfaces/client';

@Injectable({
  providedIn: 'root'
})
export class HostClientsService {
  constructor() { }
  list(q?: string): Observable<Client[]> {
    return resource('v2://host/clients')
      .params({
        page: '1',
        per_page: '999999',
        q
      })
      .get<{ data: Client[] }>()
      .pipe(
        map(response => response.data),
        map(clients => clients.sort((a, b) => {
          return a.firstname.localeCompare(b.firstname);
        }))
      )
  }
}
