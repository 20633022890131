import { tr } from '@util/tr/tr';
import { Origin } from "./origin";

export namespace ContactPrivacyHistory {

  export type Field = 'privacy_terms' | 'privacy_marketing' | 'privacy_profiling' | 'unsubscribe' | 'manual_marketing';

  const fieldMapping: { [k: string]: string } = {
    'privacy_terms': tr('Privacy & Terms'),
    'privacy_marketing': tr('Marketing purposes'),
    'privacy_profiling': tr('Profiling'),
    'unsubscribe': tr('Unsubscribed'),
    'manual_marketing': tr('Manual marketing consent')
  };

  export const mapField = (status: string) => {
    return fieldMapping[status];
  }

  interface WhatChanged {
    field: Field;
    value: boolean;
  }
  
  export interface Data {
    whatChanged: WhatChanged[];
    author: string;
    subscribed: boolean;
    createdAt: string | Date;
    deducedOrigin: Origin;
  }

}

export type ContactPrivacyHistory = ContactPrivacyHistory.Data;
