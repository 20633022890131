import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { resource } from '@util/resource';
import { ForecastedReservation } from '@interfaces/dws/reservation';
import { ForecastedOrder } from '@interfaces/dws/order';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class ForecastService {

  getReservations({periodSelection, dateStart, dateEnd}: any): Observable<ForecastedReservation[]> {
    let startDate = dateStart, endDate = dateEnd;
    if (periodSelection === 'current_year') {
      startDate = moment().startOf('year').toISOString();
      endDate = moment().endOf('year').toISOString();
    } else if (periodSelection === 'all_time') {
      startDate = null;
      endDate = null;
    }
    return resource(`crm://forecasts/reservations`)
      .params({startDate, endDate})
      .get<ForecastedReservation[]>();
  }

  getOrders({periodSelection, dateStart, dateEnd}: any): Observable<ForecastedOrder[]> {
    let startDate = dateStart, endDate = dateEnd;
    if (periodSelection === 'current_year') {
      startDate = moment().startOf('year').toISOString();
      endDate = moment().endOf('year').toISOString();
    } else if (periodSelection === 'all_time') {
      startDate = null;
      endDate = null;
    }
    return resource(`crm://forecasts/orders`)
      .params({startDate, endDate})
      .get<ForecastedOrder[]>();
  }
}