import { Reservation } from "@interfaces/reservation";
import { tr } from "../../util/tr";
import State = Reservation.State;

export namespace WineryScheduling {
  import State = Reservation.State;

  export interface Data {
    id: string;
    appointmentId: string;
    reservationId: string;
    experienceTitleIt: string;
    experienceTitleEn: string;
    contactId: number;
    wineryId: string;
    name: string;
    adults: number;
    reservationDate: string;
    experienceDate: string;
    origin: string;
    amountCents: number;
    amountCurrency: string;
    state: State;
    experienceId: string;
    roomId: string;
    employeeId: string;
    paid: boolean;
    color: string;
    languageEn: string;
    languageIt: string;
    employeeName: string;
    roomName: string;
    time: string
    createdWithSaas: boolean;
  }

  export interface SchedulingAdvancedFilters {
    experiences: SchedulingExperience[];
    origins: string[];
    rooms: SchedulingRoom[];
    employees: SchedulingEmployee[];
  }

  export interface ExperiencesTitle {
    id: string;
    experienceTitleEn: string;
    experienceTitleIt: string;
    experienceTitleDe: string;
  }

  export interface SchedulingExperience {
    id: string;
    titleEn: string;
    titleIt: string;
  }

  export interface SchedulingRoom {
    id: string;
    name: string;
  }

  export interface SchedulingEmployee {
    id: string;
    name: string;
  }

  interface StatusBase {
    cssClass: 'waiting' | 'confirmed' | 'revoked' | 'rejected' | 'completed' | 'canceled';
    icon: string;
    text: string;
  }

  type StatusWaiting = StatusBase & { type: 'waiting' };
  type StatusRejected = StatusBase & { type: 'rejected' };
  type StatusConfirmed = StatusBase & { type: 'confirmed' };
  type StatusRevoked = StatusBase & { type: 'revoked' };
  type StatusCompleted = StatusBase & { type: 'completed' };
  type StatusCanceled = StatusBase & { type: 'canceled' };
  export type MappedStatus = StatusWaiting | StatusConfirmed | StatusRejected | StatusRevoked | StatusCompleted | StatusCanceled;


  const statusMapping: { [k: string]: 'waiting' | 'confirmed' | 'revoked' | 'rejected' | 'completed' | 'canceled' } = {
    'waiting': 'waiting',
    'confirmed': 'confirmed',
    'revoked': 'revoked',
    'rejected': 'rejected',
    'completed': 'completed',
    'canceled': 'rejected'
  };


  const textMapping: { [k: string]: string } = {
    'confirmed': tr('Confirmed'),
    'completed': tr('Completed'),
    'rejected': tr('Rejected'),
    'waiting': tr('On hold'),
    'revoked': tr('Revoked'),
    'canceled': tr('Rejected')
  };

  export const status = (state: State | null, override?: 'waiting' | 'confirmed' | 'revoked' | 'rejected' | 'completed' | 'canceled'): MappedStatus => {

    const iconMapping: { [k: string]: string } = {
      'waiting': 'circled-etc',
      'rejected': 'circled-close',
      'revoked': 'circled-close',
      'confirmed': 'circled-checkmark',
      'completed': 'circled-checkmark',
      'canceled': 'circled-close'
    };

    const status = override || (state ? statusMapping[state] : false) || 'waiting';

    return {
      type: status,
      cssClass: status,
      icon: iconMapping[status],
      text: textMapping[status],
    }
  }
}
export type WineryScheduling = WineryScheduling.Data;
export type ExperiencesTitle = WineryScheduling.ExperiencesTitle;
export type SchedulingAdvancedFilters = WineryScheduling.SchedulingAdvancedFilters;
