import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { StatisticCampaign } from '../../interfaces/dws/statistic-campaign';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  updated$: Subject<void> = new Subject<void>();

  constructor() { }

  get(contactId: any, contactEmail: any, wineryId: string): Observable<DWS.Statistic> {
    return resource('crm://statistics')
      .params({contactId, wineryId, contactEmail: encodeURIComponent(contactEmail)})
      .get<DWS.Statistic>();
  }

  getOrders(contactId: any, wineryId: string): Observable<DWS.StatisticOrder> {
    return resource('crm://statistics/orders')
      .params({ contactId, wineryId })
      .get<DWS.StatisticOrder>();
  }

  getOrdersProducts(contactId: any, wineryId: string): Observable<DWS.StatisticOrderProduct[]> {
    return resource('crm://statistics/orders/products')
      .params({ contactId, wineryId })
      .get<DWS.StatisticOrderProduct[]>();
  }

  getCampaigns(wineryId: string): Observable<DWS.StatisticCampaign[]> {
    let params: any = {
      reference: environment.values['dateSendGridStatistics'],
      wineryId: wineryId
    };
    return resource('crm://statistics/campaigns')
      .params(params)
      .get<DWS.StatisticCampaign[]>()
      .pipe(
        map((statistics) => {
          if (!statistics || !statistics.length) return [];
          const data: DWS.StatisticCampaign[] = []
          statistics.map((statistic) => {
            const statisticCampaign: DWS.StatisticCampaign = {
              campaignId: statistic.campaignId,
              totalSent: statistic.totalSent,
              totalOpened: statistic.totalOpened,
              openedPercentage:  statistic.totalOpened ? Math.round(100 * statistic.totalOpened/statistic.totalSent): 0,
              lastOpenedAt: statistic.lastOpenedAt ? statistic.lastOpenedAt : '-'
            }
            data.push(statisticCampaign);
          });
          return data;
        })
      );
  }
  
  getCampaignsByIds(wineryId: string, campaigns: DWS.Campaign[]): Observable<DWS.StatisticCampaign[]> {
    let params: any = {
      reference: environment.values['dateSendGridStatistics'],
      wineryId: wineryId,
      ids: campaigns.map(campaign => campaign.id)
    };
    return resource('crm://statistics/campaigns/multiple')
      .params(params)
      .get<DWS.StatisticCampaign[]>()
      .pipe(
        map((statistics) => {
          if (!statistics || !statistics.length) return [];
          const data: DWS.StatisticCampaign[] = []
          statistics.map((statistic) => {
            const statisticCampaign: DWS.StatisticCampaign = {
              campaignId: statistic.campaignId,
              totalSent: statistic.totalSent,
              totalOpened: statistic.totalOpened,
              openedPercentage:  statistic.totalOpened ? Math.round(100 * statistic.totalOpened/statistic.totalSent): 0,
              lastOpenedAt: statistic.lastOpenedAt ? statistic.lastOpenedAt : '-',
              delivered: statistic.delivered,
              processed: statistic.processed,
              opens: statistic.opens,
              clicks: statistic.clicks,
              bounces: statistic.bounces,
              unsubscribes: statistic.unsubscribes,
            }
            data.push(statisticCampaign);
          });
          return data;
        })
      );
  }

  getSingleCampaign(campaignId: any, wineryId: string): Observable<DWS.StatisticCampaign> {
    let params: any = {
      reference: environment.values['dateSendGridStatistics'],
      wineryId: wineryId,
      id: campaignId
    };
    return resource('crm://statistics/campaigns/id')
      .params(params)
      .get<DWS.StatisticCampaign[]>()
      .pipe(
        map((statistics) => {
          if (!statistics || !statistics.length) return {} as StatisticCampaign;
          const statistic = statistics[0];
          const data: DWS.StatisticCampaign = {
            campaignId: statistic.campaignId,
            totalSent: statistic.totalSent,
            totalOpened: statistic.totalOpened,
            openedPercentage:  statistic.totalOpened ? Math.round(100 * statistic.totalOpened/statistic.totalSent): 0,
            lastOpenedAt: statistic.lastOpenedAt ? statistic.lastOpenedAt : '-',
            totalBuys: statistic.totalBuys ? statistic.totalBuys : 0,
            totalIncome: statistic.totalIncome ? statistic.totalIncome / 100 : 0
          }
          return data;
        })
      );
  }

  getFullCampaignStatistics(campaignId: any, wineryId: string): Observable<DWS.FullCampaignStatistic> {
    let params: any = {
      reference: environment.values['dateSendGridStatistics'],
      wineryId: wineryId,
      id: campaignId
    };
    return resource('crm://statistics/campaigns/id/summary')
      .params(params)
      .get<DWS.FullCampaignStatistic>()
      .pipe(
        map((statistics) => {
          if (!statistics) return {} as DWS.FullCampaignStatistic;
          return statistics as DWS.FullCampaignStatistic;
        })
      );
  }

  getFullCampaignStatisticsByDate(campaignId: number): Observable<DWS.FullCampaignStatistic[]> {
    let params: any = {
      id: campaignId
    };
    return resource('crm://statistics/campaigns/id/summarybydate')
      .params(params)
      .get<DWS.FullCampaignStatistic[]>()
      .pipe(
        map((statistics) => {
          if (!statistics) return {} as DWS.FullCampaignStatistic[];
          return statistics as DWS.FullCampaignStatistic[];
        })
      );
  }
}
