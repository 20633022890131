import { resource } from '@util/resource';
import { Observable } from 'rxjs';
import { Injectable} from '@angular/core';
import { WineSuiteParameters } from "../../interfaces/dws/wine-suite-parameters";


@Injectable({
  providedIn: 'root'
})
export class WineSuiteParametersService {
  constructor() {}

  editWineSuiteParameters(wineSuiteParameters: WineSuiteParameters): Observable<unknown> {
    return resource('crm://admin/ws-parameters')
        .headers({ 'x-admin-authorization': '1' })
        .post(wineSuiteParameters);
  }

  showWineSuiteParameters(): Observable<WineSuiteParameters> {
    return resource('crm://ws-parameters').get();
  }
}


