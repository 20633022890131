import { tr } from '@util/tr/tr';
import { CalendarConfiguration } from './../../../../../../interfaces/dws/calendar-configuration';
import { CalendarConfigurationService } from '@services/dws/calendar-configuration/calendar-configuration.service';
import { ChangeDetectorRef, Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { toast } from '@web/util/toast';
import * as moment from "moment";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'web-calendar-configuration',
    templateUrl: './calendar-configuration.component.html',
    styleUrls: ['./calendar-configuration.component.scss'],
  })
  export class CalendarConfigurationComponent implements OnInit {
    calendarConfiguration?: CalendarConfiguration;
    wineryId: string = '';
    startTime: string = '08:00';
    endTime: string = '22:00';


    constructor(@Inject(MAT_DIALOG_DATA) data: any,
                private dlg: MatDialogRef<CalendarConfigurationComponent>,
                private calendarConfigurationService: CalendarConfigurationService){

                  if (data.calendarConfiguration)  {
                    this.calendarConfiguration = data.calendarConfiguration as CalendarConfiguration;
                    this.startTime = `${this.calendarConfiguration.startTime}:00`;
                    this.endTime = `${this.calendarConfiguration.endTime}:00`;
                  }
                  if (data.wineryId) this.wineryId = data.wineryId;
                }

    ngOnInit(): void {


    }

    closeClicked() {
      this.dlg.close();
    }

    saveClicked() {
      const _startTime = moment().toDate();
      _startTime.setHours(parseInt(this.startTime.split(":")[0]));
      _startTime.setMinutes(parseInt(this.startTime.split(":")[1]));

      const _endTime = moment().toDate();
      _endTime.setHours(parseInt(this.endTime.split(":")[0]));
      _endTime.setMinutes(parseInt(this.endTime.split(":")[1]));
      

      if(_startTime >= _endTime){
        toast(tr("Start time cannot be later than the end date"));
        return;
      }

      this.calendarConfigurationService.create(this.wineryId, { startTime: this.startTime, endTime: this.endTime }).subscribe((data: any) => {
        this.dlg.close();
      });
    }
}
  