import { Conversor } from '@util/convert/conversor';

export class Base64Converter implements Conversor<string> {
  private readonly input: string;

  constructor(input: string) {
    this.input = input;
  }
  to(type: 'base64'): Promise<string>;
  to(type: 'arraybuffer'): Promise<ArrayBuffer>;
  to(type: 'blob'): Promise<Blob>;
  to(type: 'arraybuffer' | 'base64'| 'blob'): Promise<ArrayBuffer> | Promise<string> | Promise<Blob> | Promise<null> {
    switch (type) {
      case 'base64': return Promise.resolve(this.input);
      case 'arraybuffer': return Promise.resolve(this.toArrayBuffer());
      case 'blob': return Promise.resolve(this.toBlob());
      default: return Promise.resolve(null);
    }
  }

  private toBlob() {
    const binary = atob(this.input),
          len = binary.length,
          bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) bytes[i] = binary.charCodeAt(i);
    return Promise.resolve(new Blob([bytes]));
  }

  private toArrayBuffer() {
    const binary = atob(this.input),
          len = binary.length,
          bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) bytes[i] = binary.charCodeAt(i);
    return Promise.resolve(bytes.buffer as ArrayBuffer);
  }
}
