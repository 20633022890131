import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'web-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss']
})
export class NoticeComponent {
  text!: string;
  items?: string[];
  isWarning = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dlg: MatDialogRef<NoticeComponent>,
  ) {
    this.text = data.text;
    this.items = data.items;
    this.isWarning = data.isWarning !== false;
  }

  closeClicked() {
    this.dlg.close();
  }
}
