import { Injectable } from '@angular/core';
import { resource } from 'util/resource';
import { Observable } from 'rxjs';
import { DWS } from '@interfaces/dws';
import { Matcher } from 'interfaces/dws/matcher';
import ContactMatcher = Matcher.ContactMatcher;

@Injectable({
  providedIn: 'root'
})
export class ContactMatchersService {

  list(page: number = 0, count: number = 999999): Observable<DWS.Page<ContactMatcher>> {
    return resource('crm://contact-matchers')
      .params(
        { page, count }
      )
      .get<DWS.Page<ContactMatcher>>();
  }
}
