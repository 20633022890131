import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Appointment } from '@interfaces/appointment';
import { ga } from "@util/ga";

@Component({
  selector: 'app-reservation',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {
  appointment?: Appointment;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dlg: MatDialogRef<AppointmentComponent>,
  ) {
    if (data.appointment) this.appointment = data.appointment;
  }

  ngOnInit(): void {
    ga.track(`Appointment ${this.appointment?.id}`);
  }

  closeClicked() {
    this.dlg.close();
  }
}
