import { is } from "date-fns/locale";
import {Translation} from "./translation";
import {FormBuilder, Validators} from "@angular/forms";

export namespace ExperiencePriceLabel {
  export interface PriceLabel {
    id: number;
    title_translations: Translation;
    price_cents: number;
    price_currency: string;
    position: number;
    quantity: number;
    experience_id: string;
    created_at: string;
    updated_at: string;
    islabel1?: boolean;
  }
  export function createFormGroup(fb: FormBuilder, ex?: any) {
    const fpl = fb.group({
      id: [''],
      title_translations: [''],
      price_cents: ['', [Validators.min(0.00)]],
      price_currency: [''],
      position: [undefined],
      quantity: [0, [Validators.min(0.00)]],
      experience_id: [''],
      created_at: [''],
      updated_at: [''],
      islabel1: [false]
    })
    if (ex) {
      fpl.patchValue({
        id: ex.id,
        title_translations: ex.titleTranslations,
        price_cents: ex.priceCents,
        price_currency: ex.priceCurrency,
        position: ex.position,
        quantity: ex.quantity || 0,
        experience_id: ex.experienceId,
        created_at: ex.createdAt,
        updated_at: ex.updatedAt,
        islabel1: ex.islabel1 || false
      });
    }
    return fpl;
  }
}

export type ExperiencePriceLabel = ExperiencePriceLabel.PriceLabel;
