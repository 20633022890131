import { Pipe, PipeTransform } from '@angular/core';
import { locale } from '@util/locale';
import * as _ from 'lodash';

@Pipe({
  name: 'translatable'
})
export class TranslateDomainPipe implements PipeTransform {
  static transform(value: any, field: string = 'name', casing: 'snake' | 'pascal' = 'pascal'): string {
    const addSuffixToField = () => {
      let lang = locale().locale;
      const localeO = locale().getLocale(lang);
      if (!field) return lang;
      if(!locale().isLocaleDWSSupported(lang)){
        lang = localeO.fallbackLang;
      }
      const suffix = casing == 'pascal' ? lang.charAt(0).toUpperCase() + lang.slice(1) : `_${ lang }`;
      return field + suffix;
    };

    if (!value) return value;

    return value[addSuffixToField()];
  }

  transform(value: any, field: string = 'name', casing: 'snake' | 'pascal' = 'pascal'): string {
    return TranslateDomainPipe.transform(value, field, casing);
  }
}
