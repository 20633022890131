import { Component, Input, OnInit } from '@angular/core';
import { tr } from '@util/tr';
import { toast } from '@web/util/toast';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'web-external-sync-button',
  templateUrl: './external-sync-button.component.html',
  styleUrls: ['./external-sync-button.component.scss'],
})
export class ExternalSyncButtonComponent implements OnInit {

  @Input() label: string = 'Sync';
  @Input() matIconName: string = 'sync';
  @Input() statusObservable: Observable<any> | null = null;
  @Input() actionObservable: Observable<any> = of(null);
  @Input() successMessage: string = tr('Synced successfully');
  @Input() errorMessage: string = tr('Error syncing, please try again');

  status: any = null;
  isSyncing: boolean = false;

  constructor() { }

  ngOnInit() {
    this.getStatus();
  }

  getStatus() {
    this.statusObservable?.subscribe(
      (status) => this.status = status,
      (error) => {
        console.error('Error getting status', error);
        this.status = null;
      }
    );
  }

  syncClicked(event: MouseEvent) {
    event.stopPropagation();
    this.isSyncing = true;
    this.actionObservable.pipe(delay(500)).subscribe(
      () => {
        this.isSyncing = false;
        this.status = { lastSync: new Date() }; // just to update the UI
        toast(this.successMessage);
      },
      (error) => {
        this.isSyncing = false;
        toast(this.errorMessage);
      }
    );
  }
}
