import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import * as _ from 'lodash';
import { DWS } from "@interfaces/dws";

declare let gapi: any;
declare let window: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService {
  private ready: boolean = false;
  private url= 'https://www.googleapis.com/auth';
  
  constructor() {
    window['gapiInit'] = () => {
      gapi.load('auth2', () => {
        this.ready = true;
        console.log('ready!');
      })
    };
    
    const scriptElement = document.createElement('script');
    scriptElement.defer = true;
    scriptElement.async = true;
    scriptElement.src = 'https://apis.google.com/js/platform.js?onload=gapiInit';
    document.body.append(scriptElement);
  }
  
  authorize(): Observable<any> {
    const authorize$ = new Observable<any>(observer => {
      gapi.auth2.authorize({
        client_id: environment.values['google-client-id'],
        scope: `${this.url}/userinfo.profile ${this.url}/userinfo.email ${this.url}/calendar ${this.url}/calendar.events ${this.url}/calendar.app.created`,
        response_type: 'code token id_token refresh_token',
        prompt: 'consent',
        access_type: 'offline',
        approval_prompt: 'force'
        // redirect_uri: 'http://localhost:3000/google-oauth2-callback'
      }, function(response: any, error: any) {
        console.log('response', response);
        console.log('error', error);
        observer.next(response);
        observer.complete();
      })
    });
    return authorize$;
  }

  list(filters?: GoogleAuthTokenFilters, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.GoogleAuthToken>> {
    return resource('crm://google-auth-tokens')
        .params(
            { page, count, ...filters }
        )
        .get<DWS.Page<DWS.GoogleAuthToken>>();
  }

  delete(id: string): Observable<void> {
    return resource('crm://google-auth-tokens/id')
        .params( { id })
        .delete()
  }

  link(credentials: any, wineryId: string) {
    return resource('crm://google-auth-tokens')
      .post(_.merge(credentials, { wineryId }));
  }
}

export interface GoogleAuthTokenFilters {
  wineryId: string;
}