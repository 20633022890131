import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  static transform(seconds: number): string {
    const days = Math.floor(seconds / (24 * 60 * 60));
    seconds %= 24 * 60 * 60;
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    seconds = seconds % 60;
    let r = '';
    if (days > 0) r += `${days}d`;
    if (hours > 0) r += `${hours}h`;
    if (minutes > 0) r += `${minutes}m`;
    if (seconds > 0) r += `${seconds}s`;
    return r;
  }

  transform(seconds: number): string {
    return DurationPipe.transform(seconds);
  }
}
