import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor() {}

  create(form: DWS.FormDraft, state: 'DRAFT' | 'PUBLISHED', isActive: Boolean, isAnonymous: Boolean): Observable<{id: string}> {
    return resource('crm://forms')
      .post<{id: string}>({...form, state, isActive, isAnonymous});
  }

  list(filters?: FormFilters, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.Form>> {
    return resource('crm://forms')
      .params(
        { page, count, ...filters }
      )
      .get<DWS.Page<DWS.Form>>();
  }

  update(formId: string, form: DWS.FormDraft, state: 'DRAFT' | 'PUBLISHED', isActive: Boolean, isAnonymous: Boolean): Observable<null> {
    return resource('crm://forms/id')
      .params({ formId })
      .post({...form, isActive, isAnonymous, state});
  }

  updateActiveStatus(formId: string, activate: boolean): Observable<DWS.Form> {
    return resource(`crm://forms/id/${activate ? 'activate' : 'deactivate'}`)
        .params({ formId })
        .post<DWS.Form>();
  }

  duplicate(formId: string): Observable<DWS.Form> {
    return resource('crm://forms/id/clone')
        .params(
            { formId }
        )
        .post<DWS.Form>();
  }

  get(formId: string): Observable<DWS.Form> {
    return resource('crm://forms/id')
      .params(
        { formId }
      )
      .get<DWS.Form>();
  }

  getDraft(formId: string): Observable<DWS.FormDraft> {
    return resource('crm://forms/id/draft')
      .params(
        { formId }
      )
      .get<DWS.FormDraft>();
  }

  getAnswers(formId: string): Observable<DWS.FormAnswer[]> {
    return resource('crm://forms/id/answers')
      .params(
        { formId }
      )
      .get<DWS.FormAnswer[]>();
  }

  delete(formId: string){
    return resource('crm://forms/id')
        .params({formId})
        .delete();
  }

  deleteAnswer(formId: string, answerId: string) {
    return resource('crm://forms/id/answers/id')
        .params({formId, answerId})
        .delete();
  }

  getFormUrls(formId: string): Observable<DWS.FormUrl[]> {
    return resource('crm://forms/id/urls')
      .params(
        { formId }
      )
      .get<DWS.FormUrl[]>();
  }

  createUrl(name: String, formId: String) {
    return resource('crm://forms/id/urls')
      .params(
        { formId }
      )
      .post({name});
  }
}

export interface FormFilters {
  wineryId: string;
  formId?: string;
  audienceId?: number;
}
