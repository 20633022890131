export const capitalizeEachWord = (sentence: string) => {
  if (!sentence) {
    return "";
  }
  const words = sentence.replace(/\s{2,}/g, ' ').toLowerCase().trim().split(" ");

  for (let i = 0; i < words.length; i++) {
    if(words[i].length > 0){
      let trimmedWord: string = words[i].trim()
      words[i] = trimmedWord[0].trim().toUpperCase() + trimmedWord.substring(1);
    }
  }

  return words.join(" ");
};
