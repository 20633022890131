import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CalendarSchedulingEvent } from '@services/dws/scheduling/calendar-event/calendar-scheduling-event';
import { CurrencyPipe, getCurrencySymbol } from '@angular/common';
import * as moment from 'moment';
import * as _ from 'lodash';
import { tr } from '@util/tr';
import { VwReservation } from '@interfaces/dws/reservation';
import { Room } from '@interfaces/dws/room';
import { Employee } from '@interfaces/dws/employee';
import { locale } from '@util/locale';

@Component({
  selector: 'grouped-calendar-single-reservation',
  templateUrl: './grouped-calendar-single-reservation.component.html',
  styleUrls: ['./grouped-calendar-single-reservation.component.scss'],
})
export class GroupedCalendarSingleReservationComponent implements OnInit {

  @Input() reservation?: VwReservation;
  @Input() rooms: Room[] = [];
  @Input() employees: Employee[] = [];
  @Output() detailsClick: EventEmitter<VwReservation> = new EventEmitter<VwReservation>();
  cPipe: CurrencyPipe;
  deltaEmptySpaces: any[] = [];
  spaceToHave = 5;

  moment = moment;
  language = locale().locale;

  get currencySymbol() {
    return getCurrencySymbol(this.reservation?.paymentCurrency || 'EUR', 'wide', undefined) || '' as string;
  }

  get reservationPriceLabelsExcludeFirstIfPresent() {
    return this.reservation?.reservationPriceLabels?.filter(l => l.islabel1 !== true) || [];
  }

  displayDate(date: Date) {
    return _.capitalize(moment(date).locale('it').format('DD[/]MM[/]YYYY [-] HH:mm'));
  }

  formatTotal(totalCents: number): string {
    let money = (totalCents / 100);
    return this.cPipe.transform(money, this.currencySymbol, 'symbol', "1.0-2")!;
  }

  constructor(private cpipe: CurrencyPipe) {
    this.cPipe = cpipe;
  }

  ngOnInit() {
    if (this.reservation) {
      let founded = this.reservation.guestCount01! > 0 ? 1 : 0;
      founded += this.reservation.reservationPriceLabels?.length || 0;
      founded += this.reservation.reservationPriceExtras?.length || 0;
      for (var k = 0; k < (this.spaceToHave - 1 - founded); k++) {
        this.deltaEmptySpaces.push({ test: 1 });
      }
    }
  }

  capitalize(x: string) {
    return _.capitalize(x);
  }

  onClickDetail(reservation: VwReservation) {
    this.detailsClick.emit(reservation);
  }

  onClickPaid(reservation: VwReservation) {}
  
  isPaid(reservation: VwReservation){
    return reservation.paid || !!!!reservation.giftId;
  }

  isSales(reservation: VwReservation){
    return reservation.otherData?.isSales == true || reservation.otherData?.isSales == "true";
  }

  isCheckedIn(reservation: VwReservation){
    return reservation.otherData?.checkedIn == true || reservation.otherData?.checkedIn == "true";
  }

  isPaidVisible(reservation: VwReservation){
    return reservation.paid !== null || !!!!reservation.giftId;
  }

  roomName(roomId: string): string {
    return this.rooms.find(x => x.id === roomId)?.name || '';
  }

  employeeName(employeeId: string): string {
    const employee = this.employees.find(x => x.id === employeeId);
    return (employee?.firstName + ' ' + employee?.lastName) || '';
  }

  get reservationMasterContact() {
    return this.reservation?.reservationContacts?.find(x => x.contact_type === 'MASTER');
  }

  getLabel1Title(reservation: VwReservation): string {
    return reservation.experienceTranslations?.find(t => t.translation_id == 'TITLE_PRICE_LABEL_0')?._translations[this.language] || '';
  }

  getOrigin(origin: any): any {
    if(!!!origin){
      return "";
    }
    var originFormatted = "" + origin?.replace("https://", "").replace("http://", "").replace("www.", "").replace(/\:\d+/g, "").toLowerCase();
    if (("" + origin).toLocaleLowerCase().indexOf("manual") > -1 || "Wine Suite".indexOf(origin) > -1) {
      originFormatted = tr("Manual Reservation");
    }
    originFormatted = tr(originFormatted);
    return originFormatted ? originFormatted : ( origin ? tr(origin) : "" );
  }
}
