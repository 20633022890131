import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ClipboardModule} from "@angular/cdk/clipboard";
import {MatCardModule} from "@angular/material/card";
import {QrCodeGenerateComponent} from "@web/app/components/qr-code-generate/qr-code-generate.component";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {QRCodeGenerateRoutingModule} from "@web/app/components/qr-code-generate/qr-code-generate-routing.module";
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [QrCodeGenerateComponent],
  exports: [QrCodeGenerateComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    ClipboardModule,
    MatCardModule,
    TranslateModule,
    QRCodeGenerateRoutingModule,
    FormsModule
  ]
})

export class QRCodeGenerateModule {
}