<div class="add-edit-integration">
    <button (click)="closeClicked()" class="closeBtn" mat-icon-button>
        <mat-icon>close</mat-icon>
    </button>
    <div class="create-"></div>
    <div [formGroup]="form" class="integration-create">
        <div class="field-wrapper centered">
            <img
                    [src]="integration.integration?.imageUrl"
                    class="mediumImage"
                    mat-card-lg-image
            />
        </div>
        <mat-divider></mat-divider>
        <div
                *ngFor="let control of objectKeys(form.controls)"
                class="field-wrapper"
        >
            <label for="name">
                {{ control | translate }}
            </label>
            <input
                    [formControlName]="control"
                    [type]="getRegistryType(control)"
                    id="control"
                    matInput
            />
        </div>
    </div>
    <div class="actions-wrapper">
        <button (click)="saveClicked()" mat-stroked-button>
            {{ "Connect" | translate }}
        </button>
        <button
                (click)="syncClicked()"
                *ngIf="isEdit"
                mat-stroked-button
        >
            {{ "Sync" | translate }}
        </button>
        <button
                (click)="removeClicked()"
                class="rmvBtn"
                *ngIf="isEdit"
                mat-stroked-button
        >
            {{ "Remove" | translate }}
        </button>
    </div>
</div>