export class Locale {
  isoCode: string;
  dwsEnabled: boolean;
  fallbackLang: string;
  enabled: boolean;
  flag: string;
  beta: boolean;
  isDefault: boolean;

  constructor(isoCode: string, fallbackLang: string, dwsEnabled: boolean, enabled: boolean, flag: string, beta: boolean, isDefault: boolean) {
    this.dwsEnabled = dwsEnabled;
    this.enabled = enabled;
    this.fallbackLang = fallbackLang;
    this.isoCode = isoCode;
    this.flag = flag;
    this.beta = beta;
    this.isDefault = isDefault;
  }
}

function getBrowserLocales(options = {}): string[] | undefined {
  const defaultOptions = {
    languageCodeOnly: false,
  };

  const opt = {
    ...defaultOptions,
    ...options,
  };

  const browserLocales =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  return browserLocales.map(locale => {
    const trimmedLocale = locale.trim();

    return opt.languageCodeOnly
      ? trimmedLocale.split(/[-_]/)[0]
      : trimmedLocale;
  });
}


export function locale(): { locale: string; stored: string; browser: string, store: Function, getLocale: Function, isLocaleDWSSupported: Function, localesSupported: Locale[] } {

  const storeFn = function (localeIsoCode: string) {
    localStorage.setItem('locale', localeIsoCode);
  }

  const getLocaleFn = function (localeIsoCode: string): Locale | undefined {
    let founded = localeSupported.filter(x => x.isoCode === localeIsoCode);
    if (founded.length === 1) {
      return founded[0];
    } else {
      return undefined;
    }
  }

  const isLocaleSupported = function (isoCode: string) {
    let localeSupported = getLocaleFn(isoCode)!;
    return localeSupported.enabled;
  }

  const isLocaleDWSSupportedFn = function (isoCode: string) {
    let localeSupported = getLocaleFn(isoCode)!;
    return localeSupported.dwsEnabled;
  }

  const localeSupported = [{ isoCode: "it", dwsEnabled: true, fallbackLang: "it", enabled: true, beta: false, isDefault: true, flag: "assets/images/flags/it.png" },
  { isoCode: "en", dwsEnabled: true, fallbackLang: "it", beta: true, isDefault: false, enabled: true, flag: "assets/images/flags/en.png" },
  { isoCode: "fr", dwsEnabled: false, fallbackLang: "en", beta: true, isDefault: false, enabled: true, flag: "assets/images/flags/fr.png" },
  { isoCode: "de", dwsEnabled: false, fallbackLang: "en", beta: true, isDefault: false, enabled: true, flag: "assets/images/flags/de.png" },
  { isoCode: "pt", dwsEnabled: false, fallbackLang: "en", beta: true, isDefault: false, enabled: true, flag: "assets/images/flags/pt.png" }
  ];

  let storedLocale = localStorage.getItem('locale');
  let locale = 'it';
  let browserLocale = '';
  try{
    const locales = getBrowserLocales({ languageCodeOnly: true });
    if (locales && locales.length) browserLocale = locales[0];
  
    if (storedLocale && isLocaleSupported(storedLocale)) {
      locale = storedLocale;
    } else {
      if (!isLocaleSupported(browserLocale)) locale = 'it';
      localStorage.setItem('locale', locale);
      storedLocale = locale;
    }
  } catch (e) {
    locale = 'en';
  } finally{
    localStorage.setItem('locale', locale);
  }
  
  return { locale, stored: storedLocale!, browser: browserLocale, store: storeFn, getLocale: getLocaleFn, isLocaleDWSSupported: isLocaleDWSSupportedFn, localesSupported: localeSupported };
}
