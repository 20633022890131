import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {toast} from "@web/util/toast";
import {tr} from "@util/tr";
import {Moment} from "moment/moment";
import * as moment from 'moment/moment';

@Component({
  selector: 'web-date-interval',
  templateUrl: './date-interval.component.html',
  styleUrls: ['./date-interval.component.scss']
})
export class DateIntervalComponent {
  campaignOne: FormGroup;
  title: string;
  showFilterInfo: boolean;
  nFilters: number;

  constructor(
      @Inject(MAT_DIALOG_DATA) data: any,
      private dlg: MatDialogRef<DateIntervalComponent>
  ) {
    this.title = data.title;
    this.showFilterInfo = data.showFilterInfo || false;
    this.nFilters = data.nFilters || 0;
    this.campaignOne = new FormGroup({
      start: new FormControl('', Validators.required),
      end: new FormControl('', Validators.required)
    });
  }

  closeClicked() {
    this.dlg.close();
  }

  export(type: 'xlsx' | 'csv') {
    if (this.campaignOne.invalid) {
      toast(tr('Invalid range date')).then();
      return;
    }
    const v = this.campaignOne.getRawValue();
    const start = moment(v.start);
    const end = moment(v.end);
    this.dlg.close({start, end, type});
  }
  
  exportXLSXClicked() {
    this.export('xlsx')
  }
  
  exportCSVClicked() {
    this.export('csv')
  }
}
