import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard.component';
import { OnlyAuthorizedGuard } from '@services/guards/only-authorized.guard';
import { AccountResolverService } from "@services/resolvers/account-resolver.service";

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [OnlyAuthorizedGuard],
    resolve: {
      login: AccountResolverService
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
