import { Injectable } from '@angular/core';
import { ListResolverService } from '@services/dws/resolvers/list-resolver-service';
import { WineryOriginsService } from '../winery-origins.service';

@Injectable({
  providedIn: 'root'
})
export class WineryOriginsResolverService extends ListResolverService<{id: string, name: string}, WineryOriginsService> {
  constructor(private s: WineryOriginsService) {
    super(s)
  }
  
}
