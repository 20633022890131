import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';


export interface CheckResponse {
  responses?: {
    action?: string;
    createdAt?: string;
    data?: any;
    id?: string;
    reason?: string;
    reservationId?: string;
    stacktrace?: string;
    status?: string;
  }[]

}

@Injectable({
  providedIn: 'root'
})
export class CheckReservationByIdsService {
  check(ids: string[]): Observable<CheckResponse> {
    return resource('crm://scheduling/check-requests-ids')
      .post<CheckResponse>({ids: ids});
  }
}

