import { Injectable } from "@angular/core";
import { ScheduleManualReservationRequest } from "./schedule-manual-reservation-request";
import {observable, Observable, of} from "rxjs";
import { resource } from "@util/resource";
import { ManualReservation } from "../../../../interfaces/dws/scheduling/manual-reservation";
import {
  ManualReservationWebSocketService
} from "../../../manual-reservation-ws.service";

@Injectable({
  providedIn: 'root'
})
export class ManualReservationsService {
  schedule(input: ScheduleManualReservationRequest): Observable<{ id: string }> {
    return resource('crm://manual-reservations')
        .post(input);
  }

  delete(reservationId: string,
         experienceId?: string,
         experienceTitleIt?: string,
         date?: string,
         time?: string
  ): Observable<{ id: string }> {
    let encodedExpTitleIt: string = encodeURIComponent(experienceTitleIt!);
    return resource('crm://manual-reservations/id')
        .params({id: reservationId, experienceId, experienceTitleIt: encodedExpTitleIt, date, time})
        .delete();
  }

  show(reservationId: string): Observable<ManualReservation> {
    return resource('crm://manual-reservations/id')
        .params({id: reservationId})
        .get<ManualReservation>();
  }

  update(reservationId: string, input: ManualReservation.Update): Observable<{ id: string }> {
    return resource('crm://manual-reservations/id')
        .params({id: reservationId})
        .put(input)
  }

  updateIntegration(reservationId: string, input: ManualReservation.Update): Observable<string> {
    return resource('crm://scheduling/integration-reservation/winelivery/id')
        .params({id: reservationId})
        .put(input)
  }

  deleteIntegration(reservationId: string): Observable<void> {
    return resource('crm://scheduling/integration-reservation/winelivery/id')
        .params({id: reservationId})
        .delete();
  }

}
