import { FormBuilder, Validators } from "@angular/forms";

export namespace Employee {

  export interface Data {
    id: string;
    wineryId: string;
    email: string;
    firstName: string;
    lastName: string;
    createdAt: string;
    updatedAt: string;
    toUpdate?: boolean;
    toDelete?: boolean;
  }

  export function createFormGroup(fb: FormBuilder, employee?: Partial<Employee>) {
    const f = fb.group({
      id: [''],
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', [Validators.required, Validators.minLength(1)]],
      lastName: ['', [Validators.required, Validators.minLength(1)]],
      languages: [[], Validators.required],
      toUpdate: [false],
      toDelete: [false]
    })
    if (employee) {
      f.patchValue(employee);
    }
    return f;
  }

}

export type Employee = Employee.Data;
