import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DWS } from '@interfaces/dws';
import { resource } from '@util/resource';
import {ProductFieldsValidate, ProductGrid} from "../../interfaces/dws/product";

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  create(product: DWS.Product.New): Observable<DWS.Product> {
    return resource('crm://products')
      .post<DWS.Product>(product);
  }

  update(productId: string, product: DWS.Product.New): Observable<DWS.Product> {
    return resource('crm://products/id')
      .params({ id: productId })
      .put<DWS.Product>(product);
  }

  list(wineryId: string, filters?: ProductsFilter): Observable<DWS.Page<DWS.Product>> {
    return resource('crm://products')
      .params({ wineryId, page: 0, count: 9999, ...filters })
      .get<DWS.Page<DWS.Product>>();
  }

  listGrid(wineryId: string, filters?: GridProductFilter, page: number = 0, count: number = 99999,showDisabledProducts: boolean = false): Observable<DWS.Page<DWS.Product>> {
    const adaptedFilter = {...filters}
    if (adaptedFilter?.priceMin) adaptedFilter.priceMin = (adaptedFilter?.priceMin || 0) * 100;
    if (adaptedFilter?.priceMax) adaptedFilter.priceMax = (adaptedFilter?.priceMax || 0) * 100;

    return resource('crm://products/grid/wineryId')
        .params({wineryId, page: page, count: count,showDisabledProducts, ...adaptedFilter})
        .get<DWS.Page<DWS.Product>>();
  }

  get(productId: string): Observable<DWS.Product> {
    return resource('crm://products/id')
      .params({ id: productId })
      .get<DWS.Product>();
  }

  orders(productId: string): Observable<DWS.ProductOrder[]> {
    return resource('crm://products/id/orders')
      .params({ id: productId })
      .get<DWS.ProductOrder[]>();
  }

  remove(productId: string): Observable<void> {
    return resource('crm://products/id')
      .params({ id: productId })
      .delete();
  }

  checkUniqueFieldValue(field: ProductFieldsValidate, value: string, wineryId: string, id?: string): Observable<number> {
    return resource('crm://products/checkname')
        .params({
          field,
          value: encodeURIComponent(value),
          id,
          wineryId
        })
        .get<number>()
  }

  changeActiveProduct(id: string, active: boolean): Observable<void>{
    return resource('crm://products/id/active-toggle')
        .params({
          id, active
        })
        .post<void>();
  }
  
  changePriorityByAll(product: any, priority: number): Observable<unknown> {
    return resource('crm://products/id/sort-product')
        .params({id: product?.id})
        .put({
          orderingAll: priority,
          wineryId: product?.wineryId
        });
  }

  changePriorityByCategory(product: any, priority: number): Observable<any> {
    console.log(product.category.id)
    return resource('crm://products/id/sort-product-by-category')
        .params({id: product?.id})
        .put({
          orderingCategory: priority,
          wineryId: product.wineryId,
          categoryId: product.category?.id
        });
  }
}

export interface ProductsFilter {
  channelId?: string;
}

export interface GridProductFilter {
  typeIds?: string[],
  categoryIds?: string[];
  wineryChannelIds?: string[];
  name?: string;
  priceMin?: number;
  priceMax?: number;
}
