import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '../../util/resource';
import { DWS } from '../../interfaces/dws';
import { Preorder } from '../../interfaces/dws/preorder';
import PreorderOrderProduct = Preorder.PreorderOrderProduct;
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreordersService {
  constructor() { }

  list(filters: PreorderFilters, page: number = 0, pageSize: number = 999999): Observable<DWS.Page<DWS.Preorder>> {
    return resource('crm://preorders')
      .params({ page, pageSize, ...filters })
      .get<DWS.Page<DWS.Preorder>>();
  }

  get(id: string): Observable<DWS.Preorder> {
    return resource( 'crm://preorders/id')
      .params({ id })
      .get<DWS.Preorder>();
  }

  accept(id: string, body: PreorderOrderProduct): Observable<string> {
    return resource('crm://preorders/id/accept')
      .params({ id })
      .post<{ id: string }>(body)
      .pipe(
        map(p => p.id)
      );
  }

  reject(id: string, body: PreorderOrderProduct): Observable<string> {
    console.warn(body)
    return resource('crm://preorders/id/reject')
      .params({ id })
      .post<{ id: string }>(body)
      .pipe(
        map(p => p.id)
      );
  }

  remove(id: string): Observable<void> {
    return resource('crm://preorders/id')
        .params({ id })
        .delete();
  }
}

export interface PreorderFilters {
  wineryId: string;
  contactName?: string;
  preorderDate?: string;
  productsQuantity?: number;
  total?: number;
  channelId?: string;
  field: string;
  direction: 'ASC' | 'DESC'
}
