import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { LoadingModule } from '@web/app/components/loading/loading.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {NewContactSidenavModule} from "@web/app/components/new-contact-sidenav/new-contact-sidenav.module";
import { CalendarConfigurationComponent } from './calendar-configuration.component';

@NgModule({
  declarations: [CalendarConfigurationComponent],
  exports: [
    CalendarConfigurationComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatDatepickerModule,
    LoadingModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    NewContactSidenavModule,
  ]
})
export class CalendarConfigurationModule { }