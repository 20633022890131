import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReservationListComponent } from './reservation-list.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { KeycountPipeModule } from "@pipes/keycount/keycount.module";
import { MatBadgeModule } from "@angular/material/badge";
import {DateIntervalModule} from "@web/app/components/dialogs/date-interval/date-interval.module";
import { TranslateDomainModule } from "@web/app/pipes/translate/translate-domain.module";
import { MatPaginatorModule } from "@angular/material/paginator";
import { InitialsNameModule } from "@web/app/pipes/initials-name/initials-name.module";
import { FirstupperModule } from "@pipes/firstupper/firstupper.module";
import { OriginModule } from '@web/app/pipes/origin/origin.module';
import { InfoIconHoverableModule } from '../info-icon-hoverable/info-icon-hoverable.module';


@NgModule({
  declarations: [ReservationListComponent],
  exports: [
    ReservationListComponent
  ],
  imports: [
    CommonModule,
    MatSortModule,
    MatTableModule,
    TranslateModule,
    MatIconModule,
    KeycountPipeModule,
    MatBadgeModule,
    DateIntervalModule,
    TranslateDomainModule,
    MatPaginatorModule,
    FirstupperModule,
    OriginModule,
    InfoIconHoverableModule
  ]
})
export class ReservationListModule { }
