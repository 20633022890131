import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DWS } from '@interfaces/dws';
import { resource } from 'util/resource';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductTypesService {
  list(): Observable<DWS.ProductType[]> {
    return resource('crm://product-types')
      .get<DWS.Page<DWS.ProductType>>()
      .pipe(map(page => page.content));
  }
}
