import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { from, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AccountsService, Login } from 'services/accounts.service';

@Injectable({
  providedIn: 'root'
})
export class AccountResolverService implements Resolve<Login> {
  constructor(private accountsService: AccountsService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return from(this.accountsService.getLogin());
  }
}
