import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';
import { ContactsFilter } from "./contacts.service";

@Injectable({
  providedIn: 'root'
})
export class CampaignContactsService {

  constructor() {}

  createList(campaignId: number, campaignContactList: number[]): Observable<DWS.CampaignContact[]> {
    return resource('crm://campaign-contacts/campaign/campaignId')
      .params(
        { campaignId }
      )
      .post<DWS.CampaignContact[]>(campaignContactList);
  }

  list(filters?: CampaignContactsFilter, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.CampaignContact>> {
    return resource('crm://campaign-contacts')
      .params(
        { page, count, ...filters }
      )
      .get<DWS.Page<DWS.CampaignContact>>();
  }

  listForSelection(filter?: ContactsFilter, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.ReducedContact>> {
    return resource('crm://campaign-contacts/for-selection')
    .params(
        { page, count, ...filter }
    )
    .get<DWS.Page<DWS.ReducedContact>>();
  }
}

export interface CampaignContactsFilter {
  campaignId: number;
}
