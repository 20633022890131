import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { route as r } from '@util/route';
import { observe } from '@web/util/loading/loading';
import { DWS } from '@interfaces/dws';
import { TemplateHtmlService,  } from '@services/dws/template-html.service';
import { from } from 'rxjs';
import { AccountsService } from '@services/accounts.service';
import { first, map, switchMap } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
  })
  export class TemplateHtmlsResolverService implements Resolve<DWS.TemplateHtmlReduced[]>{
    constructor(private service: TemplateHtmlService, private accounts: AccountsService) { }
  
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DWS.TemplateHtmlReduced[]> {
      const templatesHtmlType = r.access(route).param('templateHtmlType');
      let trigger: string | undefined;
      if (templatesHtmlType) trigger = DWS.TYPES_TEMPLATE_HTML.find(e => templatesHtmlType.includes(e));
      const args$ = from(this.accounts.getLogin()).pipe(map(login => login!.company!.id));
      const obs$ = args$.pipe(first(), switchMap(args => this.service.listFilterByTrigger(trigger, args)));
      return observe(obs$);
    }
    
  }
  
  