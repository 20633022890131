import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
// @ts-ignore
import { Reservation } from "@interfaces/reservation";
import { AdvancedFilters } from "@interfaces/advanced-filters";
import { MatSort } from "@angular/material/sort";
import { FilterComponent } from "@web/app/components/filter/filter.component";
import { MatDialog } from "@angular/material/dialog";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import { DWS } from "@interfaces/dws";
import { OrderStatusService } from "@services/dws";
import * as _ from "lodash";
import { DateIntervalComponent } from "@web/app/components/dialogs/date-interval/date-interval.component";
import { WinerySchedulingFilters } from "@services/dws/scheduling/page-scheduling/page-schedulings.service";
import { ActivatedRoute } from "@angular/router";
import { Login } from "@services/accounts.service";
import { access } from "@util/route/access";
import { observe } from "@web/util/loading/loading";
import { Observable, forkJoin, merge, of } from "rxjs";
import { WineryScheduling } from "@interfaces/dws/winery-scheduling";
import { parse } from "date-fns";
import { TranslateDomainPipe } from "@web/app/pipes/translate/translate-domain.pipe";
import { MatPaginator } from "@angular/material/paginator";
import { map, pluck, tap } from "rxjs/operators";
import { WineryScheduleDatasource } from "@web/app/components/reservation-list/datasource/winery-schedule-datasource";
import { ReservationFilters } from "@interfaces/reservation-filters";
import { toast } from "@web/util/toast";
import { Moment } from "moment";
import { SchedulingService } from "@services/dws/scheduling/page-scheduling/page-schedulings.service";
import SchedulingExperience = WineryScheduling.SchedulingExperience;
import SchedulingRoom = WineryScheduling.SchedulingRoom;
import SchedulingEmployee = WineryScheduling.SchedulingEmployee;
import { SchedulingExportingService } from "@services/dws/scheduling/scheduling-exporting/scheduling-exporting.service";
import { ReservationExporting } from "@interfaces/dws/scheduling/reservation-exporting";
import { InfoIconHoverableComponent } from "../info-icon-hoverable/info-icon-hoverable.component";
import { VwReservation } from "@interfaces/dws/reservation";
import { locale } from "@util/locale";
import { HostReservationFormOptionsService } from "@services/host-reservation-form-options/host-reservation-form-options.service";
import { tr } from "@util/tr";
import { de } from "date-fns/locale";
import { state } from "@angular/animations";

marker("Filter does not return any result");

/* 'draft' | 'waiting' | 'confirmed' | 'revoked' | 'rejected' | 'completed' */
const weight: any = {
  waiting: -1,
  confirmed: 0,
  revoked: 3,
  completed: 1,
  rejected: 2,
  canceled: 4,
  draft: 99,
};

const compareStates = (a: Reservation, b: Reservation) => {
  if (a.aasm_state === b.aasm_state) return 0;
  const aState = a.aasm_state,
    bState = b.aasm_state;

  return weight[aState] - weight[bState];
};

@Component({
  selector: "app-reservation-list",
  templateUrl: "./reservation-list.component.html",
  styleUrls: ["./reservation-list.component.scss"],
})
export class ReservationListComponent implements AfterViewInit, OnChanges {
  @ViewChild(MatSort) sort: MatSort = new MatSort();
  @ViewChild("paginatorTop") topPaginator!: MatPaginator;
  @ViewChild("paginatorBottom") bottomPaginator!: MatPaginator;

  _reservations: Reservation[] = [];
  dataSource!: WineryScheduleDatasource;
  appliedFilters: WinerySchedulingFilters = {
    state: ["confirmed", "waiting", "draft", "completed"],
  };
  storedFilters: WinerySchedulingFilters = {};
  orderStatus: DWS.OrderStatus[] = [];
  origins: string[] = [];
  rooms: SchedulingRoom[] = [];
  employees: SchedulingEmployee[] = [];
  experiencesTitle: SchedulingExperience[] = [];

  @Input() defaultFilters: {
    searchText?: string;
    draft: boolean;
    confirmed: boolean;
    waiting: boolean;
    rejected: boolean;
    completed: boolean;
    canceled: boolean;
    revoked: boolean;
  } = {
    draft: true,
    confirmed: true,
    waiting: true,
    rejected: false,
    completed: true,
    canceled: false,
    revoked: false
  };
  @Output() reservationFilterEvent: EventEmitter<ReservationFilters> =
    new EventEmitter();

  @Output() winerySchedulingClick: EventEmitter<VwReservation> =
    new EventEmitter<VwReservation>();
  displayedColumns: string[] = [
    "id",
    "experienceTitleIt",
    "masterContactName",
    "guestCountTotal",
    "createdAt",
    "date",
    "origin",
    "state",
    "netTotalCents",
  ];
  login: Login;
  lang!: string;
  page = 0;
  constructor(
    private dialog: MatDialog,
    private orderStatusService: OrderStatusService,
    private route: ActivatedRoute,
    private schedulingService: SchedulingService,
    private schedulingExportingService: SchedulingExportingService,
    private formOptionsService: HostReservationFormOptionsService
  ) {
    this.login = access(route).data("login");
    this.lang = locale().locale;
  }

  ngOnInit() {
    observe(
      forkJoin([
        this.orderStatusService.list(),
        this.schedulingService.getAdvancedFiltersElements(
          this.login.company.id
        ),
      ])
    ).subscribe(([orderStatus, schedulingFields]) => {
      this.orderStatus = orderStatus;
      this.experiencesTitle = schedulingFields.experiences;
      this.origins = schedulingFields.origins;
      this.rooms = schedulingFields.rooms;
      this.employees = schedulingFields.employees;
    });
    this.setupDataSource();
  }

  ngAfterViewInit(): void {
    //clone default filters
    var clonedFilters = { ...this.defaultFilters };
    var searchText = clonedFilters.searchText;
    delete clonedFilters.searchText;

    // Initialize a new object to store the applied filters
    var newArr = { state: [] as string[], searchText } as WinerySchedulingFilters;

    // Iterate over the cloned filters with explicit typing
    (Object.keys(clonedFilters) as (keyof typeof clonedFilters)[]).forEach(
      (key) => {
        if (clonedFilters[key]) {
          // TypeScript now knows that key is of the correct type
          newArr.state!.push(key as Reservation.State);
        }
      }
    );

    // Assign the filtered results to appliedFilters
    this.appliedFilters = newArr;
    this.sort.sortChange.subscribe(() => (this.bottomPaginator.pageIndex = 0));
    merge(this.sort.sortChange, this.bottomPaginator.page)
      .pipe(
        tap(() => {
          this.listWineryScheduling(
            this.bottomPaginator.pageIndex,
            this.bottomPaginator.pageSize,
            this.sortBy
          );
        })
      )
      .subscribe();
    this.listWineryScheduling(
      this.bottomPaginator.pageIndex,
      this.bottomPaginator.pageSize,
      this.sortBy
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.defaultFilters) {
      this.appliedFilters = {
        state: this.builderFilter(this.defaultFilters),
        searchText: this.defaultFilters.searchText
      };
      this.listWineryScheduling(0, 50, this.sortBy);
    }
  }

  public handlePageTop(e: any) {
    let { pageSize } = e;

    this.bottomPaginator.pageSize = pageSize;

    if (!this.topPaginator.hasNextPage()) {
      this.bottomPaginator.lastPage();
    } else if (!this.topPaginator.hasPreviousPage()) {
      this.bottomPaginator.firstPage();
    } else {
      if (this.topPaginator.pageIndex < this.bottomPaginator.pageIndex) {
        this.bottomPaginator.previousPage();
      } else if (this.topPaginator.pageIndex > this.bottomPaginator.pageIndex) {
        this.bottomPaginator.nextPage();
      }
    }
  }

  public handlePageBottom(e: any) {
    if (!this.bottomPaginator.hasNextPage()) {
      this.topPaginator.lastPage();
    } else if (!this.bottomPaginator.hasPreviousPage()) {
      this.topPaginator.firstPage();
    } else {
      if (this.bottomPaginator.pageIndex < this.topPaginator.pageIndex) {
        this.topPaginator.previousPage();
      } else if (this.bottomPaginator.pageIndex > this.topPaginator.pageIndex) {
        this.topPaginator.nextPage();
      }
    }
  }

  private setupDataSource() {
    this.dataSource = new WineryScheduleDatasource(this.schedulingService);
  }

  listWineryScheduling(
    page: number = 0,
    count: number = 50,
    sortBy: string = "date,DESC"
  ) {
    this.dataSource = new WineryScheduleDatasource(this.schedulingService);
    this.dataSource.loadWineryScheduling(
      this.login.company.id,
      this.appliedFilters,
      page,
      count,
      sortBy
    );
  }

  generateInfoCall(reservation: DWS.WineryScheduling): Observable<String> {
    function convertArrayToString(
      value: DWS.SaleReservation.ParticipantsDetails[]
    ): String {
      let result: String = "";
      result = value
        .filter((item) => item.quantity !== undefined && item.quantity > 0) // Filter out items with quantity <= 0
        .map(
          (item) =>
            (item.type == "label" ? "€ " : "⭐") +
            ` ${item.name}: ${item.quantity}`
        )
        .join("\r\n");

      return result;
    }
    return this.schedulingService.getPartecipantsDetails(reservation.id).pipe(
      map((value) => convertArrayToString(value)) // Apply linear transformation and convert to string
    );
  }

  total(reservation: Reservation): number {
    return Number(Reservation.total(reservation));
  }

  start_at(reservation: Reservation): Date {
    return parse(reservation.start_at, "yyyy-MM-dd", new Date());
  }

  rowClicked(ws: VwReservation) {
    this.winerySchedulingClick.emit(ws);
  }

  origin(reservation: Reservation): string {
    try {
      return reservation.order_row
        ? new URL(reservation.order_row?.origin).hostname
        : "";
    } catch (e) {
      return "";
    }
  }

  openFilterDialog() {
    const advancedFilterFields: AdvancedFilters<WinerySchedulingFilters>[] = [
      {
        id: "reservationId",
        label: "ID Booking",
        type: "text",
      },
      {
        id: "experienceId",
        label: "Experience",
        type: "multiselect",
        options: this.experiencesTitle,
        name: (e) => TranslateDomainPipe.transform(e, "title"),
        match: (query, field, row) =>
          _.intersectionBy(query, [row.experience], "id").length > 0,
      },
      {
        id: "fullName",
        label: "Name",
        type: "text",
        match: (query, field, r) =>
          `${r.firstname} ${r.lastname}`
            .toLocaleLowerCase()
            .includes(query.toLocaleLowerCase()),
      },
      {
        id: "adults",
        label: "Adults",
        type: "number",
      },
      {
        id: "origin",
        label: "Origin",
        type: "multiselect",
        options: [...this.origins, 'Tour Operator'],
        name: (e) => e,
        compare: (a: string, b: string) => a.localeCompare(b) === 0,
      },
      {
        id: "total",
        label: "Total",
        type: "currency",
        match: (query, field, r: Reservation) =>
          AdvancedFilters.priceInRange(
            query,
            parseFloat(this.total(r).toString()) * 100
          ),
      },
      {
        id: "roomId",
        label: "Rooms",
        type: "multiselect",
        options: this.rooms,
        match: (query, field, row) =>
          _.intersectionBy(query, [row.room], "id").length > 0,
      },
      {
        id: "employeeId",
        label: "Employees",
        type: "multiselect",
        options: this.employees,
        match: (query, field, row) =>
          _.intersectionBy(query, [row.employee], "id").length > 0,
        name: (e) => e.name,
      },
      {
        id: "dateRange",
        label: "Experience of date",
        type: "date-interval",
        match: (query, field, r: Reservation) =>
          AdvancedFilters.betweenDates(query, r.start_at),
      },
    ];

    const dialog = this.dialog.open(FilterComponent, {
      width: "100%",
      minWidth: 850,
      maxWidth: 850,
      data: {
        appliedFilters: this.storedFilters,
        advancedFilterFields,
      },
    });

    dialog.afterClosed().subscribe((filters) => {
      if (!filters) return;

      this.storedFilters = { ...filters };
      this.reservationFilterEvent.emit(filters);

      if (filters["total"]) {
        _.merge(filters, {
          minTotal: filters["total"].gte,
          maxTotal: filters["total"].lte,
        });
        delete filters["total"];
      }

      // remove objects with null value
      filters = _.pickBy(filters, _.identity);

      Object.entries(filters).forEach(([key, value]) => {
        if (Array.isArray(value) && _.has(value[0], "id")) {
          // [{id,name}, {id,name}] => [{id}, {id}] => 'id,id'
          filters[key] = _.join(_.map(value, "id"), ",");
        }
      });

      filters.state = this.appliedFilters.state;
      this.appliedFilters = filters;

      this.listWineryScheduling(0, 50, this.sortBy);
      this.bottomPaginator.pageIndex = 0;

      this.dataSource.elementsSubject$.subscribe((e) => {
        if (!e) toast("Filter does not return any result");
      });
    });
  }

  filterByStatus(status: {
    confirmed?: boolean;
    waiting?: boolean;
    rejected?: boolean;
    revoked?: boolean;
    completed?: boolean;
  }) {
    const buildFilter = { state: this.builderFilter(status) };
    this.appliedFilters = { ...this.appliedFilters, ...buildFilter } as any;
    this.listWineryScheduling(0, 50, this.sortBy);
  }

  openExportDialog() {
    const dialog = this.dialog.open(DateIntervalComponent, {
      width: "100%",
      minWidth: 650,
      maxWidth: 650,
      data: {
        title: "Experience of date",
        showFilterInfo: true,
        nFilters: this.countAppliedFilters(),
      },
    });

    dialog.afterClosed().subscribe((data) => {
      const start = (data.start as Moment).toDate();
      const end = (data.end as Moment).toDate();
      const type: "csv" | "xlsx" = data.type;
      const obs$ = this.schedulingService
        .list(
          this.login.company.id,
          { ...this.appliedFilters, startTime: start, endTime: end },
          0,
          999999,
          this.sortBy
        )
        .pipe(pluck("content"));
      const obs2$ = this.formOptionsService.get(this.login.company.id, "");
      observe(forkJoin([obs$, obs2$])).subscribe(
        ([schedulingExporting, formOptions]) => {
          switch (type) {
            case "csv":
              ReservationExporting.saveAsCSV(schedulingExporting, formOptions);
              break;
            case "xlsx":
              ReservationExporting.saveAsXLSX(schedulingExporting, formOptions);
              break;
          }
        }
      );
    });
  }

  private countAppliedFilters(): any {
    let count = 0;
    if (this.appliedFilters) {
      Object.keys(this.appliedFilters).forEach(key => {
        if (key !== 'wineryCreatedAt' && key !== 'state' && this.appliedFilters![key as keyof WinerySchedulingFilters]) {
          count++;
        }
      })
    }
    return count;
  }

  private builderFilter(status: {
    revoked?: boolean;
    confirmed?: boolean;
    waiting?: boolean;
    rejected?: boolean;
    draft?: boolean;
    completed?: boolean;
    canceled?: boolean;
  }): Reservation.State[] {
    const statusArray: Array<Reservation.State> = [];
    if (status.confirmed) statusArray.push("confirmed" as Reservation.State);
    if (status.waiting) statusArray.push("waiting" as Reservation.State);
    if (status.canceled) statusArray.push("canceled" as Reservation.State);
    if (status.rejected) statusArray.push("rejected" as Reservation.State);
    if (status.revoked) statusArray.push("revoked" as Reservation.State);
    if (status.completed) statusArray.push("completed" as Reservation.State);
    if (status.draft) statusArray.push("draft" as Reservation.State);
    return statusArray;
  }

  centsInCurrency(cents: any = 0) {
    return Number(cents) / 100;
  }

  get sortBy() {
    return `${this.sort.active},${this.sort.direction.toUpperCase()}`;
  }

  showDetails(
    event: MouseEvent,
    isEntering: boolean,
    elementa: InfoIconHoverableComponent
  ): void {
    if (isEntering) {
      elementa.onMouseOverInternal(event);
    } else {
      elementa.onMouseOut(event);
    }
  }

  getMasterContactName(reservation: VwReservation): string {
    const contact = reservation.reservationContacts?.find(
      (c) => c.contact_type === "MASTER"
    );
    if (!contact) return "";
    return (contact.first_name || '') + " " + (contact.last_name || '');
  }

  getExperienceName(reservation: VwReservation): string {
    return (
      reservation.experienceTranslations?.find(
        (t) => t.translation_id == "TITLE"
      )?._translations[this.lang] || ""
    );
  }

  statusFn(wineryScheduling: any) {
    if (wineryScheduling.state?.toLowerCase() == "completed") {
      return "completeda";
    }
    if (wineryScheduling.state?.toLowerCase() == "canceled") {
      return "canceleda";
    }
    return wineryScheduling.state;
  }

  getOrigin(origin: any): any {
    if (!!!origin) {
      return "";
    }
    var originFormatted =
      "" +
      origin
        ?.replace("https://", "")
        .replace("http://", "")
        .replace("www.", "")
        .replace(/\:\d+/g, "")
        .toLowerCase();
    if (
      ("" + origin).toLocaleLowerCase().indexOf("manual") > -1 ||
      "Wine Suite".indexOf(origin) > -1
    ) {
      originFormatted = tr("Manual Reservation");
    }
    originFormatted = tr(originFormatted);
    return originFormatted ? originFormatted : origin ? tr(origin) : "";
  }
}
