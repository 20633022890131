// @ts-nocheck
import { AppEnvironment, environment as common } from '@env/environment.common';

export const environment: AppEnvironment = {
  production: true,
  name: 'dev',
  default: 'api',
  dws_base_url: 'https://dws-dev.divinea.com',
  features: {
    ...common.features,
    'automations': true,
    'sendgrid-statistics': true,
    'campaign-active': true,
    'company-management-privacy-files': true,
    'company-management-payouts': true,
    'unsubscribe-campaign': true,
    'save-template': true,
    'days-no-purchase-automation': true,
    'conversion-statistic': true,
    'subscribe-link': false,
    'subscription-conditions': true,
    'subscription-invitation-automation': true,
    'order-send-receipt-email': true,
    'product-channel-categories': true,
    'archive-contact': true,
    'winery-channel-name': true,
    'external-id-channel': true,
    'delete-channels': true,
    'winery-transactional-emails': true,
    'google-calendar-integration': true,
    'winelivery': true,
    'automatic-approve': true,
    'wineplatform': true,
    'spotty': true,
    'filters-widget': true,
    'country-in-reservation': true,
    'category-product': true,
    'availability-data-pdf': true,
    'close-matching-slot-languages-in-the-same-experience': true,
    'automatically-accept-reservations-on-same-slot': true
  },
  values: {
    ...common.values,
    'fcm-worker': 'assets/js/firebase-messaging-sw-dev.js',
    firebaseConfig: {
      apiKey: "AIzaSyC1TWN6IkoUzalJzC62x-JlnRlt9pgcw4E",
      authDomain: "divinea-dev-bdda0.firebaseapp.com",
      projectId: "divinea-dev-bdda0",
      storageBucket: "divinea-dev-bdda0.appspot.com",
      messagingSenderId: "796730530425",
      appId: "1:796730530425:web:4db1ed3da6f0d3f09412a8",
      measurementId: "G-WYPB9HSBCH"
    },
    firebaseAuthKey: 'BLLnPLbDmSDKpvn13gfPMbM_uDGhiVI0rgb68nXDsNxScGjAXfOHVRPK1YSNsNRRhlplAu26iAnU16Kvp5bdFo0',
    dateSendGridStatistics: '2021-03-26',
    'outlook-redirect-uri': 'https://dws-dev.divinea.com',
    divinea: 'divinea-dev.web.app',
    googleAnalyticsId: 'G-13JWHSY38X',
    wineplataformOrders:  'https://api-crm-dev.divinea.com/api/wineplatform/webhook',
    wineplataformCustomers:  'https://api-crm-dev.divinea.com/api/wineplatform/contact-webhook ',
    wineplataformSubscription:  'https://api-crm-dev.divinea.com/api/wineplatform/privacy-contact-webhook',
    version_postfix: 'dev',
    version_ribbon: 'dev',
    'preorder-url': 'https://preorders-dev.divinea.com',
  },
  hosts: {
    crm: {
      ...common.hosts.crm,
      host: 'api-crm-dev.divinea.com',
      protocol: 'https',
      port: ''
    },
    v2: {
      ...common.hosts.v2,
      host: 'api-dev.divinea.com',
      protocol: 'https',
      port: ''
    },
    v1: {
      ...common.hosts.v1,
      host: 'api-dev.divinea.com',
      protocol: 'https',
      port: ''
    },
    divinea: {
      site: 'divinea-staging.web.app'
    },
    websocket: {
      ...common.hosts.websocket,
      host: 'event-bus-dev.divinea.com',
      protocol: 'wss',
      port: '',
      root: 'ws'
    },
    divineaCom: {
      ...common.hosts.divineaCom,
      host: 'divinea.com',
      protocol: 'https',
      port: ''
    }
  }
};