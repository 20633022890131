import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { tr } from '@util/tr/tr';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

marker('Yes');
marker('No');

export interface ButtonConfig {
  display: boolean;
  text: string;
}

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {
  message: string;
  buttons: { yes: ButtonConfig, no: ButtonConfig };

  constructor(private dialog: MatDialogRef<ConfirmComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any) {
    this.message = data.message;
    this.buttons = data.buttons || { yes: { display: true, text: tr('Yes') }, no: { display: true, text: tr('No') }}
  }

  noClicked() {
    this.dialog.close(false);
  }

  yesClicked() {
    this.dialog.close(true);
  }
}
