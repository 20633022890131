import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { observe } from 'projects/web/src/util/loading/loading';
import { WineSuiteParametersService } from '../dws/wine-suite-parameters.service';
import { WineSuiteParameters } from '../../interfaces/dws/wine-suite-parameters';

@Injectable({
	providedIn: 'root'
})
export class WineSuiteParametersResolverService implements Resolve<WineSuiteParameters> {

	constructor(private service: WineSuiteParametersService) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WineSuiteParameters> | Promise<WineSuiteParameters> | WineSuiteParameters {
		return observe(this.service.showWineSuiteParameters());
	}
}