import { Injectable } from "@angular/core";
import { forkJoin, Observable, of, ReplaySubject, throwError } from "rxjs";
import { Authorization } from "@interfaces/authorization";
import { resource } from "@util/resource";
import { catchError, first, map, mergeMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { Account, AccountRegistration } from "@interfaces/account";
import { storage } from "@util/storage";
import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import { Host } from "@interfaces/host";
import { EditSelfAccount, SelfAccount } from "../interfaces/dws/self-account";

import { Base64 } from "@util/base64";
import { set } from "lodash";
import { tr } from "@util/tr";

export interface Login {
  account: Account;
  authorization: Authorization;
  company: Host.Company;
  selfAccount: SelfAccount;
  userDWS: any;
  wineryId: string;
}
interface LoginResponseDWS {
  idToken: string;
  wineryId: string;
  expire: Date;
  details: any;
}

interface LoginResponse {
  account: Account;
  expires_at: string;
  host_data: any;
}

marker(["Invalid credentials", "Unknown error"]);

@Injectable({
  providedIn: "root",
})
export class AccountsService {
  login$: ReplaySubject<Login | null> = new ReplaySubject<Login | null>(1);
  authorization$: ReplaySubject<Authorization | null> =
    new ReplaySubject<Authorization | null>(1);
  account$: ReplaySubject<Account | null> = new ReplaySubject<Account | null>(
    1
  );
  company$: ReplaySubject<Host.Company | null> =
    new ReplaySubject<Host.Company | null>(1);
  selfAccount$: ReplaySubject<SelfAccount | null> =
    new ReplaySubject<SelfAccount | null>(1);

  private cachedPermissions: string[] | null = null;
  cachedPermissions$: ReplaySubject<string[]> = new ReplaySubject<
    string[]
  >();

  constructor() {
    storage.get("login").then((l) => this.login$.next(l));
    this.login$.subscribe(async (l) => {
      await storage.set("login", l);
      await storage.set("is_admin", !!l?.authorization?.is_admin);
      await storage.set("is_winery", !!l?.authorization?.is_winery);
      this.authorization$.next(l ? l.authorization : null);
      this.account$.next(l ? l.account : null);
      this.company$.next(l ? l.company : null);
      this.selfAccount$.next(l ? l.selfAccount : null);
      this.cachedPermissions = l?.userDWS?.permissions || null;
      this.cachedPermissions$.next(this.cachedPermissions || []);

    });

    setInterval(() => {
      this.ping().subscribe(
        () => {},
        () => {
          this.logout();
        }
      )
    }, 60000);

  }

  logout() {
    this.login$.next(null);
  }

  login(email: string, password: string): Observable<Login> {
    const base64password = Base64.encode(password);
    // first call to validate user caoount and password and retrive jwt token
    return resource("crm://account/authenticate")
      .post<LoginResponseDWS>(
        { email, base64password },
        { observe: "response" }
      )
      .pipe(
        map((response) => {
          if (!response.body) {
            throw {
              type: response.type,
              message: "Unknown error",
              cause: response,
              status: response.status,
            };
          }
          var token = response.body.idToken;
          var wineryId = response.body.wineryId;
          var expires_at = response.body.expire;
          var details = response.body.details;
          return {
            wineryId: wineryId,
            account: details,
            userDWS: details,
            company: {id: wineryId},
            authorization: {
              expires_at: expires_at,
              token: response.headers.get("Authorization") as string,
              winery_id: wineryId,
              is_admin: false,
              is_winery: true,
            },
          } as Login;
        }),

        mergeMap((data: Login) => {
          return resource("v2://host")
            .authorization(
              "bearer",
              data.authorization.token.replace("Bearer ", "")
            )
            .get<any>()
            .pipe(
              map((hostData) => {
                let company = { ...hostData.company };
                company.id = data.wineryId;
                return { ...data, company, account: { ...hostData } };
              }),
              catchError(() => {
                // Return a null object or a default object structure on error
                //return of({ ...data, company: {}, account: {}});
                return of(data);
              })
            );
        }),

        mergeMap((data: Login) => {
          return resource("crm://self/account")
            .authorization(
              "bearer",
              data.authorization.token.replace("Bearer ", "")
            )
            .get<SelfAccount>()
            .pipe(
              map((selfAccount) => {
                return { ...data, selfAccount };
              }),
              catchError(() => {
                // Return a null object or a default object structure on error
                return of(data);
              })
            );
        }),

        catchError((e) => {
          if (e instanceof HttpErrorResponse) {
            if (e.status === 401) {
              return throwError({
                type: "unauthorized",
                message: "Invalid credentials",
                cause: e,
                status: e.status,
              });
            }
            if (e.status === 400) {
              return throwError({
                type: "notmigrate",
                message: tr("Account need migration"),
                cause: e,
                status: e.status,
              });
            }
            
            // Handle "No Route to Host" or other network errors
            if (e.error && (e.message.includes("No route to host") || e.message.includes("Network Error")) || e.status === 404) {
              return throwError({
                type: "network",
                message: tr("Cannot reach the server. Please check your network connection. If error persists, please contact support."),
                cause: e,
                status: e.status || 0,
              });
            }
          }
          return throwError({
            type: "unknown",
            message: "Unknown error",
            cause: e,
            status: e.status,
          });
        })
      );
  }

  async use(login: Login): Promise<void> {
    this.login$.next(login);
    await new Promise((r) => setTimeout(r, 40));
  }

  async getLogin(): Promise<Login | null> {
    return storage.get("login");
  }

  async getRole(): Promise<boolean | null> {
    return storage.get("role");
  }

  getAccount(): Observable<Account> {
    return this.account$.asObservable() as Observable<Account>;
  }

  recover(email: string): Observable<unknown> {
    return resource("v2://password")
      .post({
        account: {
          email,
        },
      })
      .pipe(
        catchError((e: HttpErrorResponse) => {
          if (e.status === 422) {
            return throwError({
              type: "unauthorized",
              message: "Account not found",
              cause: e,
            });
          }
          return throwError({
            type: "unknown",
            message: "Unknown error",
            cause: e,
          });
        })
      );
  }

  update(account: AccountRegistration): Observable<unknown> {
    return resource("v2://registrations").put({
      account: account,
    });
  }

  edit(editAccount: EditSelfAccount): Observable<{ token: string }> {
    return resource("crm://self/account/edit").post(editAccount);
  }

  ping(): Observable<boolean> {
    return resource("crm://ping").get<boolean>();
  }

  hasPermission(permission: string): boolean {
    return this.cachedPermissions
      ? this.cachedPermissions.includes(permission)
      : false;
  }

  hasCachedPermissions(): boolean {
    return this.cachedPermissions !== null;
  }

  async hasPermissionAsync(permission: string): Promise<boolean> {
    return this.cachedPermissions$
      .pipe(
        first(),
        map((permissions) => permissions.includes(permission))
      )
      .toPromise();
  }
}
