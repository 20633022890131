import { Winery } from '@interfaces/dws/winery';
import { FormBuilder, Validators } from '@angular/forms';
import { ConditionType, Matcher } from 'interfaces/dws/matcher';

export namespace Tag {
  export interface DataCrm {
    id: number;
    name: string;
    description?: string;
    conditionType?: ConditionType;
    targetTypology?: 'person' | 'company' | null;
    winery?: Winery;
    predefined: boolean;
    manual: any;
    values?: TagValue[];
    selected?: boolean;
    
    otherExistingTagTypology?: 'person' | 'company' | 'all';
  }

  export namespace TagValue {
    import ContactMatcherOperator = Matcher.ContactMatcherOperator;
    import ContactMatcher = Matcher.ContactMatcher;

    export interface Data {
      id: number;
      contactMatcherOperator: ContactMatcherOperator;
      contactMatcher?: ContactMatcher;
      tag: TagCrm;
      value?: number | any;
      stringValue?: string;
    }
  }

  export type TagValue = TagValue.Data;


  export function createFormGroup(fb: FormBuilder, tagValues?: Partial<TagValue>) {
    const f = fb.group({
      contactMatcherOperator: fb.group({
        id: ['', Validators.required],
      }),
      contactMatcher: fb.group({
        id: ['', Validators.required],
      }),
      value: ['', [Validators.required]],
    });
    if (tagValues) f.patchValue(tagValues);
    if (tagValues && tagValues.stringValue) f.patchValue({ value: tagValues.stringValue })
    return f;
  }
}
export type TagCrm = Tag.DataCrm;
