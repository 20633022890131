import { resource } from '@util/resource';
import { Observable } from 'rxjs';
import { Injectable} from '@angular/core';
import { WineSuiteParameters } from "../../interfaces/dws/wine-suite-parameters";
import { NotificationDWS } from '@interfaces/dws/notification-dws';


@Injectable({
  providedIn: 'root'
})
export class NotificationsAdminService {
  constructor() {}

  pushNotification(notification: NotificationDWS): Observable<unknown> {
    return resource('crm://admin/notifications/push')
        .headers({ 'x-admin-authorization': '1' })
        .post(notification);
  }
}


