import { Injectable } from '@angular/core';
import { resource } from '../../util/resource';
import { Observable } from 'rxjs';
import { DWSFormOptions } from '@interfaces/dws/experience-dws';

@Injectable({
  providedIn: 'root'
})
export class HostReservationFormOptionsService {
  get(companyId: string, experienceId: string): Observable<DWSFormOptions> {
    return resource('crm://scheduling/reservation-form-options')
      .params({wineryId: companyId, experienceId: experienceId}).get<DWSFormOptions>();
  }
}
