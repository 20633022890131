import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessPipe, ArrayPipe, JoinPipe, PluckPipe, SplitPipe } from 'pipes/array/array.pipe';


@NgModule({
  declarations: [ArrayPipe, SplitPipe, AccessPipe, PluckPipe, JoinPipe],
  exports: [ArrayPipe, SplitPipe, AccessPipe, PluckPipe, JoinPipe],
  imports: [
    CommonModule
  ]
})
export class ArrayPipeModule { }
