// tslint:disable:directive-selector
// tslint:disable:object-literal-key-quotes
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { feature } from '@web/app/modules/feature-toggle/feature-toggle.util';

@Directive({
  selector: '[feature]'
})
export class FeatureToggleDirective {
  constructor(private template: TemplateRef<any>, private container: ViewContainerRef) { }
  @Input('feature') set feature(featureName: any) {
    const container = this.container;
    container.clear();
    const enabled = feature.on(featureName);
    if (enabled) {
      container.createEmbeddedView(this.template, { $implicit: enabled, 'feature': enabled });
    }
  }
}

