import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export namespace Segment {
  export interface Data {
    id: number;
    name: string;
    description: string;
    typology: 'person' | 'company' | null;
    selected?: boolean;
    winery?: { id: string };
  }

  export function createFormGroup(fb: FormBuilder, segment?: Partial<Segment.Data>): FormGroup {
    const f = fb.group({
      name: ['', Validators.required],
      description: [''],
      winery: [],
      typology: []
    })
    if (segment) {
      f.patchValue(segment);
      if (!segment.typology) f.get('typology')!.setValue("");
    }
    return f;
  }
}

export type Segment = Segment.Data;
