import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';

@Injectable({
  providedIn: 'root'
})
export class EmailsService {

  constructor() {
  }

  list(filters?: EmailFilters): Observable<DWS.Page<DWS.Email>> {
    return resource('crm://emails')
      .params({ ...filters })
      .get<DWS.Page<DWS.Email>>();
  }

  checkEmail(email: string): Observable<DWS.Email> {
    return resource('crm://emails/email')
      .params({email: email.toLowerCase()})
      .get<DWS.Email>();
  }


  checkEmailWithWineryId(email: string, wineryId: string): Observable<DWS.Email> {
    return resource('crm://emails/emailWithWinery')
      .params({email: email.toLowerCase(), wineryId: wineryId})
      .get<DWS.Email>();
  }

  create(email: DWS.Email): Observable<DWS.Email> {
    return resource('crm://emails')
      .post<DWS.Email>(email);
  }

  update(emailId: number, email: DWS.Email) {
    return resource('crm://emails/id')
      .params({ id: emailId })
      .put(email);
  }
}

export interface EmailFilters {
  contactId?: number;
}
