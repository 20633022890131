import { Conversor } from '@util/convert/conversor';
import { ArrayBufferConverter } from '@util/convert/array-buffer';
import { BlobConverter } from '@util/convert/blob';
import { Base64Converter } from '@util/convert/base64';

function convert(input: ArrayBuffer): Conversor<ArrayBuffer>;
function convert(input: Blob): Conversor<Blob>;
function convert(input: string): Conversor<string>;

function convert(input: ArrayBuffer | Blob | string): Conversor<Blob> | Conversor<ArrayBuffer> | Conversor<string> {
  if (typeof input === 'string') return new Base64Converter(input);
  if (input instanceof ArrayBuffer) return new ArrayBufferConverter(input);
  if (input instanceof Blob) return new BlobConverter(input);
  else throw new Error('No suitable conversor found');
}

export { convert };
