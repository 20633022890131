import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DWS } from '@interfaces/dws';
import { CountriesCompleteService, CountryComplete } from '@services/countries-complete.service';

@Component({
  selector: 'app-contact-association',
  templateUrl: './contact-association.component.html',
  styleUrls: ['./contact-association.component.scss']
})
export class ContactAssociationComponent {
  contact: any = {};
  countries: CountryComplete[] = [];

  constructor(
    private dialog: MatDialogRef<ContactAssociationComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private countriesCompleteService: CountriesCompleteService
  ) {
    this.contact = data.contact;
    this.countriesCompleteService.list().subscribe(countries => {
      this.countries = countries;
    });
  }

  getCountryByIso(iso: string): CountryComplete | undefined {
    return this.countries.find(c => c.alpha2Code === iso);
  }

  get email() {
    return this.contact.email;
  }

  get phone() {
    return this.contact.phone;
  }

  noClicked() {
    this.dialog.close(false);
  }

  yesClicked() {
    this.dialog.close(true);
  }
}
