import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export class ValueInListValidator {
  private static validate(control: AbstractControl, list: any[], nullable: boolean): ValidationErrors | null {
    const value = control.value;
    if (!value || !value.length) return nullable ? null : {empty: true};
    if (list.includes(value)) {
      return null;
    } else {
      return {notInList: true};
    }
  }

  static fromList(list: any[], nullable = false): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.validate(control, list, nullable);
    };
  }
}