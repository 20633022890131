/**
 * ```
 * import { access } from '@util/route'
 * constructor(router: Router) {
 *   console.log(access(route).param('id'))
 * }
 */
import { Router } from '@angular/router';

class RouterStateAccessor {
  router: Router;

  constructor(router: Router) {
    this.router = router;
  }

  data(key?: string): any {
    const navigation = this.router.getCurrentNavigation(),
          extras     = navigation ? navigation.extras : undefined,
          extraState = extras ? extras.state : undefined;
    if (!extraState) return undefined;
    if (key) return extraState[key];
    return extraState;
  }
}

const statefn = (router: Router): RouterStateAccessor => {
  return new RouterStateAccessor(router);
};

export { statefn as state };
