import { Component, Input, OnInit } from '@angular/core';
import {
  CalendarSchedulingEvent,
  calendarStatus
} from '@services/dws/scheduling/calendar-event/calendar-scheduling-event';
import { capitalizeEachWord } from '../../../../../../../util/transform-string/capitalize-each-word';
import { VwReservation } from '@interfaces/dws/reservation';
import { Room } from '@interfaces/dws/room';
import { Employee } from '@interfaces/dws/employee';
import { locale } from '@util/locale';

@Component({
  selector: 'app-event-30m',
  template: `
  <div class="event-data event-view-{{ view }} {{ status.cssClass }}">

  <span 
  [ngStyle]="{'background-color': meta.experience && meta.experience![0]?.color ? meta.experience[0].color : 'unset'}"
  class="experienceColorFlag"></span>
  
    <div class="event-title" [ngStyle]="{ color: color }">
      <span><strong>{{ capitalizeEachWord(getExperienceName(meta)) }}{{view == 'day' ? (' | ' + capitalizeEachWord(contactFullName)) :'' }}</strong></span>
    </div>

    <div class="event-title" *ngIf="view == 'week'" [ngStyle]="{ color: color }">
      <span><strong>{{ capitalizeEachWord(contactFullName) }}</strong></span>
    </div>

    <span class="ribbon" *ngIf="meta.isTourOperator">{{'OTA' | translate}}</span>
  </div>
  `,
  styleUrls: ['./common.scss']
})
export class Event30mComponent implements OnInit {
  @Input() meta!: VwReservation;
  status!: { cssClass: string; icon: string; text: string; }
  @Input() width!: number;
  @Input() height!: number;
  @Input() color: any;
  @Input() view: 'week' | 'day' | 'month' = 'week';
  @Input() rooms: Room[] = [];
  @Input() employees: Employee[] = [];
  public capitalizeEachWord = capitalizeEachWord;
  language = locale().locale;

  ngOnInit() {
    this.status = calendarStatus(this.meta);
  }

  get contactFullName() {
    let contact = this.meta.reservationContacts?.find(contact => contact.contact_type === 'MASTER');
    if (!contact) return '';
    if (!contact.first_name && !contact.last_name) return "No name";
    return `${contact.first_name || ''} ${contact.last_name || ''}`;
  }

  getExperienceName(reservation: VwReservation): string {
    return reservation.experienceTranslations?.find(t => t.translation_id == 'TITLE')?._translations[this.language] || '';
  }
}
