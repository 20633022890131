import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';

@Injectable({
  providedIn: 'root'
})
export class PhonesService {

  constructor() {
  }

  list(filters?: PhoneFilters): Observable<DWS.Page<DWS.Phone>> {
    return resource('crm://phones')
      .params({ ...filters })
      .get<DWS.Page<DWS.Phone>>();
  }

  checkPhone(phone: string): Observable<DWS.Phone> {
    return resource('crm://phones/phone')
      .params({ phone: phone })
      .get<DWS.Phone>();
  }

  create(phone: DWS.Phone): Observable<DWS.Phone> {
    return resource('crm://phones')
      .post<DWS.Phone>(phone);
  }

  update(phoneId: number, phone: DWS.Phone) {
    return resource('crm://phones/id')
      .params({ id: phoneId })
      .put(phone);
  }

  delete(phoneId: number) {
    return resource('crm://phones/id')
      .params({ id: phoneId })
      .delete();
  }
}

export interface PhoneFilters {
  contactId?: number;
}
