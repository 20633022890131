import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReservationContactsService } from '@services/dws';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { toast } from 'projects/web/src/util/toast';
import { tr } from '@util/tr';
import { observe } from 'projects/web/src/util/loading/loading';
import { Contact } from '@interfaces/dws/contact';
import { confirm } from '../dialogs/confirm';
import { CountryComplete, CountriesCompleteService } from "@services/countries-complete.service";
import { locale } from '@util/locale';
import { Reservation } from '@interfaces/dws/reservation';
import { ValueInListValidator } from '@services/validators/value-in-list-validator';
import { StorageService } from '@services/storage.service';

export interface CountryPrefix {
  isoCode2: string[];
  isoCode3: string[];
  phonePrefix: string;
}

@Component({
  selector: 'app-new-contact-sidenav',
  templateUrl: './new-contact-sidenav.component.html',
  styleUrls: ['./new-contact-sidenav.component.scss']
})
export class NewContactSidenavComponent implements OnInit {
  @Output() close: EventEmitter<Reservation.ReservationContactDTO> = new EventEmitter<Reservation.ReservationContactDTO>();
  @Input() wineryId!: string;
  @Input() isAppointment = false;
  @Input() reservationId?: string;
  @Input() isTourOperator = false;
  @Input() set contact(contact: Reservation.ReservationContactDTO) {
    this.form.reset();
    this.contactIsNew = !contact?.id || (typeof (contact as any).id == 'string' && (contact as any).id.includes('-'));
    this.contactEdit = contact;
    contact && this.form.patchValue({
      ...contact,
      mainPhonePrefix: contact.phoneNumber?.split(' ')[0].replace('+', ''),
      phoneNumber: contact.phoneNumber?.split(' ')[1],
    });
  }

  form: FormGroup;
  countriesPrefixData: CountryPrefix[] = [];
  filteredCountriesPrefixData: CountryPrefix[] = [];
  countriesIsoAndNames: {name: string, iso: string}[] = [];
  filteredCountriesIsoAndNames: {name: string, iso: string}[] = [];

  contactEdit: Contact = {};
  contactIsNew: boolean = true;

  constructor(
    private fBuilder: FormBuilder,
    private countriesCompleteService: CountriesCompleteService,
    private reservationContactsService: ReservationContactsService,
    private storageService: StorageService,
  ) {
    this.displayPhonePrefix = this.displayPhonePrefix.bind(this);
    this.displayCountry = this.displayCountry.bind(this);
    
    observe(this.countriesCompleteService.list()).subscribe(country => {
      if (country) {
        this.getCountryPrefixData(country);
        this.getCountryIsoCodeData(country);
        this.form.get('mainPhonePrefix')?.setValidators([ValueInListValidator.fromList(this.countriesPrefixData.map(x => x.phonePrefix))]);
        this.form.get('countryIso')?.setValidators([ValueInListValidator.fromList(this.countriesIsoAndNames.map(x => x.iso))]);
      }
    });

    this.form = fBuilder.group({
      id: [''],
      firstName: [''],
      lastName: [''],
      email: ['', [Validators.email]],
      phoneNumber: [''],
      countryIso: [''],
      contactType: ['MASTER'],
      mainPhonePrefix: [''],
      manualMarketing: [false],
    });

    this.form.get('email')?.valueChanges.subscribe((value) => {
      if (!value?.length || this.form.get('email')?.invalid) {
        this.form.get('manualMarketing')?.setValue(false);
      }
    });
  }

  private getCountryIsoCodeData(countries: CountryComplete[]) {
    let local: any = locale().locale;
    countries.forEach(country => {
      this.countriesIsoAndNames.push({
        name: country.translations[local as keyof {en: string, it: string}] || country.name,
        iso: country.alpha2Code
      });
    })

    this.countriesIsoAndNames = this.sortCountries(this.countriesIsoAndNames);

    this.filteredCountriesIsoAndNames = this.countriesIsoAndNames;
  }

  private getCountryPrefixData(countries: CountryComplete[]) {
    countries.forEach(country => {
      if (country.callingCodes) {
        country.callingCodes.forEach((prefixCode: string) => {
          const alreadyInListIndex = this.countriesPrefixData.findIndex(countryPData => countryPData.phonePrefix === prefixCode)
          if (alreadyInListIndex > 0) {
            this.countriesPrefixData[alreadyInListIndex].isoCode2.push(country.alpha2Code);
            this.countriesPrefixData[alreadyInListIndex].isoCode3.push(country.alpha3Code);
          } else {
            this.countriesPrefixData.push({
              phonePrefix: prefixCode,
              isoCode3: [country.alpha3Code],
              isoCode2: [country.alpha2Code]
            } as CountryPrefix)
          }
        })
      }
    })
    
    this.form.get('mainPhonePrefix')?.valueChanges.subscribe((value) => {
      const mainPhonePrefix = value;
      const phone = this.form.get('phone')?.value || '';
      this.form.get('phone')?.setValue(`+${mainPhonePrefix} ${phone}`);
    });

    this.countriesPrefixData = this.sortPrefixes(this.countriesPrefixData);

    this.filteredCountriesPrefixData = this.countriesPrefixData;
  }

  getPrefixWithPlus(): string {
    const prefix = this.form.get('mainPhonePrefix')?.value;
    const phone = this.form.get('phone')?.value;
    if (!prefix) {
      return '';
    }
      return `+${prefix} `;
  }

  ngOnInit() {
    if (this.isAppointment) {
      this.form.get('email')!.clearValidators();
    }
  }

  cancelClicked() {
    this.close.emit(undefined);
  }

  async saveClicked() {
    let toReturn = this.form.value;
    if (!toReturn.firstName && !toReturn.lastName && !toReturn.email && !toReturn.phoneNumber && !toReturn.countryIso) {
      toast(tr("The reservation can't be saved without at least one client information"));
      return;
    } else if (!toReturn.email) {
      const cont = await confirm.yesno(tr("The contact will be saved without email address, it won't be crated in the CRM and transactional emails won't be sent. Do you want to continue?"));
      if (!cont) {
        return;
      }
    }
    
    if (this.form.invalid) {
      toast(tr("Invalid email"));
      return;
    }

    this.updateTopCountriesAndPrefixes(
      {name: this.displayCountry(toReturn.countryIso), iso: toReturn.countryIso},
      this.countriesPrefixData.find(x => x.phonePrefix === toReturn.mainPhonePrefix)!
    );
    
    if (toReturn.phoneNumber) {
      toReturn.phoneNumber = this.getPrefixWithPlus() + toReturn.phoneNumber;
    }
    delete toReturn.mainPhonePrefix;
    toReturn.reservationId = this.reservationId;
    if (this.isTourOperator) {
      if (!toReturn.otherData) toReturn.otherData = {};
      toReturn.otherData.tourOperator = true;
    }
    
    if (this.reservationId && this.reservationId != 'NEW') { 
      this.updateReservatioContactRightNow(toReturn);
    } else {
      this.close.emit(toReturn);
    }
  }

  private updateReservatioContactRightNow(contact: Reservation.ReservationContactDTO) {
    if (!this.contactIsNew) {
      this.reservationContactsService.update(contact).subscribe(
        (c) => {
          toast(tr("Resevation contact updated successfully"));
          this.close.emit(contact);
        },
        () => {
          toast(tr("Error updating the contact, please try again later"));
        }
      );
    } else {
      contact.id = undefined;
      if (!contact.contactType) contact.contactType = 'MASTER';
      this.reservationContactsService.create(contact).subscribe(
        (c) => {
          toast(tr("Resevation contact created successfully"));
          this.close.emit(c);
        },
        () => {
          toast(tr("Error creating the contact, please try again later"));
        }
      );
    }
  }
  
  filterExperiences(event: Event) {
    let searchText = (event.target as HTMLInputElement).value || '';
    searchText = searchText.replace('+', '').toLowerCase();
  
    this.filteredCountriesPrefixData = this.countriesPrefixData.filter(countryPrefix => 
      countryPrefix.phonePrefix.toLowerCase().includes(searchText) ||
      countryPrefix.isoCode2.some(x => x.toLowerCase().includes(searchText)) ||
      countryPrefix.isoCode3.some(x => x.toLowerCase().includes(searchText))
    );
  }

  filterCountries(event: Event) {
    let searchText = (event.target as HTMLInputElement).value || '';
    searchText = searchText.toLowerCase();
  
    this.filteredCountriesIsoAndNames = this.countriesIsoAndNames.filter(country => 
      country.iso.toLowerCase().includes(searchText) ||
      country.name.toLowerCase().includes(searchText)
    );
  }

  displayPhonePrefix(prefix: string, o?: CountryPrefix): string {
    var obj = o || this.countriesPrefixData?.find(countryPrefix => countryPrefix.phonePrefix === prefix);
    return obj ? `+${obj.phonePrefix} | ${ obj.isoCode3.find(x => x == 'USA') ? 'USA' : obj.isoCode3[0] }` : '';
  }

  displayCountry(iso: string, o?: {name: string, iso: string}): string {
    var obj = o || this.countriesIsoAndNames?.find(country => country.iso === iso);
    return obj ? obj.name : '';
  }

  updateTopCountriesAndPrefixes(country: {name: string, iso: string}, prefix: CountryPrefix) {
    let topCountries = this.storageService.load('topCountries');
    let topPrefixes = this.storageService.load('topPhonePrefixes');
    if (!topCountries) {
      topCountries = this.countriesIsoAndNames.map(x => ({...x, count: 0}));
    }
    if (!topPrefixes) {
      topPrefixes = this.countriesPrefixData.map(x => ({...x, count: 0}));
    }
    let countryIndex = topCountries.findIndex((x: any) => x.iso === country.iso);
    let prefixIndex = topPrefixes.findIndex((x: any) => x.phonePrefix === prefix?.phonePrefix);
    if (countryIndex >= 0) {
      topCountries[countryIndex].count++;
    }
    if (prefixIndex >= 0) {
      topPrefixes[prefixIndex].count++;
    }
    topCountries = topCountries.sort((a: any, b: any) => b.count - a.count);
    topPrefixes = topPrefixes.sort((a: any, b: any) => b.count - a.count);
    this.storageService.save('topCountries', topCountries);
    this.storageService.save('topPhonePrefixes', topPrefixes);
  }

  sortPrefixes(prefixes: CountryPrefix[]): CountryPrefix[] {
    let topPrefixes = this.storageService.load('topPhonePrefixes') || [];

    let italy = prefixes.find(prefix => prefix.isoCode3.includes('ITA'));

    let topUsedPrefixes = topPrefixes
        .filter((topPrefix: any) => !topPrefix.isoCode3.includes('ITA'))
        .slice(0, 2)
        .map((topPrefix: any) => {
            return prefixes.find(prefix => prefix.phonePrefix === topPrefix.phonePrefix);
        })
        .filter(Boolean);

    let remainingPrefixes = prefixes.filter(prefix => 
        !prefix.isoCode3.includes('ITA') && 
        !topUsedPrefixes.some((topPrefix: any) => topPrefix.phonePrefix === prefix.phonePrefix)
    ).sort((a, b) => a.phonePrefix.localeCompare(b.phonePrefix));

    return [
        ...(italy ? [italy] : []), 
        ...topUsedPrefixes, 
        ...remainingPrefixes
    ];
}

  sortCountries(countries: {name: string, iso: string}[]): {name: string, iso: string}[] {
    let topCountries = this.storageService.load('topCountries') || [];

    let italy = countries.find(country => country.iso === 'IT');

    let topUsedCountries = topCountries
        .filter((topCountry: any) => topCountry.iso !== 'IT')
        .slice(0, 2)
        .map((topCountry: any) => {
            return countries.find(country => country.iso === topCountry.iso);
        })
        .filter(Boolean);

    let remainingCountries = countries.filter(country => 
        country.iso !== 'IT' && 
        !topUsedCountries.some((topCountry: any) => topCountry.iso === country.iso)
    ).sort((a, b) => a.name.localeCompare(b.name));

    return [
        ...(italy ? [italy] : []), 
        ...topUsedCountries, 
        ...remainingCountries
    ];
  }
}