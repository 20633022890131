import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { LoadingComponent } from '@web/app/components/loading/loading.component';

function onCancel(f: () => any) {
  return (observable: any) => new Observable(observer => {
    let completed = false;
    let errored = false;
    const subscription = observable.subscribe({
      next: (v: unknown) => observer.next(v),
      error: (e: any) => {
        errored = true;
        observer.error(e);
      },
      complete: () => {
        completed = true;
        observer.complete();
      }
    });
    return () => {
      subscription.unsubscribe();
      if (!completed && !errored) f();
    };
  });
}

function _observe<T>(obs: Observable<T>, showLoading: boolean = true): Observable<T> {
  const ctx = () => {
    let wasSet = false;
    const timeout = setTimeout(() => {
      if (showLoading) LoadingComponent.loading = true;
      wasSet = true;
    }, 200);
    return obs.pipe(
      catchError(err => {
        if (wasSet) {
          if (showLoading) LoadingComponent.loading = false;
        }
        clearTimeout(timeout);
        return throwError(err);
      }),
      tap(() => {
        if (wasSet) {
          if (showLoading) LoadingComponent.loading = false;
        }
        clearTimeout(timeout);
      }),
      onCancel(() => {
        if (wasSet) {
          if (showLoading) LoadingComponent.loading = false;
        }
        clearTimeout(timeout);
      })
    );
  };
  return ctx() as Observable<T>;
}

function _observeHidded<T>(obs: Observable<T>): Observable<T> {
  return _observe(obs, false)
}

export { _observe as observe, _observeHidded as observeNoLoading };
