import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';

@Injectable({
  providedIn: 'root'
})
export class WineriesService {

  constructor() {
  }

  list(filters: WineryFilters, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.Winery>> {
    return resource('crm://wineries')
      .params(
        { page, count, ...filters }
      )
      .headers({ 'x-admin-authorization': '1' })
      .get<DWS.Page<DWS.Winery>>();
  }
}

export interface WineryFilters {
  name?: string;
}
