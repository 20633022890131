import { CampaignEditor } from 'interfaces/dws/campaign-editor';
import { Winery } from './winery';
import { MatTableDataSource } from '@angular/material/table';
import { DWS } from 'interfaces/dws/index';

export namespace TemplateHtml {
  export interface Data {
    id?: number;
    title: string;
    type: string;
    languageCode: string;
    liveTemplateData: CampaignEditor;
    draftTemplateData?: CampaignEditor;
    winery?: Winery;
  }

  export interface ReducedData {
    id?: number;
    title: string;
    type: string;
    languageCode: string;
    winery?: Winery;
    isDraft: boolean;
    isInactive: boolean;
    activationHasBeenChanged: boolean;
    duplicateSketch: boolean;
  }

  export interface NewBase {
    title: string;
    type: string;
    languageCode: string;
    liveTemplateData: CampaignEditor;
    draftTemplateData?: CampaignEditor;
    winery?: Winery
  }
  export interface NewWinery extends NewBase {
    winery?: Winery
  }
}

export type TemplateHtml = TemplateHtml.Data;
export type TemplateHtmlReduced = TemplateHtml.ReducedData;

export namespace CategoryTemplateHtml {
  export interface Data {
    name: string;
    trigger: string;
    templatesHtml: TemplateHtmlReduced[];
    dataSource: MatTableDataSource<DWS.TemplateHtml.ReducedData & { checked?: boolean }>
  }
  
  export const make = (trigger: TriggerTemplateHtmlType): TemplateHtmlCategory & { checked?: boolean } => {
    return {
      name: TYPES_NAMES_TEMPLATE_HTML[trigger],
      trigger,
      templatesHtml: [],
      dataSource: new MatTableDataSource<DWS.TemplateHtml.ReducedData>([]),
      
      // @ts-ignore
      get checked(): boolean {
        return this.dataSource.data.every(te => !te.winery || te.checked);
      },
      
      // @ts-ignore
      set checked(v: boolean) {
        this.dataSource.data.forEach(te => te.checked = v);
      }
    }
  }
}

export type TemplateHtmlCategory = CategoryTemplateHtml.Data;

type TriggerTemplateHtmlType =  'pdf-buy-as-a-gift-to'; // | '';

export const TYPES_NAMES_TEMPLATE_HTML = {
  'pdf-buy-as-a-gift-to': 'Gift PDF voucher to Buyer',
}

export const TYPES_TEMPLATE_HTML: TriggerTemplateHtmlType[] = [
  'pdf-buy-as-a-gift-to'
];
