import { environment } from '@env/environment';

async function get<T = any>(key: string, prefix?: string): Promise<T | null> {
  // @ts-ignore
  const appKey = environment.values['storage-key'];
  if (prefix) key = `${appKey}:${prefix}:${key}`;
  else key = `${appKey}:${key}`;
  const data = await localStorage.getItem(key);
  if (data) return JSON.parse(data);
  else return null;
}

export { get };
