<mat-toolbar [ngStyle]="{ background: '#009ADE' }">
  <div class="reservation-title" *ngIf="reservation">
      <span>{{ getExperienceName(reservation) }}</span>
      <span *ngIf="availability">
      - {{ "Posti disponibili" | translate }}:
      {{ availability.availableSeats > 0 ? availability.availableSeats : 0 }} (
      {{ availability.occupiedCount }}/{{ availability.maxParties }} )</span
      >
  </div>
  <button mat-icon-button (click)="cancelClicked()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>

<div class="content-wrapper" *ngIf="reservation">
  <div class="content-row">
    <mat-grid-list cols="1" rowHeight="40px" class="reservation-information">
      <mat-grid-tile>
        <div class="field-wrapperlt">
          <mat-icon class="matIconForLabel">event_note</mat-icon>
            <label class="wrapperltLabelforValue">{{
                displayDate(
                moment(reservation.date + "T" + reservation.time).toDate()
                )
                }}</label>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="field-wrapperlt">
          <mat-icon class="matIconForLabel">language</mat-icon>
            <label class="wrapperltLabelforLabel"
            >{{ "Language" | translate }}:
            </label>
            <label class="wrapperltLabelforValue">{{
                reservation.languageIso
                ? ("lang_" + reservation.languageIso | translate)
                : ""
                }}</label>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="field-wrapperlt" *ngIf="reservation.roomId">
          <mat-icon class="matIconForLabel">sensor_door</mat-icon>
            <label class="wrapperltLabelforLabel"
            >{{ "Room" | translate }}:
            </label>
            <label class="wrapperltLabelforValue">{{
                roomName(reservation.roomId) | truncate : [30, "..."]
                }}</label>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="field-wrapperlt" *ngIf="reservation.employeeId">
          <mat-icon class="matIconForLabel">person</mat-icon>
            <label class="wrapperltLabelforLabel"
            >{{ "Employee" | translate }}:
            </label>
            <label class="wrapperltLabelforValue">{{
                employeeName(reservation.employeeId) | truncate : [30, "..."]
                }}</label>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="field-wrapperlt">
          <mat-icon class="matIconForLabel">euro</mat-icon>
            <label class="wrapperltLabelforLabel"
            >{{ "Total" | translate }}:
            </label>
            <label class="wrapperltLabelforValue">{{
                formatTotal(reservation.netTotalCents || 0)
                }}</label>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="1" rowHeight="40px" class="reservation-information">
      <mat-grid-tile>
        <div class="field-wrapperlt">
            <img
                    alt="conversion"
                    class="imageIconForLabel"
                    src="assets/icons/icon-price-e-filled.png"
            />
            <label class="wrapperltLabelforLabel"
            >{{
                (reservation
                ? getLabel1Title(reservation)
                : ("Price 1" | translate)
                ) | truncate : [20, "..."]
                }}:
            </label>
            <label class="wrapperltLabelforValue">{{
                reservation.guestCount01
                }}</label>
        </div>
      </mat-grid-tile>
        <mat-grid-tile
                *ngFor="let priceLabel of reservationPriceLabelsExcludeFirstIfPresent"
        >
        <div class="field-wrapperlt" *ngIf="priceLabel.quantity > 0">
            <img
                    alt="conversion"
                    class="imageIconForLabel"
                    src="assets/icons/icon-price-e-filled.png"
            />
            <label class="wrapperltLabelforLabel">
                {{ priceLabel?.title_translations?.it | truncate : [20, "..."] }}:
            </label>
            <label class="wrapperltLabelforValue">{{
                priceLabel.quantity
                }}</label>
        </div>
      </mat-grid-tile>
        <mat-grid-tile
                *ngFor="let priceExtra of reservation.reservationPriceExtras"
        >
        <div class="field-wrapperlt" *ngIf="priceExtra.quantity > 0">
            <img
                    alt="conversion"
                    class="imageIconForLabel"
                    src="assets/icons/icon-price-e.png"
            />
            <label
                    *ngIf="priceExtra?.title_translations"
                    class="wrapperltLabelforLabel"
            >
                {{
                priceExtra?.title_translations[language] | truncate : [20, "..."]
                }}:
            </label>
            <label class="wrapperltLabelforValue">{{
                priceExtra.quantity
                }}</label>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="1" rowHeight="40px" class="reservation-information">
      <mat-grid-tile>
        <div class="field-wrapperlt wrapperltright">
            <button
                    (click)="addClicked()"
                    class="dws-elevation-z2 aggiungiButton"
                    mat-flat-button
            >
                {{ "Add" | translate }} <i class="icon-circled-plus"></i>
            </button>
        </div>
      </mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
      <mat-grid-tile rowspan="2">
        <div class="field-wrapperlt wrapperltcenter">
            <button
                    (click)="sendEmailToAll()"
                    class="darkblueButton"
                    mat-flat-button
            >
            <mat-icon>email</mat-icon>
                {{ "Send email to All" | translate }}
          </button>
            <button
                    (click)="exportExperienceClicked()"
                    class="whiteBlueExportButton"
                    mat-flat-button
            >
            <mat-icon>print</mat-icon>
          </button>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <mat-divider></mat-divider>
    <div class="content-row containerReservationViews" style="height: auto">
        <mat-grid-list cols="1" rowHeight="250px" style="width: 100%">
            <mat-grid-tile
                    *ngFor="let reserv of reservation.optionalData?.childEvents"
            >
                <grouped-calendar-single-reservation
                        (detailsClick)="onClickDetails(reserv)"
                        [employees]="employees"
                        [reservation]="reserv"
                        [rooms]="rooms"
                        style="width: 100%; height: 100%"
                ></grouped-calendar-single-reservation
                >
            </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>

<reservation-pdf-exporter #exporterPDF></reservation-pdf-exporter>