import { Contact } from './dws/contact';
import { KeycountPipe } from '../pipes/keycount/keycount.pipe';
import * as _ from 'lodash';
import * as moment from 'moment';

export namespace AdvancedFilters {
    export type Data<T> = {
        id: keyof T;
        label: string;
        type: string | 'select';
        options?: unknown[];
        match?: (query: any, field: keyof T, row: T | any) => boolean;
        name?: (data: any) => string;
        compare?: (a: any, b: any) => boolean;
    }

    export const match = <T>(query: any, filter: Data<T>, row: T | any): boolean => {
        if (!query || !filter.id) return true;
        if (filter.match) return filter.match(query, filter.id, row);
        else return (row[filter.id] as any || '').toString()
            .toLocaleLowerCase()
            .includes(query.toString().toLocaleLowerCase())
    }

    export const filter = <T>(data: T[] | any[], fields: Data<T>[], query: { [field: string]: any }) => {
        if (KeycountPipe.transform(query) == 0) return data;
        return data.filter(value => fields.reduce((match, filter) => {
            const queryValue = query[filter.id as string];
            let filterApplied = false;
            if (Array.isArray(queryValue) && queryValue.length > 0) filterApplied = true;
            else if (typeof queryValue === 'object' && KeycountPipe.transform(queryValue) > 0) filterApplied = true;
            else if (typeof queryValue === 'string' && queryValue != '') filterApplied = true;
            if (!filterApplied) return match;
            return match || AdvancedFilters.match(query[filter.id as string], filter, value);
        }, false as boolean))
    }

    export const priceInRange = <T>(query: { gte: any, lte: any }, priceCents: number) => {
        const price = priceCents / 100;
        if (query.gte && query.lte) return _.inRange(price, query.gte, query.lte);
        else if (query.gte && !query.lte) return price >= query.gte;
        else if (!query.gte && query.lte) return price <= query.lte;
        else return true;
    }

    export const betweenDates = <T>(query: { start: any, end: any }, date: string | undefined) => {
        if (query.start && query.end) return moment(date).isBetween(query.start, query.end, "days", "[]");
        return true;
    }

    export const presenceBetweenDates = <T>(query: { start: any, end: any }, presences: any[] | undefined) => {
        if (query.start && query.end && presences != null) {
            for (let p of presences) {
                if (moment(p.register_date).isBetween(query.start, query.end, "days", "[]")) return true;
            }
        }
        return false;
    }

    export const presence = <T>(query: string[], presences: any[] | undefined) => {
        if (presences != null) {
            for (let p of presences) {
                if (query.indexOf(moment(p.register_date).format('yyyy-MM-DD')) != -1) return true;
            }
        }
        return false;
    }

    export const registrationPresenceDate = <T>(query: { start: string, end: string, presence: string[] }, contactPresences: Contact.Presence[] | undefined) => {
        if (contactPresences == null) return false;
        console.log("aaabbbccc")
        for (let cp of contactPresences) {
            let visitReasonIn = true
            if (query.presence != null) {
                const nameOfPresence = cp.visit_reason === 'reservation' ? 'In cantina' : cp.visit_reason_id;
                visitReasonIn = query.presence.indexOf(nameOfPresence!) !== -1;
            }
            const registerDateRange = moment(cp.register_date).isBetween(query.start, query.end, "days", "[]");

            if (visitReasonIn) {
                console.log(query.presence)
            }


            if (visitReasonIn && registerDateRange ) return true;
        }

        return false
    }
}

export type AdvancedFilters<T> = AdvancedFilters.Data<T>;
