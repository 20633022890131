import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export namespace SaleReservation {
  export interface Data {
    start_at: string | undefined;
    id: string;
    wineryId: string;
    experienceId?: string;
    titleEn: string;
    titleIt: string;
    fullName: string;
    guestCount01: number;
    reservationDate: string | Date;
    experienceDate: string | Date;
    origin?: string;
    state?: 'draft' | 'waiting' | 'confirmed' | 'revoked' | 'rejected' | 'completed';
    netTotalCents: number;
    paymentCurrency: string;
    roomId?: string;
    employeeId?: string;
    createdWithSaas?: boolean;
  }


  export interface Filter {
    reservationId?: string;
    experienceId?: string;
    roomId?: string[];
    employeeId?: number;
    origin?: string[];
    state?: string;
    fullName?: string;
    guestCount01?: number;
    minTotal?: number;
    maxTotal?: number;
    startTime?: string | Date;
    endTime?: string | Date;
    dateRange?: { start?: string | Date, end?: string | Date };
  }

  export interface ParticipantsDetails {
    reservationId?: string;
    id?: string;
    name?: string;
    quantity?: number;
    type?: string;
  }

  export function createFormGroup(fb: FormBuilder, saleReservation?: Partial<SaleReservation.Data>): FormGroup {
    const f = fb.group({
      name: ['', Validators.required],
      description: [''],
      winery: [],
      typology: []
    })
    return f;
  }
}

export type SaleReservationFilters = SaleReservation.Filter
export type SaleReservation = SaleReservation.Data;
export type ParticipantsDetails = SaleReservation.ParticipantsDetails;