import { Injectable } from '@angular/core';
import { DWSWebhookConfig } from '@interfaces/dws/integration';
import { resource } from '@util/resource';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebhooksService {

  constructor() {
  }

  activate(webhook : DWSWebhookConfig): Observable<String>{
    return resource('crm://wswebhook/active')
    .put<String>({
        ...webhook
    })
  }

  deactivate(webhook : DWSWebhookConfig): Observable<String>{
    return resource('crm://wswebhook/deactivate')
    .put<String>({
        ...webhook
    })
  }

  delete(webhook : DWSWebhookConfig): Observable<String>{
    return resource('crm://wswebhook/delete')
    .post<String>({
        ...webhook
    })
  }

  create(webhook : DWSWebhookConfig): Observable<String>{
    return resource('crm://wswebhook/create')
    .post<String>({
        ...webhook
    })
  }

  update(webhook : DWSWebhookConfig): Observable<String>{
    return resource('crm://wswebhook/update')
    .put<String>({
        ...webhook
    })
  }

  list(): Observable<DWSWebhookConfig[]>{
    return resource('crm://wswebhook')
    .get<DWSWebhookConfig[]>()
  }

  getSingleWebhook(id: String): Observable<any>{
    return resource('crm://wswebhook/wsWebhookId')
    .params({wsWebhookId: id})
    .get<any>()
  }
}