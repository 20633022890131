import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';

@Injectable({
  providedIn: 'root'
})
export class CompanyContactsService {

  constructor() {
  }

  list(wineryId: string, name?: string): Observable<DWS.Page<DWS.CompanyContact>> {
    return resource('crm://company-contacts')
      .params({
        wineryId,
        name
      }).get<DWS.Page<DWS.CompanyContact>>();
  }

  create(companyContact: DWS.CompanyContact): Observable<DWS.CompanyContact> {
    return resource('crm://company-contacts')
      .post<DWS.CompanyContact>(companyContact);
  }

  get(contactId: any): Observable<DWS.CompanyContact> {
    return resource('crm://company-contacts/id')
      .params({ id: contactId })
      .get<DWS.CompanyContact>();
  }

  update(companyId: number, companyContact: DWS.CompanyContact) {
    return resource('crm://company-contacts/id')
      .params({ id: companyId })
      .put(companyContact);
  }
}
