import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { observe } from '@web/util/loading/loading';
import { route as r } from '@util/route';
import { ContactsService } from '@services/dws/contacts.service';
import { CrmOrdersService } from '@services/dws/crm-orders.service';
import { DWS } from '@interfaces/dws';

@Injectable({
  providedIn: 'root'
})
export class CrmOrderResolverService implements Resolve<DWS.CrmOrder> {
  constructor(
    private crmOrdersService: CrmOrdersService,
    private contactsService: ContactsService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<DWS.CrmOrder> {
    const orderId = r.access(route).param('orderId');
    if (orderId === 'new' || !orderId) return Promise.resolve(null) as any;
    const order = await observe(this.crmOrdersService.get(orderId)).toPromise();
    if (order.contactId) order.contact = await observe(this.contactsService.get(order.contactId!, order.winery.id)).toPromise();
    return Promise.resolve(order);
  }
}
