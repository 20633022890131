import { Winery } from '@interfaces/dws/winery';
import { FormBuilder, Validators } from '@angular/forms';
import { ConditionType, Matcher } from 'interfaces/dws/matcher';
import { Contact } from 'interfaces/dws/contact';

export namespace Audience {

  import ContactMatcherType = Matcher.ContactMatcherType;
  export type SubscribeType = 'only' | 'including';

  export interface Data {
    id: number;
    name: string;
    manual: any;
    conditionType: ConditionType;
    subscribeType?: SubscribeType;
    winery: Winery;
    predefined: boolean;
    values?: AudienceValue[];
    contacts?: Contact[];
    contactMatcherType?: ContactMatcherType;
    deletable?: boolean;
  }

  export namespace AudienceValue {
    import ContactMatcherOperator = Matcher.ContactMatcherOperator;
    import ContactMatcher = Matcher.ContactMatcher;

    export interface Data {
      id: number;
      contactMatcherOperator: ContactMatcherOperator;
      contactMatcher?: ContactMatcher;
      audience: Audience;
      value?: number | any;
      stringValue?: string;
    }
  }

  export type AudienceValue = AudienceValue.Data;


  export function createFormGroup(fb: FormBuilder, audienceValues?: Partial<AudienceValue>) {
    const f = fb.group({
      contactMatcherOperator: fb.group({
        id: ['', Validators.required],
      }),
      contactMatcher: fb.group({
        id: ['', Validators.required],
      }),
      value: ['', [Validators.required]]
    });
    if (audienceValues) f.patchValue(audienceValues);
    if (audienceValues && audienceValues.stringValue) f.patchValue({ value: audienceValues.stringValue })
    return f;
  }
}

export type Audience = Audience.Data;
