import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DWS } from '@interfaces/dws';
import { catchError, pluck } from 'rxjs/operators';
import { resource } from 'util/resource';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsService {

  list(page: number = 0, count: number = 999999): Observable<DWS.PaymentMethod[]> {
    return resource('crm://order-payment-types')
      .params(
        {page, count}
      )
      .get<DWS.Page<DWS.PaymentMethod>>()
      .pipe(
        pluck('content')
      )
  }

  getPaymentPageUrl(orderable_id: string): Observable<string> {
    return resource('v2://payment-page')
      .params(
        {orderable_id}
      )
      .get()
      .pipe(
        catchError(() => {
          return of('');
        }),
        pluck('url')
      )
  }
}
