import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoadingComponent {
  private static _loadingCount: number = 0;
  static set loading(v: boolean) {
    setTimeout(() => {
      this._loadingCount += v ? 1 : -1;
      if (this._loadingCount < 0) this._loadingCount = 0;
    })
  }

  static get loading(): boolean {
    return this._loadingCount > 0;
  }
  @HostBinding('class.showing') @Input() show: boolean = false;
}
