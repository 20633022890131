import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmModule } from '@web/app/components/dialogs/confirm';
import { FeatureToggleModule } from "@web/app/modules/feature-toggle/feature-toggle.module";
import { NgxMaskModule } from 'ngx-mask';
import { AddEditIntegrationComponent } from './add-edit-integration.component';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';


@NgModule({
  declarations: [AddEditIntegrationComponent],
  entryComponents: [AddEditIntegrationComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    FlexModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatListModule,
    TranslateModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCardModule,
    FormsModule,
    NgxMaskModule,
    MatCheckboxModule,
    ConfirmModule,
    FeatureToggleModule
  ]
})
export class AddEditIntegrationModule { }
