import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { toast } from '@web/util/toast';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { tr } from "@util/tr";
import { GiftsService } from "@services/dws/gifts.service";
import { observe } from '@web/util/loading/loading';
import { marker } from "@biesbjerg/ngx-translate-extract-marker";
import { ga } from "@util/ga";

marker("Email has been sent");
marker("There was an error sending the email. Try later");

@Component({
  selector: 'web-resend-gift-email',
  templateUrl: './resend-gift-email.component.html',
  styleUrls: ['./resend-gift-email.component.scss']
})
export class ResendGiftEmail implements OnInit {

  giftId: string = '';
  giftEmail: string = '';
  form: FormGroup;

  constructor(
      @Inject(MAT_DIALOG_DATA) data: any,
      private dlg: MatDialogRef<ResendGiftEmail>,
      private fb: FormBuilder,
      private giftsService: GiftsService
  ) {
    this.giftId = data.giftId;
    this.giftEmail = data.giftEmail;

    this.form = this.fb.group({
      email: [this.giftEmail, [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
    ga.track('Resend Gift Email');
  }

  closeClicked() {
    this.dlg.close();
  }

  sendClicked() {
    if (this.form.invalid) {
      toast(tr('Fill in the required information'));
      return;
    }
    const formValue = {...this.form.value};
    observe(this.giftsService.resendEmail(this.giftId, this.giftEmail, formValue.email))
        .subscribe(() => {
              toast(tr("Email has been sent"));
              this.dlg.close();
            },
            () => toast(tr("There was an error sending the email. Try later"))
        )

  }
}
