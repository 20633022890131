<div class="event-data event-view-{{ view }} {{ status.cssClass }} {{ countInWaiting(meta.optionalData?.childEvents) &gt; 0 ? 'pending' : 'confirmed'}}">
  <span
          [ngStyle]="{'background-color': meta.optionalData?.experienceColor?  meta.optionalData?.experienceColor : 'unset'}"
          class="experienceColorFlag"></span>

    <span *ngIf="countInWaiting(meta.optionalData?.childEvents) &gt; 0"
          class="badgeReservations badgeReservationsInWaiting"
          matBadge="{{ countInWaiting(meta.optionalData?.childEvents) }}" matBadgeOverlap="true"
          matBadgePosition="before">   </span>
     
  <div class="event-title" [ngStyle]="{ color: color }">
    <span><strong>{{ capitalizeEachWord(getExperienceName(meta)) }}</strong></span>
      <span *ngIf="smallEvent" class="badgeReservations" matBadge="{{ meta.optionalData?.childEvents.length }}"
            matBadgeOverlap="true"
            matBadgePosition="before">  </span>
  </div>

  <div class="align" *ngIf="!smallEvent">
    <div>
      <div class="event-total-reservations">
        <span>{{'Reservations' | translate}}:</span>
          <span *ngIf="meta.optionalData?.childEvents.length &lt; 100" class="badgeReservations"
                matBadge="{{ meta.optionalData?.childEvents.length }}" matBadgeOverlap="true"
                matBadgePosition="before">   </span>
          <span *ngIf="meta.optionalData?.childEvents.length &gt; 99" class="badgeReservations badgeReservationsBig"
                matBadge="{{ meta.optionalData?.childEvents.length }}" matBadgeOverlap="true"
                matBadgePosition="before">   </span>
      </div>

      <ng-container>
        <div class="event-total" [ngStyle]="{ color: color }">
          <i class="icon-global"></i>
          <span>{{ meta.languageIso ? (('lang_' + meta.languageIso) | translate) : '' }}</span>
        </div>

        <div class="event-total" [ngStyle]="{ color: color }">
          <i class="icon-group"></i>
          <span>{{countGuests(meta)}}</span>
        </div>

        <div class="event-total" [ngStyle]="{ color: color }">
          <i class="icon-euro"></i>
            <span>{{formatTotal(meta.netTotalCents || 0)}}</span>
        </div>

        <div class="event-time" [ngStyle]="{ color: color }" *ngIf="view !== 'week' && view !== 'day'">
          <i class="icon-clock"></i>
          <span>
            {{
            moment(meta.date + 'T' + meta.time).toDate().toLocaleTimeString("en", {
            hour: "2-digit",
            minute: "2-digit"
            })
            }}</span>
        </div>

          <div *ngIf="interval() > 60*60 && meta.roomId" [ngStyle]="{ color: color }" class="event-room">
          <i class="icon-door"></i>
              <span>{{ capitalizeEachWord(roomName(meta.roomId)) }}</span>
        </div>

          <div *ngIf="interval() > 60*60 && meta.employeeId" [ngStyle]="{ color: color }" class="event-people">
          <i class="icon-employee"></i>
              <span>{{ capitalizeEachWord(employeeName(meta.employeeId)) }}</span>
        </div>
      </ng-container>
    </div>

  </div>
    <span *ngIf="meta.isTourOperator" class="ribbon">{{'OTA' | translate}}</span>
</div>