import { File } from '@interfaces/dws/file'
import { ProductChannel } from '@interfaces/dws/product-channel';
import {ProductCategory} from "./product-category";

export namespace Product {
  export interface New {
    active: boolean,
    barcode: string,
    descriptionEn: string,
    descriptionIt: string,
    id: string,
    nameEn: string,
    nameIt: string,
    priceCents: 0,
    type: {
      id: string
    },
    winery: {
      id: string
    },
    category: string
  }

  export interface Data {
    active: boolean,
    barcode?: string,
    createdAt: string,
    descriptionEn?: string,
    descriptionIt: string,
    id: string,
    nameEn?: string,
    nameIt: string,
    priceCents: number,
    type: {
      id: string,
      nameEn: string,
      nameIt: string
    },
    updatedAt: string,
    winery: {
      createdAt: string,
      id: string,
      name: string,
      updatedAt: string
    }
    wineryId: string,
    files?: File[]
    channels?: ProductChannel[];
    category?: ProductCategory;
    orderingAll?: number;
    orderingByCategory?: number
  }

  export interface DataGrid {
    active: boolean,
    barcode?: string,
    createdAt: string,
    descriptionEn?: string,
    descriptionIt: string,
    id: string,
    nameEn?: string,
    nameIt: string,
    priceCents: number,
    type: {
      id: string,
      nameEn: string,
      nameIt: string
    },
    updatedAt: string,
    winery: {
      createdAt?: string,
      id?: string,
      name?: string,
      updatedAt?: string
    }
    files?: File[]
    channels?: ReducedProductChannel[];
    category?: ProductCategory[];
  }

  export interface ReducedProductChannel {
    id: string;
    wineryChannelName: string;
    priceCents: number;
    active: boolean;
  }

  export interface InOrder {
    id?: any;
    amountCents: number;
    amountDiscountCents: number;
    barcode?: any;
    descriptionIt: string;
    descriptionEn?: string;
    nameIt: string;
    nameEn?: string;
    product: { id: any } | Data;
    quantity: number;
    type: { id: string };
  }

  export interface Filter {
    price?: number;
    name?: string;
    channels?: string[],
    categories?: string[],
    types?: string[],
  }
}

export enum ProductFieldsValidate {
  nameEn = 'nameEn',
  nameIt = 'nameIt'
}

export type Product = Product.Data;
export type ProductGrid = Product.DataGrid;
export type ReducedProductChannel = Product.ReducedProductChannel;
