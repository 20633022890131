import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';

@Injectable({
  providedIn: 'root'
})
export class CampaignTemplatesService {

  constructor() {}

  create(campaign: DWS.CampaignTemplate): Observable<DWS.CampaignTemplate> {
    return resource('crm://campaign-templates')
      .post<DWS.CampaignTemplate>(campaign);
  }

  list(filters?: CampaignTemplatesFilter, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.CampaignTemplate>> {
    return resource('crm://campaign-templates')
      .params(
        { page, count, ...filters }
      )
      .get<DWS.Page<DWS.CampaignTemplate>>();
  }

  update(campaignTemplateId: number, campaignTemplate: DWS.CampaignTemplate) {
    return resource('crm://campaign-templates/id')
      .params({ campaignTemplateId: campaignTemplateId })
      .put(campaignTemplate);
  }

  delete(campaignTemplateId: number){
    return resource('crm://campaign-templates/id')
        .params({ campaignTemplateId: campaignTemplateId })
        .delete();
  }
}

export interface CampaignTemplatesFilter {
  wineryId: string;
  campaignsId?: number|string|any;
}
