import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';

@Injectable({
  providedIn: 'root'
})
export class CampaignTargetsService {

  constructor() {}

  create(campaignTarget: DWS.CampaignTemplate): Observable<DWS.CampaignTarget> {
    return resource('crm://campaign-targets')
      .post<DWS.CampaignTarget>(campaignTarget);
  }


  createList(campaignId: number, campaignTargetList: DWS.CampaignTarget[]): Observable<DWS.CampaignTarget[]> {
    return resource('crm://campaign-targets/campaign/campaignId')
      .params(
        { campaignId }
      )
      .post<DWS.CampaignTarget[]>(campaignTargetList);
  }

  list(filters?: CampaignTargetsFilter, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.CampaignTarget>> {
    return resource('crm://campaign-targets')
      .params(
        { page, count, ...filters }
      )
      .get<DWS.Page<DWS.CampaignTarget>>();
  }

  update(campaignTargetId: number, campaignTarget: DWS.CampaignTarget) {
    return resource('crm://campaign-targets/id')
      .params({ campaignTargetId })
      .put(campaignTarget);
  }
}

export interface CampaignTargetsFilter {
  campaignId: number;
}
