import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';
import { HttpCacheService } from '@services/http-cache.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactsTagsService {

  constructor(private cache: HttpCacheService) {
  }

  create(contactTag: DWS.ContactTag): Observable<DWS.ContactTag> {
    return resource('crm://contacts-tags')
      .post<DWS.ContactTag>(contactTag)
      .pipe(tap(() => this.expire()));
  }

  delete(contactId: any, tagId: number): Observable<void> {
    return resource('crm://contacts-tags/contactId/tagId')
      .params({ contactId, tagId })
      .delete()
      .pipe(tap(() => this.expire()));
  }

  list(filter: ContactsTagsFilter): Observable<DWS.Page<DWS.ContactTag>> {
    return resource('crm://contacts-tags')
      .params(
        { page: 0, count: 9999999, ...filter }
      )
      .get<DWS.Page<DWS.ContactTag>>();
  }

  private expire() {
    this.cache.clear().toPromise().then(() => 'cache flush');
  }
}

export interface ContactsTagsFilter {
  contactId?: number;
  tagId?: number | number[];
  wineryId?: string;
}
