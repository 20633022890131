import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { observe } from "@web/util/loading/loading";
import { SchedulingService, WinerySchedulingFilters } from "@services/dws/scheduling/page-scheduling/page-schedulings.service";
import { VwReservation } from "@interfaces/dws/reservation";

export class WineryScheduleDatasource implements DataSource<VwReservation> {

  private winerySchedulingSubject = new BehaviorSubject<VwReservation[]>([]);
  private elementsSubject = new Subject<number>();
  public elementsSubject$: Observable<number> = this.elementsSubject.asObservable();

  constructor(
      private schedulingService: SchedulingService
  ) {
  }

  connect(collectionViewer: CollectionViewer) {
    return this.winerySchedulingSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.winerySchedulingSubject.complete();
  }

  loadWineryScheduling(wineryId: string, filters?: WinerySchedulingFilters, page = 0, count = 999999, sortBy?: string) {
    observe(this.schedulingService.list(wineryId, filters, page, count, sortBy))
        .subscribe(
            s => {
              this.winerySchedulingSubject.next(s.content)
              this.elementsSubject.next(Number(s.totalElements));
            },
            e => console.error(e));
  }
}
