import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import {
  DWSIntegration,
  DWSIntegrationConnector,
  WineryIntegration,
} from "@interfaces/dws/integration";
import { Login } from "@services/accounts.service";
import { WineryIntegrationsService } from "@services/dws/winery-integrations.service";
import { access } from "@util/route/access";
import { tr } from "@util/tr";
import { LoadingComponent } from "@web/app/components/loading/loading.component";
import { AddEditIntegrationComponent } from "@web/app/components/dialogs/add-edit-integration/add-edit-integration.component";
import { toast } from "@web/util/toast";
import { forkJoin } from "rxjs";
import { observe } from "@web/util/loading/loading";

@Component({
  selector: "web-integrations",
  templateUrl: "./integrations.component.html",
  styleUrls: ["./integrations.component.scss"],
})
export class IntegrationsComponent implements OnInit {
  categoriesControl = new FormControl();
  statusControl = new FormControl();
  searchTerm = new FormControl();
  categories: String[] = [];
  integrations: DWSIntegrationConnector[] = [];
  wIntegrations: WineryIntegration[] = [];
  isNewForDays = 90;

  login: Login;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private wineryIntegrationsService: WineryIntegrationsService
  ) {
    this.login = access(route).data("login");
  }

  ngOnInit() {
    this.loadPage();
  }

  loadPage() {
    LoadingComponent.loading = true;
    var wineryId = this.route.snapshot.data.company.id;
    forkJoin([
      this.wineryIntegrationsService.getIntegrations(),
      this.wineryIntegrationsService.getWineryIntegrations(wineryId),
    ]).subscribe({
      next: ([integrations, wineryIntegrations]) => {
        this.integrations = integrations;
        this.prepareCategoriesAndIntegrations(integrations);
        this.wIntegrations = wineryIntegrations;
      },
      error: (err) => {
        console.error("Error fetching integrations:", err);
        toast(tr("An error occurred. Try later"));
      },
      complete() {
        LoadingComponent.loading = false;
      },
    });
  }

  prepareCategoriesAndIntegrations(integrations: DWSIntegrationConnector[]) {
    const categories = integrations
      .map((integration) => integration.integrationType || "NO CAT")
      .filter((value, index, self) => self.indexOf(value) === index);
    this.categories = categories;
    this.integrations = integrations;
  }

  get filteredCategories() {
    return (
      this.categoriesControl.value?.length > 0
        ? this.categoriesControl.value || this.categories
        : this.categories
    )?.filter((c: string) => this.filteredIntegrations(c)?.length > 0);
  }

  filteredIntegrations(category: string) {
    return this.integrations.filter(
      (integration) =>
        (integration.integrationType === category ||
          !!!integration.integrationType ||
          !!!category) &&
        (!this.searchTerm.value ||
          integration.integrationName
            .toLowerCase()
            .includes(this.searchTerm.value.toLowerCase()) ||
          integration.integration?.name
            .toLowerCase()
            .includes(this.searchTerm.value.toLowerCase())) &&
        (!!!this.statusControl.value ||
          this.statusControl.value ===
            this.isEnabledInThisWinery(integration.integration!))
    );
  }

  generateBadge(
    integration: DWSIntegration
  ): [string | undefined, string | undefined] {
    if (this.isInstalledInThisWinery(integration)) {
      if(this.isEnabledInThisWinery(integration) && this.isConnected(integration)){
        return [tr("Connected"), "#68CC45"];
      } else {
        return [tr("Disconnected"), "#eb4646"];
      }
    }

    if (!integration.active) {
      if (new Date(integration?.createdAt!) > new Date()) {
        return [tr("Next"), "#F5BA58"];
      }
    } else {
      const futureDate = new Date();
      futureDate.setDate(futureDate.getDate() - (this.isNewForDays));
    
      if (new Date(integration?.createdAt!) > futureDate) {
        return [tr("New"), "#47A7FF"];
      }
    }
    
    return [undefined, undefined];
  }

  getBadgeColor(integration: DWSIntegration): string | undefined {
    return this.generateBadge(integration)[1];
  }

  getBadgeText(integration: DWSIntegration): string | undefined {
    return this.generateBadge(integration)[0];
  }

  toggleActiveState(integration: DWSIntegration, active: boolean) {
    this.updateIntegrationOrOutputError(
      { wineryId: this.login.company.id,integrationId: integration.id, integration: integration } as WineryIntegration,
      active
    );
  }

  isConnected(integration: DWSIntegration): boolean {
       var founded = this.wIntegrations.find(
      (wInt) =>
        wInt && wInt.integration && wInt.integration?.id === integration!.id
    );

    return (founded?.connected || false);
  }

  isInstalledInThisWinery(integration: DWSIntegration): boolean {
    var founded = this.wIntegrations.find(
      (wInt) =>
        wInt && wInt.integration && wInt.integration?.id === integration!.id
    );
    return !!founded;
  }

  isEnabledInThisWinery(integration: DWSIntegration): boolean {
    var founded = this.wIntegrations.find(
      (wInt) =>
        wInt && wInt.integration && wInt.integration?.id === integration!.id && wInt.disabled === false
    );
    return !!founded;
  }

  installOrManage(integration: any) {
    let wineryIntegration = this.wIntegrations.find(
      (wi) => wi?.integrationId === integration.integration.id
    );
    this.dialog
      .open(AddEditIntegrationComponent, {
        minWidth: 480,
        panelClass: "no-overflow",
        data: { wineryId: wineryIntegration?.wineryId ,integration, wineryIntegration },
      })
      .afterClosed()
      .subscribe((data) => {
        this.loadPage();
      });
  }

  updateIntegrationOrOutputError(
    integration: WineryIntegration,
    active: boolean
  ) {
    if (active) {
      observe(
      this.wineryIntegrationsService
        .connectIntegration(this.login.company.id, integration)
      ).subscribe({
          next: () => {
            toast(tr("Integration saved successfully"));
          },
          error: (err) => {
            console.error("Error updating integration:", err);
            toast(tr("An error occurred. Try later"));
          },
          complete: () => {
            this.loadPage();
          }
        });
    } else {
      observe(
      this.wineryIntegrationsService
        .disconnectIntegration(this.login.company.id, integration)
      ).subscribe({
          next: () => {
            toast(tr("Integration saved successfully"));
          },
          error: (err) => {
            console.error("Error updating integration:", err);
            toast(tr("An error occurred. Try later"));
          },
          complete: () => {
            this.loadPage();
          }
        });
    }
  }

  //async removeIntegationClicked(integration: WineryInfo.Integration) {
  //
  //  const confirmed = await confirm.yesno(
  //    tr("are you sure to want to disable this integration?"),
  //    tr('Yes'),
  //    tr('Back')
  //  );
  //
  //  if (!confirmed) return;
  //  observe(this.wineryIntegrations.disconnectIntegration(this.wineryInfo.id, integration))
  //    .subscribe(() => this.updated.emit())
  //}
  //
  //async connectWineryIntegration(integration: WineryInfo.Integration) {
  //  const dialog = this.dialog.open(IntegrationConfirmComponent, {
  //    width: '250%',
  //    minWidth: 400,
  //    maxWidth: 850,
  //    minHeight: 400,
  //    maxHeight: 600,
  //    data: {
  //      integration: integration
  //    }
  //  });
  //
  //  dialog.afterClosed().subscribe((data) => {
  //
  //    observe(this.wineryIntegrations.connectIntegration(this.wineryInfo.id, integration))
  //      .subscribe(() => {
  //        toast(tr("Integrated"));
  //        this.integrationChanged.emit();
  //        this.editChange.emit(!this.edit);
  //      })
  //  });
  //}
  //
  //async connectWineryLivery() {
  //  const dialog = this.dialog.open(ConfirmPrivacyComponent, {
  //    width: '250%',
  //    minWidth: 400,
  //    maxWidth: 850,
  //    minHeight: 400,
  //    maxHeight: 600,
  //    data: {
  //      alreadyConnected: this.isWineliveryConnected
  //    }
  //  });
  //
  //  dialog.afterClosed().subscribe((data) => {
  //    if (this.isWineliveryConnected || !data) return;
  //    observe(this.wineryIntegrations.connectWinelivery(this.wineryInfo.id))
  //      .subscribe(() => {
  //        toast(tr("Integrated"));
  //        this.wineliveryChange.emit();
  //        this.editChange.emit(!this.edit);
  //      })
  //  });
  //}
  //
  //async disableWineliveryClicked() {
  //  const disableWinelivery = await confirm.yesno(
  //    tr('Are you sure you want to disable this channel?'),
  //    tr('Yes'),
  //    tr('Back')
  //  );
  //  if (!disableWinelivery) return;
  //
  //  const disableInstantBooking = await confirm.yesno(
  //    tr('Do you want to disable Instant Booking together with the Winelivery channel?'),
  //    tr('Yes'),
  //    tr('No')
  //  );
  //
  //  observe(this.wineryIntegrations.disconnectWinelivery(this.wineryInfo.id, disableInstantBooking))
  //    .subscribe(
  //      () => toast(tr("Winelivery channel disabled with success")),
  //      () => toast(tr("There was an error when disable Winelivery channel. try later"))
  //    )
  //  this.wineliveryChange.emit();
  //}
}
