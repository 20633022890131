import { NgModule } from '@angular/core';
import {
  FeatureOffPipe,
  FeatureOnPipe,
  FeatureTogglePipe
} from './feature-toggle.pipe';
import { FeatureToggleDirective } from './feature-toggle.directive';

@NgModule({
  declarations: [FeatureTogglePipe, FeatureOnPipe, FeatureOffPipe, FeatureToggleDirective],
  imports: [],
  exports: [FeatureTogglePipe, FeatureOnPipe, FeatureOffPipe, FeatureToggleDirective]
})
export class FeatureToggleModule {
}
