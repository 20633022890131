
// tslint:disable:no-bitwise

export function hash(data: string) {
  let h = 0, i, chr;
  if (!data || data.length === 0) return h;
  for (i = 0; i < data.length; i++) {
    chr = data.charCodeAt(i);
    h  = ((h << 5) - h) + chr;
    h |= 0; // Convert to 32bit integer
  }
  return h;
}
