import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { Winery } from '../../interfaces/dws/winery';

@Injectable({
  providedIn: 'root'
})
export class CampaignTargetWineriesService {

  constructor() { }

  list(campaignId: number): Observable<Winery[]> {
    return resource('crm://campaign-target-wineries/campaignId')
      .params({ campaignId })
      .get<Winery[]>();
  }
}
