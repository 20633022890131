import {
  Component,
  EventEmitter, Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { getCurrencySymbol, CurrencyPipe } from '@angular/common';
import * as moment from 'moment';
import * as _ from 'lodash';
import { CalendarSchedulingEvent } from '@services/dws/scheduling/calendar-event/calendar-scheduling-event';
import { ReservationDetailService } from '@services/dws/scheduling/reservation-detail/reservation-detail.service';
import { observe } from '@web/util/loading/loading';
import { ReservationPdfExporterComponent } from '@web/app/components/reservation-pdf-exporter/reservation-pdf-exporter.component';
import { NgEventBus } from 'ng-event-bus';
import { ReservationDetail } from '@services/dws/scheduling/reservation-detail/reservation-detail';
import { tr } from '@util/tr';
import { CompanyService } from '@services/company.service';
import { ExperienceAvailabilityService } from '@services/dws/experience-availability.service';
import { ExperienceAvailability } from '@interfaces/dws/experience-availabilities';
import { VwReservation } from '@interfaces/dws/reservation';
import { Room } from '@interfaces/dws/room';
import { Employee } from '@interfaces/dws/employee';
import { locale } from '@util/locale';

const WAITING_STATE_STRING = 'waiting';
const COMPLETED_STATE_STRING = 'completed';
const CONFIRMED_STATE_STRING = 'confirmed';
const REJECTED_STATE_STRING = 'rejected';
const REVOKED_STATE_STRING = 'revoked';

@Component({
  selector: 'app-grouped-reservations-detail',
  templateUrl: './grouped-reservations-detail.component.html',
  styleUrls: ['./grouped-reservations-detail.component.scss']
})
export class GroupedReservationsDetailComponent implements OnInit, OnChanges {
  @Input() reservation?: VwReservation;
  @Output() closeClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() addClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() detailsClick: EventEmitter<VwReservation> = new EventEmitter<VwReservation>();
  cPipe: CurrencyPipe;
  
  moment = moment;
  @Input() rooms: Room[] = [];
  @Input() employees: Employee[] = [];

  @ViewChild('exporterPDF')
  private exporterPDF!: ReservationPdfExporterComponent;
  company: any;

  availability: ExperienceAvailability | undefined = undefined;
  language: any = locale().locale;

  get reservationPriceLabelsExcludeFirstIfPresent() {
    return this.reservation?.reservationPriceLabels?.filter(l => l.islabel1 !== true) || [];
  }

  constructor(
    private cpipe: CurrencyPipe,
    private reservationDetailService: ReservationDetailService,
    private availabilityService: ExperienceAvailabilityService,
    private eventBus: NgEventBus,
    private companyService: CompanyService
  ) {
    this.cPipe = cpipe;
    this.eventBus.on('reservation:isLoading').subscribe((meta: any) => {});
    this.eventBus.on('reservation:newLoaded').subscribe((meta: any) => {
      var reservations = meta.data.reservations || [];
      var thisID = this.reservation?.id;
      if(reservations.length > 0) {
        var founded = reservations.filter((x :CalendarSchedulingEvent) => x.id === thisID);
        if(founded.length === 1) {
          this.reservation = founded[0].meta;
          this.getDataUpdated();
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.reservation){
      this.getDataUpdated();
      console.debug(changes.reservation.currentValue);
    }
  }
  
  ngOnInit(): void {    
    this.company = this.companyService.loadFromStorage();
  }

  getDataUpdated(): void {
    /* let childEvents = this.reservation?.optionalData?.childEvents;
    if (childEvents && childEvents.length > 1) {
      let ids = childEvents.map((x: VwReservation) => x.id);
      observe(this.reservationDetailService.getMultipleReservationV2Query(ids), false)
        .subscribe(reservationsDet => {
          childEvents?.map((x: VwReservation) => {
            let childId = x.id;
            let detail = reservationsDet.find(y => y.id === childId);
            if (detail) {
              x.reservationDetails = detail;
            }
          });
          this.reservation!.optionalData!.childEvents = this.orderReservations(childEvents!);
          childEvents?.map((y: VwReservation) => {
            if (y.origin) {
              // TODO REFACTOR ALL THIS STEP - no details founded in DWS, search on RUBY
              observe(this.reservationDetailService.get(y.id!, false), false)
                .subscribe(reservation => {
                  //y.reservationDetails = reservation;
                  // trasform DATA
                  y.message = reservation.message;
                  y.giftId = reservation.gift;
                });
            }
          });
       
        });
    } */
    this.getUpdatedAvailabilities(this.reservation);
  }

  getUpdatedAvailabilities(reservation: VwReservation | undefined) {
     if(reservation){
      var dayStart = moment(reservation.date).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toDate();
      var dayEnd = moment(reservation.date).set({hour: 23, minute: 59, second: 59, millisecond: 0}).add(1, 'day').toDate();
      this.availabilityService.getAvailabilities(reservation.wineryId!, dayStart, dayEnd).subscribe((data) => {
        var datee = moment(reservation.date).format('yyyy-MM-DD');
        var time = moment(reservation.time).format('HH:mm:ss');
        var experienceAva =  data.filter(x => x.experience.id === reservation.experienceId);
          var founded = experienceAva.filter(x => datee === x.day && time === x.time);
          if(founded.length === 1) {
            this.availability = founded[0];
          }
      });
    }
  }

  orderReservations(elems: CalendarSchedulingEvent[]): CalendarSchedulingEvent[] | undefined {
    const orderState = [WAITING_STATE_STRING, CONFIRMED_STATE_STRING, COMPLETED_STATE_STRING, REJECTED_STATE_STRING, REVOKED_STATE_STRING];
    let childs: CalendarSchedulingEvent[] = [];
    for (let i in orderState) {
      let state = orderState[i];
      // FILTER for status
      let thisStateElems = elems.filter(x => x.state === state);
      // ORDER BY DATE
      if (state === WAITING_STATE_STRING) {
        thisStateElems = _.sortBy(thisStateElems, (x) => x.reservationDetails.createdAt!);
      } else {
        thisStateElems = _.sortBy(thisStateElems, (x) => x.reservationDetails.createdAt!);
        thisStateElems = thisStateElems.reverse();
      }
      childs = childs.concat(thisStateElems);
    }
    return childs;
  }

  displayDate(date: Date) {
    return _.capitalize(moment(date).locale('it').format('DD[/]MM[/]YYYY [-] HH:mm'));
  }

  formatTotal(totalCents: number): string {
    let money = (totalCents / 100);
    return this.cPipe.transform(money, this.currencySymbol, 'symbol', "1.0-2")!;
  }

  async addClicked() {
    this.addClick.emit();
  }

  async exportExperienceClicked() {
    if (this.reservation) {
      let date = moment(this.reservation.date).toDate();
      let wineryId = this.reservation.wineryId;
      let experienceId = this.reservation.experienceId;
      this.exporterPDF.downloadAsPDF(date, moment(this.reservation.date).endOf('day').toDate(), experienceId, wineryId!);
    }

  }

  async onClickDetails(reservation: CalendarSchedulingEvent) {
    this.detailsClick.emit(reservation);
  }


  async cancelClicked() {
    return this.closeClick.emit();
  }

  get currencySymbol() {
    return getCurrencySymbol(this.reservation?.paymentCurrency || 'EUR', 'wide', undefined) || '' as string;
  }

  generateMessage(reservation: ReservationDetail.Data): string {
    var messageOrig = tr("Contact_USER_MULTI");
    var name = reservation?.contact.name;
    var email = reservation?.contact.email;
    var phone = reservation?.contact.phone;
    var date = reservation?.date;
    const inputDate = new Date(date!);
    // Format the date in "dd-mm-yyyy" format
    const outputDateStr = `${inputDate.getDate().toString().padStart(2, '0')}-${(inputDate.getMonth() + 1).toString().padStart(2, '0')}-${inputDate.getFullYear()}`;
    var time = reservation?.time.substring(0, 5);
    var reservationId = reservation?.id;
    var cantina = this.company.business_name;
    // @ts-ignore: Unreachable code error
    var experience = reservation?.experience?.title?.valueIt || this.reservation?.titleIt; // TODO lang
    return this.formatString(messageOrig, name, outputDateStr, time, cantina, reservationId, experience);
  }

  generateSubject(reservation: ReservationDetail.Data): string {
    var messageOrig = tr("Contact_USER_subj_MULTI");
    //var reservationId = reservation?.id;
    var date = reservation?.date;
    const inputDate = new Date(date!);
    // Format the date in "dd-mm-yyyy" format
    const outputDateStr = `${inputDate.getDate().toString().padStart(2, '0')}-${(inputDate.getMonth() + 1).toString().padStart(2, '0')}-${inputDate.getFullYear()}`;
    var time = reservation?.time.substring(0, 5);
    // @ts-ignore: Unreachable code error
    var experience = reservation?.experience?.title?.valueIt || this.reservation?.titleIt; // TODO lang
    var cantina = this.company.business_name;
    return this.formatString(messageOrig, "", outputDateStr, time, cantina, "", experience);
  }

  sendEmailToAll() {
    var exampleRes = this.reservation!.optionalData!.childEvents![0]!.reservationDetails;
    var message = this.generateMessage(exampleRes);
    var subject = this.generateSubject(exampleRes);
    var emails = this.reservation?.optionalData?.childEvents?.map((x: VwReservation) => x.reservationContacts?.find(c => c.contact_type == 'MASTER')?.email).join(";");
    var email = this.company.company_email;
    const url = 'mailto:'+email+'?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(message) + "&bcc=" + emails; // Replace with the URL you want to open
    window.open(url, '_blank');
  }


  formatString(template: string, ...values: any[]): string {
    return template.replace(/{(\d+)}/g, (match, index) => {
      const adjustedIndex = parseInt(index) - 1;
      const value = values[adjustedIndex];
      return value !== undefined ? value : match;
    });
  }

  roomName(roomId: string): string {
    return this.rooms.find(x => x.id === roomId)?.name || '';
  }

  employeeName(employeeId: string): string {
    const ids = employeeId.split(', ');
    const employees = this.employees.filter(e => ids.includes(e.id) && (e?.firstName || e?.lastName));
    if (employees.length === 0) return '';
    return (employees[0].firstName || '') + ' ' + (employees[0].lastName || '') + (employees.length > 1 ? ' + ' + (employees.length - 1) : '');
  }

  getMasterContactName(reservation: VwReservation): string {
    const contact = reservation.reservationContacts?.find(c => c.contact_type === 'MASTER');
    if (!contact) return '';
    return contact.first_name + ' ' + contact.last_name;
  }

  getExperienceName(reservation: VwReservation): string {
    return reservation.experienceTranslations?.find(t => t.translation_id == 'TITLE')?._translations[this.language] || '';
  }

  getLabel1Title(reservation: VwReservation): string {
    return reservation.experienceTranslations?.find(t => t.translation_id == 'TITLE_PRICE_LABEL_0')?._translations[this.language] || '';
  }
}