import { Injectable } from '@angular/core';
import { resource } from "@util/resource";
import * as _ from "lodash";
import { Observable } from "rxjs";
import { DWS } from "@interfaces/dws";
import { OutlookAuthToken } from "@interfaces/dws/Outlook-auth-token";
import { MsalService } from "@azure/msal-angular";

@Injectable({
  providedIn: 'root'
})
export class OutlookAuthService {

  constructor(
      private msalService: MsalService
  ) {
  }

  authorize(): Promise<any> {
    return this.msalService.loginPopup({
      scopes: ['User.Read', 'MailboxSettings.Read', 'Calendars.ReadWrite', 'openid', 'offline_access'],
    }).toPromise();
  }

  list(filters?: OutlookAuthTokenFilters, page: number = 0, count: number = 999999): Observable<DWS.Page<OutlookAuthToken>> {
    return resource('crm://outlook-auth-tokens')
        .params(
            { page, count, ...filters }
        )
        .get<DWS.Page<OutlookAuthToken>>();
  }

  delete(id: string): Observable<void> {
    return resource('crm://outlook-auth-tokens/id')
        .params( { id })
        .delete()
  }

  link(credentials: any, wineryId: string) {
    return resource('crm://outlook-auth-tokens')
        .post(_.merge(credentials, { wineryId }));
  }
}

export interface OutlookAuthTokenFilters {
  wineryId: string;
}