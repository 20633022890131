import { environment } from '@env/environment';

const on = (toggle: string): boolean => {
  const loginStr = localStorage.getItem('winery-saas:login') || '{}';
  let login, companyId;
  try {
    login = JSON.parse(loginStr);
  } catch (e) {
    login = null;
  }
  
  if (login && login.company) companyId = login.company.id;
  if (companyId == 'ac6db286-bd37-42c3-a668-db92cad2a688') return true;
  return (environment.features || {})[toggle];
}

const off = (toggle: string) => !on(toggle);

const feature = { on, off }
export { feature };