import { Injectable } from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import { resource } from '@util/resource';
import { map, tap } from 'rxjs/operators';
import { ManualOrigin, Reservation } from '@interfaces/reservation';
import { compareAsc, formatISO, parse } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class ReservationsService {
  updated$: Subject<void> = new Subject<void>();

  private waitingList: { [reservationId: string]: Subscription } = {}

  constructor() { }

  /**
   * Adds a reservation to a waiting list with a subscription. When
   * the subscription completes or errors, the reservation is removed from the waiting list.
   *
   * Use together with isOnWaitingList method to check if a reservation is being updated.
   * @param reservationId the reservation id that is being updated
   * @param subscription the update subscription that will be listened on
   */
  addToWaitingList(reservationId: string, subscription: Subscription) {
    this.waitingList[reservationId] = subscription;
    subscription.add(() => delete this.waitingList[reservationId])
  }

  /**
   * Returns if a reservation is on the waiting list, added by addToWaitingList
   * @param reservationId the reservation Id to check
   */
  isOnWaitingList(reservationId: string) {
    return this.waitingList.hasOwnProperty(reservationId)
  }


  list(start_reservation: string | Date, end_reservation: string | Date, aasm_state?: any[]): Observable<Reservation[]> {
    if (start_reservation instanceof Date) {
      start_reservation = formatISO(start_reservation, { representation: 'date' });
    }
    if (end_reservation instanceof Date) {
      end_reservation = formatISO(end_reservation, { representation: 'date' });
    }

    if (aasm_state && aasm_state.indexOf('confirmed') > -1) aasm_state.push('completed');

    return resource('v2://host/reservations')
      .params({
        start_reservation, end_reservation, aasm_states: aasm_state ? aasm_state.join(',') : undefined,
        page: '1',
        per_page: '999999'
      })
      .get<{ data: Reservation[] }>()
      .pipe(
        map(response => response.data),
        map(reservations => reservations.sort((a, b) => {
            const dateA = parse(a.start_at, 'yyyy-MM-dd', new Date()),
                  dateB = parse(b.start_at, 'yyyy-MM-dd', new Date());
            return compareAsc(dateA, dateB);
          })
        ),
        tap((reservations: Reservation[]) => reservations.forEach(r => r.type = 'reservation')),
      )
  }

  get(reservationId: string): Observable<Reservation> {
    return resource('v2://host/reservations/id')
      .params({ id: reservationId })
      .get<Reservation>()
      .pipe(tap(r => r.type = 'reservation'));
  }

  byGiftId(giftId: string): Observable<Reservation.Detail> {
    return resource('v2://host/gifts/id/reservations')
        .params({ id: giftId })
        .get<Reservation.Detail>()
        //.pipe(tap(r => r.type = 'reservation'))
        ;
  }

  update(reservationId: string, reservation: Reservation.New): Observable<Reservation> {
    const data = Reservation.serialize(reservation);
    return resource('v2://host/reservations/id')
      .params({ id: reservationId })
      .put(data)
  }

  create(reservation: Reservation.New): Observable<Reservation> {
    const data = Reservation.serialize(reservation);
    return resource('v2://host/reservations')
      .post(data)
  }

  confirm(reservationId: string): Observable<unknown> {
    return resource('v1://host/reservations/id/confirm')
      .params({ id: reservationId })
      .post()
  }

  reject(reservationId: string): Observable<unknown> {
    return resource('v1://host/reservations/id/reject')
      .params({ id: reservationId })
      .post()
  }

  delete(reservationId: string): Observable<void> {
    return resource('v2://host/reservations/id')
      .params({ id: reservationId })
      .delete();
  }

  statistics(contactId: any, contactEmail: any, wineryId: string): Observable<Reservation.Statistic> {
    return resource('crm://reservations/statistics')
      .params({ contactId, wineryId, contactEmail })
      .get<Reservation.Statistic>();
  }

  exportCsv(start_reservation: Date, end_reservation: Date): Observable<Blob> {
    return resource('v2://host/reservations/export-csv')
        .params({
          start_reservation: formatISO(start_reservation, {representation: 'date'}),
          end_reservation: formatISO(end_reservation, {representation: 'date'}),
          page: '1',
          per_page: '999999'
        })
        .get("blob")
  }

  listManualOrigins(): Observable<Reservation.ManualOrigin[]> {
    return resource('v2://manual-origins')
      .get<any[]>()
      .pipe(
        map(arr => {
          return arr.map(e => { return {id: e.id, nameEn: e.name_en, nameIt: e.name_it} as ManualOrigin})
        })
      );
  }

  listPaymentMethods(): Observable<Reservation.ManualPaymentMethod[]> {
    return resource('v2://manual-payment-methods')
        .get<Reservation.ManualPaymentMethod[]>();
  }

  detail(reservationId: string): Observable<Reservation.Detail> {
    return resource("v2://host/reservations/detail/id")
        .params({id: reservationId})
        .get<Reservation.Detail>();
  }
}
