import { Injectable } from '@angular/core';
import { DWS } from '@interfaces/dws';
import { resource } from '@util/resource';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalendarConfigurationService {
  constructor(

  ) { }
  
  get(wineryId: string): Observable<DWS.CalendarConfiguration> {
    return resource('crm://winery/wineryId/calendar-configuration')
        .params({wineryId})
        .get<any>();
  }

  create(wineryId: string,  configuration?: DWS.CalendarConfiguration): Observable<DWS.CalendarConfiguration> {
    return resource('crm://winery/wineryId/calendar-configuration')
        .params({wineryId})
        .post<any>({
          startTime: `${configuration?.startTime}:00`,
          endTime: `${configuration?.endTime}:00`,
        });
  }
}
