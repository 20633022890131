import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  constructor() {
  }
  
  get(companyId: string): Observable<any> {
    return resource('v2://companies/id')
      .params({ id: companyId })
      .get<{ data: any[] }>()
      .pipe(map(res => res?.data?.[0]))
  }

  self(): Observable<any> {
    return resource('v2://host/company/self')
      .get<{ data: any }>()
  }

  loadFromStorage(): any{
    var loginS = localStorage.getItem('winery-saas:login')!;
    var login = JSON.parse(loginS);
    var company = login?.company;
    return company;
  }
}
