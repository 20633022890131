import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';

@Injectable({
  providedIn: 'root'
})
export class WineryOriginsService {

  constructor() {
  }

  list(page: number = 0, count: number = 999999): Observable<DWS.Page<{id: string, name: string}>> {
    return resource('crm://winery-origins')
      .params(
        { page, count }
      )
      .get<DWS.Page<{id: string, name: string}>>();
  }

  create(name: string): Observable<{id: string, name: string}> {
    return resource('crm://winery-origins')
      .post<{id: string, name: string}>({ name });
  }

  listBookingEngineUrls(): Observable<{name: string}[]> {
    return resource('crm://winery-origins/urls')
      .get<{name: string}[]>();
  }
}
