import { Injectable } from '@angular/core';
import { ReservationDetail } from './reservation-detail';
import { Observable, of } from 'rxjs';
import { Reservation } from '@interfaces/reservation';
import { map } from 'rxjs/operators';
import { resource } from "@util/resource";
import { ReservationsService } from '@services/reservations.service';
import { ManualReservationsService } from '../manual-reservations/manual-reservations.service';
import { ManualReservation } from '@interfaces/dws/scheduling/manual-reservation';
import Language = ReservationDetail.Language;
import VisitReason = ReservationDetail.VisitReason;
import ReservationHistoryActionType = ReservationDetail.ReservationHistoryActionType;
import { ReservationLog } from '@interfaces/reservation';
import { ReservationsDwsService } from '@services/dws/reservations-dws.service';
import { AccountsService } from '@services/accounts.service';
import { DWS } from '@interfaces/dws';
import { ReservationDTO } from '@interfaces/dws/reservation';

@Injectable({
  providedIn: 'root'
})
export class ReservationDetailService {
  constructor(
    private reservationServiceRuby: ReservationsService,
    private manualReservationService: ManualReservationsService,
    private javaReservationService: ReservationsDwsService,
    private accountService: AccountsService
  ) {}

  get(reservationId: string, isManual = true, category = 'manual'): Observable<ReservationDetail | undefined> {
    /* return from(this.accountService.getLogin()).pipe( //to do decomment to kill ruby
      switchMap((login) => {
        if (isManual || category === 'integration') {
          return this.manualReservationService.show(reservationId)
            .pipe(map((e: ManualReservation) => {
              return ReservationDetailService.convertManualToReservationDetail(e);
            }));
        }
        return forkJoin([
          this.reservationServiceRuby.detail(reservationId),
          this.javaReservationService.list(login?.company.id!, {reservationId})
        ]).pipe(map(([ruby, java]) => {
            let toReturn = ReservationDetailService.convertToReservationDetail(ruby);
            toReturn.dwsData = java.content[0];
            return toReturn;
          }));
      })
    ); */

    return of(undefined);
    /* if (isManual || category === 'integration') {
      return this.manualReservationService.show(reservationId)
        .pipe(map((e: ManualReservation) => {
          return ReservationDetailService.convertManualToReservationDetail(e);
        }));
    }
    return this.reservationServiceRuby.detail(reservationId)
      .pipe(map((e: Reservation.Detail) => {
        console.debug(e);
        return ReservationDetailService.convertToReservationDetail(e);
      })); */
  }

  getMultipleReservationV2(reservationIds: string[]): Observable<ReservationDetail[]> {
    return resource('crm://reservations/multiple').params({ "ids": reservationIds.join(",") })
      .get();
  }

  rejectReservationV2(reservationId: string): Observable<any> {
    return resource('crm://v2/reservations/id/reject')
      .params({ id: reservationId })
      .put();
  }

  revokeReservationV2(reservationId: string): Observable<any> {
    return resource('crm://v2/reservations/id/revoke')
      .params({ id: reservationId })
      .put();
  }

  cancelReservationV2(reservationId: string): Observable<any> {
    return resource('crm://v2/reservations/id/cancel')
      .params({ id: reservationId })
      .put();
  }

  confirmReservationV2(reservationId: string): Observable<any> {
    return resource('crm://v2/reservations/id/confirm')
      .params({ id: reservationId })
      .put();
  }

  getV2(reservationId: string): Observable<DWS.ReservationDTO> {
   return resource('crm://v2/reservations/id')
          .params({ id: reservationId })
          .get<DWS.ReservationDTO>();
  }
  
  saveV2(reservation: ReservationDTO, openSlots: boolean, closeSlots: boolean): Observable<any> {
   return resource('crm://v2/reservations/id')
          .params({ id: reservation.id || 'NEW' })
          .put<DWS.ReservationDTO>({
            reservation,
            wineryId: reservation.wineryId,
            contactId: reservation.contactId,
            openSlots, 
            closeSlots
          });
  }

  getMultipleReservationV2Query(reservationIds: string[]): Observable<ReservationDetail[]> {
    return resource('crm://reservations/query').post({ "ids": reservationIds });
  }

  update(reservation: ReservationDetail.Update, isManual: Boolean, type: string = 'manual'): Observable<any> {
    const reservationToJava = this.toManualReservation(reservation)
    if (isManual && type === 'integration') {
      return this.manualReservationService.updateIntegration(reservation.id, reservationToJava);
    } else {
      const reservationToRuby = this.toNewReservation(reservation);
      return this.reservationServiceRuby.update(reservation.id!, reservationToRuby);
    }
  }

  delete(reservationId: string, isManual: Boolean, type: string = 'manual'): Observable<unknown> {
    if (isManual && type === 'integration') {
      return this.manualReservationService.deleteIntegration(reservationId);
    } else {
      return this.reservationServiceRuby.delete(reservationId);
    }
  }

  getReservationHistory(reservationId: string, actionType?: ReservationHistoryActionType, details?: Object): Observable<ReservationLog[]> {
    let params:any = { reservationId };
    if (actionType) {
      params.actionType = actionType;
    }
    if (details) {
      params.details = details;
    }
    return resource('crm://scheduling/reservation-history')
      .params(params)
      .get<ReservationLog[]>();
  }

  getAdminReservationHistory(reservationId: string, actionType?: ReservationHistoryActionType, details?: Object): Observable<ReservationLog[]> {
    let params:any = { reservationId };
    if (actionType) {
      params.actionType = actionType;
    }
    if (details) {
      params.details = details;
    }
    return resource('crm://scheduling/reservation-history')
      .params(params)
      .headers({ 'x-admin-authorization': '1' })
      .get<ReservationLog[]>();
  }

  toNewReservation(e: ReservationDetail.Update): Reservation.New {
    const name = e.contact.name.split(' ');
    return {
      adults: e.guestCount01,
      children: e.guestCount02,
      created_with_saas: false,
      email: e.contact.email,
      employee_id: e.employee?.id,
      employee_name: e.employee?.name,
      experience: {
        id: e.experienceId
      },
      firstname: name.shift() || '',
      lang: {
        id: e.language.id,
        name: e.language.nameIt
      },
      lastname: name.join(' '),
      notes: e?.notes,
      pay_at_the_winery: e.payAtTheWinery,
      phone: e.contact.phone,
      room_id: e.room?.id,
      room_name: e.room?.name,
      start_at: e.date,
      total: (e.netTotal * 100).toString(),
      time: e.time,
      visit_reason: e.visitReason ? { id: e.visitReason!.id, name: e.visitReason!.nameIt } : undefined,
      manual_origin: e.manualOriginId ? { id: e.manualOriginId!.toString() } : undefined,
      countryOfResidenceIso: e.countryOfResidenceIso,
      experience_price_labels: e.experiencePriceLabels,
      experience_price_extras: e.experiencePriceExtras
    };
  }

  toManualReservation(e: ReservationDetail.Update): ManualReservation.Update {
    return {
      date: e.date,
      time: e.time,
      guestCount01: e.guestCount01,
      guestCount02: e.guestCount02,
      languageEn: e.language.nameEn,
      languageIt: e.language.nameIt,
      manualPaymentMethodId: e.manualPaymentMethodId,
      netTotal: e.netTotal * 100,
      netTotalCurrency: e.paymentCurrency,
      grossTotal: e.grossTotal * 100,
      grossTotalCurrency: e.paymentCurrency,
      manualDiscountCents: e.discountCents ? (e.discountCents * 100) : undefined,
      manualDiscountCurrency: e.paymentCurrency,
      roomId: e.room?.id,
      roomName: e.room?.name,
      employeeId: e.employee?.id,
      employeeName: e.employee?.name,
      manualOriginId: e.manualOriginId,
      notes: e.notes,
      visitReasonIt: e.visitReason?.nameIt,
      visitReasonEn: e.visitReason?.nameEn,
      countryOfResidenceIso: e.countryOfResidenceIso,
      experiencePriceLabels: e.experiencePriceLabels,
      experiencePriceExtras: e.experiencePriceExtras,
      experience: {
        experienceId: e.experienceId,
        titleIt: e.experienceTitleIt
      }
    }
  }

/*   public static convertManualToReservationDetail(e: ManualReservation.Data): ReservationDetail.Data {
    console.debug(e);
    return {
      id: e.id,
      wineryId: e.wineryId,
      experienceId: e.experienceId,
      contact: e.contact,
      state: e.state,
      date: e.date,
      time: e.time,
      guestCount01: e.guestCount01,
      guestCount02: e.guestCount02,
      notifyContact: e.notifyContact,
      notes: e.notes,
      netTotal: this.toMoney(e.netTotal),
      grossTotal: this.toMoney(e.grossTotal),
      discountCents: this.toMoney(e.discountCents),
      paymentCurrency: e.paymentCurrency,
      language: {
        id: '',
        nameEn: e.languageEn,
        nameIt: e.languageIt,
        iso: '',
        nameDe: e.languageDe
      } as Language,
      room: e.room,
      employee: e.employee,
      manualOriginId: e.manualOriginId,
      manualPaymentMethodId: e.manualPaymentMethodId,
      visitReason: {
        nameEn: e.visitReasonEn,
        nameIt: e.visitReasonIt,
        nameDe: e.visitReasonDe
      } as VisitReason,
      createdWithSaas: e.type !== 'integration',
      payAtTheWinery: true,
      type: e.type,
      createdAt: undefined,
      experiencePriceLabels: e.experiencePriceLabels,
      experiencePriceExtras: e.experiencePriceExtras,
      experience: {
        id: e.experience.id,
        color: e.experience.color,
        duration: e.experience.duration,
        experienceId: e.experience.experienceId,
        lat: e.experience.lat,
        lng: e.experience.lng,
        priceLabel01It: e.experience.priceLabel01It,
        priceLabel01En: e.experience.priceLabel01En,
        priceLabel01De: e.experience.priceLabel01De,
        priceLabel02It: e.experience.priceLabel01It,
        priceLabel02En: e.experience.priceLabel02En,
        priceLabel02De: e.experience.priceLabel01De,
        price: { cents: 0, currency: 'EUR' }, // maybe we should fix
        price02Active: false,
        price02: { cents: 0, currency: 'EUR' }, // maybe we should fix
        eventDates: [],
        titleIt: e.experience.titleIt,
        titleEn: e.experience.titleEn,
        titleDe: e.experience.priceLabel01De,
        type: {
          id: '',
          nameIt: e.experience.typeIt,
          nameEn: e.experience.typeEn,
          nameDe: e.experience.typeDe,
          color: e.experience.color,
          code: ''
        },
        experiencePriceLabels: e.experiencePriceLabels,
        experiencePriceExtras: e.experiencePriceExtras,
        languages: [{ id: '', nameIt: e.languageIt, nameEn: e.languageEn, nameDe: e.languageDe, iso: '' }]

      } as ReservationDetail.Experience
      //TODO
    } as ReservationDetail.Data;
  } */

/*   public static convertAPIToReservationDetail(e: ManualReservation.Data): ReservationDetail.Data {
    console.warn(e)
    return {
      id: e.id,
      wineryId: e.wineryId,
      experienceId: e.experienceId,
      contact: e.contact,
      state: e.state,
      date: e.date,
      time: e.time,
      guestCount01: e.guestCount01,
      guestCount02: e.guestCount02,
      notifyContact: e.notifyContact,
      notes: e.notes,
      netTotal: this.toMoney(e.netTotal),
      grossTotal: this.toMoney(e.grossTotal),
      discountCents: this.toMoney(e.discountCents),
      paymentCurrency: e.paymentCurrency,
      language: {
        id: '',
        nameEn: e.languageEn,
        nameIt: e.languageIt,
        iso: '',
        nameDe: e.languageDe
      } as Language,
      room: e.room,
      employee: e.employee,
      manualOriginId: e.manualOriginId,
      manualPaymentMethodId: e.manualPaymentMethodId,
      visitReason: {
        nameEn: e.visitReasonEn,
        nameIt: e.visitReasonIt,
        nameDe: e.visitReasonDe
      } as VisitReason,
      createdWithSaas: e.type !== 'integration',
      payAtTheWinery: true,
      type: e.type,
      experiencePriceLabels: e.experiencePriceLabels,
      experiencePriceExtras: e.experiencePriceExtras,
      createdAt: e.createdAt,
      experience: {
        id: e.experience.id,
        color: e.experience.color,
        duration: e.experience.duration,
        experienceId: e.experience.experienceId,
        lat: e.experience.lat,
        lng: e.experience.lng,
        priceLabel01It: e.experience.priceLabel01It,
        priceLabel01En: e.experience.priceLabel01En,
        priceLabel01De: e.experience.priceLabel01De,
        priceLabel02It: e.experience.priceLabel01It,
        priceLabel02En: e.experience.priceLabel02En,
        priceLabel02De: e.experience.priceLabel01De,
        price: { cents: 0, currency: 'EUR' }, // maybe we should fix
        price02Active: false,
        price02: { cents: 0, currency: 'EUR' }, // maybe we should fix
        eventDates: [],
        titleIt: e.experience.titleIt,
        titleEn: e.experience.titleEn,
        titleDe: e.experience.priceLabel01De,

        type: {
          id: '',
          nameIt: e.experience.typeIt,
          nameEn: e.experience.typeEn,
          nameDe: e.experience.typeDe,
          color: e.experience.color,
          code: ''
        },
        experiencePriceLabels: e.experiencePriceLabels,
        experiencePriceExtras: e.experiencePriceExtras,
        languages: [{ id: '', nameIt: e.languageIt, nameEn: e.languageEn, nameDe: e.languageDe, iso: '' }],
      } as ReservationDetail.Experience,
      origin: e.origin
      //TODO
    } as ReservationDetail.Data;
  } */

/*   public static convertToReservationDetail(e: Reservation.Detail) {
    //console.debug(e.due_cents)

    return {
      id: e.id,
      contact: {
        name: `${e.firstname} ${e.lastname}`,
        phone: e.phone,
        contactId: undefined,
        email: e.email,
        address: {
          countryIso: e.optional_data?.country?.iso
        }
      },
      date: e.start_at,
      employee: {
        id: e.employee_id,
        name: e.employee_name
      },
      experience: {
        id: e.experience.id,
        color: e.experience.type.color,
        duration: e.experience.interval.toString(),
        experienceId: e.experience.id,
        lat: undefined,
        lng: undefined,
        priceLabel01En: e.experience.price01_label_en,
        priceLabel01It: e.experience.price01_label_it,
        priceLabel01De: e.experience?.price01_label_de,
        priceLabel02En: e.experience?.price02_label_en,
        priceLabel02It: e.experience?.price02_label_it,
        priceLabel02De: e.experience?.price02_label_de,
        price02Active: e.experience?.price02_active,
        price: { ...e.experience?.price },
        price02: { ...e.experience?.price02 },
        eventDates: e.experience?.event_date,
        titleEn: e.experience?.title_en,
        titleIt: e.experience?.title_it,
        titleDe: e.experience?.title_de,
        type: {
          id: e.experience.type.id,
          nameIt: e.experience.type.name_it,
          nameEn: e.experience.type.name_en,
          code: e.experience.type.code,
          color: e.experience.type.color
        }
      },
      grossTotal: ReservationDetailService.toMoney(e.gross_total_cents),
      guestCount01: e.adults,
      guestCount02: e.children,
      language: {
        id: e.language.id,
        nameIt: e.language.name_it,
        nameEn: e.language.name_en,
      },
      discountCents: this.toMoney(e.discount_total_cents),
      manualOriginId: undefined,
      netTotal: this.toMoney(e.net_total_cents),
      due: this.toMoney(e.due_cents),
      paymentCurrency: e.payment_currency,
      notes: e.notes,
      notifyContact: true,
      origin: e.origin,
      paid: e.paid,
      payAtTheWinery: e.pay_at_the_winery,
      room: {
        id: e.room_id,
        name: e.room_name
      },
      time: e.time,
      state: e.aasm_state,
      visitReason: {
        id: e.visit_reason.id,
        nameEn: e.visit_reason.name_en,
        nameIt: e.visit_reason.name_it
      },
      wineryId: undefined,
      couponCode: e.coupon_code,
      message: e.message,
      paymentMethod: e.payment_method,
      shippingAddress: e.shipping_address,
      feedback: e.feedback,
      gift: e.gift_id,
      createdWithSaas: false,
      experiencePriceLabels: e.experience_price_labels,
      experiencePriceExtras: e.experience_price_extras,
    } as ReservationDetail;
  } */

  private static toMoney(value: number) {
    return parseFloat((value / 100).toString().replace(/,/g, ''));
  }
}