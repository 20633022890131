import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import {FlexLayoutModule, FlexModule} from '@angular/flex-layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxMaskModule } from 'ngx-mask';
import { DurationModule } from '@web/app/pipes/duration/duration.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { ConfirmModule } from '@web/app/components/dialogs/confirm';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {TranslateModule} from "@ngx-translate/core";
import { DateIntervalComponent } from "@web/app/components/dialogs/date-interval/date-interval.component";
import { MatRadioModule } from '@angular/material/radio';


@NgModule({
  declarations: [DateIntervalComponent],
  entryComponents: [DateIntervalComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    FlexModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    TranslateModule,
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NgxMaskModule,
    DurationModule,
    MatCheckboxModule,
    MatSelectModule,
    ConfirmModule,
    MatSidenavModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    FlexLayoutModule,
    MatRadioModule
  ]
})
export class DateIntervalModule {}
