import { ButtonConfig, ConfirmComponent } from '@web/app/components/dialogs/confirm/confirm.component';
import { tr } from '@util/tr/tr';
import { inject } from '@util/inject';
import { MatDialog } from '@angular/material/dialog';

export interface ConfirmConfig {
  message: string;
  buttons: {
    yes?: ButtonConfig;
    no?: ButtonConfig
  }
}

const open = async (cfg: ConfirmConfig): Promise<boolean> => {
  const dlg: MatDialog = await inject(MatDialog);
  const ref = dlg.open(ConfirmComponent, {
    data: cfg,
    width: '520px',
    height: 'auto'
  });
  return (await ref.afterClosed().toPromise()) || false;
}

const yesno = async (message: string, yesText: string = 'Yes', noText: string = 'No'): Promise<boolean> => {
  if (yesText === 'Yes') yesText = tr('Yes');
  if (noText === 'No') noText = tr('No');
  return open({
    message,
    buttons: {
      yes: {
        display: true,
        text: yesText
      },
      no: {
        display: true,
        text: noText
      }
    }
  })
}

const yes = async (message: string, yesText: string = 'Yes'): Promise<boolean> => {
  if (yesText === 'Yes') yesText = tr('Yes');
  return open({
    message,
    buttons: {
      yes: {
        display: true,
        text: yesText
      },
      no: {
        display: false,
        text: ''
      }
    }
  })
}

const confirm = { yesno, yes };

export { confirm }


