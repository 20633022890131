import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateDomainModule } from '@web/app/pipes/translate/translate-domain.module';
import { MatBadgeModule } from '@angular/material/badge';
import { MatToolbarModule } from '@angular/material/toolbar';
import { CommonMessagePopupComponent } from './common-message-popup.component';

@NgModule({
  declarations: [CommonMessagePopupComponent],
  exports: [CommonMessagePopupComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    TranslateDomainModule,
    MatBadgeModule,
    MatToolbarModule,
    TranslateModule
  ]
})
export class CommonMessagePopupModule { }
