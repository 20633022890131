import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { from, isObservable, Observable, of } from 'rxjs';
import { DWS } from '@interfaces/dws';
import { observe } from '@web/util/loading/loading';
import { first, map, switchMap } from 'rxjs/operators';

interface PageableService<T> {
  list(...args: any[]): Observable<DWS.Page<T>>;
}

export class ListResolverService<T = any, S extends PageableService<T> = any> implements Resolve<T[]> {
  constructor(private service: S) {
  }
  
  protected args(): any[] | Promise<any[]> | Observable<any[]> {
    return [];
  }
  
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<T[]> {
    if (!this.service) {
      return of([]);
    }
    
    let sourceArgs = this.args(), args$: Observable<any>;
    if (Promise.resolve(sourceArgs) == sourceArgs) args$ = from(sourceArgs);
    else if (isObservable(sourceArgs)) args$ = sourceArgs;
    else args$ = of(sourceArgs);
  
    const obs$ = args$.pipe(first(), switchMap(args => this.service.list(...args)));
    return observe(obs$).pipe(map((data: DWS.Page<T>) => data.content));
  }
}