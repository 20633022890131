import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { AdvancedFilters } from '@interfaces/advanced-filters';
import * as moment from 'moment';
import { Moment } from 'moment';
import { MatDatepicker, MatDatepickerInputEvent } from "@angular/material/datepicker";
import * as _ from 'lodash';
import { PresenceFilterComponent } from "@web/app/components/presence-filter/presence-filter.component";
import { QRCodeReduced } from "@interfaces/dws/qr-code";

const transformArrayIntoObject = (key: String, arr: AdvancedFilters<any>[]) => {
  return arr.reduce((obj: any, item: { [x: string]: any; }) => ({ ...obj, [item[`${key}`]]: [undefined, []] }), {});
}

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements AfterViewInit {
  form: FormGroup | undefined;
  advancedFilter: AdvancedFilters<any>[] = [];

  dates: number[] = [];
  @ViewChild('datepicker', {read: undefined, static: false}) datepicker!: MatDatepicker<any>;
  dateClass = (date: Moment) => {
    return this.dates.indexOf(date.toDate().getTime()) != -1 ? ['selected'] : null;
  }

  shh(a: any) { return a as any };

  compareFn(c1: any, c2: any): boolean {
    if (c1 && c2) {
      if (c1.id && c2.id) return c1.id === c2.id;
      return c1 === c2
    }
    return false;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dlg: MatDialogRef<FilterComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder
  ) {

    if (data.advancedFilterFields) {
      this.advancedFilter = data.advancedFilterFields;
      const fg = data.advancedFilterFields.reduce((group: any, field: AdvancedFilters<any>) => {
        if (field.type === 'currency') group[field.id] = fb.group({ lte: [], gte: [] });
        else if (field.type === 'date-interval') group[field.id] = fb.group({start: [], end: []});
        else if (field.type === 'presence') group[field.type] = fb.group({start: [], end: [], presence: []})
        else group[field.id] = []
        return group;
      }, {} as any)
      this.form = this.fb.group(fg);
    }

    if (data.appliedFilters && Object.values(data.appliedFilters).length) {
      const f = { ...data.appliedFilters };
      /* adjust currency fields because ngx-mask is bugged */
      for (const k of this.advancedFilter) {
        const value = f[k.id];
        if (k.type === 'currency') {
          if (!value) {
            f[k.id] = { gte: null, lte: null };
            continue;
          }
          f[k.id] = {
            gte: parseFloat(value.gte).toFixed(2).replace('.', ','),
            lte: parseFloat(value.lte).toFixed(2).replace('.', ','),
          }
        } else if (k.type === 'date-interval') {
          if (!value) {
            f[k.id] = { start: null, end: null};
            continue;
          }
          f[k.id] = {
            start: value.start,
            end: value.end
          }
        } else if (k.type === 'date' && value) {
          f[k.id] = moment.utc(value)
        } else if (k.type === 'multiselect-date' && value) {
          this.dates = value;
        }  else if (k.type === 'presence' && value) {
          console.warn(this.form?.get(k.type)?.value)
          const presence = !!value.presence && value.presence.length ? value.presence : null
          f[k.type]= {start: value.start, end: value.end, presence}
        }
      }
      this.form?.patchValue(f);
    }
  }

  ngAfterViewInit() {
    if (this.advancedFilter.map(adv => adv.type).includes('multiselect-date') && this.dates.length) {
      setTimeout(() => {
        const appliedDates = _.cloneDeep(this.dates);
        this.dates = [];
        appliedDates.forEach(date => {
          this.datepicker.select(moment(date));
        })
      })
    }
  }

  resetClicked() {
    this.form?.get('presence')?.reset();
    this.form?.reset();
  }

  submitClicked(clear: boolean = false) {
    if (clear) {
      this.form?.get('presence')?.reset()
      this.form?.reset()
    }

    /* adjust currency fields because ngx-mask is bugged */
    const v = this.form?.value;
    for (const k of this.advancedFilter) {
      if (!v[k.id]) continue;
      if (k.type === 'currency') {
        if (v[k.id].gte == null && v[k.id].lte == null) {
          v[k.id] = undefined;
          continue;
        }
        if (typeof v[k.id].gte === 'string') v[k.id].gte = parseFloat(v[k.id].gte.replace(',', '.'))
        if (typeof v[k.id].lte === 'string') v[k.id].lte = parseFloat(v[k.id].lte.replace(',', '.'))
      } else if (k.type === 'date-interval') {
        if (v[k.id].start == null && v[k.id].end == null) {
          v[k.id] = undefined;
          continue;
        }
      } else if (k.type === 'date') {
        if (v[k.id] == null) {
          continue;
        } else if (moment.isMoment(v[k.id])) {
          v[k.id] = v[k.id].format('YYYY-MM-DD');
        } else {
          v[k.id] = moment(v[k.id]).format('YYYY-MM-DD');
        }
      } else if (k.type === 'multiselect-date') {
        v[k.id] = this.dates.map(date => moment(date).format('yyyy-MM-DD'));
        continue;
      }
      if (Array.isArray(v[k.id]) && v[k.id].length == 0) v[k.id] = null;
    }
    this.dlg.close(v);
  }

  closeClicked() {
    this.dlg.close();
  }

  removeDate(date: number, id: any): void {
    const index = this.dates.indexOf(date);

    if (index >= 0) {
      this.dates.splice(index, 1);
      this.datepicker.select(undefined);
    }
    if (this.dates.length) {
      this.form?.get(id)!.setValue(this.dates);
    }
  }

  dateChanged(ev: MatDatepickerInputEvent<any>) {
    if (ev.value) {
      const date = new Date(ev.value).getTime();
      if (this.dates.indexOf(date) == -1) {
        this.dates.push(date);
      }
    }
  }

  openQrModal(form: FormGroup, qrCodes: QRCodeReduced) {
    const dialog = this.dialog.open(PresenceFilterComponent, {
      width: '100%',
      minWidth: 600,
      maxWidth: 650,
      data: {
        qrCodes: qrCodes,
        appliedFilters: form?.value
      }
    })
    dialog.afterClosed().subscribe((data) => {
      if (!data) return;
      const qrChannels = data.presenceFilters.qrCodeChannels;
      const presence = !!qrChannels && qrChannels.length ? qrChannels : null;
      const r = {
        start: data.presenceFilters.startDate,
        end: data.presenceFilters.lastDate,
        presence
      };
      form.patchValue(r);
    })
  }

  chosenPresenceOptions(form: FormGroup) {
    const presenceOptions = form.getRawValue();
    const start = !presenceOptions.start ? "" : moment(presenceOptions.start).format("DD-MM-YYYY");
    const end = !presenceOptions.end ? "" :  moment(presenceOptions.end).format("DD-MM-YYYY");
    const presences = !presenceOptions.presence ? "" : presenceOptions.presence.toString().replaceAll(',', ', ');
    const symbolOrSpace = !!start && !!end && !!presences ? "|" : " "
    return !start && !end && !presences ? "" : `${start} - ${end} ${symbolOrSpace} ${presences}`
  }
}
