import { FormBuilder, Validators } from '@angular/forms';

export namespace Room {

  export interface Data {
    id: string;
    wineryId: string;
    name: string;
    capacity: number;
    color: string;
    capacity_days?: { day: 1 | 2 | 3 | 4 | 5 | 6 | 7; value: number }[];
    createdAt: string;
    updatedAt: string;
    toUpdate?: boolean;
    toDelete?: boolean;
    generatedId?: string;
  }

  export function createFormGroup(fb: FormBuilder, room?: Partial<Room>) {
    const f = fb.group({
      id: [''],
      name: ['', Validators.required],
      capacity: ['', [Validators.required, Validators.min(1)]],
      capacity_days: [[]],
      capacity_1: ['', [Validators.min(1)]],
      capacity_2: ['', [Validators.min(1)]],
      capacity_3: ['', [Validators.min(1)]],
      capacity_4: ['', [Validators.min(1)]],
      capacity_5: ['', [Validators.min(1)]],
      capacity_6: ['', [Validators.min(1)]],
      capacity_7: ['', [Validators.min(1)]],
      color:  ['', [Validators.required]],
      toUpdate: [false],
      toDelete: [false]
    })
    if (room) {
      f.patchValue(room);
    }
    return f;
  }

}

export type Room = Room.Data;
