import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError, TimeoutError } from 'rxjs';
import { AccountsService } from '@services/accounts.service';
import { catchError, first, flatMap, map } from 'rxjs/operators';
import { AuthenticateAdminService } from 'services/dws';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  constructor(
    private accounts: AccountsService,
    private auth: AuthenticateAdminService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.headers.get('x-skip-authorization')) {
      return next.handle(req.clone({
        headers: req.headers.delete('x-skip-authorization')
      }));
    }
    let token$ = this.accounts.authorization$.pipe(
      first(),
      map(auth => auth ? auth.token : null)
    );
    if (req.headers.get('x-admin-authorization')) {
      token$ = this.auth.authorization$.pipe(
        first(),
        map(auth => auth ? auth.access_token : null)
      );
    }
    if (req.headers.get('Authorization') != null) return next.handle(req);
    return token$
      .pipe(
        catchError(e => {
          if (e instanceof TimeoutError) return of(null);
          else return throwError(e);
        }),
        flatMap(token => {
          if (!token) return next.handle(req);
          const r = req.clone({
            setHeaders: {
              'Authorization': token
            }
          })
          return next.handle(r);
        })
      )
  }
}
