import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { route as r } from '@util/route';
import { observe } from '@web/util/loading/loading';
import { DWS } from '@interfaces/dws';
import { FormsService } from '../forms.service';

@Injectable({
    providedIn: 'root'
})
export class FormDraftResolverService implements Resolve<DWS.FormDraft> {
    constructor(private formsService: FormsService) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DWS.FormDraft> {
        const formId = r.access(route).param('formId');
        if (!formId || formId === 'new') return of(null) as any;
        return observe(this.formsService.getDraft(formId))
    }
}
