import { Injectable } from '@angular/core';
import { resource } from '@util/resource';
import { format } from 'date-fns'
import { Observable } from "rxjs";
import { Moment } from 'moment/moment';
import { VwReservation } from '@interfaces/dws/reservation';
import { start } from 'repl';
import * as moment from 'moment/moment';

export interface ExportDataExperienceRequest {
  wineryId: string;
  experienceId: string | undefined;
  startDate: Date;
  endDate: Date;
  startTime?: string;
  endTime?: string;
}

export interface ExportDataExperienceResponse {
  results: ExportDataForExperienceResponse[];
}

export interface ExportDataForExperienceResponse {
  summary: {
    experienceName: string;
    date: Moment;
    time: string;
    labelsTotal: Record<string, number>,
    extrasTotal: Record<string, number>,
    durationSeconds: number;
    totalParticipants: number;
    maxParticipants: number;
    sumNetTotalCents: number;
    language: string;
  },
  reservations: VwReservation[]
}

@Injectable({
  providedIn: 'root'
})
export class ExportDataForExperienceService {
  public fetch(request: ExportDataExperienceRequest): Observable<ExportDataExperienceResponse> {
    return resource('crm://experiences/export')
      .params({
        wineryId: request.wineryId,
        experienceId: request.experienceId,
        startDate: moment(request.startDate).utc(true).toISOString(), endDate: moment(request.endDate).utc(true).toISOString()
      })
      .get<ExportDataExperienceResponse>()
  }
}