import { Injectable } from '@angular/core';
import { resource } from '@util/resource';
import { Observable } from 'rxjs';
import { DWS } from '@interfaces/dws';
import { HttpCacheService } from '@services/http-cache.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DWSTagsService {
  constructor(private cache: HttpCacheService) {

  }

  create(tag: DWS.TagCrm): Observable<DWS.TagCrm> {
    return resource('crm://tags')
      .post<DWS.TagCrm>(tag)
      .pipe(tap(() => this.expire()));
  }

  update(id: number, tag: DWS.TagCrm): Observable<DWS.TagCrm> {
    return resource('crm://tags/id')
      .params({ id })
      .put<DWS.TagCrm>(tag)
      .pipe(tap(() => this.expire()));
  }

  list(filter?: TagsFilter, page: number = 0, count: number = 999999): Observable<DWS.Page<DWS.TagCrm>> {
    return resource('crm://tags')
      //.headers({ 'x-force-cache': '1' })
      .params(
        { page, count, ...filter }
      )
      .get<DWS.Page<DWS.TagCrm>>();
  }

  get(id: number): Observable<DWS.TagCrm> {
    return resource('crm://tags/id')
      .headers({ 'x-force-cache': '1' })
      .params(
        { id }
      )
      .get<DWS.TagCrm>();
  }

  syncWinery(wineryId: string): Observable<DWS.TagCrm> {
    return resource('crm://tags/winery/wineryId')
      .headers({ 'x-force-cache': '1' })
      .params(
        { wineryId }
      )
      .get<DWS.TagCrm>();
  }

  delete(id: number): Observable<void> {
    return resource('crm://tags/id')
      .params({ id })
      .delete()
      .pipe(tap(() => this.expire()));
  }

  private expire() {
    this.cache.clear().toPromise().then(() => 'cache flush');
  }
}

export interface TagsFilter {
  wineryIds: string[];
}
