import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject } from "rxjs";
import { ManualReservationWebSocketService } from "./manual-reservation-ws.service";


class ManualReservationQueueMOJO {
  requestId: string;
  reservationId: string;
  createdAt: Date;
  updatedAt?: Date;
  status: string;

  constructor(requestId: string, reservationId: string, status: string) {
    this.requestId = requestId;
    this.reservationId = reservationId;
    this.status = status;
    this.createdAt = new Date();
    this.updatedAt = new Date();
  }

}

const INTERNAL_STORAGE_REQUEST_MANUAL_KEY = 'manual_reservation_requests_pending';
@Injectable({
  providedIn: 'root'
})
export class ManualReservationRequestsListenerService {
  pendingRequestIds$: BehaviorSubject<ManualReservationQueueMOJO[]> = new BehaviorSubject<ManualReservationQueueMOJO[]>([]);
  constructor() { }

  listPendingRequests(): string[] {
    if (this.hasSomethingPending()) {
      var pendingManualReservationRequestArr = this.listPendingRequestsObj()!.map(request => request.requestId);
      return pendingManualReservationRequestArr;
    } else {
      return [];
    }
  }

  listPendingRequestsObj(): ManualReservationQueueMOJO[] {
    const pendingManualReservationRequestStr = localStorage.getItem(INTERNAL_STORAGE_REQUEST_MANUAL_KEY) as string;
    var objs: ManualReservationQueueMOJO[] = [];
    if (!!!!pendingManualReservationRequestStr && !!!!JSON.parse(pendingManualReservationRequestStr)) {
      try {
        var objs2 = JSON.parse(pendingManualReservationRequestStr) as ManualReservationQueueMOJO[];
        const timeoutRequest = 60000;
        for (const obj of objs2) {
          var requestId = obj.requestId;
          var reservationId = obj.reservationId;
          var createdAt = obj.createdAt;
          var createdAtD = new Date(createdAt);
          if (!((new Date()).getTime() - createdAtD.getTime() > timeoutRequest)) {
            // Still waiting for response
            objs.push(obj);
          }

        }
      } catch (err) {
        console.debug("cannot retrive pending requests");
      }
    } else {
      localStorage.setItem(INTERNAL_STORAGE_REQUEST_MANUAL_KEY, "[]");
      objs = [];
    }

    return objs;
  }

  listenPendingRequests(): Observable<ManualReservationQueueMOJO[]> {
    return this.pendingRequestIds$;
  }

  isRequestPending(id: string): boolean {
    var requestPending = this.listPendingRequestsObj();
    var requestIds = requestPending!.filter(sId => sId.requestId === id);
    return requestIds !== null && requestIds.length > 0;
  }

  addRequestIdToListener(requestId: string, reservationId: string, status: string) {
    var mojo = new ManualReservationQueueMOJO(requestId, reservationId, status);
    this.addIdToListener(mojo);
    console.debug(this.listPendingRequests())
    this.pendingRequestIds$.next(this.listPendingRequestsObj()!)
  }

  removeRequestIdFromListener(id: string) {
    this.removeToListener(id);
    console.debug(this.listPendingRequests())
    this.pendingRequestIds$.next(this.listPendingRequestsObj()!)
  }

  private addIdToListener(obj: ManualReservationQueueMOJO) {
    let pendingReservationsObj: ManualReservationQueueMOJO[] = [];
    if (this.hasSomethingPending()) {
      pendingReservationsObj = this.listPendingRequestsObj()!;
    }
    pendingReservationsObj.push(obj);
    localStorage.setItem(INTERNAL_STORAGE_REQUEST_MANUAL_KEY, JSON.stringify(pendingReservationsObj));
  }

  private removeToListener(requestId: string) {
    if (this.hasSomethingPending()) {
      let pendingReservationsRequestIds = this.listPendingRequestsObj();
      pendingReservationsRequestIds = pendingReservationsRequestIds!.filter(reservationId => reservationId.requestId !== requestId);
      localStorage.setItem(INTERNAL_STORAGE_REQUEST_MANUAL_KEY, JSON.stringify(pendingReservationsRequestIds));
    }
  }

  private hasSomethingPending(): boolean {
    return !!!!this.listPendingRequestsObj();
  }
}
