import { Experience } from '@interfaces/experience';
import { tr } from '@util/tr/tr';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Tag } from '@interfaces/tag';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { format } from 'date-fns';
import { merge as _merge } from 'lodash';
import { Statistic as StatisticData } from '@interfaces/dws/statistic';
import { TranslatableName } from './dws/translatable-name';
import { Translation } from "./translation";
import { ExperiencePriceLabel } from "./experience-price-label";
import { ExperiencePriceExtra } from "./experience-price-extra";

marker('Confirmed');
marker('Rejected');
marker('On hold');
marker('Completed');
marker('Canceled');

export namespace Reservation {

  export interface New {
    adults: number;
    children: number;
    email: string;
    firstname: string;
    lastname: string;
    phone: string;
    total?: string;
    start_at: string;
    time: string;
    created_with_saas: boolean;
    pay_at_the_winery: boolean;
    lang: Tag;
    visit_reason?: Tag;
    room_id?: string;
    room_name?: string;
    employee_id?: string;
    employee_name?: string;
    notes: string;
    experience: {
      id?: string;
    },
    manual_origin?: ManualOrigin;
    manual_discount_cents?: number;
    manual_payment_method?: ManualPaymentMethod;
    notify_contact?: boolean;
    countryOfResidenceIso?: string;
    experience_price_labels: ExperiencePriceLabel[];
    experience_price_extras: ExperiencePriceExtra[];
  }

  export interface Detail {
    id: string;
    aasm_state: string;
    adults: number;
    children: number;
    coupon_code?: string;
    email: string;
    firstname: string;
    lastname: string;
    phone?: string;
    start_at: string;
    time: string;
    paid: boolean;
    payment_method?: string;
    net_total_cents: number;
    due_cents: number;
    gross_total_cents: number;
    discount_total_cents: number;
    payment_currency: string;
    origin: string;
    message: string;
    notes: string;
    pay_at_the_winery: boolean;
    feedback: Feedback;
    gift_id?: string;
    room_id: string;
    room_name: string;
    employee_id: string;
    employee_name: string;
    manual_payment_method_id: number;
    shipping_address: ShippingAddress;
    visit_reason: TranslatableItem;
    language: TranslatableItem;
    experience: ExperienceDetail;
    optional_data?: OptionalData;
    experience_price_labels: ExperiencePriceLabel[];
    experience_price_extras: ExperiencePriceExtra[];
  }

  export interface OptionalData {
    country?: Country,
    birth_date?: string | Date,
    privacy_marketing?: boolean,
    privacy_profiling?: boolean
  }

  export interface Country {
    iso?: string;
    name?: string;
    nameEn?: string;
    nameIt?: string;
  }

  export interface TranslatableItem {
    id: string;
    name_it: string;
    name_en: string
  }

  export interface ExperienceDetail {
    id: string;
    title_it: string;
    title_en: string;
    title_de?: string;
    price01_label_it: string;
    price01_label_en: string;
    price01_label_de?: string;
    price02_active: boolean;
    price02_label_it: string;
    price02_label_en: string;
    price02_label_de?: string;
    price: Money;
    price02: Money;
    event_date: string[];
    interval: number;
    type: ExperienceType;
    experience_price_labels: ExperiencePriceLabel[];
    experience_price_extras: ExperiencePriceExtra[];
  }

  export interface ExperienceType {
    id: string;
    code: string;
    color: string;
    name_it: string;
    name_en: string;
  }

  export interface Money {
    cents: number;
    currency: string;
  }

  interface Coupon {
    id: string;
    discount_percent: number;
    code: string;
  }

  interface ShippingAddress {
    country: TranslatableName,
    addressLine1: string,
    addressLine2: string,
    city: string,
    province: string,
    region: string,
    zipcode: string
  }

  interface Base {
    id: string;
    aasm_state: State;
    adults: number;
    children: number;
    coupon?: Coupon;
    created_at: string;
    email: string;
    firstname: string;
    guest_profile?: any | null;
    lastname: string;
    message?: string;
    phone: string;
    start_at: string;
    tags: Tag[];
    room_id?: string;
    room_name?: string;
    employee_id?: string;
    employee_name?: string;
    time: string;
    updated_at: string;
    experience: Experience;
    order_row: OrderRow;
    order: Order;
    total?: string | number;
    feedback?: Feedback;
    created_with_saas: boolean;
    notes: string;
    type: 'reservation';
    gift?: {
      order: Order
    }
    manual_origin?: ManualOrigin;
    manual_discount_cents?: number;
    manual_payment_method?: ManualPaymentMethod;
    notify_contact?: boolean;
    experience_price_labels: ExperiencePriceLabel[];
    experience_price_extras: ExperiencePriceExtra[];
  }

  type Paid = Base & {
    pay_at_the_winery: false,
    invoice_data: any;
    total_amount: string;
    total_amount_currency: string;
    total_amount_symbol: string;
    shipping_address: ShippingAddress;
  }

  type WineryPayment = Base & {
    pay_at_the_winery: true;
    invoice_data: any;
    total_amount: string;
    total_amount_currency: string;
    total_amount_symbol: string;
    shipping_address: ShippingAddress;
  }

  interface Payment {
    id: string;
    order_id: string;
    intent: string;
    aasm_state: string;
    provider: string;
    account_id: string;
    amount_cents: number;
    amount_currency: string;
  }

  interface Order {
    payments: Payment[]
  }

  interface OrderRow {
    id: string;
    order_id: string;
    orderable_type: string;
    orderable_id: string;
    quantity: number;
    unit: string;
    amount_cents: number;
    amount_currency: string;
    created_at: string;
    updated_at: string;
    origin: string;
    amount_before_discount_cents: number;
  }

  export type Data = Paid | WineryPayment;
  export type State = 'draft' | 'waiting' | 'confirmed' | 'revoked' | 'rejected' | 'completed' | 'canceled';
  export type Type = 'appointment' | 'automatic' | 'integration' | 'manual';

  interface StatusBase {
    cssClass: 'draft' | 'waiting' | 'confirmed' | 'revoked' | 'rejected' | 'completed' | 'canceled';
    icon: string;
    text: string;
    color: string;
  }

  type StatusWaiting = StatusBase & { type: 'waiting' };
  type StatusRejected = StatusBase & { type: 'rejected' };
  type StatusConfirmed = StatusBase & { type: 'confirmed' };
  type StatusRevoked = StatusBase & { type: 'revoked' };
  type StatusDraft = StatusBase & { type: 'draft' };
  type StatusCompleted = StatusBase & { type: 'completed' };
  type StatusCanceled = StatusBase & { type: 'canceled' };
  export type MappedStatus = StatusWaiting | StatusConfirmed | StatusRejected | StatusRevoked | StatusCompleted | StatusCanceled | StatusDraft;


  const statusMapping: { [k: string]: 'draft' | 'waiting' | 'confirmed' | 'revoked' | 'rejected' | 'completed' | 'canceled' } = {
    'draft': 'draft',
    'waiting': 'waiting',
    'confirmed': 'confirmed',
    'revoked': 'revoked',
    'rejected': 'rejected',
    'completed': 'completed',
    'canceled': 'canceled'
  };

  export const mapStatus = (status: string) => {
    return statusMapping[status];
  }

  export const paid = (reservation: Reservation) => {
    if (reservation.gift) {
      return reservation.gift.order.payments.length > 0
    } else return !reservation.pay_at_the_winery || (reservation.order.payments && reservation.order.payments.length > 0)
  }

  export const total = (reservation: Reservation): string => {
    if (reservation.pay_at_the_winery) {
      if (reservation.order_row && reservation.order_row.amount_cents) return (reservation.order_row.amount_cents / 100).toString();
      else return reservation.total_amount?.replace(',', '.');
    }

    let r_total_cents = 0;
    if (reservation.order?.payments?.length) {
      r_total_cents = reservation.order.payments.reduce((v, p) => v + p.amount_cents, 0);
    }

    if (reservation.gift?.order?.payments?.length) {
      r_total_cents += reservation.gift.order.payments.reduce((v, p) => v + p.amount_cents, 0);
    }

    return (r_total_cents / 100).toString();
  }

  export const total_discount = (reservation: Reservation) => {
    let discount = 0;
    if (reservation.manual_discount_cents && reservation.manual_discount_cents > 0) discount = (reservation.manual_discount_cents / 100);
    if (reservation.order_row && reservation.order_row.amount_cents != reservation.order_row.amount_before_discount_cents) {
      discount = ((reservation.order_row.amount_before_discount_cents - reservation.order_row.amount_cents) / 100);
    }
    return discount;
  }

  export const paymentMethod = (reservation: Reservation) => {
    if (reservation.pay_at_the_winery) return 'winery';
    if (reservation.gift?.order?.payments?.length) {
      return reservation.gift.order.payments[0].provider;
    }
    if (reservation.order?.payments?.length) {
      return reservation.order.payments[0].provider;
    }
    if (reservation.coupon?.discount_percent! >= 100) return 'coupon';
    return null;
  }

  const textMapping: { [k: string]: string } = {
    'confirmed': tr('Confirmed'),
    'completed': tr('Completed'),
    'rejected': tr('Rejected'),
    'waiting': tr('On hold'),
    'revoked': tr('Revoked'),
    'draft': tr('Draft'),
    'canceled': tr('Canceleda')
  };

  export const status = (reservation: Reservation | null, override?: 'waiting' | 'draft' | 'confirmed' | 'revoked' | 'rejected' | 'completed' | 'canceled'): MappedStatus => {
    const color = Experience.color;

    const iconMapping: { [k: string]: string } = {
      'waiting': 'circled-etc',
      'draft': 'circled-etc',
      'rejected': 'circled-close',
      'revoked': 'circled-close',
      'confirmed': 'circled-checkmark',
      'completed': 'circled-checkmark',
      'canceled': 'circled-close'
    };

    const status = override || (reservation ? statusMapping[reservation.aasm_state] : false) || 'waiting';

    return {
      type: status,
      cssClass: status,
      icon: iconMapping[status],
      text: textMapping[status],
      color: reservation ? color(reservation.experience, status) : ''
    }
  }

  export const mapText = (status: string) => {
    return textMapping[statusMapping[status]];
  }

  export interface RatingFieldDefinition {
    id: string;
    title: string;
    type: 'rating';
  }

  export interface MultipleChoiceFieldDefinition {
    id: string;
    title: string;
    type: 'multiple_choice';
    choices: {
      id: string;
      label: string;
    }[]
  }

  export interface YesNoFieldDefinition {
    id: string;
    title: string;
    type: 'yes_no'
  }

  export interface LongTextFieldDefinition {
    id: string;
    title: string;
    type: 'long_text'
  }


  export interface TextFieldDefinition {
    id: string;
    title: string;
    type: 'text'
  }

  export type FieldDefinition = TextFieldDefinition |
    LongTextFieldDefinition |
    YesNoFieldDefinition |
    MultipleChoiceFieldDefinition |
    RatingFieldDefinition


  export interface Definition {
    id: string;
    title: string;
    fields: FieldDefinition[];
  }

  export interface NumberAnswer {
    type: 'number';
    number: number;
    field: FieldDefinition;
  }

  export interface ChoiceAnswer {
    type: 'choice';
    field: FieldDefinition;
    choice: {
      label: string;
    };
  }

  export interface BooleanAnswer {
    type: 'boolean';
    boolean: boolean;
    field: FieldDefinition;
  }

  export interface TextAnswer {
    type: 'text';
    text: string;
    field: FieldDefinition;
  }

  export type Answer = TextAnswer |
    BooleanAnswer |
    ChoiceAnswer |
    NumberAnswer;

  export interface Feedback {
    token: string;
    hidden: { [k: string]: string };
    answers: Answer[];
    form_id: string;
    landed_at: Date;
    definition: Definition;
    submitted_at: Date;
  }

  export interface ManualOrigin {
    id: string;
    nameIt?: string;
    nameEn?: string;
  }

  export interface ManualPaymentMethod {
    id: number;
    name: string;
  }

  export type Statistic = StatisticData;

  export const assembleUpdate = (form: FormGroup, experienceId: string, reservation: Reservation): Reservation.New => {
    const {
      adults,
      children,
      total,
      date,
      time,
      lang,
      notes,
      visit_reason,
      room,
      employee,
      created_with_saas,
      manual_origin,
      manual_discount,
      manual_payment_method
    } = form.getRawValue().reservation,
      { email, firstname, lastname, phone } = form.getRawValue().client;
    let dateStr = '';
    if (moment.isMoment(date)) dateStr = (date as Moment).format('YYYY-MM-DD');
    else if (date instanceof Date) dateStr = format(date, 'yyyy-MM-dd');
    else if (moment(date, "YYYY-MM-DD").isValid()) dateStr = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD")
    else dateStr = date;
    const discount = manual_discount ? manual_discount * 100 : 0;

    let partial: Partial<Reservation.New> = {
      start_at: dateStr,
      time,
      room_id: room?.id,
      employee_id: employee?.id,
      manual_payment_method,
      notes
    };

    if (reservation.created_with_saas) {
      partial = {
        ...partial,
        start_at: dateStr,
        time,
        adults: parseInt(adults),
        children: parseInt(children),
        total,
        lang: lang,
        manual_origin,
        manual_payment_method,
        room_id: room?.id,
        employee_id: employee?.id,
        notes,
        manual_discount_cents: discount
      }
    }

    const final: Reservation.New = {
      adults: reservation.adults,
      children: reservation.children,
      created_with_saas: reservation.created_with_saas,
      email: reservation.email.toLowerCase(),
      employee_id: reservation.employee_id,
      experience: { id: experienceId },
      firstname: reservation.firstname,
      lang: reservation.tags.filter(t => t.category?.code === 'spklang')[1] as Tag,
      lastname: reservation.lastname,
      manual_origin: reservation.manual_origin,
      notes: reservation.notes,
      pay_at_the_winery: reservation.pay_at_the_winery,
      phone: reservation.phone,
      room_id: reservation.room_id,
      start_at: reservation.start_at,
      total: reservation.total_amount,
      visit_reason: visit_reason as Tag,
      time: reservation.time,
      experience_price_labels: reservation.experience_price_labels,
      experience_price_extras: reservation.experience_price_extras
    }

    return { ...final, ...partial };
  }

  export const assemble = (form: FormGroup, experienceId: string): Reservation.New => {
    const { adults, children, total, date, time, lang, notes, visit_reason, room, employee, created_with_saas, manual_origin, manual_payment_method, manual_discount, pay_at_the_winery, notify_contact, experience_price_labels, experience_price_extras } = form.getRawValue().reservation,
      { email, firstname, lastname, phone } = form.getRawValue().client;
    let dateStr = '';
    if (moment.isMoment(date)) dateStr = (date as Moment).format('YYYY-MM-DD');
    else if (date instanceof Date) dateStr = format(date, 'yyyy-MM-dd');
    else if (moment(date, "YYYY-MM-DD").isValid()) dateStr = moment(date, "YYYY-MM-DD").format("YYYY-MM-DD")

    else dateStr = date;
    const discount = manual_discount ? manual_discount * 100 : 0;
    return {
      adults: parseInt(adults),
      children: parseInt(children),
      email,
      firstname,
      lastname,
      phone,
      notes,
      total,
      start_at: dateStr,
      time,
      created_with_saas,
      pay_at_the_winery: true,
      lang,
      visit_reason,
      room_id: room.id,
      room_name: room.name,
      employee_id: employee.id,
      employee_name: employee.name,
      experience: {
        id: experienceId
      },
      manual_origin,
      manual_discount_cents: discount,
      manual_payment_method,
      notify_contact,
      experience_price_labels,
      experience_price_extras,
    };
  }

  export const serialize = (reservation: Reservation.New): any => {
    const tags = reservation.lang ? [{ type: 'tag', 'id': reservation.lang.id }] : [];
    if (reservation.visit_reason) tags.push({ type: 'tag', 'id': reservation.visit_reason.id });
    const created_with_saas = reservation.created_with_saas === undefined ? true : reservation.created_with_saas;
    let reservationData = {
      data: {
        attributes: {
          adults: reservation.adults,
          children: reservation.children,
          email: reservation.email?.toLowerCase(),
          firstname: reservation.firstname,
          lastname: reservation.lastname,
          phone: reservation.phone,
          total: reservation.total,
          start_at: reservation.start_at,
          time: reservation.time,
          created_with_saas: created_with_saas,
          notes: reservation.notes,
          room_id: reservation.room_id,
          employee_id: reservation.employee_id,
          manual_origin_id: reservation.manual_origin?.id,
          manual_discount_cents: created_with_saas ? reservation.manual_discount_cents : 0,
          manual_payment_method_id: reservation.manual_payment_method?.id,
          experience_price_labels: reservation.experience_price_labels,
          experience_price_extras: reservation.experience_price_extras,
        },
        relationships: {
          experience: {
            data: {
              type: 'experience',
              id: reservation.experience.id
            }
          },
        },
      },
      type: 'reservation'
    }

    if (created_with_saas) {
      reservationData.data.attributes = _merge(reservationData.data.attributes, { notify_contact: reservation.notify_contact })
      reservationData.data.relationships = _merge(reservationData.data.relationships, { tags: { data: tags } })
    }
    if (reservation.pay_at_the_winery) {
      reservationData.data.attributes = _merge(reservationData.data.attributes, { pay_at_the_winery: reservation.pay_at_the_winery })
    }
    return reservationData;
  }
}

export interface ReservationLog {
  id: number;
  wineryId: string;
  reservationId: string;
  contactId: string;
  automatic: boolean;
  actionType: string;
  details: Object;
  accountId: string;
  updateTimestamp: Date;
  externalEntityTable: string;
  externalEntityPk: string;
}

export type Reservation = Reservation.Data;
export type ManualOrigin = Reservation.ManualOrigin
