import { FormBuilder, Validators } from '@angular/forms';
import {Audit} from "./audit";
import {Winery} from "./winery";

export namespace DiscountCode {
  export enum DiscountTypeEnum {
    percent = 'percent',
    absolute = 'absolute'
  }

  export interface Data extends Audit {
    id?: string;
    code?: string;
    discountPercent?: number;
    active?: boolean;
    discountType?: DiscountTypeEnum;
    discountCents?: number;
    startAt?: Date | string;
    endAt?: Date | string;
    minimumOrderCents?: number;
    useMax?: number;
    useCount?: number;
    winery?: Winery.Data;
  }

  export function createFormGroup(fb: FormBuilder, discountCode?: Partial<Data>) {
    const f = fb.group({
      code: ['', Validators.required],
      discountPercent: [''],
      active: [''],
      discountType: [''],
      discountCents: [''],
      startAt: ['', Validators.required],
      endAt: ['', Validators.required],
      minimumOrderCents: [''],
      useMax: [''],
      useCount: [''],
      winery: [{
        id: ['', Validators.required]
      }]
    })
    if (discountCode)
      f.patchValue(discountCode);
    return f;
  }
}

export type DiscountCode = DiscountCode.Data;
