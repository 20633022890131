import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ga } from "@util/ga";
import { DashboardComponent } from '@web/app/pages/dashboard/dashboard.component';
import { toasty } from "@web/util/toast";
import { VwReservation } from '@interfaces/dws/reservation';
import { Employee } from '@interfaces/dws/employee';
import { Room } from '@interfaces/dws/room';


@Component({
  selector: 'app-grouped-reservations',
  templateUrl: './grouped-reservations.component.html',
  styleUrls: ['./grouped-reservations.component.scss']
})
export class GroupedReservationsComponent implements OnInit {
  reservation?: VwReservation;
  byGift?: boolean;
  returnGift?: boolean;
  dashboardComponent?: DashboardComponent;
  rooms: Room[] = [];
  employees: Employee[] = [];
  
  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dlg: MatDialogRef<GroupedReservationsComponent>,
  ) {
    if (data.reservation) this.reservation = data.reservation;
    if (data.dashboardComponent) this.dashboardComponent = data.dashboardComponent;
    if (data.byGift) this.byGift = data.byGift;
    if (data.employees) this.employees = data.employees;
    if (data.rooms) this.rooms = data.rooms;
  }

  ngOnInit(): void {
    ga.track(`Grouped Reservation ${this.reservation?.id}`);
  }

  closeClicked() {
    this.dlg.close();
  }

  addClicked() {
    this.dashboardComponent?.openReservationDialogWithLoadDataCloned(this.reservation!.id!);
  }

  detailsClicked(reservation: VwReservation) {

    var isPending = this.dashboardComponent?.requestIdIsPending(reservation.id!);
    if (isPending) {
      var mex = this.dashboardComponent?.generatePendingMessage();
      toasty(mex)
        .position('bottom')
        .duration(5000)
        .present();
      return;
    }

    this.dashboardComponent?.openReservationDialogWithLoadData(reservation);
  }

  returnToGift() {
    this.dlg.close({
      returnGift: true
    });
  }
}
