import { Contact } from '@interfaces/dws/contact';

export enum PhoneEnum {
  master = 'master',
  secondary = 'secondary',
  work = 'work'
}

export interface Phone {
  id?: number;
  countryCode?: string;
  phone?: string;
  type?: PhoneEnum;
  contact?: Contact;
}
