import { Tag } from '@interfaces/tag';
import {
  differenceInMinutes,
  formatISO,
  isBefore,
  isWithinInterval,
  parseISO,
  setHours,
  setMinutes,
  subDays
} from 'date-fns';
import { Host } from '@interfaces/host';

export namespace ExperienceAvailability {

  export interface Data {
    id?: string;
    active?: boolean;
    adults_count?: number;
    max_adults: number;
    min_adults: number;
    tags: Tag[];
    time: string;
    week_day: string;
    override_max_adults?: number;
    override_active?: boolean;
    override_tags?: Tag[];
  }

  export interface Override {
    override_time: string;
    override_date: string;
    set_max_adults: number;
    set_active: boolean;
    apply_forward: boolean;
    set_tags: Tag[];
  }

  export interface ForDay {
    day: string;
    availability: ExperienceAvailability[]
  }

  export const maxAdults = (av: ExperienceAvailability): number => {
    return av.override_max_adults !== null ? av.override_max_adults! : av.max_adults;
  }


  export const isDayAvailable = (availabilities: ExperienceAvailabilityForDay[], day: Date, closings?: Host.CompanyClosing[]) => {
    if (isBefore(day, subDays(new Date(), 1))) return false;
    let hoursAvailable = availableTimesFor(availabilities, day);
    let is_closed = false;
    if (closings) {
      is_closed = closings.find((closings) => {
        if (isWithinInterval(
          day,
          { start: parseISO(closings.first), end: parseISO(closings.last)}
        )) return closings;
        return false;
      }) != null;
    }
    return !is_closed && hoursAvailable.length > 0;
  }

  export const availableTimesFor = (availabilitiesForDay: ExperienceAvailabilityForDay[], day: Date) => {
    const dayStr = formatISO(day, { representation: 'date' });
    const availabilityForDay: ExperienceAvailabilityForDay|undefined = availabilitiesForDay.find(a => a.day === dayStr);
    if (!availabilityForDay) return [];
    const timetable = availabilityForDay
      .availability
      .filter(av => {
        if (av.override_active === null) return av.active;
        else return av.override_active;
      })
      .filter(av => av.adults_count! < ExperienceAvailability.maxAdults(av))
      .map(av => av.time);
    return timetable.filter(time => {
      const [ HH, mm ] = time.split(':').map(x => parseInt(x));
      const dayAndTime = setMinutes(setHours(day, HH), mm);
      const diff = differenceInMinutes(dayAndTime, new Date()) / 60;
      return diff > 24;
    })
  }
}

export type ExperienceAvailability = ExperienceAvailability.Data;
export type ExperienceAvailabilityOverride = ExperienceAvailability.Override;
export type ExperienceAvailabilityForDay = ExperienceAvailability.ForDay;

