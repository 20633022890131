import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';
import { SchedulingAdvancedFilters } from "../../../../interfaces/dws/winery-scheduling";
import * as moment from 'moment';
import { ParticipantsDetails } from '@interfaces/dws/scheduling/sale/sale-reservation';
import { Reservation } from '@interfaces/reservation';
import { VwReservation } from '@interfaces/dws/reservation';

@Injectable({
  providedIn: 'root'
})
export class SchedulingService {

  constructor() {}

  list(wineryId: string, filters?: WinerySchedulingFilters, page: number = 0, count: number = 999999, sortBy?: string): Observable<DWS.Page<VwReservation>> {
    if (filters) {
      const startTime = filters?.dateRange?.start || filters?.startTime;
      const endTime = filters?.dateRange?.end || filters?.endTime;

      if (startTime) {
        filters.startTime = moment(startTime).startOf('day').format("YYYY-MM-DD");
      } else {
        filters.startTime = startTime;
      }
      if (endTime) {
        filters.endTime = moment(endTime).endOf('day').format("YYYY-MM-DD");
      } else {
        filters.endTime = endTime;
      }

      delete filters.dateRange;

      if (filters.total) {
        filters.minTotal = filters.total.min;
        filters.maxTotal = filters.total.max;
        delete filters.total;
      }
    }

    return resource('crm://scheduling/page')
        .params(
            { page, count, wineryId, ...filters, sortBy }
        )
        .get<DWS.Page<VwReservation>>();
  }

  getAdvancedFiltersElements(wineryId: string): Observable<SchedulingAdvancedFilters> {
    return resource('crm://scheduling/advanced-filters')
        .params({wineryId})
        .get<SchedulingAdvancedFilters>();
  }

  getPartecipantsDetails(reservationId: string): Observable<ParticipantsDetails[]>{
    return resource('crm://scheduling/get-partecipants-details')
        .params({reservationId})
        .get<ParticipantsDetails[]>();
  }
}

export interface WinerySchedulingFilters {
  reservationId?: string;
  experienceId?: string[];
  fullName?: string;
  adults?: number;
  origin?: string[];
  minTotal?: number;
  maxTotal?: number;
  roomId?: string[];
  employeeId?: string[];
  startTime?: string | Date;
  endTime?: string | Date;
  state?: Reservation.State[];
  lang?: string;
  type?: Reservation.Type[];

  searchText?: string;

  dateRange?: { start: string, end: string };
  total?: { min: number, max: number };
}
